import EQFaqBox from '../../components/EQFaqBox'
//import EQState from '../../services/EQState'
import PageEditorial from '../PageEditorial'

export default class PageServizi extends PageEditorial {
  getPageCode() {
    return 'SERVIZI'
  }

  getChildrenParts() {
    //const pageCode = this.getPageCode()
    //const C = EQState.Contents[pageCode]

    return (
      <div>
        {/*
            <div className="description2">
                <p dangerouslySetInnerHTML={{__html: this.cc("DESCRIPTION2", "Contenuto")}}></p>
            </div>
            */}
        <EQFaqBox
          category={'ServiziWhite'}
          isItWhite={true}
          showTitle={false}
          areThereOtherQuestions={false}
          showSeparator={false}
        />
        <EQFaqBox category={'Servizi'} />
      </div>
    )
  }
}
