import EQFaqBox from '../../components/EQFaqBox'
import PageEditorial from '../PageEditorial'

export default class PageFaq extends PageEditorial {
  getPageCode() {
    return 'FAQ'
  }

  getChildrenParts() {
    let faq_faq = 'Faq'
    return (
      <EQFaqBox
        category={faq_faq}
        showTitle={false}
      />
    )
  }
}
