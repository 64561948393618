import { Box, Grid } from '@mui/material'

import EQHeader from '../EQHeader'
import EQLoading from '../EQLoading'
import EQService from '../../services/EQService'
import EQState from '../../services/EQState'
import Pages from '../../services/Pages'
import React from 'react'
import ReplayIcon from '@mui/icons-material/Replay'
import { withRouter } from 'react-router-dom'

class EQBookStep_Error extends React.Component {
  componentDidMount() {
    if (!EQState.Booking) {
      document.location.href = '/'
      return
    }
    const page = Pages.current()
    document.title = page.title
    window.scrollTo(0, 0)

    EQService.mapContents('HOME').then((m) => {
      EQState.change(EQState.Contents, {
        HOME: m,
      })
      this.forceUpdate()
    })
  }

  retry() {
    EQState.change(EQState.UserSelection, {
      BookPage: `/book/retry-pay`,
    })
    this.props.history.push({
      pathname: `/book/retry-pay`,
    })
  }

  render() {
    if (!EQState.Booking || !EQState.Booking.BookingResult) {
      return null
    }

    if (!EQState.Contents.HOME) {
      return <EQLoading message="Verifica pagamento..." />
    }

    const MC = EQState.Contents.HOME
    let booking = EQState.Booking.BookingResult
    //let pname = booking.paziente.nome + ' ' + booking.paziente.cognome

    return (
      <div className="home">
        <EQHeader></EQHeader>
        <Box mt={7}>
          <div className="header">
            <img
              src={MC.VISUAL.urlImmagine}
              alt={MC.VISUAL.SottoTitolo}
              title={MC.VISUAL.SottoTitolo}
            />
          </div>
          <div className="logo">
            <img
              src="/img/logo_big.svg"
              alt="Logo EqueCure"
            />
          </div>
        </Box>
        <Box mt={0}>
          <p className="playFair normalWeight textCenter bookstep-start-label">
            <b className="text-danger">Si &egrave; verificato un errore.</b>
            <br />
            Contatta il supporto tecnico di EQUECURE.
            <br />
          </p>

          <Grid container>
            <Grid
              item
              xs={6}>
              <div className="codice-prenotazione">
                <div className="label">IL TUO CODICE DI PRENOTAZIONE</div>
                <div className="code">{booking.codicePratica}</div>
              </div>
            </Grid>
            <Grid
              item
              xs={6}>
              <div className="codice-prenotazione">
                <div className="label">CODICE ERRORE</div>
                <div className="code">ERR_PAY</div>
              </div>
            </Grid>
          </Grid>

          <Box
            mr={3}
            ml={3}
            mb={3}
            mt={4}>
            <p className="textCenter">
              Puoi provare ad eseguire nuovamente la transazione di pagamento
            </p>
            <a
              className="buttonLink"
              style={{ backgroundColor: '#0082B1' }}
              onClick={() => this.retry()}>
              <ReplayIcon
                style={{ color: 'white', position: 'relative', top: '6px' }}
              />
              &nbsp;RIPROVA
            </a>
          </Box>
        </Box>
      </div>
    )
  }
}

export default withRouter(EQBookStep_Error)
