import React from 'react'
import { Box, Modal, Typography } from '@mui/material'
import EQService from '../services/EQService'
import CloseIcon from '@mui/icons-material/Close'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  width: '86%',
  bgcolor: 'background.paper',
  border: '0',
  boxShadow: 24,
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  outline: 'none',
}

export default class EQHelpModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      content: null,
    }
  }

  componentDidMount() {
    EQService.getContent(this.props.code).then((m) => {
      this.setState({ content: m })
    })
  }

  closeModal() {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  render() {
    if (!this.state.content) return null
    let cssClass = 'color-' + this.props.color
    const MC = this.state.content
    return (
      <Modal
        open={true}
        className={cssClass}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box
          sx={style}
          className="helpModal">
          <button
            onClick={() => this.closeModal()}
            className="iconClose clickable">
            <Box>
              <CloseIcon></CloseIcon>
            </Box>
            <div>CHIUDI</div>
          </button>
          <Box
            mt={2}
            sx={{ textAlign: 'center' }}>
            {MC.urlImmagine ? (
              <img
                className="circularImg"
                src={MC.urlImmagine}
                alt={MC.SottoTitolo}
                title={MC.SottoTitolo}
              />
            ) : (
              <img
                className="circularImg"
                src="/img/Medico.png"
                alt={MC.SottoTitolo}
                title={MC.SottoTitolo}
              />
            )}
          </Box>
          <Box p={2}>
            <Typography
              className="playFair"
              id="modal-modal-title"
              variant="h5"
              component="h2">
              {MC.SottoTitolo}
            </Typography>
            <Typography
              dangerouslySetInnerHTML={{ __html: MC.Contenuto }}
              id="modal-modal-description"
              sx={{ mt: 2, overflow: 'auto' }}></Typography>

            <Box sx={{ textAlign: 'center' }}>
              <small>
                <a
                  className="linkunderlined"
                  onClick={() => this.closeModal()}>
                  Chiudi finestra
                </a>
              </small>
            </Box>
          </Box>
        </Box>
      </Modal>
    )
  }
}
