import React, { Fragment } from 'react'

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import Box from '@mui/material/Box'
import CloseIcon from '@mui/icons-material/Close'
import Drawer from '@mui/material/Drawer'
import EQCalendarStates from '../../libs/EQCalendarStates'
import EQService from '../../services/EQService'
import EQState from '../../services/EQState'
import MenuItem from '@mui/material/MenuItem'
import { withRouter } from 'react-router-dom'

class EQCaregiverMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showLogin: false,
      CaregiverHome: EQState.CaregiverHome,
      open: false,
    }
  }

  componentDidMount() {
    EQState.addListener(EQState.CaregiverHome, this)
  }

  componentWillUnmount() {
    EQState.removeListener(EQState.CaregiverHome, this)
  }

  handleClick() {
    this.setState({ open: true })
  }

  handleClose() {
    this.setState({ open: false })
  }

  showLogin() {
    this.setState({ showLogin: true })
  }

  closeLogin() {
    this.setState({ showLogin: null })
  }

  doLogout() {
    EQService.creaSessione().then((x) => {
      EQState.change(
        EQState.UserSelection,
        {
          IsLogged: false,
          _rememberMe: false,
          isJollyWorking: false,
        },
        null,
        true
      )
      EQState.reset(EQState.CaregiverHome)
      document.location.href = '/'
    })
  }

  gotoPage(p) {
    EQState.change(EQState.CaregiverHome, {
      _selectedEvent: null,
    })
    document.location.href = p
  }

  call() {
    EQService.phoneCallBy('caregiver')
  }

  render() {
    if (document.location.href.indexOf('/caregiver/login') >= 0) {
      return null
    }

    let prof = EQState.CaregiverHome.Professionista
    let st = { visibility: 'hidden' }
    let daConfermare = []

    if (prof === null) {
      EQState.retrieve(EQState.CaregiverHome)
    }
    prof = EQState.CaregiverHome.Professionista
    if (prof === null) {
      return null
    }

    if (EQState.CaregiverHome._daConfermare) {
      EQState.CaregiverHome._daConfermare.forEach((c) => {
        if (!EQCalendarStates.isToBeConfirmed(c)) return
        daConfermare.push(c)
      })
    }

    if (daConfermare.length > 0) st = { visibility: 'visible' }

    return (
      <Fragment>
        <Box
          className="accountMenu logged"
          sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
          <AccountCircleOutlinedIcon
            onClick={() => this.handleClick()}></AccountCircleOutlinedIcon>
        </Box>

        <Drawer
          id="accountMenu"
          anchor="right"
          variant="temporary"
          open={this.state.open}
          onClose={() => this.handleClose()}
          sx={{ width: '90%' }}>
          <Box
            className="accountMenuContainer"
            sx={{ backgroundColor: '#0a6aa1' }}>
            <Box className="triangleContainer">
              <div
                className="caregiver-alert-badge"
                style={st}
                onClick={() => this.showToBeConfirmed()}>
                {daConfermare.length}
              </div>
            </Box>

            <Box sx={{ textAlign: 'right' }}>
              <button
                onClick={() => this.handleClose()}
                className="iconClose clickable">
                <Box
                  mt={1}
                  mr={1}>
                  <CloseIcon></CloseIcon>
                </Box>
                <div>CHIUDI</div>
              </button>
            </Box>
            <Box
              className="menuItems"
              mb={5}
              mt={5}>
              <MenuItem>
                Ciao&nbsp;
                <span style={{ textTransform: 'capitalize' }}>
                  {prof.user.userName}
                </span>
                ,
              </MenuItem>
              {EQState.CaregiverHome &&
                EQState.CaregiverHome._daConfermare &&
                EQState.CaregiverHome._daConfermare.length > 0 && (
                  <MenuItem>
                    <a
                      onClick={() => this.gotoPage('/caregiver/toBeConfirmed')}
                      className="menu-item">
                      Richieste in attesa_
                    </a>
                  </MenuItem>
                )}
              <MenuItem>
                <a
                  onClick={() => this.gotoPage('/caregiver/calendar')}
                  className="menu-item">
                  Il mio calendario_
                </a>
              </MenuItem>
              <MenuItem>
                <a
                  onClick={() => this.gotoPage('/caregiver/counters')}
                  className="menu-item">
                  I miei numeri_
                </a>
              </MenuItem>
              <MenuItem>
                <a
                  onClick={() => this.gotoPage('/caregiver/account')}
                  className="menu-item">
                  Il mio profilo_
                </a>
              </MenuItem>
              <MenuItem>
                <a
                  href="#"
                  onClick={() => this.doLogout()}>
                  Logout
                </a>
              </MenuItem>
            </Box>
            <Box
              sx={{
                display: 'grid',
                gap: 1,
                gridTemplateColumns: 'repeat(2, 1fr)',
              }}
              mt={12}
              pr={2}
              pt={5}
              pb={5}>
              <Box
                className="privacyItems"
                pl={4}
                sx={{ textAlign: 'left' }}>
                <div>
                  <a href="/privacy">Privacy policy</a>
                </div>
                <div>
                  <a href="/condizioni">Condizioni d'uso</a>
                </div>
                <div>
                  <a href="/cookie-policy">Cookie policy</a>
                </div>
              </Box>
              <Box>
                <div className="servclienti">
                  <img
                    onClick={this.call}
                    src="/img/telefono-caregiver.svg"
                    className="svg-style cursor-pointer"
                    alt="telefono"
                  />
                </div>
              </Box>
            </Box>
          </Box>
        </Drawer>
      </Fragment>
    )
  }
}

export default withRouter(EQCaregiverMenu)
