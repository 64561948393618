import './PagePrivacy.scss'

//import EQState from '../../services/EQState'
import PageEditorial from '../PageEditorial'

export default class PagePrivacy extends PageEditorial {
  getPageCode() {
    return 'PRIVACY'
  }

  getChildrenParts() {
    //const pageCode = this.getPageCode()
    //const C = EQState.Contents[pageCode]
    return (
      <div>
        <div className="description2">
          <div className="title">{this.cc('DESCRIPTION2', 'Titolo')}</div>
          <div
            dangerouslySetInnerHTML={{
              __html: this.cc('DESCRIPTION2', 'Contenuto'),
            }}></div>
        </div>
      </div>
    )
  }
}
