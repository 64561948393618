import { Box } from '@mui/system'
import React from 'react'
import EQService from '../../services/EQService'
import EQState from '../../services/EQState'
import EQFavCaregiverCard from '../core/EQFavCaregiverCard'
import EQFaqBox from '../EQFaqBox'

export default class EQMyFavCaregivers extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      professionisti: null,
    }
    EQState.UserSelection.map_tipiProfessionista = null
    EQState.UserSelection.map_cooperative = null
  }

  componentDidMount() {
    EQService.getProfessionistiPreferiti().then((pps) => {
      this.setState({ professionisti: pps })
      EQState.change(EQState.UserSelection, {
        PreferredCaregivers: pps,
        PreferredCaregivers_Map: pps.map((x) => x.idProfessionista),
      })
    })

    EQService.getTipiProfessionista().then((x) => {
      EQState.UserSelection.map_tipiProfessionista = {}
      x.forEach(
        (x) =>
          (EQState.UserSelection.map_tipiProfessionista[
            x.idTipoProfessionista
          ] = x)
      )
      this.forceUpdate()
    })

    EQService.getCooperative().then((x) => {
      EQState.UserSelection.map_cooperative = {}
      x.forEach(
        (x) => (EQState.UserSelection.map_cooperative[x.idCooperativa] = x)
      )
      this.forceUpdate()
    })
  }

  // getProfessionistiPreferiti
  render() {
    return (
      <Box>
        <div className="description">
          <span>
            Qui puoi trovare i professionisti ai quali hai assegnato il cuore.
          </span>

          {this.state.professionisti &&
            this.state.professionisti.map((c) => {
              return (
                <EQFavCaregiverCard
                  style={{ marginTop: '16px', marginBottom: '16px' }}
                  caregiver={c}
                  key={c.idProfessionista}
                  handleGoForward={() => this.goForward()}
                />
              )
            })}
        </div>
        <Box>
          <EQFaqBox category="Preferiti"></EQFaqBox>
        </Box>
      </Box>
    )
  }
}
