/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable react/jsx-pascal-case */

import { Box, Container } from '@mui/material'
import React, { Fragment } from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import EQCalendarStates from '../libs/EQCalendarStates'
import EQCaregiver_Account from '../components/caregiver/EQCaregiver_Account'
import { EQCaregiver_Home } from '../components/caregiver/EQCaregiver_Calendar'
import EQCaregiver_Login from '../components/caregiver/EQCaregiver_Login'
import { EQCaregiver_Numbers } from '../components/caregiver/EQCaregiver_Numbers'
import { EQCaregiver_ToBeConfirmed } from '../components/caregiver/EQCaregiver_ToBeConfirmed'
import EQEventModal from '../components/caregiver/EQEventModal'
import EQFooter from '../components/EQFooter'
import EQHeaderCaregiver from '../components/caregiver/EQHeaderCaregiver'
import EQLoading from '../components/EQLoading'
import EQNextEventAlert from '../components/core/EQNextEventAlert'
import EQServiceCaregiver from '../services/EQServiceCaregiver'
import EQState from '../services/EQState'
import Formats from '../libs/Formats'
import { MuiThemeProvider } from 'material-ui/styles'

let tout = null

export default class PageCaregiver extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sessionValidated: false,
      CaregiverHome: EQState.retrieve(EQState.CaregiverHome),
      prossimoServizio: null,
      showFooter: false,
    }
  }

  reloadState() {
    if (EQState.CaregiverHome._justLogged) {
      EQState.CaregiverHome._justLogged = false
      this.loadNewRequests()
      this.loadNextEvent()
    } else {
      this.forceUpdate()
    }
    if (EQState.CaregiverHome._selectedEvent) {
      window.scrollTo(0, 0)
    }
  }

  isSessionReady() {
    if (!EQState.CaregiverHome) {
      EQState.retrieve(EQState.CaregiverHome)
    }

    let sessionReady = true
    if (!EQState.CaregiverHome) {
      console.warn('Session is not ready in caregiver page')
      sessionReady = false
    }
    return sessionReady
  }

  scheduleRefresh() {
    //console.log("Scheduling refresh...");
    tout = setTimeout(() => {
      this.doRefresh()
    }, 1000 * 60)
  }

  doRefresh() {
    //console.log("Refreshing...");
    this.loadNextEvent()
    this.loadNewRequests()
    this.scheduleRefresh()
  }

  componentDidMount() {
    if (!this.isSessionReady()) {
      if (document.location.pathname.indexOf('login') <= 0)
        document.location.href = '/caregiver/login'
      return
    }

    EQState.addListener(EQState.CaregiverHome, this)

    // verifichiamo se session è valida
    EQServiceCaregiver.checkSession()
      .then((ll) => {
        window.scrollTo(0, 0)
        this.state.sessionValidated = true

        this.doRefresh()

        // Visualizziamo il footer dopo un po', così evitiamo uno sfarfallio
        setTimeout(() => {
          console.log('showing footer...')
          this.setState({
            showFooter: true,
          })
        }, 1000)
      })
      .catch((e) => {
        console.warn('Sessione CareGiver non valida in PageCaregiver')
        if (document.location.pathname.indexOf('login') <= 0) {
          document.location.href = '/caregiver/login'
        } else {
          this.setState({
            sessionValidated: true,
          })
        }
      })
  }

  componentWillUnmount() {
    EQState.removeListener(EQState.CaregiverHome, this)
    if (tout) {
      clearTimeout(tout)
      tout = null
    }
  }

  loadNewRequests() {
    EQServiceCaregiver.listAppuntamentiDaConfermare().then((ll) => {
      let daConfermare = []
      ll.data.forEach((e) => {
        if (!EQCalendarStates.isToBeConfirmed(e)) return
        let canAccept = false
        let dataLimite = e.dataLimiteConfermaProfessionista
        // TEST dataLimite="2022-04-23T17:48:00Z";
        if (dataLimite) {
          let limitDate = Formats.fromServerDate(dataLimite)
          let lp = Formats.formatDateSpan(new Date(), limitDate, 'accept')
          if (lp !== null) {
            canAccept = true
          }
        }
        if (canAccept) {
          daConfermare.push(e)
        }
      })

      EQState.change(EQState.CaregiverHome, {
        _daConfermare: daConfermare,
      })
    })
  }

  loadNextEvent() {
    let old_prossimoServizio = this.state.prossimoServizio
    let now = new Date()
    // TEST TODO now=new Date(2022,5,8,7,0,0); //////////////////////////////////////////////
    let recentSpan = new Date()
    let prossimoServizio = null
    recentSpan.setHours(recentSpan.getHours() + 2)
    EQServiceCaregiver.listAppuntamenti(now, recentSpan).then((ll) => {
      console.log('next to come', ll.data)
      ll.data.forEach((c) => {
        if (prossimoServizio !== null) return // già trovato
        if (
          c.tipoOccupazioneCalendario.idTipoOccupazione !==
            EQCalendarStates.ServizioConfermatoDalProfessionista &&
          c.tipoOccupazioneCalendario.idTipoOccupazione !==
            EQCalendarStates.ServizioIniziato
        ) {
          return // non interessante qui
        }
        prossimoServizio = c
      })

      console.log('prossimoServizio', prossimoServizio)

      // TEST-TODO
      /*
            if(prossimoServizio==null && this.state.calendario!=null) {
                if(this.state.calendario.length>0) {
                    prossimoServizio=this.state.calendario[0];
                    console.error("faking prossimoServizio",prossimoServizio);
                }
            }
            */

      let toUpdate = false
      if (old_prossimoServizio === null && prossimoServizio !== null)
        toUpdate = true
      else if (old_prossimoServizio !== null && prossimoServizio === null)
        toUpdate = true
      else if (
        old_prossimoServizio !== null &&
        prossimoServizio !== null &&
        old_prossimoServizio.idCalendario !== prossimoServizio.idCalendario
      )
        toUpdate = true
      if (toUpdate) {
        this.setState({
          prossimoServizio: prossimoServizio,
        })
      }
    })
  }

  showProssimoServizio() {
    this.showEvent(this.state.prossimoServizio)
  }

  showEvent(e) {
    EQState.change(EQState.CaregiverHome, {
      _selectedEvent: e,
    })
  }

  closeEvent() {
    EQState.change(EQState.CaregiverHome, {
      _selectedEvent: null,
    })
  }

  render() {
    if (!this.state.sessionValidated) {
      return (
        <Box mt={3}>
          <EQLoading />
        </Box>
      )
    }

    let pageStyle = {}
    if (EQState.CaregiverHome._selectedEvent) {
      pageStyle = { display: 'none' }
    }
    return (
      <MuiThemeProvider>
        <Fragment>
          <EQNextEventAlert
            selectedEvent={this.state.prossimoServizio}
            mode="caregiver"
          />
          {EQState.CaregiverHome._selectedEvent && (
            <EQEventModal
              onClose={() => this.closeEvent()}
              selectedEvent={EQState.CaregiverHome._selectedEvent}
            />
          )}
          <div className="caregiver-home">
            <Box className="main-content">
              <EQHeaderCaregiver></EQHeaderCaregiver>
              <Container className="container">
                <div style={pageStyle}>
                  <Router>
                    <Switch>
                      <Route path="/caregiver/login">
                        <EQCaregiver_Login />
                      </Route>
                      <Route path="/caregiver/calendar">
                        <EQCaregiver_Home />
                      </Route>
                      <Route path="/caregiver/account">
                        <EQCaregiver_Account />
                      </Route>
                      <Route path="/caregiver/counters">
                        <EQCaregiver_Numbers />
                      </Route>
                      <Route path="/caregiver/toBeConfirmed">
                        <EQCaregiver_ToBeConfirmed />
                      </Route>
                    </Switch>
                  </Router>
                </div>
              </Container>
            </Box>
            <div style={pageStyle}>
              {this.state.showFooter && <EQFooter></EQFooter>}
            </div>
          </div>
        </Fragment>
      </MuiThemeProvider>
    )
  }
}
