import React from 'react'

export default class EQFloatingFormIcon extends React.Component {
  render() {
    return (
      <div
        className={`floating-form-icon ${
          this.props.extra ? this.props.extra : ''
        }`}>
        <div>{this.props.children}</div>
      </div>
    )
  }
}
