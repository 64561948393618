import { Checkbox } from '@mui/material'
import React, { Fragment } from 'react'
import CheckBoxOutlineBlankTwoToneIcon from '@mui/icons-material/CheckBoxOutlineBlankTwoTone'
import CheckBoxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone'

export default class EQSquareCheckbox extends React.Component {
  constructor(props) {
    super(props)
  }

  //                     icon={<SquareIcon className="cbWhiteSquare"/>} >

  render() {
    const { ...others } = this.props
    return (
      <Fragment>
        <Checkbox
          className="eq-square-checkbox"
          {...others}
          icon={<CheckBoxOutlineBlankTwoToneIcon />}
          checkedIcon={<CheckBoxTwoToneIcon />}
        />
      </Fragment>
    )
  }
}
