import React, { Fragment } from 'react'
import { Box, Grid, Modal } from '@mui/material'
import EQServiceCaregiver from '../../services/EQServiceCaregiver'
import Utils from '../../libs/Utils'
import EQState from '../../services/EQState'
import EQProfTypes from '../../libs/EQProfTypes'
import EQSelect from '../core/EQSelect'
import { MenuItem } from 'material-ui'
import TextField from '@mui/material/TextField'
import { MobileDatePicker } from '@mui/lab'
import DateAdapter from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import it from 'date-fns/locale/it'
import TimePicker from '@mui/lab/TimePicker'
import Formats from '../../libs/Formats'
import InfoIcon from '@mui/icons-material/Info'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  width: '80%',
  bgcolor: 'background.paper',
  border: '0',
  boxShadow: 24,
  p: 2,
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
}

export default class EQGotoDay extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      stage: 0,
      selectedDate: new Date(),
    }
  }

  closeModal(withChange) {
    if (this.props.onClose) {
      if (withChange) this.props.onClose(this.state.selectedDate)
      else this.props.onClose(null)
    }
  }

  cancelOperation() {
    this.closeModal()
  }

  changeDate() {
    this.closeModal(true)
  }

  handleDayChange(newValue, key) {
    let st = {}
    st[key] = newValue
    this.setState(st)
  }

  render() {
    return (
      <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box
            mt={0}
            p={2}>
            <div className="textCenter playFair app-text modal-title">
              Seleziona data
            </div>

            <LocalizationProvider
              dateAdapter={DateAdapter}
              locale={it}>
              <form>
                <Box
                  xs={12}
                  mt={2}>
                  <MobileDatePicker
                    disabled={false}
                    label="Dal"
                    value={this.state.selectedDate}
                    onChange={(v) => this.handleDayChange(v, 'selectedDate')}
                    renderInput={(params) => (
                      <TextField
                        style={{ width: '100%' }}
                        {...params}
                      />
                    )}
                    inputFormat="dd/MM/yyyy"
                  />
                </Box>
              </form>
            </LocalizationProvider>

            <Grid
              container
              mt={2}
              mb={2}>
              <Grid
                item
                sx={{ textAlign: 'left' }}
                xs={6}>
                <a
                  className="buttonLink lnkForward"
                  onClick={() => this.cancelOperation()}>
                  ANNULLA
                </a>
              </Grid>
              <Grid
                item
                sx={{ textAlign: 'right' }}
                xs={6}>
                <a
                  className="buttonLink lnkForward"
                  onClick={() => this.changeDate()}>
                  OK
                </a>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    )
  }
}
