import React, { Fragment } from 'react'

import { Box } from '@mui/system'
import CaregiverIcon from '@mui/icons-material/AccessibilityNewTwoTone'
import EQCalendarStates from '../../libs/EQCalendarStates'
import EQConfirmModal from './EQConfirmModal'
import EQEventHelper from '../../libs/EQEventHelper'
import EQRefuseModal from './EQRefuseModal'
import EQState from '../../services/EQState'
import EventIcon from '@mui/icons-material/CalendarMonthTwoTone'
import Formats from '../../libs/Formats'
import { Grid } from '@mui/material'

export default class EQEventList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      appuntamenti: this.props.events,
    }
  }

  openDetail(book) {
    EQState.change(EQState.CaregiverHome, {
      _selectedEvent: book,
    })
  }

  refuseEvent(a) {
    this.setState({
      selectedEvent: a,
      refuse_open: true,
    })
  }

  cancelRefuseEvent() {
    this.setState({
      selectedEvent: null,
      refuse_open: false,
    })
  }

  acceptEvent(a) {
    this.setState({
      selectedEvent: a,
      accept_open: true,
    })
  }

  cancelAcceptEvent() {
    this.setState({
      selectedEvent: null,
      accept_open: false,
    })
  }

  render() {
    return (
      <Box
        my={4}
        mx={2}>
        {this.state.appuntamenti &&
          this.state.appuntamenti.map((a) => {
            const estate = a.tipoOccupazioneCalendario.idTipoOccupazione

            let isRefused = false
            if (
              estate === EQCalendarStates.ServizioRifiutatoDalProfessionista
            ) {
              isRefused = true
            }

            let isNear = false
            let canAccept = false
            console.log('CanAccept', canAccept)
            let statePresentation = a.tipoOccupazioneCalendario.nome
            if (
              estate ==
              EQCalendarStates.ServizioInAttesaDiConfermaProfessionista
            ) {
              let dataLimite = a.dataLimiteConfermaProfessionista
              // TEST-TODO dataLimite="2022-05-02T21:55:00Z";
              if (dataLimite) {
                let limitDate = Formats.fromServerDate(dataLimite)
                let lp = Formats.formatDateSpan(new Date(), limitDate, 'accept')
                if (lp !== null) {
                  statePresentation = lp
                  canAccept = true
                  let diffMinutes =
                    (limitDate.getTime() - new Date().getTime()) / (1000 * 60)
                  if (diffMinutes < 30) {
                    isNear = true
                  }
                } else {
                  isRefused = true
                }
              } else {
                isRefused = true
              }
            }

            const appointmentDateStart = Formats.fromServerDate(
              a.servizioPrenotazione.richiestoDal
            )
            const appointmentDateEnd = Formats.fromServerDate(
              a.servizioPrenotazione.richiestoAl
            )
            const dayNames = Formats.dayNames()
            const monthNames = Formats.monthNames()

            /*
                        let bookStatusIcon = "";
                        let st = { backgroundColor: a.tipoOccupazioneCalendario.codiceColore };
                        if(a.tipoOccupazioneCalendario.idTipoOccupazione === EQCalendarStates.ServizioInAttesaDiConfermaProfessionista){
                            bookStatusIcon = ( <img src="/img/ico_spinner.png" alt="In attesa di conferma" /> );
                            if(isNear) {
                                st.color='red';
                                st.fontWeight='bold';
                            }
                            st.backgroundColor='#ffe371';
                        }
                        else if(a.tipoOccupazioneCalendario.idTipoOccupazione === EQCalendarStates.ServizioConfermatoDalProfessionista){
                            bookStatusIcon = ( <DoneIcon /> );
                        }
                        else if(a.tipoOccupazioneCalendario.idTipoOccupazione === EQCalendarStates.ServizioRifiutatoDalProfessionista){
                            bookStatusIcon = ( <ClearIcon /> )
                            st.color = ['white'];
                        }
                        else if(a.tipoOccupazioneCalendario.idTipoOccupazione === EQCalendarStates.ServizioIniziato){
                            bookStatusIcon = ( <ClearIcon /> )
                            st.color = ['white'];
                        }
                        else if(a.tipoOccupazioneCalendario.idTipoOccupazione === EQCalendarStates.ServizioConfermatoDaAltroProfessionista){
                            bookStatusIcon = ( <Cancel /> )
                            st.color = ['white'];
                        }
                        */

            let statusStyle = EQEventHelper.getStatusStyle(a)
            let bookStatusIcon = statusStyle.icon
            let st = statusStyle.style

            if (isNear) {
              st.color = 'red'
              st.fontWeight = 'bold'
            }

            let daydiff = false
            if (appointmentDateStart.getDay() !== appointmentDateEnd.getDay()) {
              daydiff = true
            }

            return (
              <Fragment key={a.idCalendario}>
                <div className="appointment-card">
                  <div
                    className="bookCard"
                    onClick={() => this.openDetail(a)}>
                    <div className="serviceBody">
                      <div className="serviceTitle">
                        <img
                          className="infoIcon"
                          src="/img/ico_i.png"
                          alt="Dettagli"
                        />
                        <label>Servizio:</label>
                        <div>
                          <strong>
                            {a.servizioPrenotazione.servizio.nome}
                          </strong>
                        </div>
                      </div>
                      <div className="serviceTime">
                        <div
                          style={{
                            verticalAlign: 'top',
                            paddingRight: '10px',
                          }}>
                          <EventIcon />
                        </div>
                        <div>
                          {!daydiff && (
                            <Fragment>
                              <div className="dateText">
                                {dayNames[appointmentDateStart.getDay()]}{' '}
                                {appointmentDateStart.getDate()}{' '}
                                {monthNames[appointmentDateStart.getMonth()]}
                              </div>
                              <div>
                                dalle ore:{' '}
                                <strong>
                                  {(
                                    '0' + appointmentDateStart.getHours()
                                  ).slice(-2)}
                                  :
                                  {(
                                    '0' + appointmentDateStart.getMinutes()
                                  ).slice(-2)}
                                </strong>
                              </div>
                              <div>
                                alle ore:{' '}
                                <strong>
                                  {('0' + appointmentDateEnd.getHours()).slice(
                                    -2
                                  )}
                                  :
                                  {(
                                    '0' + appointmentDateEnd.getMinutes()
                                  ).slice(-2)}
                                </strong>
                              </div>
                            </Fragment>
                          )}
                          {daydiff && (
                            <Fragment>
                              <div>
                                Da{' '}
                                <span className="dateText">
                                  {dayNames[appointmentDateStart.getDay()]}{' '}
                                  {appointmentDateStart.getDate()}{' '}
                                  {monthNames[appointmentDateStart.getMonth()]}
                                </span>
                              </div>
                              <div>
                                alle ore:{' '}
                                <strong>
                                  {(
                                    '0' + appointmentDateStart.getHours()
                                  ).slice(-2)}
                                  :
                                  {(
                                    '0' + appointmentDateStart.getMinutes()
                                  ).slice(-2)}
                                </strong>
                              </div>
                              <div>
                                A{' '}
                                <span className="dateText">
                                  {dayNames[appointmentDateEnd.getDay()]}{' '}
                                  {appointmentDateEnd.getDate()}{' '}
                                  {monthNames[appointmentDateEnd.getMonth()]}
                                </span>
                              </div>
                              <div>
                                alle ore:{' '}
                                <strong>
                                  {('0' + appointmentDateEnd.getHours()).slice(
                                    -2
                                  )}
                                  :
                                  {(
                                    '0' + appointmentDateEnd.getMinutes()
                                  ).slice(-2)}
                                </strong>
                              </div>
                            </Fragment>
                          )}
                        </div>
                      </div>
                      {EQCalendarStates.isLinkedToASingleCaregiver(a) && (
                        <div className="serviceAssistant">
                          <div
                            style={{
                              verticalAlign: 'top',
                              paddingRight: '10px',
                            }}>
                            <CaregiverIcon />
                          </div>
                          <div>
                            Assistente: <strong>{a.professionista.nome}</strong>
                          </div>
                        </div>
                      )}
                      <div className="serviceNumber">
                        <div>
                          <label>IL TUO CODICE DI PRENOTAZIONE</label>
                        </div>
                        <div>
                          <strong>
                            {a.servizioPrenotazione.prenotazione.codicePratica}
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>

                  {(estate ==
                    EQCalendarStates.ServizioInAttesaDiConfermaProfessionista ||
                    estate ==
                      EQCalendarStates.ServizioInAstaAttesaDiConfermaProfessionista) && (
                    <div className="event-modal-state can-accept ">
                      <div
                        className="serviceContents"
                        style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                        <div className="appuntamento-box">
                          <Grid
                            container
                            mb={1}
                            mt={1}
                            columnGap={1}>
                            <Grid
                              item
                              xs={3}
                              sx={{ maxWidth: '28%' }}>
                              <a
                                className="buttonLink bg-red text-white lnkForward flex-row-center"
                                onClick={() => this.refuseEvent(a)}>
                                RIFIUTA
                              </a>
                            </Grid>
                            <Grid
                              item
                              xs={8}>
                              <a
                                className="buttonLink bg-green text-white lnkForward flex-row-center"
                                onClick={() => this.acceptEvent(a)}>
                                ACCETTA
                              </a>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                      <div
                        className="serviceFooter"
                        style={st}>
                        <div className="serviceFooterContainer">
                          <img
                            src="/img/ico_spinner.png"
                            alt="In attesa di conferma"
                          />{' '}
                          {statePresentation}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {this.state.refuse_open && (
                  <EQRefuseModal
                    onClose={() => this.cancelRefuseEvent()}
                    selectedEvent={this.state.selectedEvent}
                  />
                )}

                {this.state.accept_open && (
                  <EQConfirmModal
                    onClose={() => this.cancelAcceptEvent()}
                    selectedEvent={this.state.selectedEvent}
                  />
                )}
              </Fragment>
            )
          })}
      </Box>
    )
  }
}
