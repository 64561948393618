import { Select } from '@mui/material'
import React, { Fragment } from 'react'
import { default as UUID } from 'node-uuid'

export default class EQSelect extends React.Component {
  constructor(props) {
    super(props)
    this.id = 'eq-select-' + UUID.v4()
  }

  render() {
    const { label, disabled, ...others } = this.props
    const lblcss = disabled ? 'select-label disabled' : 'select-label'
    return (
      <Fragment>
        <label
          className={lblcss}
          htmlFor={this.id}>
          {label}
        </label>
        <Select
          className="eq-select"
          id={this.id}
          label={label}
          {...others}
          disabled={disabled}>
          {this.props.children}
        </Select>
      </Fragment>
    )
  }
}
