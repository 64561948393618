/* eslint-disable react/no-direct-mutation-state */
import { Box, Grid } from '@mui/material'

import { ArrowForward } from '@mui/icons-material'
import EQCalendarStates from '../../libs/EQCalendarStates'
import EQNextEventAlert from '../core/EQNextEventAlert'
import EQService from '../../services/EQService'
import EQServiceBook from '../../services/EQServiceBook'
import EQState from '../../services/EQState'
import EventIcon from '@mui/icons-material/CalendarMonthTwoTone'
import FavoriteIcon from '@mui/icons-material/Favorite'
import Formats from '../../libs/Formats'
import React from 'react'

export default class EQLoggedUserSummary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      prossimoAppuntamento: 'loading',
      inSospeso: null,
      professionisti: 'loading',
    }
  }

  reloadState() {
    this.forceUpdate()
  }

  componentDidMount() {
    EQState.addListener(EQState.UserSelection, this)
    this.loadInfo()
  }

  loadInfo() {
    if (this.state.loaded) return
    if (EQService.isLogged()) {
      this.state.loaded = true

      const from = new Date()
      const to = new Date().setMonth(from.getMonth() + 3)
      EQServiceBook.listAppuntamentiCliente(from, to, {
        disableErrorAlert: true,
      }).then((l) => {
        // bugid#8932
        let filtered = []
        for (let i = 0; i < l.length; i++) {
          if (
            l[i].tipoOccupazioneCalendario.idTipoOccupazione !==
              EQCalendarStates.CancellatoDaAdmin &&
            l[i].tipoOccupazioneCalendario.idTipoOccupazione !==
              EQCalendarStates.CancellatoDalCliente &&
            l[i].tipoOccupazioneCalendario.idTipoOccupazione !==
              EQCalendarStates.CancellatoDalSistema
          ) {
            filtered.push(l[i])
          }
        }

        let app_prossimo = null
        let app_inSospeso = null
        for (let i = 0; i < filtered.length; i++) {
          if (
            filtered[i].tipoOccupazioneCalendario.idTipoOccupazione ===
              EQCalendarStates.ServizioInAttesaDiConfermaProfessionista ||
            filtered[i].tipoOccupazioneCalendario.idTipoOccupazione ===
              EQCalendarStates.ServizioInAstaAttesaDiConfermaProfessionista
          ) {
            if (!app_inSospeso) app_inSospeso = filtered[i]
          } else {
            if (!app_prossimo) app_prossimo = filtered[i]
          }
          if (app_inSospeso && app_prossimo) break
        }
        this.setState({
          prossimoAppuntamento: app_prossimo,
          inSospeso: app_inSospeso,
        })
      })

      EQService.getProfessionistiPreferiti({ disableErrorAlert: true }).then(
        (pps) => {
          this.setState({
            professionisti: pps,
          })
        }
      )
    }
  }

  componentDidUpdate() {
    this.loadInfo()
  }

  componentWillUnmount() {
    EQState.removeListener(EQState.UserSelection, this)
  }

  showCaregivers() {
    document.location.href = '/user/caregivers'
  }

  showBookings() {
    document.location.href = '/user/books'
  }

  render() {
    if (!EQService.isLogged()) return null

    let vicina = false
    let n_day = ''
    if (
      this.state.prossimoAppuntamento !== 'loading' &&
      this.state.prossimoAppuntamento
    ) {
      let dateFrom = Formats.fromServerDate(this.state.prossimoAppuntamento.dal)
      n_day = Formats.dateToDayMonth(dateFrom)
      if ((dateFrom.getTime() - new Date().getTime()) / (1000 * 60 * 60) < 2) {
        vicina = true
      }
      // vicina=true; // TEST-TODO
    }

    let s_day = ''
    if (this.state.prossimoAppuntamento !== 'loading' && this.state.inSospeso) {
      let dateFrom = Formats.fromServerDate(this.state.inSospeso.dal)
      s_day = Formats.dateToDayMonth(dateFrom)
    }

    let nome = ''
    if (
      EQService.sessioneWeb &&
      EQService.sessioneWeb.user &&
      EQService.sessioneWeb.user.clientes &&
      EQService.sessioneWeb.user.clientes[0]
    ) {
      nome = EQService.sessioneWeb.user.clientes[0].nome
    }

    let hasNotification = false
    if (
      this.state.prossimoAppuntamento !== 'loading' &&
      this.state.prossimoAppuntamento
    ) {
      hasNotification = true
    } else if (
      this.state.professionisti !== 'loading' &&
      this.state.professionisti.length > 0
    ) {
      hasNotification = true
    } else if (
      this.state.professionisti !== 'loading' &&
      this.state.inSospeso
    ) {
      hasNotification = true
    }

    let inHome = false
    if (!EQState.UserSelection.showGroupServices) inHome = true

    return (
      <Box
        mt={2}
        mb={0}
        px={2}>
        {this.state.prossimoAppuntamento !== 'loading' &&
          this.state.professionisti !== 'loading' && (
            <Box className="textCenter">
              {!inHome && !hasNotification && (
                <div
                  className="playFair afterLogo"
                  style={{ marginBottom: '10px' }}>
                  Ciao <b style={{ textTransform: 'capitalize' }}>{nome},</b>
                  di quale servizio hai bisogno? Troveremo il professionista più
                  adatto
                </div>
              )}
              {!inHome && hasNotification && <></>}
              {inHome && hasNotification && (
                <div
                  className="playFair afterLogo"
                  style={{ marginBottom: '10px' }}>
                  Ciao <b style={{ textTransform: 'capitalize' }}>{nome},</b>{' '}
                  ecco le tue principali notifiche di oggi:
                </div>
              )}
              {inHome && !hasNotification && (
                <div
                  className="playFair afterLogo"
                  dangerouslySetInnerHTML={{
                    __html: EQState.Contents.HOME.INTROTEXT.Contenuto,
                  }}
                  style={{ marginBottom: '10px' }}></div>
              )}
            </Box>
          )}

        {/* inHome && (this.state.prossimoAppuntamento=="loading" || this.state.professionisti=="loading") &&
                    <EQLoading message=" "/>
                */}

        {inHome &&
          this.state.prossimoAppuntamento !== 'loading' &&
          this.state.prossimoAppuntamento && (
            <Box
              className="notification"
              onClick={() => this.showBookings()}>
              <Grid container>
                <Grid
                  item
                  xs={2}>
                  <EventIcon
                    className="larger-icon black-icon"
                    style={{ paddingTop: '4px' }}
                  />
                </Grid>
                <Grid
                  item
                  xs={8}
                  style={{ textAlign: 'left' }}>
                  La prossima prenotazione è per: <b>{n_day}</b>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ paddingTop: '8px' }}>
                  <ArrowForward className="larger-icon no-padding black-icon" />
                </Grid>
              </Grid>
            </Box>
          )}

        {inHome &&
          this.state.prossimoAppuntamento !== 'loading' &&
          this.state.inSospeso && (
            <Box
              className="notification"
              onClick={() => this.showBookings()}>
              <Grid container>
                <Grid
                  item
                  xs={2}>
                  <EventIcon
                    className="larger-icon black-icon"
                    style={{ paddingTop: '4px' }}
                  />
                </Grid>
                <Grid
                  item
                  xs={8}
                  style={{ textAlign: 'left' }}>
                  Hai una prenotazione in sospeso: <b>{s_day}</b>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ paddingTop: '8px' }}>
                  <ArrowForward className="larger-icon no-padding black-icon" />
                </Grid>
              </Grid>
            </Box>
          )}

        {inHome &&
          this.state.professionisti !== 'loading' &&
          this.state.professionisti.length > 0 && (
            <Box
              className="notification"
              onClick={() => this.showCaregivers()}>
              <Grid container>
                <Grid
                  item
                  xs={2}>
                  <FavoriteIcon
                    className="larger-icon red-icon"
                    style={{ paddingTop: '4px' }}
                  />
                </Grid>
                <Grid
                  item
                  xs={8}
                  style={{ textAlign: 'left' }}>
                  Prenota ancora con
                  <br />
                  <b>i tuoi preferiti</b>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ paddingTop: '8px' }}>
                  <ArrowForward className="larger-icon no-padding black-icon" />
                </Grid>
              </Grid>
            </Box>
          )}

        {this.state.prossimoAppuntamento !== 'loading' &&
          this.state.professionisti !== 'loading' && (
            <Box className="textCenter">
              {/*(!inHome && hasNotification) && 
                            <div className="playFair afterLogo" style={{marginBottom:'10px'}}>
                                hai bisogno di:
                            </div>
                        */}
              {inHome && hasNotification && (
                <div
                  className="playFair afterLogo"
                  style={{ marginBottom: '10px' }}>
                  hai bisogno di:
                </div>
              )}
            </Box>
          )}

        {vicina && (
          <EQNextEventAlert selectedEvent={this.state.prossimoAppuntamento} />
        )}
      </Box>
    )
  }
}
