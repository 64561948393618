/* eslint-disable react/no-direct-mutation-state */
// ! remove above line for enabling esling direct mutation state
import { Box, Button, Grid } from '@mui/material'
import React, { Fragment } from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import EQBusinessRules from '../../libs/EQBusinessRules'
import EQLoading from '../EQLoading'
import EQService from '../../services/EQService'
import EQServiceBook from '../../services/EQServiceBook'
import EQSquareCheckbox from '../core/EQSquareCheckbox'
import EQState from '../../services/EQState'
import ErrorIcon from '@mui/icons-material/ErrorTwoTone'
import Formats from '../../libs/Formats'
import Pages from '../../services/Pages'
import PersonOutlineIcon from '@mui/icons-material/PersonOutlineTwoTone'
import StripeConf from '../../libs/StripeConf'
import { withRouter } from 'react-router-dom'

class EQBookStep_DoBook extends React.Component {
  constructor(pars) {
    super(pars)
    this.state = {
      bookingStatus: 'confirming',
      priceToPay: 0,
    }
  }

  componentDidMount() {
    if (!EQState.UserSelection.InvoiceData) {
      document.location.href = '/'
      return
    }
    const page = Pages.current()
    document.title = page.title
    window.scrollTo(0, 0)

    this.book()
  }

  book() {
    // Resettiamo vetuali altri booking preesistenti
    EQState.reset(EQState.Booking)
    EQState.persist(EQState.Booking)

    const sameAddresses = EQState.UserSelection.InvoiceData.sameAddresses
    const bookPars = {
      nomeFatturazione: sameAddresses
        ? EQState.UserSelection.SelectedRarentalRelation.nome
        : EQState.UserSelection.InvoiceData.nome,
      cognomeFatturazione: sameAddresses
        ? EQState.UserSelection.SelectedRarentalRelation.cognome
        : EQState.UserSelection.InvoiceData.cognome,
      indirizzoFatturazione: sameAddresses
        ? EQState.UserSelection.Location.address
        : EQState.UserSelection.InvoiceData.indirizzo,
      codiceFiscaleFatturazione: sameAddresses
        ? EQState.UserSelection.SelectedRarentalRelation.codiceFiscale
        : EQState.UserSelection.InvoiceData.codiceFiscale,
      comuneFatturazione: sameAddresses
        ? EQState.UserSelection.Location.CAP.comune
        : EQState.UserSelection.InvoiceData.comune,
      capFatturazione: sameAddresses
        ? EQState.UserSelection.Location.CAP.cap
        : EQState.UserSelection.InvoiceData.cap,
      provinciaFatturazione: sameAddresses
        ? EQState.UserSelection.Province
        : EQState.UserSelection.InvoiceData.provincia,
      codiceCoupon: EQState.UserSelection.Coupon
        ? EQState.UserSelection.CouponCode
        : '',
      isAsta: EQBusinessRules.isEmergenza(
        EQState.UserSelection.idTipologiaServizio
      ),
      acquisti: [],
    }

    console.clear()
    console.log(bookPars, EQState.UserSelection)

    // Inseriamo gli acquisti un caregiver alla volta
    EQState.UserSelection.Solutions_Caregivers.forEach((c) => {
      // Inseriamo per il caregiver i servizi confermati
      c._serviziPrenotazione.forEach((sp) => {
        let servizioConfermato = false
        if (bookPars.isAsta) {
          // se è un'asta, allora confermiamo tutti
          servizioConfermato = true
        } else if (sp._confirmed && sp._confirmedFor === c.idProfessionista) {
          // altrimenti confermiamo solo i selezionati dal cliente
          servizioConfermato = true
        }

        if (servizioConfermato) {
          let prezzo = 0
          sp.soluzioneTrovatas.forEach((st) => {
            if (st.idProfessionista !== c.idProfessionista) return
            prezzo += parseFloat(st.prezzo)
          })
          this.state.priceToPay = prezzo

          let servizio = ''
          if (
            EQState.UserSelection.servizi &&
            EQState.UserSelection.servizi.length > 0
          ) {
            //console.log("Servizio da .servizi");
            servizio = EQState.UserSelection.servizi[0]
          } else {
            //console.log("Servizio da .GruppoServizio.servizios");
            servizio =
              EQState.UserSelection.GruppoServizio.servizios[0].idServizio
          }

          const acquisto = {
            idProfessionista: c.idProfessionista,
            idServizio: servizio,
            dal: sp.richiestoDal,
            al: sp.richiestoAl,
            prezzoPrevisto: prezzo,
            idMansioni: EQState.UserSelection.mansioni,
          }

          let pkey =
            acquisto.idServizio +
            '_' +
            Formats.toServerDate(Formats.fromServerDate(sp.richiestoDal))
          console.log('cerchiamo ' + pkey)
          if (
            EQState.UserSelection.ServiziAPacchetto &&
            EQState.UserSelection.ServiziAPacchetto[pkey]
          ) {
            acquisto.idPacchetto = EQState.UserSelection.ServiziAPacchetto[pkey]
          } else {
            acquisto.idPacchetto = 0
          }

          bookPars.acquisti.push(acquisto)
        }
      })
    })

    //console.log("BOOKING bookPars",bookPars);
    EQServiceBook.book(bookPars)
      .then((booking) => {
        // Salviamo le informazioni su local storage
        console.log('Recording BookingResult in state')
        EQState.change(EQState.Booking, {
          IdSessioneWeb: EQService.sessionId(),
          BookingResult: booking,
        })
        EQState.persist(EQState.Booking)

        if (EQService.sessioneWeb.isJolly) {
          document.location.href = '/book/send-pay-link'
          return
        }

        //console.log("BOOKING PREPARED",booking);
        this.setState({
          bookingStatus: 'payment',
          errorMessage: '',
        })

        this.setupStripePaymentForm()
      })
      .catch((e) => {
        console.error('error_confirming', e)
        this.setState({
          bookingStatus: 'error_confirming',
        })
      })
  }

  setupStripePaymentForm() {
    // Preparazione transazione su stripe
    let stripePars = StripeConf.create()
    EQServiceBook.generaTokenStripe(stripePars).then((res) => {
      const stripeSecret = res
      const options = {
        clientSecret: stripeSecret,
      }
      this.state.stripe = window.Stripe(StripeConf.pubkey)
      this.state.elements = this.state.stripe.elements(options)
      const paymentElement = this.state.elements.create('payment')
      paymentElement.mount('#payment-element')
      setTimeout(() => {
        this.setState({
          setupComplete: true,
        })
      }, 2000)
    })
  }

  sendDataToStripe() {
    // Trasmissione dati transazione a Stripe
    let stripePars = StripeConf.create()
    this.state.stripe
      .confirmSetup({
        elements: this.state.elements,
        confirmParams: {
          return_url: stripePars.successEndpoint,
        },
      })
      .then((result) => {
        console.info('result from confirmSetup', result)
        if (result.error) {
          console.error('error_on_stripe', result.error)
          this.setState({
            bookingStatus: 'error_redirecting_stripe',
            errorMessage: '(' + result.error.message + ')',
          })
        }
      })
  }

  goBack() {
    EQState.change(EQState.UserSelection, {
      BookPage: `/book/recap`,
    })
    this.props.history.push({
      pathname: `/book/recap`,
    })
  }

  checkCondizioni(e) {
    this.setState({
      condizioniOk: e.target.checked,
    })
  }

  checkCondizioniPrestatori(e) {
    this.setState({
      condizioniPrestatoriOk: e.target.checked,
    })
  }

  render() {
    if (!EQState.UserSelection.InvoiceData) return null

    let pname =
      EQState.UserSelection.SelectedRarentalRelation.nome +
      ' ' +
      EQState.UserSelection.SelectedRarentalRelation.cognome

    let errorMessage = ''
    let error = false
    if (this.state.bookingStatus.indexOf('error') === 0) {
      error = true
      errorMessage = this.state.errorMessage
      if (this.state.bookingStatus === 'error_on_stripe') {
        errorMessage = '(errore connessione con Stripe)'
      } else if (this.state.bookingStatus === 'error_redirecting_stripe') {
        // already set
      } else if (this.state.bookingStatus === 'error_confirming') {
        errorMessage = '(errore conferma prenotazione)'
      }
    }

    let codicePratica = null
    if (EQState.Booking && EQState.Booking.BookingResult)
      codicePratica = EQState.Booking.BookingResult.codicePratica

    return (
      <Box mt={0}>
        {codicePratica && (
          <Fragment>
            <p className="playFair normalWeight textCenter bookstep-start-label">
              Prenotazione <b>{codicePratica}</b> per
            </p>
            <div className="row">
              <div className="col1-icon">
                <PersonOutlineIcon />
              </div>
              <div className="col2-icon">
                <h3 className="textCenter patient-name">{pname}</h3>
              </div>
            </div>
          </Fragment>
        )}

        {this.state.bookingStatus === 'confirming' && (
          <Box
            className="boxBianco needlesButtons"
            mt={2}
            mb={4}>
            <EQLoading
              type="boxBianco"
              message="Preparazione prenotazione..."
            />
          </Box>
        )}

        {error && (
          <Box
            className="boxCeleste needlesButtons"
            mt={2}
            mb={4}
            sx={{ textAlign: 'center', color: 'red' }}>
            <div style={{ marginTop: '40px', marginBottom: '40px' }}>
              <ErrorIcon className="error-icon" />
            </div>
            Si è verificato un errore tecnico.
            <br />
            {errorMessage}
            <br />
            Contatta il supporto di EQUECURE
            <br />
          </Box>
        )}

        {this.state.bookingStatus === 'payment' && (
          <Fragment>
            <Box
              className="boxBianco needlesButtons"
              mt={2}
              mb={4}>
              <form id="payment-form">
                <div id="payment-element"></div>
              </form>
            </Box>

            <div className="boxCondizioni">
              <Box className="squarecheckbox-on-white">
                <EQSquareCheckbox
                  id={'chCart_CondizioniEqueCure'}
                  onChange={(e) => this.checkCondizioni(e)}
                />
                <label htmlFor={'chCart_CondizioniEqueCure'}>
                  Accetto le{' '}
                  <a
                    href="/condizioni"
                    target="_blank">
                    condizioni d'uso EqueCure
                  </a>
                </label>
              </Box>
              <Box className="squarecheckbox-on-white">
                <EQSquareCheckbox
                  id={'chCart_CondizioniPrestatori'}
                  onChange={(e) => this.checkCondizioniPrestatori(e)}
                />
                <label htmlFor={'chCart_CondizioniPrestatori'}>
                  Accetto le{' '}
                  <a
                    href="/condizioni"
                    target="_blank">
                    condizioni Prestatori di servizio
                  </a>
                </label>
              </Box>
            </div>

            {this.state.setupComplete && (
              <Grid
                className="book-navigation shrink"
                container
                mt={0}
                mb={0}>
                {/*
                                <Grid item xs={6}>
                                    <Button className="lnkButton lnkBack" startIcon={<ArrowBackIcon />} onClick={() => this.goBack()}>
                                        Indietro
                                    </Button>                    
                                </Grid>
                                */}

                <Grid
                  item
                  sx={{ textAlign: 'center', paddingTop: '12px' }}
                  xs={12}>
                  <div style={{ marginRight: '20px', marginLeft: '20px' }}>
                    <button
                      id="submit"
                      type="button"
                      className="buttonLink lnkForward bg-green text-white"
                      style={{
                        borderColor: 'transparent',
                        fontSize: '14px',
                        fontFamily: 'Raleway',
                        display: 'block',
                        width: '100%',
                      }}
                      disabled={
                        !this.state.condizioniOk ||
                        !this.state.condizioniPrestatoriOk ||
                        !this.state.elements
                      }
                      onClick={() => this.sendDataToStripe()}>
                      PROCEDI
                    </button>
                  </div>
                  <p
                    className="textCenter"
                    style={{
                      margin: '16px 10px 0 10px',
                      color: '#48d700',
                      fontWeight: 'bold',
                    }}>
                    Non addebiteremo niente finchè non inizieremo il servizio.
                    Potrai disdire la prenotazione.
                  </p>
                  <p
                    className="textCenter"
                    style={{
                      margin: '10px 20px 0 20px',
                      color: '#b8b8b8',
                      fontSize: '12px',
                    }}>
                    I servizi sono erogati da cooperative, società e liberi
                    professionisti.
                  </p>
                </Grid>
              </Grid>
            )}
            {!this.state.setupComplete && (
              <div
                className="textCenter"
                style={{ marginBottom: '20px', marginTop: '20px' }}>
                <EQLoading message=" " />
              </div>
            )}
          </Fragment>
        )}

        {error && (
          <Grid
            container
            mt={2}
            mb={3}>
            <Grid
              item
              xs={6}>
              <Button
                sx={{ fontWeight: 'bold', color: 'black' }}
                className="lnkBack"
                startIcon={<ArrowBackIcon style={{ color: '#38809A' }} />}
                onClick={() => this.goBack()}>
                Indietro
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    )
  }
}

export default withRouter(EQBookStep_DoBook)
