import React from 'react'
import { Box, Modal, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  width: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  paddingBottom: 2,
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '20px 20px 0 0',
  outline: 'none',
}

export default class EQServiceHelpModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      content: null,
    }
  }

  componentDidMount() {
    this.setState({ content: this.props.code })
  }

  closeModal() {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  render() {
    if (!this.state.content) return null

    let cssClass = 'color-' + this.props.color

    return (
      <Modal
        open={true}
        className={cssClass}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box
          sx={style}
          className="helpModal">
          <Box sx={{ textAlign: 'right' }}>
            <button
              onClick={() => this.closeModal()}
              className="iconClose clickable">
              <Box>
                <CloseIcon></CloseIcon>
              </Box>
              <div>CHIUDI</div>
            </button>
          </Box>
          <Box
            sx={{ textAlign: 'center' }}
            pt={2}>
            <img
              className="circularImg"
              style={{ width: '120px' }}
              src="/img/doctor.jpg"
              alt="Dottore"
            />
          </Box>
          <Box
            px={2}
            pb={2}>
            <Box
              my={2}
              sx={{ maxHeight: '300px', overflow: 'scroll' }}>
              <Typography
                dangerouslySetInnerHTML={{ __html: this.state.content }}
                id="modal-modal-description"
                sx={{ my: 2 }}></Typography>
            </Box>

            <Box sx={{ textAlign: 'center' }}>
              <small>
                <a
                  className="linkunderlined"
                  onClick={() => this.closeModal()}>
                  Chiudi finestra
                </a>
              </small>
            </Box>
          </Box>
        </Box>
      </Modal>
    )
  }
}
