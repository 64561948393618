import { Box, Grid, Modal } from '@mui/material'
import React, { Fragment } from 'react'

import DateAdapter from '@mui/lab/AdapterDateFns'
import EQProfTypes from '../../libs/EQProfTypes'
import EQSelect from '../core/EQSelect'
import EQServiceCaregiver from '../../services/EQServiceCaregiver'
import EQState from '../../services/EQState'
import Formats from '../../libs/Formats'
import InfoIcon from '@mui/icons-material/Info'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { MenuItem } from 'material-ui'
import { MobileDatePicker } from '@mui/lab'
import TextField from '@mui/material/TextField'
import TimePicker from '@mui/lab/TimePicker'
import Utils from '../../libs/Utils'
import it from 'date-fns/locale/it'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  width: '80%',
  bgcolor: 'background.paper',
  border: '0',
  boxShadow: 24,
  p: 2,
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
}

export default class EQHolidayModal extends React.Component {
  constructor(props) {
    super(props)

    let dayMin = new Date()
    dayMin.setDate(dayMin.getDate() + 1)
    let dayMin2 = new Date(dayMin.getTime())
    dayMin2.setDate(dayMin.getDate() + 1)

    let startHour = new Date()
    startHour.setHours(9, 0, 0, 0)
    let endHour = new Date()
    endHour.setHours(10, 0, 0, 0)
    let maxStartHour = new Date()
    maxStartHour.setHours(23)

    this.state = {
      stage: 0,
      fromDate: new Date(dayMin.getTime()),
      toDate: new Date(dayMin2.getTime()),
      dayMin: dayMin,
      fromHour: startHour,
      toHour: endHour,
      maxStartHour: maxStartHour,
    }
  }

  closeModal(withChanges) {
    if (this.props.onClose) {
      this.props.onClose(withChanges)
    }
  }

  cancelOperation() {
    this.closeModal()
  }

  continueOperation() {
    this.setState({
      stage: 1,
    })
  }

  actuallyDoOperation() {
    EQServiceCaregiver.rifiutaAppuntamento(this.props.selectedEvent).then(
      (x) => {
        EQServiceCaregiver.getAppuntamento(
          this.props.selectedEvent.idCalendario
        ).then((x) => {
          Utils.refill(this.props.selectedEvent, x.data)
          this.closeModal()
        })
      }
    )
  }

  handleModeChange(event) {
    let selector = event.target
    this.setState({
      selectedMode: selector.value,
    })
  }

  handleDayChange(newValue, key) {
    let st = {}
    st[key] = newValue
    if (key === 'fromDate') {
      if (this.state.fromDate > this.state.toDate) {
        let td = new Date(this.state.fromDate.getTime())
        td.setDate(td.getDate() + 1)
        st['toDate'] = td
      }
    }
    this.setState(st)
  }

  handleTimeChange(newValue, key) {
    let st = {}
    st[key] = newValue
    if (key === 'fromHour') {
      if (this.state.toHour < newValue) {
        let plusOne = new Date(newValue.getTime() + 1000 * 60 * 60)
        st['toHour'] = plusOne
      }
    }
    this.setState(st)
  }

  isFormOk() {
    if (this.state.selectedMode === 1) {
      return true
    } else if (this.state.selectedMode === 2) {
      return true
    } else {
      return false
    }
  }

  continueOperation() {
    if (this.state.selectedMode === 1) {
      let fd = new Date(this.state.fromDate.getTime())
      fd.setHours(0, 0, 0, 0)
      let fds = Formats.toServerDate(fd)
      let td = new Date(this.state.toDate.getTime())
      td.setHours(0, 0, 0, 0)
      let tds = Formats.toServerDate(td)
      EQServiceCaregiver.richiediFerie(fds, tds).then((x) => {
        this.closeModal(true)
      })
    } else if (this.state.selectedMode === 2) {
      let fd = new Date(this.state.fromDate.getTime())
      fd.setHours(0, 0, 0, 0)
      fd.setHours(
        this.state.fromHour.getHours(),
        this.state.fromHour.getMinutes(),
        0,
        0
      )
      let fds = Formats.toServerDate(fd)
      let td = new Date(this.state.fromDate.getTime())
      td.setHours(0, 0, 0, 0)
      td.setHours(
        this.state.toHour.getHours(),
        this.state.toHour.getMinutes(),
        0,
        0
      )
      let tds = Formats.toServerDate(td)
      EQServiceCaregiver.richiediFerie(fds, tds).then((x) => {
        this.setState({
          stage: 1,
        })
      })
    }
  }

  render() {
    let professionista = EQState.CaregiverHome.Professionista
    console.log('profesisonista', professionista)

    let what = 'ferie o permessi'
    let what2 = 'lavoro'
    if (professionista.idInquadramento !== EQProfTypes.Dipendente) {
      what = 'indisponibilità'
      what2 = 'ritorno in disponibilità'
    }

    let modeOptions = [
      <MenuItem
        key={0}
        value={0}>
        Seleziona
      </MenuItem>,
      <MenuItem
        key={1}
        value={1}>
        Tutto il giorno
      </MenuItem>,
      <MenuItem
        key={2}
        value={2}>
        Alcune ore
      </MenuItem>,
    ]

    let minDate2 = new Date(this.state.fromDate)
    minDate2.setDate(minDate2.getDate() + 1)

    let sent_title = ''
    let sent_content = ''
    if (this.state.stage === 1) {
      // Richiesta inviata, personalizziamo i messaggi
      if (professionista.idInquadramento === EQProfTypes.Dipendente) {
        sent_title = 'La tua richiesta è stata inviata alla tua Cooperativa'
        sent_content =
          'Riceverai una notifica quando verrà confermata, nel frattempo non riceverai richieste di prenotazione nei giorni e negli orari che hai indicato'
      } else if (
        professionista.idInquadramento === EQProfTypes.LiberoProfessionista
      ) {
        sent_title =
          'I giorni/orari che hai indicato sono stati segnati nel calendario come "non disponibili"'
        sent_content =
          'Non riceverai richieste di prenotazione nei giorni e negli orari che hai indicato.'
      } else if (professionista.idInquadramento === EQProfTypes.CoCoCo) {
        sent_title =
          'I giorni/orari che hai indicato sono stati segnati nel calendario come "non disponibili"'
        sent_content =
          'Non riceverai richieste di prenotazione nei giorni e negli orari che hai indicato. La Cooperativa verrà informata.'
      }
    }

    return (
      <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          {this.state.stage === 0 && (
            <Box
              mt={0}
              p={2}>
              <div className="textCenter playFair app-text modal-title">
                Stai richiedendo
                <br />
                <b>{what}</b>
              </div>

              <LocalizationProvider
                dateAdapter={DateAdapter}
                locale={it}>
                <form>
                  <EQSelect
                    label="Richiesta per"
                    defaultValue={
                      this.state.selectedMode ? this.state.selectedMode : 0
                    }
                    onChange={(e) => this.handleModeChange(e)}>
                    {modeOptions}
                  </EQSelect>

                  {this.state.selectedMode === 1 && (
                    <Fragment>
                      <Box
                        xs={12}
                        mt={2}>
                        <MobileDatePicker
                          disabled={false}
                          label="Dal"
                          value={this.state.fromDate}
                          onChange={(v) => this.handleDayChange(v, 'fromDate')}
                          renderInput={(params) => (
                            <TextField
                              style={{ width: '100%' }}
                              {...params}
                            />
                          )}
                          minDate={this.state.dayMin}
                          inputFormat="dd/MM/yyyy"
                        />
                      </Box>
                      <Box xs={12}>
                        <div
                          className="app-text"
                          style={{ fontSize: '80%' }}>
                          <span className="small-icon">
                            <InfoIcon />
                          </span>
                          <span className="small-icon-legend">
                            Primo giorno di {what}
                          </span>
                        </div>
                      </Box>
                      <Box
                        xs={12}
                        mt={2}>
                        <MobileDatePicker
                          disabled={false}
                          label="Al"
                          value={this.state.toDate}
                          onChange={(v) => this.handleDayChange(v, 'toDate')}
                          renderInput={(params) => (
                            <TextField
                              style={{ width: '100%' }}
                              {...params}
                            />
                          )}
                          minDate={minDate2}
                          inputFormat="dd/MM/yyyy"
                        />
                      </Box>
                      <Box xs={12}>
                        <div
                          className="app-text"
                          style={{ fontSize: '80%' }}>
                          <span className="small-icon">
                            <InfoIcon />
                          </span>
                          <span className="small-icon-legend">
                            Primo giorno di {what2}
                          </span>
                        </div>
                      </Box>
                    </Fragment>
                  )}

                  {this.state.selectedMode === 2 && (
                    <Fragment>
                      <Box
                        xs={12}
                        mt={2}>
                        <MobileDatePicker
                          disabled={false}
                          label="Nel giorno"
                          value={this.state.fromDate}
                          onChange={(v) => this.handleDayChange(v, 'fromDate')}
                          renderInput={(params) => (
                            <TextField
                              style={{ width: '100%' }}
                              {...params}
                            />
                          )}
                          minDate={this.state.dayMin}
                          inputFormat="dd/MM/yyyy"
                        />
                      </Box>
                      <Box
                        xs={12}
                        mt={2}>
                        <Grid container>
                          <Grid
                            item
                            xs={6}
                            pr={1}>
                            <TimePicker
                              ampm={false}
                              disabled={false}
                              label="Dalle ore"
                              value={this.state.fromHour}
                              onChange={(v) =>
                                this.handleTimeChange(v, 'fromHour')
                              }
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              maxTime={this.state.maxStartHour}
                              inputFormat="HH:mm"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            pl={1}>
                            <TimePicker
                              ampm={false}
                              disabled={false}
                              label="Alle ore"
                              value={this.state.toHour}
                              onChange={(v) =>
                                this.handleTimeChange(v, 'toHour')
                              }
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              minTime={
                                new Date(
                                  this.state.fromHour.getTime() + 1000 * 60 * 60
                                )
                              }
                              inputFormat="HH:mm"
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Fragment>
                  )}
                </form>
              </LocalizationProvider>

              <Grid
                container
                mt={2}
                mb={2}>
                <Grid
                  item
                  sx={{ textAlign: 'left' }}
                  xs={6}>
                  <a
                    className="buttonLink lnkForward"
                    onClick={() => this.cancelOperation()}>
                    ANNULLA
                  </a>
                </Grid>
                <Grid
                  item
                  sx={{ textAlign: 'right' }}
                  xs={6}>
                  {this.isFormOk() && (
                    <a
                      className="buttonLink lnkForward"
                      onClick={() => this.continueOperation()}>
                      CONTINUA
                    </a>
                  )}
                </Grid>
              </Grid>
            </Box>
          )}
          {this.state.stage === 1 && (
            <Box
              mt={0}
              p={2}>
              <div className="textCenter playFair app-text modal-title">
                Hai richiesto <b>{what}</b>
              </div>

              <div className="textCenter app-text">
                <b>{sent_title}</b>
              </div>
              <div className="textCenter app-text">{sent_content}</div>

              <Grid
                container
                mt={0}
                mb={2}>
                <Grid
                  item
                  sx={{ textAlign: 'center' }}
                  xs={12}>
                  <a
                    className="buttonLink lnkForward"
                    onClick={() => this.closeModal(true)}>
                    OK
                  </a>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Modal>
    )
  }
}
