import {
  AccountCircleTwoTone,
  DialpadTwoTone,
  EmailTwoTone,
  LocalPhoneTwoTone,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material'
import { Box, InputAdornment } from '@mui/material'

import EQFaqBox from '../EQFaqBox'
import EQFloatingFormIcon from '../core/EQFloatingFormIcon'
import EQLoading from '../EQLoading'
import EQService from '../../services/EQService'
import EQTextField from '../core/EQTextField'
import { IconButton } from 'material-ui'
import PersonOutlineIcon from '@mui/icons-material/PersonOutlineTwoTone'
import React from 'react'
import Utils from '../../libs/Utils'

export default class EQMySettings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      user: null,
      passwordShown: false,
    }
  }

  componentDidMount() {
    this.loadUser()
  }

  loadUser() {
    let sId = EQService.sessionId()
    EQService.getSessione(sId).then((s) => {
      console.log('sessione', s)
      this.setState({
        clienteChanged: false,
        userChanged: false,
        user: s.user,
      })
    })
  }

  updateUserValue(evt, key) {
    const val = evt.target.value
    let st = this.state.user
    st[key] = val
    console.log('updateUserValue', this.state)
    this.setState({
      userChanged: true,
    })
  }

  updateClienteValue(evt, key) {
    const val = evt.target.value
    let st = this.state.user.clientes[0]
    st[key] = val
    console.log('updateClienteValue', this.state)
    this.setState({
      clienteChanged: true,
    })
  }

  changeUser() {
    if (this.state.user.clientes[0].nome.trim() === '') {
      Utils.notifyError('Specificare il nome')
      return
    }
    if (this.state.user.clientes[0].cognome.trim() === '') {
      Utils.notifyError('Specificare il cognome')
      return
    }
    if (this.state.user.email.trim() === '') {
      Utils.notifyError("Specificare l'email")
      return
    }
    if (this.state.user.mobilePhone.trim() === '') {
      Utils.notifyError('Specificare il cellulare')
      return
    }
    EQService.changeEmail(this.state.user.email).then((r) => {
      EQService.changeMobilePhone(this.state.user.mobilePhone).then((r2) => {
        EQService.aggiornaCliente(this.state.user.clientes[0]).then((r3) => {
          Utils.notifyConfirm('Modifiche effettuate')
        })
      })
    })
  }

  updateInputValue(evt, key) {
    const val = evt.target.value
    let st = {}
    st[key] = val
    this.setState(st)
  }

  changePassword() {
    if (this.state.newPassword.trim() === '') {
      Utils.notifyError('Specificare la nuova password')
      return
    }
    if (this.state.confirmPassword.trim() === '') {
      Utils.notifyError('Specificare la nuova password')
      return
    }
    if (this.state.confirmPassword !== this.state.newPassword) {
      Utils.notifyError('Le password indicate non coincidono')
      return
    }
    EQService.cambiaPassword(
      this.state.user.userName,
      this.state.oldPassword,
      this.state.newPassword
    ).then((res) => {
      Utils.notifyError('Password modificata')
    })
  }

  togglePassword() {
    this.setState({ passwordShown: !this.state.passwordShown })
  }

  render() {
    if (!this.state.user) {
      return <EQLoading />
    }

    const u = this.state.user
    const c = u.clientes[0]

    return (
      <Box style={{ marginTop: '20px', padding: '18px' }}>
        <Box className="boxBianco">
          <Box pb={1}>
            <h2 className="playFair textCenter normalWeight">Impostazioni</h2>
          </Box>
          <Box className="loginForm">
            <Box mt={1}>
              <EQFloatingFormIcon>
                <AccountCircleTwoTone />
              </EQFloatingFormIcon>
              <EQTextField
                style={{ marginLeft: '10px' }}
                disabled
                fullWidth
                label="Username"
                defaultValue={u.userName}></EQTextField>
            </Box>
            <Box
              mt={4}
              className="separatorContainer">
              <img
                src="/img/separator.png"
                alt="Separatore"
              />
            </Box>
            <Box mt={4}>
              <div className="textCenter playFair app-text">La tua scheda</div>
            </Box>
            <Box mt={2}>
              <EQFloatingFormIcon>
                <PersonOutlineIcon />
              </EQFloatingFormIcon>
              <EQTextField
                style={{ marginLeft: '10px' }}
                fullWidth
                label="Nome"
                defaultValue={c.nome}
                onChange={(evt) =>
                  this.updateClienteValue(evt, 'nome')
                }></EQTextField>
            </Box>
            <Box mt={1}>
              <EQTextField
                style={{ marginLeft: '10px' }}
                fullWidth
                label="Cognome"
                defaultValue={c.cognome}
                onChange={(evt) =>
                  this.updateClienteValue(evt, 'cognome')
                }></EQTextField>
            </Box>
            <Box my={1}>
              <EQFloatingFormIcon>
                <EmailTwoTone />
              </EQFloatingFormIcon>
              <EQTextField
                style={{ marginLeft: '10px' }}
                fullWidth
                label="E-mail"
                defaultValue={u.email}
                onChange={(evt) =>
                  this.updateUserValue(evt, 'email')
                }></EQTextField>
            </Box>
            <Box my={2}>
              <EQFloatingFormIcon>
                <LocalPhoneTwoTone />
              </EQFloatingFormIcon>
              <EQTextField
                style={{ marginLeft: '10px' }}
                fullWidth
                label="Telefono"
                defaultValue={u.mobilePhone}
                onChange={(evt) =>
                  this.updateUserValue(evt, 'mobilePhone')
                }></EQTextField>
            </Box>
            <Box className="textCenter flex-row-center">
              <a
                className="buttonLink lnkForward button-DOMICILIARE"
                onClick={() => this.changeUser()}>
                SALVA SCHEDA
              </a>
            </Box>
            <Box
              mt={6}
              className="separatorContainer">
              <img
                src="/img/separator.png"
                alt="Separatore"
              />
            </Box>
            <Box mt={4}>
              <div className="textCenter playFair app-text">
                Modifica la tua password
              </div>
            </Box>
            <Box mt={2}>
              <EQFloatingFormIcon>
                <DialpadTwoTone />
              </EQFloatingFormIcon>
              <EQTextField
                style={{ marginLeft: '10px' }}
                onChange={(evt) => this.updateInputValue(evt, 'oldPassword')}
                type={this.state.passwordShown ? 'text' : 'password'}
                fullWidth
                id="password"
                label="Vecchia Password"
                InputProps={{
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        style={{ marginRight: '-14px' }}
                        aria-label="toggle password visibility"
                        onClick={() => this.togglePassword()}
                        onMouseDown={() => this.togglePassword()}>
                        {this.state.passwordShown ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}></EQTextField>
            </Box>
            <Box my={1}>
              <EQTextField
                style={{ marginLeft: '10px' }}
                onChange={(evt) => this.updateInputValue(evt, 'newPassword')}
                type={this.state.passwordShown ? 'text' : 'password'}
                fullWidth
                id="password"
                label="Nuova Password"
                InputProps={{
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        style={{ marginRight: '-14px' }}
                        aria-label="toggle password visibility"
                        onClick={() => this.togglePassword()}
                        onMouseDown={() => this.togglePassword()}>
                        {this.state.passwordShown ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}></EQTextField>
            </Box>
            <Box my={1}>
              <EQTextField
                style={{ marginLeft: '10px' }}
                onChange={(evt) =>
                  this.updateInputValue(evt, 'confirmPassword')
                }
                type={this.state.passwordShown ? 'text' : 'password'}
                fullWidth
                id="password"
                label="Conferma Password"
                InputProps={{
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        style={{ marginRight: '-14px' }}
                        aria-label="toggle password visibility"
                        onClick={() => this.togglePassword()}
                        onMouseDown={() => this.togglePassword()}>
                        {this.state.passwordShown ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}></EQTextField>
            </Box>

            <Box className="textCenter flex-row-center">
              <a
                className="buttonLink lnkForward button-DOMICILIARE"
                onClick={() => this.changePassword()}>
                SALVA MODIFICA PASSWORD
              </a>
            </Box>
          </Box>
        </Box>

        <Box
          mt={6}
          mb={2}
          className="separatorContainer">
          <img
            src="/img/separator.png"
            alt="Separatore"
          />
        </Box>
        <EQFaqBox category="MieImpostazioni"></EQFaqBox>
      </Box>
    )
  }
}
