import AddIcon from '@mui/icons-material/Add'
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowBack'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowForward'
import { Box } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import EQCalendarStates from '../../libs/EQCalendarStates'
import EQFaqBox from '../EQFaqBox'
import EQFilterCalendarModal from './EQFilterCalendarModal'
import EQGotoDay from './EQGotoDay'
import EQHolidayModal from './EQHolidayModal'
import EQLoading from '../EQLoading'
import EQProfTypes from '../../libs/EQProfTypes'
import EQServiceCaregiver from '../../services/EQServiceCaregiver'
import EQState from '../../services/EQState'
import Formats from '../../libs/Formats'
import React from 'react'
import SearchIcon from '@mui/icons-material/SearchTwoTone'
import TodayIcon from '@mui/icons-material/CalendarMonthTwoTone'
import TuneIcon from '@mui/icons-material/Tune'

export class EQCaregiver_Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      CaregiverHome: EQState.CaregiverHome,
      refDate: new Date(),
      calendario: null,
    }
    this.selectedDateRef = React.createRef()
    this.rollingCalendarRef = React.createRef()
    this.scrollingHoursRef = React.createRef()
  }

  componentDidMount() {
    if (!EQState.CaregiverHome || !EQState.CaregiverHome.IsLogged) {
      EQState.retrieve(EQState.CaregiverHome)
    }
    if (!EQState.CaregiverHome || !EQState.CaregiverHome.IsLogged) {
      console.warn('Session is not ready in Home')
      document.location.href = '/caregiver/login'
      return
    }
    this.scrollDayIntoView()
    this.scrollHourIntoView()
    this.loadCalendarMonth()
  }

  loadCalendarMonth() {
    this.setState({
      calendario: null,
      calendarioPerGiorno: null,
    })

    let firstDay = new Date(
      this.state.refDate.getFullYear(),
      this.state.refDate.getMonth(),
      1
    )
    let firstDayNextMonth = new Date(
      this.state.refDate.getFullYear(),
      this.state.refDate.getMonth() + 1,
      1
    )
    console.log('loading calendar month', {
      refDate: this.state.refDate,
      start: firstDay,
      end: firstDayNextMonth,
    })

    // console.log("refDate",this.state.refDate.getMonth())
    EQServiceCaregiver.listAppuntamenti(firstDay, firstDayNextMonth).then((ll) => {
        //console.log(" ----- cc",ll.data)
        let calendarMap = {}
        ll.data.forEach((c) => {
          if (EQCalendarStates.isWaitingPayment(c)) return null
          if (EQCalendarStates.isAcceptOutdated(c)) return null
          if (EQCalendarStates.isRefused(c)) return null
          if (EQCalendarStates.isAcceptedByOther(c)) return null

          // Per ora NON facciamo vedere sul calendario i servizi in attesa di conferma
          // (per supportare eventi multipli nello stesso tempo in caso di asta)
          //if(EQCalendarStates.isToBeConfirmed(c)) return null;
          // Cambiato idea per ora, ma a capire cosa succede in caso di asta

          //console.log(EQCalendarStates.isRefused(c),c);

          const firstDay = Formats.fromServerDate(c.dal)
          const lastDay = Formats.fromServerDate(c.al)          
          lastDay.setMilliseconds(lastDay.getMilliseconds() - 1)
          let days = 0
          if (Formats.startOfDay(lastDay).getTime() - Formats.startOfDay(firstDay).getTime() !== 0) {
            // console.log("diff days",Formats.startOfDay(firstDay).getTime(),Formats.startOfDay(lastDay).getTime())
            //console.warn(c.dal,c.al,(lastDay.getTime() - firstDay.getTime()) / (1000 * 3600 * 24))
            days = /*Math.ceil*/((lastDay.getTime() - firstDay.getTime()) / (1000 * 3600 * 24)) // rimosso ceil() - bugid#9481
          }
          // console.log("days",days);
          let currentDay = new Date(firstDay.getTime())
          for (let d = 0; d <= days; d++) {
            if (currentDay.getMonth() !== this.state.refDate.getMonth()) {
              currentDay.setDate(currentDay.getDate() + 1)
              continue
            }
            // console.log("Adding event on date",currentDay.getDate());
            let calList = calendarMap[currentDay.getDate()]
            if (!calList) {
              calList = []
              calendarMap[currentDay.getDate()] = calList
            }
            calList.push(c)
            currentDay.setDate(currentDay.getDate() + 1)
          }
        })

        this.setState({
          calendario: ll.data,
          calendarioPerGiorno: calendarMap,
        })

        console.log('calendarioPerGiorno', this.state.calendarioPerGiorno)
      }
    )
  }

  scrollDayIntoView() {
    setTimeout(() => {
      if (this.selectedDateRef.current) {
        const el = this.selectedDateRef.current
        const elLeft = el.offsetLeft + el.offsetWidth
        const prnt = this.rollingCalendarRef.current
        const prnLeft = prnt.offsetLeft
        prnt.scrollLeft = elLeft - prnLeft
      }
    }, 50)
  }

  scrollHourIntoView() {
    if (this.scrollingHoursRef.current) {
      this.scrollingHoursRef.current.scrollTop = 240
    }
  }

  gotoToday() {
    this.gotoDate(new Date())
  }

  gotoDate(date) {
    if (!date) {
      this.setState({
        search_open: false,
      })
      return
    }

    this.setState({
      refDate: date,
      search_open: false,
    })

    setTimeout(() => {
      this.scrollDayIntoView()
      this.scrollHourIntoView()
      this.loadCalendarMonth()
    }, 0)
  }

  selectDay(d) {
    this.setState({
      refDate: d,
    })
    this.scrollHourIntoView()
  }

  nextMonth() {
    this.state.refDate.setMonth(this.state.refDate.getMonth() + 1)
    this.loadCalendarMonth()
    this.setState({
      refDate: this.state.refDate,
    })
  }

  prevMonth() {
    this.state.refDate.setMonth(this.state.refDate.getMonth() - 1)
    this.loadCalendarMonth()
    this.setState({
      refDate: this.state.refDate,
    })
  }

  showEvent(e) {
    EQState.change(EQState.CaregiverHome, {
      _selectedEvent: e,
    })
  }

  scrollLeft() {
    if (this.rollingCalendarRef.current) {
      this.rollingCalendarRef.current.scrollLeft = Math.max(
        0,
        this.rollingCalendarRef.current.scrollLeft - 40
      )
    }
  }

  scrollRight() {
    if (this.rollingCalendarRef.current) {
      this.rollingCalendarRef.current.scrollLeft =
        this.rollingCalendarRef.current.scrollLeft + 40
    }
  }

  showHoliday() {
    window.scrollTo(0, 0)
    this.setState({ holiday_open: true })
  }

  closeHoliday(withChange) {
    this.setState({ holiday_open: false })
    if (withChange) {
      this.loadCalendarMonth()
    }
  }

  showFilter() {
    window.scrollTo(0, 0)
    this.setState({ filter_open: true })
  }

  closeFilter(f) {
    this.setState({ filter_open: false })
    if (f) {
      if (f === 'none') {
        this.setState({
          filter: null,
        })
      } else {
        this.setState({
          filter: f,
        })
      }
    }
  }

  showSearch() {
    window.scrollTo(0, 0)
    this.setState({ search_open: true })
  }

  closeSearch() {
    this.setState({ searc_open: false })
  }

  render() {
    if (!EQState.CaregiverHome || !EQState.CaregiverHome.IsLogged) {
      return (
        <Box mt={4}>
          <EQLoading />
        </Box>
      )
    }

    let dayLetters = Formats.dayLetters()
    let dayNames = Formats.dayNames()
    let monthNames = Formats.monthNames()

    let tdWeekdays = []
    let tdDays = []
    let firstDay = new Date(
      this.state.refDate.getFullYear(),
      this.state.refDate.getMonth(),
      1
    )
    let lastDay = new Date(
      this.state.refDate.getFullYear(),
      this.state.refDate.getMonth() + 1,
      0
    )
    let days = Math.ceil(
      (lastDay.getTime() - firstDay.getTime()) / (1000 * 3600 * 24)
    )
    let currentDay = new Date(firstDay.getTime())
    for (let d = 0; d <= days; d++) {
      tdWeekdays.push(<th key={d}>{dayLetters[currentDay.getDay()]}</th>)
      let css = ''
      let rf = null
      if (currentDay.getDate() === this.state.refDate.getDate()) {
        css = 'selected'
        rf = this.selectedDateRef
      } else {
        if (this.state.calendarioPerGiorno) {
          let dayEvents = this.state.calendarioPerGiorno[currentDay.getDate()]
          if (dayEvents) {
            dayEvents = dayEvents.filter(
              (x) =>
                x.tipoOccupazioneCalendario.idTipoOccupazione !==
                EQCalendarStates.IndisponibilitaContrattuale
            )
            if (dayEvents.length > 0) {
              css = 'with-events'
            }
          }
        }
      }
      css = css + ' ' + dayLetters[currentDay.getDay()]
      let newDay = new Date(currentDay.getTime())
      tdDays.push(
        <td
          key={d}
          className={css}
          ref={rf}>
          <div onClick={() => this.selectDay(newDay)}>
            {currentDay.getDate()}
          </div>
        </td>
      )
      currentDay.setDate(currentDay.getDate() + 1)
    }

    let refDatePresentation =
      dayNames[this.state.refDate.getDay()] + ' ' + this.state.refDate.getDate()
    // + " " + monthNames[this.state.refDate.getMonth()]
    //+ " " + this.state.refDate.getFullYear();

    let monthPresentation =
      monthNames[this.state.refDate.getMonth()] +
      ' ' +
      this.state.refDate.getFullYear()

    let hourRows = []
    for (let h = 10; h <= 46; h++) {
      let hh = '' + h / 2 // contiamo sulle mezz'ore
      if (hh.length < 2) hh = '0' + hh
      let label = hh + ':00'
      if (h === 10) label = 'Notte'
      if (h === 46) label = 'Notte'
      hourRows.push(
        <div
          key={h}
          className="hour-row">
          <div>{label}</div>
          <div></div>
        </div>
      )
    }

    let eventDivs = []
    if (this.state.calendarioPerGiorno) {
      let dayEvents = this.state.calendarioPerGiorno[this.state.refDate.getDate()]
      console.log('dayEvents for ' + this.state.refDate.getDate(),dayEvents,this.state.calendarioPerGiorno)
      if (dayEvents) {
        let displacement = 0
        dayEvents.forEach((c, ci) => {
          if (EQCalendarStates.isWaitingPayment(c)) return null
          if (EQCalendarStates.isAcceptOutdated(c)) return null
          if (EQCalendarStates.isRefused(c)) return null
          if (EQCalendarStates.isAcceptedByOther(c)) return null

          if (this.state.filter) {
            if (c.tipoOccupazioneCalendario.idTipoOccupazione !== this.state.filter) {
              console.log('filtering out', this.state.filter, c)
              return null // filtrato
            }
          }

          // Tagliamo l'evento sul giorno
          let fromDate = Formats.fromServerDate(c.dal)
          if (fromDate.getDate() < this.state.refDate.getDate()) {
            fromDate = new Date(this.state.refDate.getTime())
            fromDate.setHours(5, 0, 0, 0)
            console.log('Adjusting fromDate on current day', fromDate)
          }
          let toDate = Formats.fromServerDate(c.al)
          toDate.setMilliseconds(toDate.getMilliseconds() - 1)
          if (toDate.getDate() > this.state.refDate.getDate()) {
            console.log('Adjusting toDate on current day', toDate)
            toDate = new Date(this.state.refDate.getTime())
            toDate.setHours(23, 0, 0, 0)
            console.log('Adjusting toDate on current day gives', toDate)
          }

          // sanity check for bugid#9481
          let diff=toDate.getTime() - fromDate.getTime();
          if(diff<=0) {
            console.warn("skipping for diff",c.dal,c.al,diff);
            return null; // filtrato
          }

          // Identifichiamo inizio e durata
          let hstart = fromDate.getHours()
          let hduration = (toDate.getHours() - fromDate.getHours()) * 2 + 2 // risoluzione mezz'ora
          if (fromDate.getHours() === toDate.getHours()) hduration = 2

          console.log('---', {
            idCalendar: c.idCalendario,
            fromDate: fromDate,
            toDate: toDate,
            hstart: hstart,
            hduration: hduration,
          })

          if (hduration > 48) hduration = 48
          if (hduration === 0) hduration = 48 // ferie - tutto il giorno
          if (hstart < 5) {
            hduration = hduration - hstart + 5
            hstart = 5
          }
          let css = 'calendar-event'
          let additionalCss =
            'event-' + c.tipoOccupazioneCalendario.idTipoOccupazione
          let st = {
            height: hduration * 20 + 1 + 'px',
            top: (hstart - 5) * 40 + 18 - displacement + 'px',
          }
          displacement = displacement + (hduration * 20 + 1)
          let cname = EQCalendarStates.labelForEvent(c)
          if (
            c.tipoOccupazioneCalendario.idTipoOccupazione ===
            EQCalendarStates.ServizioConfermatoDalProfessionista
          ) {
            if (fromDate < new Date()) {
              additionalCss = 'event-late'
              cname = 'Appuntamento in ritardo'
            }
          }
          if (
            c.tipoOccupazioneCalendario.idTipoOccupazione ===
            EQCalendarStates.FerieConfermate
          ) {
            let professionista = EQState.CaregiverHome.Professionista
            if (professionista.idInquadramento !== EQProfTypes.Dipendente) {
              cname = 'Indisponibilità'
            }
          }

          let secondRow = null
          if (c.servizioPrenotazione && c.servizioPrenotazione.prenotazione)
            secondRow = c.servizioPrenotazione.prenotazione.indirizzo

          if (hduration > 2 && secondRow) {
            eventDivs.push(
              <div
                key={c.idCalendar + '_' + ci}
                className={css + ' ' + additionalCss}
                onClick={() => this.showEvent(c)}
                style={st}>
                {cname}
                <br />
                {secondRow}
              </div>
            )
          } else {
            eventDivs.push(
              <div
                key={ci}
                className={css + ' ' + additionalCss}
                onClick={() => this.showEvent(c)}
                style={st}>
                {cname}
              </div>
            )
          }
        })
      }
    }

    return (
      <div>
        <Box style={{ marginTop: '70px' }}>
          <Box
            className="boxBianco"
            style={{ marginTop: '10px', padding: 0 }}>
            <Box py={0}>
              <p className="playFair normalWeight textCenter bookstep-start-label">
                Il mio calendario
              </p>
            </Box>
            <Box
              pb={0}
              px={2}>
              <div style={{ float: 'right', textAlign: 'right' }}>
                <AddIcon
                  className="calendar-icon"
                  onClick={() => this.showHoliday()}
                />
              </div>
              <div
                style={{
                  float: 'right',
                  marginLeft: '-15px',
                  marginRight: '15px',
                }}>
                <SearchIcon
                  className="calendar-icon"
                  onClick={() => this.showSearch()}
                />
              </div>
              {this.state.filter && (
                <div
                  style={{
                    float: 'left',
                    textAlign: 'left',
                    width: '48px',
                    color: '#13a8c9',
                  }}>
                  <TuneIcon
                    className="calendar-icon"
                    onClick={() => this.showFilter()}
                  />
                </div>
              )}
              {!this.state.filter && (
                <div
                  style={{ float: 'left', textAlign: 'left', width: 'auto' }}
                  onClick={() => this.showFilter()}>
                  <TuneIcon className="calendar-icon" />
                  <span
                    className="playFair"
                    style={{
                      marginLeft: '4px',
                      position: 'relative',
                      top: '-6px',
                    }}>
                    Filtri
                  </span>
                </div>
              )}
              <div onClick={() => this.gotoToday()}>
                <TodayIcon className="calendar-icon" />
                <span
                  className="playFair"
                  style={{
                    marginLeft: '4px',
                    position: 'relative',
                    top: '-6px',
                  }}>
                  Oggi
                </span>
              </div>
            </Box>

            <Box
              className="boxBianco calendar"
              style={{ marginTop: 0 }}>
              <Box className="rolling-month">
                <div>
                  <ArrowCircleLeftIcon onClick={() => this.prevMonth()} />
                </div>

                <div
                  style={{
                    fontSize: '1.3rem',
                    marginLeft: '5px',
                    marginRight: '5px',
                  }}>
                  {monthPresentation}
                </div>
                <div>
                  <ArrowCircleRightIcon onClick={() => this.nextMonth()} />
                </div>
              </Box>

              <Box style={{ display: 'flex' }}>
                <div
                  className="rolling-calendar-arrow-left"
                  onClick={() => this.scrollLeft()}>
                  <div>
                    <ChevronLeftIcon />
                  </div>
                </div>
                <div
                  className="rolling-calendar-outer"
                  ref={this.rollingCalendarRef}>
                  <table className="rolling-calendar">
                    <thead>
                      <tr>{tdWeekdays}</tr>
                    </thead>
                    <tbody>
                      <tr>{tdDays}</tr>
                    </tbody>
                  </table>
                </div>
                <div
                  className="rolling-calendar-arrow-right"
                  onClick={() => this.scrollRight()}>
                  <div>
                    <ChevronRightIcon />
                  </div>
                </div>
              </Box>

              <Box
                mt={3}
                className="selected-date">
                {refDatePresentation}
              </Box>

              {!this.state.calendario && (
                <Box mt={3}>
                  <div className="scrolling-hours">
                    <EQLoading />
                  </div>
                </Box>
              )}
              {this.state.calendario && (
                <Box mt={3}>
                  <div
                    className="scrolling-hours"
                    ref={this.scrollingHoursRef}>
                    <div className="calendar-events">{eventDivs}</div>
                    {hourRows}
                  </div>
                </Box>
              )}
            </Box>
          </Box>

          {this.state.holiday_open && (
            <EQHolidayModal
              onClose={(withChange) => this.closeHoliday(withChange)}
            />
          )}

          {this.state.filter_open && (
            <EQFilterCalendarModal
              onClose={(f) => this.closeFilter(f)}
              selectedFilter={this.state.filter}
            />
          )}

          {this.state.search_open && (
            <EQGotoDay
              onClose={(selectedDate) => this.gotoDate(selectedDate)}
            />
          )}
        </Box>
        <Box>
          <EQFaqBox category="CalendarioCaregiver"></EQFaqBox>
        </Box>
      </div>
    )
  }
}
