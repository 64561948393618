import { Box, FormControlLabel, FormGroup, Typography } from '@mui/material'

import Cookies from 'universal-cookie'
import EQService from '../services/EQService'
import EQSquareCheckbox from './core/EQSquareCheckbox'
import EQState from '../services/EQState'
import React from 'react'
import TextField from '@mui/material/TextField'
import Utils from '../libs/Utils'
import validator from 'validator'
import { withRouter } from 'react-router-dom'

class EQRegistrationModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      goToAfterLogin: null,
      UserSelection: EQState.UserSelection,
      mobilePhone: '',
      email: '',
      username: '',
      nonCryptedPassword: '',
      verifyPassword: '',
      nome: '',
      cognome: '',
      otp: '',
      idUser: 0,
      accettaCondizioniUso: false,
      accettaPrivacyPolicy: false,
      accettaMarketing: false,
      iscrittoNewsletter: false,
      showOtp: false,
      error: false,
      errorMessage: {},
    }
    this.goForward = this.goForward.bind(this)
  }

  reloadState() {
    this.setState({
      UserSelection: EQState.UserSelection,
    })
  }

  componentDidMount() {
    EQState.addListener(EQState.UserSelection, this)

    const cookies = new Cookies()
    EQService.creaSessione().then((s) => {
      cookies.set('sessionID', s.idSessioneWeb, { path: '/' })
      EQService.sessionRelinked = true
    })
    this.setState({ goToAfterLogin: this.props.goToAfterLogin })
  }

  componentWillUnmount() {
    EQState.removeListener(EQState.UserSelection, this)
  }

  closeModal() {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  updateInputValue(evt) {
    const val = evt.target.value
    const field = evt.target.id
    this.setState({
      [field]: val,
    })
  }

  updateCbValue(evt) {
    const field = evt.target.id
    this.setState({
      [field]: evt.target.checked,
    })
  }

  goForward() {
    EQState.change(EQState.UserSelection, { IsLogged: true })
    this.props.history.push({
      pathname: `/book/person`,
    })
  }

  showOtp() {
    this.setState({
      showOtp: true,
    })
  }

  doRegistration() {
    let isError = false
    EQService.isEmailInUse(this.state.email).then((r) => {
      if (r) {
        isError = true
        this.setState((prev) => ({
          ...prev,
          error: true,
          errorMessage: {
            ...prev.errorMessage,
            email: 'Indirizzo email già registrato',
          },
        }))
      } else {
        isError = false
        this.setState((prev) => ({
          ...prev,
          error: false,
          errorMessage: { ...prev.errorMessage, email: '' },
        }))

        EQService.isMobileInUse(this.state.mobilePhone).then((r) => {
          if (r) {
            isError = true
            this.setState((prev) => ({
              ...prev,
              error: true,
              errorMessage: {
                ...prev.errorMessage,
                mobilePhone: 'Numero telefonico già registrato',
              },
            }))
          } else {
            isError = false
            this.setState((prev) => ({
              ...prev,
              error: false,
              errorMessage: { ...prev.errorMessage, mobilePhone: '' },
            }))

            EQService.isUserNameInUse(this.state.username).then((r) => {
              if (r) {
                isError = true
                this.setState((prev) => ({
                  ...prev,
                  error: true,
                  errorMessage: {
                    ...prev.errorMessage,
                    username: 'Username già in uso, sceglierne una differente',
                  },
                }))
              } else {
                isError = false
                this.setState((prev) => ({
                  ...prev,
                  error: false,
                  errorMessage: { ...prev.errorMessage, username: '' },
                }))
                this.actuallyDoRegstration()
              }
            })
          }
        })
      }
    })
  }

  actuallyDoRegstration() {
    let isError = false
    if (
      !validator.isAlpha(this.state.nome) ||
      !validator.isLength(this.state.nome, { min: 2 })
    ) {
      isError = true
      this.setState({
        error: true,
        errorMessage: { nome: 'Inserire un nome valido' },
      })
    } else {
      this.setState((prev) => ({
        ...prev,
        error: false,
        errorMessage: { ...prev.errorMessage, nome: '' },
      }))
    }

    if (
      !validator.isAlpha(this.state.cognome) ||
      !validator.isLength(this.state.cognome, { min: 2 })
    ) {
      isError = true
      this.setState((prev) => ({
        ...prev,
        error: true,
        errorMessage: {
          ...prev.errorMessage,
          cognome: 'Inserire un cognome valido',
        },
      }))
    } else {
      this.setState((prev) => ({
        ...prev,
        error: false,
        errorMessage: { ...prev.errorMessage, cognome: '' },
      }))
    }

    if (!validator.isEmail(this.state.email)) {
      isError = true
      this.setState((prev) => ({
        ...prev,
        error: true,
        errorMessage: {
          ...prev.errorMessage,
          email: 'Inserire un indirizzo email valido',
        },
      }))
    } else {
      this.setState((prev) => ({
        ...prev,
        error: false,
        errorMessage: { ...prev.errorMessage, email: '' },
      }))
    }

    if (!validator.isMobilePhone(this.state.mobilePhone, 'it-IT')) {
      isError = true
      this.setState((prev) => ({
        ...prev,
        error: true,
        errorMessage: {
          ...prev.errorMessage,
          mobilePhone: 'Inserire un numero telefonico valido',
        },
      }))
    } else {
      this.setState((prev) => ({
        ...prev,
        error: false,
        errorMessage: { ...prev.errorMessage, mobilePhone: '' },
      }))
    }

    if (
      !validator.isAlphanumeric(this.state.username, 'it-IT', {
        ignore: '-.',
      }) ||
      !validator.isLength(this.state.username, { min: 2, max: 32 })
    ) {
      isError = true
      this.setState((prev) => ({
        ...prev,
        error: true,
        errorMessage: {
          ...prev.errorMessage,
          username: 'Inserire un username valido',
        },
      }))
    } else {
      this.setState((prev) => ({
        ...prev,
        error: false,
        errorMessage: { ...prev.errorMessage, username: '' },
      }))
    }

    if (!validator.isStrongPassword(this.state.nonCryptedPassword)) {
      isError = true
      this.setState((prev) => ({
        ...prev,
        error: true,
        errorMessage: {
          ...prev.errorMessage,
          password:
            'La password deve contenere almeno: - una lettera maiuscola - una lettera minuscola - una numero - un simbolo ed avere una lunghezza di almeno 8 caratteri',
        },
      }))
    } else {
      this.setState((prev) => ({
        ...prev,
        error: false,
        errorMessage: { ...prev.errorMessage, password: '' },
      }))
    }

    if (this.state.nonCryptedPassword.trim() !== this.state.verifyPassword) {
      isError = true
      this.setState((prev) => ({
        ...prev,
        error: true,
        errorMessage: {
          ...prev.errorMessage,
          verifyPassword: 'Le password non coincidono',
        },
      }))
    } else {
      this.setState((prev) => ({
        ...prev,
        error: false,
        errorMessage: { ...prev.errorMessage, verifyPassword: '' },
      }))
    }

    if (!this.state.accettaCondizioniUso) {
      isError = true
      this.setState((prev) => ({
        ...prev,
        error: true,
        errorMessage: {
          ...prev.errorMessage,
          accettaCondizioniUso: "Obbligatorio accettare le condizioni d'uso",
        },
      }))
    } else {
      this.setState((prev) => ({
        ...prev,
        error: false,
        errorMessage: { ...prev.errorMessage, accettaCondizioniUso: '' },
      }))
    }

    if (!this.state.accettaPrivacyPolicy) {
      isError = true
      this.setState((prev) => ({
        ...prev,
        error: true,
        errorMessage: {
          ...prev.errorMessage,
          accettaPrivacyPolicy: 'Obbligatorio accettare la privacy policy',
        },
      }))
    } else {
      this.setState((prev) => ({
        ...prev,
        error: false,
        errorMessage: { ...prev.errorMessage, accettaPrivacyPolicy: '' },
      }))
    }

    if (!isError) {
      EQService.registration(
        this.state.email,
        this.state.mobilePhone,
        this.state.username,
        this.state.nonCryptedPassword,
        this.state.cognome,
        this.state.nome,
        this.state.accettaCondizioniUso,
        this.state.accettaPrivacyPolicy,
        this.state.accettaMarketing,
        this.state.iscrittoNewsletter
      ).then((m) => {
        if (m.statusString === 'KO') {
          Utils.notifyError("Errore nell'operazione")
          return
        } else {
          this.setState({
            idUser: m.idUser,
          })
          this.loginAndGoForward()
        }
      })
    }
  }

  loginAndGoForward() {
    EQService.login(this.state.username, this.state.nonCryptedPassword).then(
      (m) => {
        if (m.statusString === 'KO') {
          Utils.notifyError('Login errato')
          return
        } else {
          EQState.UserSelection._idSessioneWeb = m.sessioneWeb.idSessioneWeb
          EQState.UserSelection._loggedTime = new Date()
          EQState.persist(EQState.UserSelection)
          this.goForward()
        }
      }
    )
  }

  doOtp() {
    if (!this.state.otp.trim() === '') {
      Utils.notifyError('Specificare OTP')
      return
    }

    EQService.creaSessione().then(() => {
      EQService.otp(this.state.otp, this.state.idUser).then((m) => {
        if (m.statusString === 'KO') {
          Utils.notifyError('OTP non valida')
          return
        } else {
          EQService.login(
            this.state.username,
            this.state.nonCryptedPassword
          ).then((m) => {
            if (m.statusString === 'KO') {
              Utils.notifyError('Login errato')
              return
            } else {
              EQState.UserSelection._idSessioneWeb = m.sessioneWeb.idSessioneWeb
              EQState.UserSelection._loggedTime = new Date()
              EQState.persist(EQState.UserSelection)
              this.goForward()
            }
          })
        }
      })
    })
  }

  render() {
    let content
    if (this.state.showOtp) {
      content = (
        <Box
          px={2}
          pt={8}>
          <Typography
            align="center"
            className="playFair"
            variant="h5"
            sx={{ fontSize: '15pt', color: '#fff' }}>
            Inserisci OTP
          </Typography>

          <Box className="loginForm">
            <Box mt={3}>
              <TextField
                defaultValue={''}
                name="otp"
                autoComplete="false"
                onChange={(evt) => this.updateInputValue(evt)}
                fullWidth
                id="otp"
                label="OTP"
                placeholder="Otp"></TextField>
            </Box>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <button
              className="buttonGreen"
              onClick={() => this.doOtp()}>
              COMPLETA REGISTRAZIONE
            </button>
          </Box>
        </Box>
      )
    } else {
      content = (
        <Box>
          <div className="linguetta">
            <span>
              Siamo
              <br />
              a Milano
              <br />e provincia
            </span>
          </div>

          <Box
            px={2}
            pt={8}>
            <Typography
              align="center"
              className="playFair"
              variant="h5"
              sx={{ fontSize: '15pt', color: '#fff' }}>
              Registrati per accedere ai servizi
            </Typography>

            <Box className="loginForm">
              <Box mt={2}>
                <TextField
                  onChange={(evt) => this.updateInputValue(evt)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  id="nome"
                  label="Nome"
                  placeholder="Nome"
                  error={!!this.state.errorMessage.nome}
                  helperText={
                    this.state.errorMessage.nome && this.state.errorMessage.nome
                  }></TextField>
              </Box>
              <Box mt={2}>
                <TextField
                  onChange={(evt) => this.updateInputValue(evt)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  id="cognome"
                  label="Cognome"
                  placeholder="Cognome"
                  error={!!this.state.errorMessage.cognome}
                  helperText={
                    this.state.errorMessage.cognome &&
                    this.state.errorMessage.cognome
                  }></TextField>
              </Box>
              <Box mt={2}>
                <TextField
                  onChange={(evt) => this.updateInputValue(evt)}
                  type="email"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  id="email"
                  label="Email"
                  placeholder="email@email.it"
                  error={!!this.state.errorMessage.email}
                  helperText={
                    this.state.errorMessage.email &&
                    this.state.errorMessage.email
                  }></TextField>
              </Box>
              <Box
                mt={2}
                sx={{ position: 'relative' }}>
                <TextField
                  onChange={(evt) => this.updateInputValue(evt)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type="number"
                  id="mobilePhone"
                  label="Cellulare"
                  placeholder="333*****"
                  error={!!this.state.errorMessage.mobilePhone}
                  helperText={
                    this.state.errorMessage.mobilePhone &&
                    this.state.errorMessage.mobilePhone
                  }
                  inputProps={{ style: { paddingLeft: '40px' } }}></TextField>
                <span
                  style={{
                    fontFamily: 'raleway',
                    fontSize: '16px',
                    fontWeight: '700',
                    position: 'absolute',
                    left: '14px',
                    top: '19px',
                  }}>
                  +39
                </span>
              </Box>
              <Box mt={2}>
                <TextField
                  onChange={(evt) => this.updateInputValue(evt)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  id="username"
                  label="Username"
                  placeholder="username"
                  error={!!this.state.errorMessage.username}
                  helperText={
                    this.state.errorMessage.username &&
                    this.state.errorMessage.username
                  }></TextField>
              </Box>
              <Box my={2}>
                <TextField
                  onChange={(evt) => this.updateInputValue(evt)}
                  type="password"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  id="nonCryptedPassword"
                  label="Password"
                  placeholder="****"
                  error={!!this.state.errorMessage.password}
                  helperText={
                    this.state.errorMessage.password &&
                    this.state.errorMessage.password
                  }></TextField>
              </Box>
              <Box my={2}>
                <TextField
                  onChange={(evt) => this.updateInputValue(evt)}
                  type="password"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  id="verifyPassword"
                  label="Verifica Password"
                  placeholder="****"
                  error={!!this.state.errorMessage.verifyPassword}
                  helperText={
                    this.state.errorMessage.verifyPassword &&
                    this.state.errorMessage.verifyPassword
                  }></TextField>
              </Box>
              <Box sx={{ color: '#fff' }}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <EQSquareCheckbox
                        id="accettaCondizioniUso"
                        required
                        sx={{ '&.Mui-checked': { color: '#fff' } }}
                      />
                    }
                    label={
                      <div className="cbLabel">
                        Accetto le{' '}
                        <a href="/condizioni">condizioni di servizio</a>
                      </div>
                    }
                    onChange={(evt) => this.updateCbValue(evt)}
                  />
                  {!!this.state.errorMessage.accettaCondizioniUso && (
                    <p className="MuiFormHelperText-root Mui-error css-1wc848c-MuiFormHelperText-root">
                      {this.state.errorMessage.accettaCondizioniUso}{' '}
                    </p>
                  )}

                  <FormControlLabel
                    control={
                      <EQSquareCheckbox
                        id="accettaPrivacyPolicy"
                        required
                        sx={{ '&.Mui-checked': { color: '#fff' } }}
                      />
                    }
                    label={
                      <div className="cbLabel">
                        Accetto <a href="/privacy">privacy policy</a>
                      </div>
                    }
                    onChange={(evt) => this.updateCbValue(evt)}
                  />

                  {!!this.state.errorMessage.accettaPrivacyPolicy && (
                    <p className="MuiFormHelperText-root Mui-error css-1wc848c-MuiFormHelperText-root">
                      {this.state.errorMessage.accettaPrivacyPolicy}{' '}
                    </p>
                  )}

                  <FormControlLabel
                    control={
                      <EQSquareCheckbox
                        id="accettaMarketing"
                        sx={{ '&.Mui-checked': { color: '#fff' } }}
                      />
                    }
                    label={
                      <div className="cbLabel">
                        Accetto{' '}
                        <a href="/marketing">marketing e comunicazione</a>
                      </div>
                    }
                    onChange={(evt) => this.updateCbValue(evt)}
                  />
                  {/* <FormControlLabel control={<EQSquareCheckbox id="iscrittoNewsletter" sx={{'&.Mui-checked': {color: '#fff',},}} />} label="Ricevi newsletter" onChange={(evt) => this.updateCbValue(evt)} /> */}
                </FormGroup>
              </Box>
            </Box>
            <Box
              sx={{ textAlign: 'center' }}
              pb={3}>
              <button
                className="buttonGreen"
                onClick={() => this.doRegistration()}>
                REGISTRATI, &Egrave; GRATIS
              </button>
            </Box>
          </Box>
        </Box>
      )
    }

    return <div>{content}</div>
  }
}
export default withRouter(EQRegistrationModal)
