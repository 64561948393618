import { PickersDay, StaticDatePicker } from '@mui/lab'

import { Button } from '@mui/material'
import React from 'react'
import TextField from '@mui/material/TextField'

export default class EQWeekendDatePicker extends React.Component {
  constructor(props) {
    super(props)
  }

  isWeekEnd(d) {
    let dayOfWeek = d.getDay()
    return dayOfWeek === 6 || dayOfWeek === 0 // 6 saturday, 0 sunday
  }

  findIndexDate(date, dates) {
    return dates.findIndex((x) => {
      return x.valueOf() === date.valueOf()
    })
  }

  handleClick(date, e) {
    if (this.props.disablePast) {
      let tomorrow = new Date()
      tomorrow.setDate(tomorrow.getDate() + 1)
      tomorrow.setHours(0, 0, 0, 0)
      if (date < tomorrow) return
    }

    if (this.props.maxDate) {
      if (date > this.props.maxDate) return
    }

    let elem = e.target
    if (elem.tagName.toLowerCase() === 'span') elem = elem.parentNode
    elem.classList.toggle('eq-weekend-selected')
    if (this.props.onChange) this.props.onChange(date)
  }

  render() {
    return (
      <div className="eq-weekend-picker">
        <StaticDatePicker
          disablePast={this.props.disablePast}
          minDate={this.props.minDate}
          maxDate={this.props.maxDate}
          displayStaticWrapperAs="desktop"
          openTo="day"
          renderDay={(date, selectedDates, pickersDayProps) => {
            let css = ''
            let idx = this.findIndexDate(date, this.props.value)
            if (idx >= 0) css = 'eq-weekend-selected'
            if (date.getDay() === 6) {
              let sat = date.getDate()
              let tomorrow = new Date(date)
              tomorrow.setDate(tomorrow.getDate() + 1)
              let sun = tomorrow.getDate()
              let timeRelativeStyle = 'future'
              if (new Date() > date) timeRelativeStyle = 'past'
              return (
                <Button
                  key={date.getTime()}
                  className={'eq-weekend-btn ' + css + ' ' + timeRelativeStyle}
                  onClick={(e) => this.handleClick(date, e)}>
                  <span>{sat}</span> <span>{sun}</span>
                </Button>
              )
            }
            return (
              <PickersDay
                key={date.getTime()}
                {...pickersDayProps}
              />
            )
          }}
          shouldDisableDate={(d) => {
            return !this.isWeekEnd(d)
          }}
          renderInput={(params) => <TextField {...params} />}
          onChange={() => {}}
        />
      </div>
    )
  }
}
