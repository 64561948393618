import './EQBookModal.scss'

// ! delete above line for enable eslint jsx-a11y/anchor-is-valid
import { Box, Modal } from '@mui/material'
import React, { Fragment } from 'react'

import CancelBookNodal from './CancelBookModal'
import ClearIcon from '@mui/icons-material/Clear'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import EQCalendarStates from '../../libs/EQCalendarStates'
import EQFloatingFormIcon from '../core/EQFloatingFormIcon'
import EQLoading from '../EQLoading'
import EQService from '../../services/EQService'
import EQServiceBook from '../../services/EQServiceBook'
import EventIcon from '@mui/icons-material/CalendarMonthTwoTone'
import Formats from '../../libs/Formats'
import LocalHospitalIcon from '@mui/icons-material/AccessibilityNewTwoTone'
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import QuestionAnswerTwoToneIcon from '@mui/icons-material/QuestionAnswerTwoTone'
import SendIcon from '@mui/icons-material/Send'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  maxHeight: 500,
  width: '90%',
  height: '90%',
  bgcolor: 'background.paper',
  border: '0',
  boxShadow: 24,
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '20px 20px 0 0',
}

export default class EQBookModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mBook: this.props.mBook,
      comuni: this.props.comuni,
      mOpen: this.props.mOpen,
      cancel_open: false,
      chatText: false,
    }
  }

  componentDidMount() {
    const theBook = this.state.mBook
    const appointmentDateStart = Formats.fromServerDate(
      theBook.servizioPrenotazione.richiestoDal
    )
    if (appointmentDateStart > new Date()) {
      this.loadPenali()
    }
  }

  closeModal() {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  cancelBook() {
    this.setState({
      cancel_open: true,
    })
  }

  bookingCancelDone() {
    this.setState({
      cancel_open: false,
    })
    this.closeModal()
  }

  bookingCancelCancelled() {
    this.setState({
      cancel_open: false,
    })
  }

  loadPenali() {
    const theBook = this.state.mBook
    EQServiceBook.getPenali(theBook.idCalendario).then((x) => {
      this.setState({
        penali: x,
      })
    })
  }

  getMansioni(mansioni) {
    let mansioniString = ''
    mansioni.forEach((m, index) => {
      mansioniString +=
        m.mansione.nome + (index < mansioni.length - 1 ? ' - ' : '')
    })
    return mansioniString
  }

  manageChat() {
    this.setState({ chatText: !this.state.chatText })
  }

  sendText() {
    const textArea = document.getElementById('textArea')
    const textMessage = textArea.value
    const caregiverID = this.props.mBook.idProfessionista
    if (textMessage) {
      EQService.sendMessage(
        caregiverID,
        this.state.mBook.idCalendario,
        textMessage
      )
    }
    textArea.value = ''
    this.manageChat()
  }
  textAreaAdjust() {
    const element = document.getElementById('textArea')

    element.style.height = '1px'
    element.style.height = 25 + element.scrollHeight + 'px'
  }

  render() {
    if (!this.state.mBook) return null
    const theBook = this.state.mBook
    const appointmentDateStart = Formats.fromServerDate(
      theBook.servizioPrenotazione.richiestoDal
    )
    const appointmentDateEnd = Formats.fromServerDate(
      theBook.servizioPrenotazione.richiestoAl
    )
    const dayNames = Formats.dayNames()
    const monthNames = Formats.monthNames()

    let patologie = null
    if (theBook.servizioPrenotazione.prenotazione.paziente.pazientePatologias) {
      patologie =
        theBook.servizioPrenotazione.prenotazione.paziente.pazientePatologias
          .map(({ patologia }) => patologia.nome)
          .join(', ')
    }

    console.clear()
    console.log(theBook)
    let comune = null
    if (theBook && this.state.comuni) {
      comune = this.state.comuni.find(
        (x) => x.idComune === theBook.servizioPrenotazione.prenotazione.idComune
      )
    }

    console.log(theBook)

    let bookStatusIcon = ''

    let st = { backgroundColor: theBook.tipoOccupazioneCalendario.codiceColore }
    if (
      theBook.tipoOccupazioneCalendario.idTipoOccupazione ===
      EQCalendarStates.ServizioInAttesaDiConfermaProfessionista
    ) {
      bookStatusIcon = (
        <img
          src="/img/ico_spinner.png"
          alt="In attesa di conferma"
        />
      )
    } else if (
      theBook.tipoOccupazioneCalendario.idTipoOccupazione ===
      EQCalendarStates.ServizioConfermatoDalProfessionista
    ) {
      bookStatusIcon = <DoneIcon />
    } else if (
      theBook.tipoOccupazioneCalendario.idTipoOccupazione ===
      EQCalendarStates.ServizioRifiutatoDalProfessionista
    ) {
      bookStatusIcon = <ClearIcon />
      st.color = ['white']
    } else if (
      theBook.tipoOccupazioneCalendario.idTipoOccupazione ===
      EQCalendarStates.ServizioIniziato
    ) {
      bookStatusIcon = <ClearIcon />
      st.color = ['white']
    }

    console.clear()
    console.log(comune)

    return (
      <Fragment>
        <Modal
          open={this.state.mOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box
            sx={style}
            className="bookModal">
            <h3
              className="playFair"
              style={{ marginTop: '10px' }}>
              Dettaglio appuntamento
            </h3>
            <Box
              className="textRight"
              style={{ position: 'relative', top: '-52px', zIndex: '1200' }}>
              <button
                onClick={() => this.closeModal()}
                className="iconClose clickable">
                <Box
                  mt={1}
                  mr={0}>
                  <CloseIcon></CloseIcon>
                </Box>
                <div>CHIUDI</div>
              </button>
            </Box>
            <Box
              pb={4}
              px={4}
              className="enlarge-height"
              style={{
                marginTop: '-20px',
                overflow: 'auto',
                height: '300px ',
              }}>
              {/*<p>Lorem ipsum sit dolora amen et nonnulla est tam bona quam bella. Omnia Gallia.</p>*/}

              <Box className="bookDetailContainer">
                <EQFloatingFormIcon></EQFloatingFormIcon>
                <Box
                  pl={2}
                  className="mb-5">
                  <Box className="bookDetailContent inline-block">
                    <Box>SERVIZIO</Box>
                    <Box>
                      <strong>
                        {theBook.servizioPrenotazione.servizio.nome}
                      </strong>
                    </Box>
                    <Box pl={1}>
                      <em>
                        {this.getMansioni(
                          theBook.servizioPrenotazione
                            .mansioneServizioPrenotaziones
                        )}
                      </em>
                    </Box>
                  </Box>
                </Box>
                <EQFloatingFormIcon extra={'unset -mb-10 mr-4 '}>
                  <EventIcon />
                </EQFloatingFormIcon>
                <Box
                  pb={2}
                  pl={3}>
                  <Box className="bookDetailContent inline-block">
                    <Box className="dateText">
                      {dayNames[appointmentDateStart.getDay()]}{' '}
                      {appointmentDateStart.getDate()}{' '}
                      {monthNames[appointmentDateStart.getMonth()]}
                    </Box>
                    <Box>
                      dalle ore:{' '}
                      <strong>
                        {('0' + appointmentDateStart.getHours()).slice(-2)}:
                        {('0' + appointmentDateStart.getMinutes()).slice(-2)}
                      </strong>
                    </Box>
                    <Box>
                      alle ore:{' '}
                      <strong>
                        {('0' + appointmentDateEnd.getHours()).slice(-2)}:
                        {('0' + appointmentDateEnd.getMinutes()).slice(-2)}
                      </strong>
                    </Box>
                  </Box>
                </Box>
                <EQFloatingFormIcon extra={'unset -mb-10'}>
                  <LocalHospitalIcon />
                </EQFloatingFormIcon>
                <Box
                  pb={2}
                  pl={2}>
                  <Box className="bookDetailContent inline-block">
                    ASSISTENTE
                  </Box>
                  <Box>
                    <strong>{theBook.professionista.nome}</strong>
                  </Box>
                </Box>
                <Box className="textRight">
                  <label>IL TUO CODICE DI PRENOTAZIONE</label>
                  <Box>
                    <strong>
                      {theBook.servizioPrenotazione.prenotazione.codicePratica}
                    </strong>
                  </Box>
                </Box>
                <EQFloatingFormIcon></EQFloatingFormIcon>
                <Box
                  pb={2}
                  pl={2}>
                  <Box className="bookDetailContent inline-block">
                    <label>NOME E COGNOME</label>
                    <Box>
                      <strong>
                        {
                          theBook.servizioPrenotazione.prenotazione.paziente
                            .nome
                        }{' '}
                        {
                          theBook.servizioPrenotazione.prenotazione.paziente
                            .cognome
                        }
                      </strong>
                    </Box>
                  </Box>
                </Box>
                <EQFloatingFormIcon extra={'unset -mb-10'}>
                  <LocationOnOutlinedIcon />
                </EQFloatingFormIcon>
                <Box
                  pb={2}
                  pl={2}>
                  <Box className="bookDetailContent inline-block">
                    <label>L'ATTIVIT&Agrave; SI SVOLGER&Agrave; IN</label>
                    <Box>
                      <strong>
                        {theBook.servizioPrenotazione.prenotazione.indirizzo}
                      </strong>
                    </Box>
                    <Box>
                      <strong>
                        {comune.cap} {comune.nome} ({comune.provincia})
                      </strong>
                    </Box>
                  </Box>
                </Box>
                <EQFloatingFormIcon extra={'unset -mb-10'}>
                  <LocalHospitalOutlinedIcon />
                </EQFloatingFormIcon>
                <Box
                  pb={2}
                  pl={2}>
                  <label>PATOLOGIE</label>
                  <Box>
                    <strong>{patologie}</strong>
                  </Box>
                </Box>
              </Box>

              {appointmentDateStart > new Date() &&
                EQCalendarStates.canStateBeCancelled(theBook) && (
                  <div
                    className="cancellation"
                    style={{ marginTop: '8px' }}>
                    {this.state.penali && (
                      <Box
                        sx={{ textAlign: 'center' }}
                        mt={4}
                        mb={4}>
                        {this.state.penali.percentualeApplicata !== 0 && (
                          <div className="cancellation-policy">
                            Nel caso di cancellazione, entro le ore&nbsp;
                            {Formats.dateToHourMinutes(
                              Formats.fromServerDate(
                                this.state.penali.dataLimite
                              )
                            )}
                            &nbsp;del&nbsp;
                            {Formats.dateToDayMonthShort(
                              Formats.fromServerDate(
                                this.state.penali.dataLimite
                              )
                            )}
                            &nbsp;verrà applicata una penale del&nbsp;
                            {this.state.penali.percentualeApplicata}% pari
                            a&nbsp;
                            {this.state.penali.importoPenale}&euro;
                          </div>
                        )}
                        {this.state.penali.percentualeApplicata === 0 && (
                          <div className="cancellation-policy">
                            La cancellazione è gratuita fino alle ore&nbsp;
                            {Formats.dateToHourMinutes(
                              Formats.fromServerDate(
                                this.state.penali.dataLimite
                              )
                            )}
                            &nbsp;del&nbsp;
                            {Formats.dateToDayMonthShort(
                              Formats.fromServerDate(
                                this.state.penali.dataLimite
                              )
                            )}
                          </div>
                        )}
                        <div className="flex-row-center">
                          <a
                            className="buttonLink bg-red text-white"
                            onClick={() => this.cancelBook()}>
                            ANNULLA APPUNTAMENTO
                          </a>
                        </div>
                      </Box>
                    )}
                    {this.state.penali === null && (
                      <EQLoading message="Caricamento politiche di cancellazione..." />
                    )}
                  </div>
                )}

              <Box
                sx={{ textAlign: 'center' }}
                mt={1}
                mb={1}>
                <a
                  className="linkunderlined"
                  onClick={() => this.closeModal()}>
                  CHIUDI FINESTRA
                </a>
              </Box>
            </Box>
            <div
              className="serviceFooter"
              style={st}>
              <div className="serviceFooterContainer">
                {bookStatusIcon} {theBook.tipoOccupazioneCalendario.nome}
              </div>
            </div>

            <div
              id="chat-container"
              style={{ display: 'absolute', zIndex: 2000 }}>
              {this.state.chatText && (
                <div
                  className="bubble"
                  style={{ zIndex: 2000 }}>
                  <textarea
                    id="textArea"
                    onKeyUp={() => this.textAreaAdjust()}
                    style={{ overflow: 'hidden', zIndex: 2000 }}
                    rows="2"
                    cols="20"
                    placeholder="inserisci il messaggio qui..."
                  />
                  <button
                    className="send-button"
                    onClick={() => this.sendText()}>
                    {' '}
                    <SendIcon />{' '}
                  </button>
                </div>
              )}
              <button
                className="chat-button"
                onClick={() => this.manageChat()}>
                <QuestionAnswerTwoToneIcon sx={{ color: 'white' }} />
              </button>
            </div>
          </Box>
        </Modal>

        {this.state.cancel_open && (
          <CancelBookNodal
            onCancel={() => this.bookingCancelCancelled()}
            onDone={() => this.bookingCancelDone()}
            booking={theBook}
          />
        )}
      </Fragment>
    )
  }
}
