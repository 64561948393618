import React, { Fragment } from 'react'
import { slide as Menu } from 'react-burger-menu'
import EQState from '../services/EQState'
import { Box } from '@mui/material'
import EQService from '../services/EQService'
import EQBusinessRules from '../libs/EQBusinessRules'
import Utils from '../libs/Utils'
import './EQMobileMenu.scss'

export default class EQMobileMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tipologieServizio: null,
      menuOpen: false,
      UserSelection: EQState.UserSelection,
    }
  }

  isMenuOpen() {}

  componentDidMount() {
    EQState.addListener(EQState.UserSelection, this)
    EQService.mapTipologieServizio().then((m) => {
      this.setState({ tipologieServizio: m })
    })
    if (!EQState.Contents.HOME) {
      EQService.mapContents('HOME').then((m) => {
        EQState.change(EQState.Contents, {
          HOME: m,
        })
        this.forceUpdate()
      })
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset'
    EQState.removeListener(EQState.UserSelection, this)
  }

  call() {
    try {
      window.gtag('event', 'clickTelefono', {})
    } catch (error) {}
    EQService.phoneCallBy('client')
  }

  handleOnOpen() {
    this.setState({ menuOpen: true })
    document.body.style.overflow = 'hidden'
  }

  handleOnClose() {
    document.body.style.overflow = 'unset'
    this.setState({ menuOpen: false })
  }

  showHp(event) {
    /*
        event.preventDefault();
        EQState.change(EQState.UserSelection, {
            showGroupServices:false,
            GruppoServizio: null,
            showServices: false
        });
        this.handleOnClose();
        */
    document.location.href = '/'
  }

  openGroupServices(event, idTipologia, url) {
    event.preventDefault()
    try {
      window.gtag('event', 'clickServizio', {
        value: url,
      })
    } catch (error) {}

    EQState.change(EQState.UserSelection, {
      idTipologiaServizio: idTipologia,
      showGroupServices: true,
      GruppoServizio: null,
      showServices: false,
    })
    if (!window.location.pathname.startsWith('/services/1'))
      window.location.href = '/services/' + idTipologia + '-' + url
    else this.handleOnClose()
  }

  render() {
    if (!EQState.Contents.HOME) return null

    const MC = EQState.Contents.HOME
    return (
      <Fragment>
        <Menu
          customCrossIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24">
              <path
                d="M0 0h24v24H0z"
                fill="none"
              />
              <path
                fill="#ffffff"
                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
              />
            </svg>
          }
          isOpen={this.state.menuOpen}
          onOpen={() => this.handleOnOpen()}
          onClose={() => this.handleOnClose()}
          onStateChange={() => this.isMenuOpen()}
          className="burgerMenu"
          width={'100%'}>
          <div className="menuTop">
            <div className="menuServices">
              <a
                href={'/services/100-' + Utils.urlify(MC.SERVIZIO1.SottoTitolo)}
                id="lnkMenuEmergenza"
                className="menu-item txtEMERGENZA"
                onClick={(e) =>
                  this.openGroupServices(
                    e,
                    100,
                    Utils.urlify(MC.SERVIZIO1.SottoTitolo)
                  )
                }>
                {MC.SERVIZIO1.SottoTitolo}
              </a>

              {this.state.tipologieServizio &&
                this.state.tipologieServizio.map((tipologiaServizio) => {
                  let disabled = false
                  if (EQBusinessRules.isServiceGroupDisabled(tipologiaServizio))
                    disabled = true
                  if (disabled) return
                  return (
                    <a
                      key={tipologiaServizio.idTipologiaServizio}
                      href={
                        '/services/' +
                        tipologiaServizio.idTipologiaServizio +
                        '-' +
                        Utils.urlify(tipologiaServizio.nome)
                      }
                      onClick={(e) =>
                        this.openGroupServices(
                          e,
                          tipologiaServizio.idTipologiaServizio,
                          Utils.urlify(tipologiaServizio.nome)
                        )
                      }
                      className={`menu-item txt${tipologiaServizio.codice} cursor-pointer`}>
                      {tipologiaServizio.nome}
                    </a>
                  )
                })}
            </div>
            <div className="menuPages">
              <a
                href="/"
                id="lnkMenuHp"
                className="menu-item">
                _Pagina principale
              </a>
              <a
                href="/siamo-diversi"
                id="lnkMenuDifferent"
                className="menu-item">
                _Perchè siamo diversi
              </a>
              <a
                href="/i-nostri-servizi"
                id="lnkNostriServizi"
                className="menu-item">
                _I nostri servizi
              </a>
              <a
                href="/servizio-clienti"
                id="lnkMenuCustomerService"
                className="menu-item">
                _Servizio Clienti
              </a>
              <a
                href="/faq"
                id="lnkMenuFaq"
                className="menu-item">
                _Domande e risposte
              </a>
            </div>
          </div>
          <div className="menuBottom">
            {/* era https://docs.google.com/forms/d/e/1FAIpQLScYpfcH_7cAGIr8LlmXzK0cANn-e8TH3sWQf0AQD2WhLKcihg/viewform */}
            <a
              href="https://forms.gle/jk8MP4yJD2HLr1Xv6"
              target="_blank"
              rel="noreferrer noopener"
              className="menu-item ">
              Vuoi entrare nel team di EqueCure?
            </a>
            <a
              href="mailto:info@equecure.com?subject=Richiesta informazioni partner"
              className="menu-item ">
              Vuoi diventare una cooperativa partner?
            </a>

            <div className="menuBottomFooter">
              <div className="menuContacts">
                <Box>
                  <img
                    onClick={this.call}
                    src="/img/telefono.svg"
                    alt="chiama EqueCure"
                    className="svg-style cursor-pointer"
                  />
                </Box>

                {/* 
                                <Box>
                                    <div className='whatsapp'>
                                        <div className="text">Whatsapp</div>
                                        <div className="number">
                                            349 4594566
                                            <div className='icoContainer'><img src="/img/ico_whatsapp.svg" alt="Whatsapp" /></div>
                                            
                                        </div>
                                    </div>
                                </Box>
                                */}
              </div>

              <div className="menuPrivacy">
                <a
                  href="/privacy"
                  className="menu-item  ">
                  Privacy Policy
                </a>
                <a
                  href="/condizioni"
                  className="menu-item ">
                  Condizioni d'uso
                </a>
                <a
                  href="/cookie-policy"
                  className="menu-item ">
                  Cookie Policy
                </a>
              </div>
            </div>
          </div>
        </Menu>
      </Fragment>
    )
  }
}
