import Cookies from 'universal-cookie'
import EQState from './EQState'
import Pages from './Pages'
import Utils from '../libs/Utils'
import axios from 'axios'

export default class EQService {
  static baseUrl =
    EQService.isDevelopment() || EQService.isPreProduction()
      ? 'https://cure-prepro.dev2016.memetic.it/api/'
      : '/api/'

  static DEBUG = false
  static sessionRelinked = false
  static contents = {}
  static content = {}
  static tipologieServizio = {}
  static gruppiServizio = {}
  static sessioneWeb = {}
  static prenotazione = {}

  static isDevelopment() {
    return window.location.href.indexOf('localhost') >= 0
  }

  static isPreProduction() {
    return window.location.href.indexOf('cure-prepro') >= 0
  }

  static get(url, params = null, settings = null) {
    if (!settings) settings = {}

    let str = []
    for (let p in params)
      if (params.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(params[p]))
      }

    let finalUrl = EQService.baseUrl + url
    if (params) {
      finalUrl = finalUrl + '?' + str.join('&')
    }
    return axios.get(finalUrl).catch((e) => {
      if (this.DEBUG) console.error('Errors in call', e)
      if (!settings.disableErrorAlert) {
        if (e.response && e.response.data && e.response.data.exceptionMessage) {
          Utils.notifyError(e.response.data.exceptionMessage)
          console.error(url, e.response.data.exceptionMessage)
        } else {
          let message = 'Si è verificato un errore'
          Utils.notifyError(message)
          console.error(url, message)
        }
      }
    })
  }

  static post(url, params = null, conf = null, settings = null) {
    if (!settings) settings = {}
    let finalUrl = EQService.baseUrl + url
    return axios.post(finalUrl, params, conf).catch((e) => {
      if (this.DEBUG) console.error('Errors in call', e)
      if (!settings.disableErrorAlert) {
        if (e.response && e.response.data && e.response.data.exceptionMessage) {
          Utils.notifyError(e.response.data.exceptionMessage)
        } else {
          Utils.notifyError('Si è verificato un errore')
        }
      }
    })
  }

  static getForcedSessionId() {
    const pars = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    })
    let tk = pars['tk']
    return tk
  }

  static sessionId() {
    // Se è stata passata una sessione come parametro URL, la recuperiamo di li
    let tk = EQService.getForcedSessionId()
    if (tk) {
      console.log('Getting session id from tk')
      return tk
    }
    // Se nel local storage è presente una sessione utente salvata,
    // con all'interno l'id di sessione lato server, allora restituiamo questa
    if (EQState.UserSelection && EQState.UserSelection._idSessioneWeb) {
      console.log(
        'Getting session id from EQState.UserSelection._idSessioneWeb'
      )
      return EQState.UserSelection._idSessioneWeb
    }
    if (EQState.Booking && EQState.Booking.IdSessioneWeb) {
      console.log('Getting session id from EQState.Booking.IdSessioneWeb')
      return EQState.Booking.IdSessioneWeb
    }
    if (EQState.CaregiverHome && EQState.CaregiverHome._IdSessioneWeb) {
      console.log(
        'Getting session id from EQState.CaregiverHome._IdSessioneWeb'
      )
      return EQState.CaregiverHome._IdSessioneWeb
    }

    // Altrimenti cerchiamo di recuperare l'id di sessione server dai cookie
    const cookies = new Cookies()
    if (!cookies.get('sessionID')) {
      console.warn('sessionId not in cookies')
      return null
    }
    console.log('Getting session id from cookies')
    const sId = cookies.get('sessionID')
    return sId
  }

  static relinkSessionId(cb) {
    if (EQService.sessionRelinked) {
      console.info('Session is already relinked to the server')
      if (cb) cb()
      return
    }

    const cookies = new Cookies()
    let sId = EQService.sessionId()
    if (!sId) {
      console.info('Creating a new session id')
      EQService.creaSessione().then((s) => {
        if (!s) return
        cookies.set('sessionID', s.idSessioneWeb, { path: '/' })
        EQService.sessionRelinked = true
        if (cb) cb()
      })
    } else {
      console.info("Getting an old session using id '" + sId + "'")
      EQService.getSessione(sId).then((s) => {
        if (!s) return
        if (s.scaduta) {
          console.warn('Session is no more ready, creating a new one')
          EQService.creaSessione().then((s) => {
            if (!s) return
            cookies.set('sessionID', s.idSessioneWeb, { path: '/' })
            EQService.sessionRelinked = true
            if (cb) cb()
          })
        } else {
          EQService.sessionRelinked = true
          if (cb) cb()
        }
      })
    }
  }

  static resetLocalSession(session) {
    const sessionId = session.idSessioneWeb
    console.groupCollapsed('Resetting session id to ' + sessionId)

    let oldSessionId = EQState.UserSelection._idSessioneWeb

    if (EQState.UserSelection) {
      EQState.UserSelection._idSessioneWeb = sessionId
      console.warn('Checking isJolly', session)
      EQState.UserSelection.isJollyWorking = session.isJolly === true
      EQState.persist(EQState.UserSelection)
    }
    if (oldSessionId !== sessionId) {
      console.warn('Resetting EQState.Booking due different session id')
      EQState.reset(EQState.Booking)
      EQState.persist(EQState.Booking)
    }

    const cookies = new Cookies()
    cookies.set('sessionID', sessionId)
    console.groupEnd()
  }

  static creaSessione() {
    let result = new Promise((resolve, reject) => {
      EQService.get('/SessioneWeb/CreaNuovaSessioneAnonima').then((res) => {
        if (!res) return
        const r = res.data
        EQService.sessioneWeb = r
        EQService.resetLocalSession(r)
        resolve(r)
      })
    })
    return result
  }

  static getSessione(idSessioneWeb) {
    // ce' la keepalive per mantenere attive le sessioni
    let result = new Promise((resolve, reject) => {
      EQService.get(
        '/SessioneWeb/GetById',
        { idSessioneWeb: idSessioneWeb },
        { disableErrorAlert: true }
      )
        .then((res) => {
          if (!res) return
          const r = res.data
          if (this.DEBUG) console.log('Get session', r)
          EQService.sessioneWeb = r
          EQService.resetLocalSession(r)
          resolve(r)
        })
        .catch((e) => {
          console.error('getSessione::Error', e)
        })
    })
    return result
  }

  static isUserNameInUse(username) {
    let result = new Promise((resolve, reject) => {
      EQService.get('/Cliente/IsUserNameInUse', { username: username }).then(
        (res) => {
          if (!res) return
          const y = res.data
          resolve(y)
        }
      )
    })
    return result
  }

  static isEmailInUse(email) {
    let result = new Promise((resolve, reject) => {
      EQService.get('/Cliente/IsEmailInUse', { email: email }).then((res) => {
        if (!res) return
        const y = res.data
        resolve(y)
      })
    })
    return result
  }

  static isMobileInUse(mobile) {
    let result = new Promise((resolve, reject) => {
      EQService.get('/Cliente/IsMobileInUse', { mobile: mobile }).then(
        (res) => {
          if (!res) return
          const y = res.data
          resolve(y)
        }
      )
    })
    return result
  }

  static isLogged() {
    if (!EQState.UserSelection.IsLogged) {
      //console.log("NOT LOGGED 1");
      return false
    }
    const lastLogin = new Date(EQState.UserSelection._loggedTime)
    const elapsed = new Date().getTime() - lastLogin.getTime()
    if (elapsed / (1000 * 60 * 60 * 24) > 1) {
      if (!EQState.UserSelection._rememberMe) {
        //console.log("NOT LOGGED 2");
        return false
      }
    }
    this.getSessione(EQService.sessionId()).then((s) => {
      if (!s) return
      if (!s.autenticata) {
        //console.log("NOT LOGGED 3");
        return false
      }
    })
    //console.log(" LOGGED");

    return true
  }

  static logout() {
    EQState.reset(EQState.UserSelection)
    EQState.reset(EQState.Booking)

    const cookies = new Cookies()
    cookies.remove('sessionID')
  }

  static login(login, password) {
    const sId = EQService.sessionId()
    let result = new Promise((resolve, reject) => {
      EQService.get('/SessioneWeb/DoLoginCliente', {
        username: login,
        password: password,
        idSessioneWeb: sId,
      }).then((res) => {
        if (!res) return
        const y = res.data
        resolve(y)
      })
    })
    return result
  }

  static loginPerson(login, password) {
    const sId = EQService.sessionId()
    let result = new Promise((resolve, reject) => {
      EQService.get('/SessioneWeb/DoLogin', {
        username: login,
        password: password,
        idSessioneWeb: sId,
      }).then((res) => {
        if (!res) return
        const y = res.data
        resolve(y)
      })
    })
    return result
  }

  static isProfessionista(sessionID) {
    let result = new Promise((resolve, reject) => {
      EQService.get('/SessioneWeb/IsProfessionista', {
        idSessioneWeb: sessionID,
      }).then((res) => {
        if (!res) return
        const y = res.data
        resolve(y)
      })
    })
    return result
  }

  static resetPassword(email) {
    let result = new Promise((resolve, reject) => {
      EQService.get('/SessioneWeb/RichiediCambioPassword', {
        userEmail: email,
      }).then((res) => {
        if (!res) return
        const y = res.data
        resolve(y)
      })
    })
    return result
  }

  static confirmResetPassword(newPassword, guid) {
    let result = new Promise((resolve, reject) => {
      EQService.get('/SessioneWeb/ConfermaCambioPassword', {
        guid: guid,
        newPassword: newPassword,
      }).then((res) => {
        if (!res) return
        const y = res.data
        resolve(y)
      })
    })
    return result
  }

  static loginProfessionista(login, password) {
    const sId = EQService.sessionId()
    let result = new Promise((resolve, reject) => {
      EQService.get('/SessioneWeb/DoLoginProfessionista', {
        username: login,
        password: password,
        idSessioneWeb: sId,
      }).then((res) => {
        if (!res) return
        const y = res.data
        resolve(y)
      })
    })
    return result
  }

  static changeEmail(email) {
    const sId = EQService.sessionId()
    let result = new Promise((resolve, reject) => {
      EQService.get('/SessioneWeb/ChangeEmail', {
        newEmail: email,
        idSessioneWeb: sId,
      }).then((res) => {
        if (!res) return
        const y = res.data
        resolve(y)
      })
    })
    return result
  }

  static changeMobilePhone(n) {
    const sId = EQService.sessionId()
    let result = new Promise((resolve, reject) => {
      EQService.get('/SessioneWeb/ChangeMobilePhone', {
        newEmail: n,
        idSessioneWeb: sId,
      }).then((res) => {
        if (!res) return
        const y = res.data
        resolve(y)
      })
    })
    return result
  }

  static sendOtp() {
    let result = new Promise((resolve, reject) => {
      EQService.get('/SessioneWeb/GeneraEInviaOTP', {
        idSessioneWeb: EQService.sessionId(),
      }).then((res) => {
        if (!res) return
        const y = res.data
        resolve(y)
      })
    })
    return result
  }

  static otp(otp, idUser) {
    let result = new Promise((resolve, reject) => {
      EQService.get('/Cliente/VerificaOTP', {
        idUser: idUser,
        OTP: otp,
      }).then((res) => {
        if (!res) return
        const y = res.data
        resolve(y)
      })
    })
    return result
  }

  static registration(
    email,
    mobilePhone,
    username,
    nonCryptedPassword,
    cognome,
    nome,
    accettaCondizioniUso,
    accettaPrivacyPolicy,
    accettaMarketing,
    iscrittoNewsletter
  ) {
    let result = new Promise((resolve, reject) => {
      EQService.get('/Cliente/CreaNuovo', {
        email: email,
        nome: nome,
        cognome: cognome,
        mobilePhone: mobilePhone,
        username: username,
        nonCryptedPassword: nonCryptedPassword,
        accettaCondizioniUso: accettaCondizioniUso,
        accettaPrivacyPolicy: accettaPrivacyPolicy,
        accettaMarketing: accettaMarketing,
        iscrittoNewsletter: iscrittoNewsletter,
      }).then((res) => {
        if (!res) return
        const y = res.data
        resolve(y)
      })
    })
    return result
  }

  static getContent(contentCode) {
    let result = new Promise((resolve, reject) => {
      if (EQService.content[contentCode]) {
        resolve(EQService.content[contentCode])
        return
      }

      if (!contentCode) return null
      EQService.get('/cms/GetJollyByCode', { code: contentCode }).then(
        (res) => {
          if (!res) return
          let y = {}
          for (const property in res.data) {
            y[property.replace('jollY_', '')] = res.data[property]
          }

          EQService.content[contentCode] = y
          resolve(y)
        }
      )
    })
    return result
  }

  static removeCachedContents() {
    console.groupCollapsed('Removing cached contents')
    EQService.contents = {}
    EQService.content = {}
    console.groupEnd()
  }

  static mapContentsOnCurrentPage(bypassCache) {
    const page = Pages.current()
    document.title = page.title
    return EQService.mapContents(page.cms, bypassCache)
  }

  static mapContents(areaCode, bypassCache) {
    let result = new Promise((resolve, reject) => {
      if (EQService.contents[areaCode] && !bypassCache) {
        resolve(EQService.contents[areaCode])
        return
      }

      EQService.get('/cms/GetAreaByCode', { code: areaCode }).then((res) => {
        if (!res) return
        let map = {}
        res.data.jollY_Jolly.forEach((x) => {
          let y = {}
          for (const property in x) {
            y[property.replace('jollY_', '')] = x[property]
          }
          map[x.jollY_Codice] = y
        })
        EQService.contents[areaCode] = map
        resolve(map)
      })
    })
    return result
  }

  static mapTipologieServizio() {
    let result = new Promise((resolve, reject) => {
      if (
        EQService.tipologieServizio &&
        EQService.tipologieServizio.length > 0
      ) {
        resolve(EQService.tipologieServizio)
        return
      }

      EQService.get('/Servizio/ListAllTipologie').then((res) => {
        if (!res) return
        let map = res.data
        EQService.tipologieServizio = map
        resolve(map)
      })
    })
    return result
  }

  static getGruppiServizio(idTipologiaServizio) {
    let result = new Promise((resolve, reject) => {
      if (EQService.gruppiServizio[idTipologiaServizio]) {
        resolve(EQService.gruppiServizio[idTipologiaServizio])
        return
      }

      EQService.get('/Servizio/ListGruppiServizioByTipologia', {
        idTipologia: idTipologiaServizio,
      }).then((res) => {
        if (!res) return
        let map = res.data
        EQService.contents = map
        resolve(map)
      })
    })
    return result
  }

  static getGruppiServizioEmergenza() {
    let result = new Promise((resolve, reject) => {
      EQService.get('/Servizio/ListGruppiServizioEmergenza').then((res) => {
        if (!res) return
        let map = res.data
        EQService.contents = map
        resolve(map)
      })
    })
    return result
  }

  static getCategorieMansioni() {
    let result = new Promise((resolve, reject) => {
      EQService.get('/Servizio/ListAllCategorieMansione').then((res) => {
        if (!res) return
        let map = res.data
        resolve(map)
      })
    })
    return result
  }

  static getServizioById(idServizio) {
    let result = new Promise((resolve, reject) => {
      EQService.get('/Servizio/GetServizioById?idServizio=' + idServizio).then(
        (res) => {
          if (!res) return
          let map = res.data
          resolve(map)
        }
      )
    })
    return result
  }

  static getProfessionistiInHome() {
    let result = new Promise((resolve, reject) => {
      EQService.get('/Professionista/GetProfessionistiInHome').then((res) => {
        if (!res) return
        let pps = res.data
        resolve(pps)
      })
    })
    return result
  }

  static getProfessionistiPreferiti(settings) {
    let queryString = '?idSessioneWeb=' + EQService.sessionId()
    let result = new Promise((resolve, reject) => {
      EQService.get(
        '/Professionista/ListProfessionistiPreferiti' + queryString,
        null,
        settings
      ).then((res) => {
        if (!res) return
        let pps = res.data
        resolve(pps)
      })
    })
    return result
  }

  static setProfessionistaPreferito(caregiver) {
    let queryString =
      '?idSessioneWeb=' +
      EQService.sessionId() +
      '&idProfessionista=' +
      caregiver.idProfessionista
    let result = new Promise((resolve, reject) => {
      EQService.get(
        '/Professionista/AddProfessionistaPreferito' + queryString
      ).then((res) => {
        if (!res) return
        let pps = res.data
        resolve(pps)
      })
    })
    return result
  }

  static unsetProfessionistaPreferito(caregiver) {
    let queryString =
      '?idSessioneWeb=' +
      EQService.sessionId() +
      '&idProfessionista=' +
      caregiver.idProfessionista
    let result = new Promise((resolve, reject) => {
      EQService.get(
        '/Professionista/RemoveProfessionistaPreferito' + queryString
      ).then((res) => {
        if (!res) return
        let pps = res.data
        resolve(pps)
      })
    })
    return result
  }

  static getCooperative() {
    let result = new Promise((resolve, reject) => {
      EQService.get('/Professionista/ListAllCooperative').then((res) => {
        if (!res) return
        let pps = res.data
        resolve(pps)
      })
    })
    return result
  }

  static getTipiProfessionista() {
    let result = new Promise((resolve, reject) => {
      EQService.get('/Professionista/ListAllTipoProfessionista').then((res) => {
        if (!res) return
        let pps = res.data
        resolve(pps)
      })
    })
    return result
  }

  static getProfessionistiByIds(ids) {
    let result = new Promise((resolve, reject) => {
      EQService.post('/Professionista/ListProfessionistiByIds', ids).then(
        (res) => {
          try {
            resolve(res.data)
          } catch (e) {
            reject(res)
          }
        }
      )
    })
    return result
  }

  static aggiornaCliente(c) {
    let pars = {
      idSessioneWeb: EQService.sessionId(),
      cognome: c.cognome,
      nome: c.nome,
      accettaMarketing: c.accettaMarketing,
      iscrittoNewsletter: c.iscrittoNewsletter,
    }
    let result = new Promise((resolve, reject) => {
      EQService.get('/Cliente/AggiornaCliente', pars).then((res) => {
        try {
          resolve(res.data)
        } catch (e) {
          reject(res)
        }
      })
    })
    return result
  }

  static cambiaPassword(username, oldPassword, newPassword) {
    let pars = {
      username: username,
      password: oldPassword,
      newPassword: newPassword,
    }
    let result = new Promise((resolve, reject) => {
      EQService.get('/SessioneWeb/ChangePassword', pars).then((res) => {
        if (!res) return
        resolve(res.data)
      })
    })
    return result
  }

  static phoneCallBy(who) {
    let number = ''
    if (who === 'caregiver') {
      number = process.env.REACT_APP_PHONENUMBER_CAREGIVER
    } else if (who === 'client') {
      number = process.env.REACT_APP_PHONENUMBER
    }
    window.open(`tel:${number}`, '_self')
  }

  static sendMessage(recieverId, calendarId, message) {
    const sessionID = EQService.sessionId()
    //! invece di toUserId -> toUserGUID   > controlla su swagger
    const pars = {
      idSessioneWeb: sessionID,
      toUserId: recieverId,
      idCalendario: calendarId,
      messaggio: message,
    }

    let result = new Promise((resolve, reject) => {
      EQService.post('/Chat/SendMessage', pars).then((res) => {
        if (!res) return
        resolve(res.data)
      })
    })
    return result
  }

  static sendReply(idMessageToReply, messaggio) {
    const idSessioneWeb = EQService.sessionId()
    const pars = {
      idSessioneWeb: idSessioneWeb,
      idMessageToReply: idMessageToReply,
      messaggio: messaggio,
    }

    let result = new Promise((resolve, reject) => {
      EQService.post('/Chat/SendReply', pars).then((res) => {
        if (!res) return
        resolve(res.data)
      })
    })
    return result
  }

  static GetMessaggioById(idMessaggio) {
    const idSessioneWeb = EQService.sessionId()
    const pars = {
      idSessioneWeb: idSessioneWeb,
      idMessaggio: idMessaggio,
    }

    let result = new Promise((resolve, reject) => {
      EQService.get('/Chat/GetMessaggioById', pars).then((res) => {
        if (!res) return
        resolve(res.data)
      })
    })
    return result
  }
}
