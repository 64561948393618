import { Box, Container } from '@mui/material'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import EQBookStep_Advisory from '../components/book/EQBookStep_Advisory'
import EQBookStep_Caregivers from '../components/book/EQBookStep_Caregivers'
import EQBookStep_Cart from '../components/book/EQBookStep_Cart'
import EQBookStep_Dates from '../components/book/EQBookStep_Dates'
import EQBookStep_DoBook from '../components/book/EQBookStep_DoBook'
import EQBookStep_Do_Setup from '../components/book/EQBookStep_Do_Setup'
import EQBookStep_Error from '../components/book/EQBookStep_Error'
import EQBookStep_Location from '../components/book/EQBookStep_Location'
import EQBookStep_Medical from '../components/book/EQBookStep_Medical'
import EQBookStep_Otp from '../components/book/EQBookStep_Otp'
import EQBookStep_Person from '../components/book/EQBookStep_Person'
import EQBookStep_Recap from '../components/book/EQBookStep_Recap'
import EQBookStep_RetryPayment from '../components/book/EQBookStep_RetryPayment'
import EQBookStep_Success from '../components/book/EQBookStep_Success'
import EQBook_SendPayLink from '../components/book/EQBook_SendPayLink'
import EQBusinessRules from '../libs/EQBusinessRules'
import EQFooter from '../components/EQFooter'
import EQHeaderBook from '../components/EQHeaderBook'
import EQService from '../services/EQService'
import EQState from '../services/EQState'
import EQStepBar from '../components/EQStepBar'
import Formats from '../libs/Formats'
import { MuiThemeProvider } from 'material-ui/styles'
/* eslint-disable react/jsx-pascal-case */
import React from 'react'

let interval = null

export default class PageBook extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      UserSelection: EQState.UserSelection,
    }
  }

  reloadState() {
    this.setState({
      UserSelection: EQState.UserSelection,
    })
  }

  componentDidMount() {
    if (!this.isSessionReady()) {
      document.location.href = '/'
      return
    }

    EQState.UserSelection = EQState.retrieve(EQState.UserSelection)

    if (this.isAlertVisible()) {
      this.startTimeout()
    }

    window.scrollTo(0, 0)
    EQState.addListener(EQState.UserSelection, this)
    console.log(document.location.pathname)
  }

  /* a ogni cambio di pagina, se isAlertVisible avrebbe valore false resetto il timer, sennò stabilito un setTimeout(_,0),
    così da permettere a react di valutare il path corrente e che il timer non sia in corso, faccio partire un timer
     */
  componentDidUpdate() {
    setTimeout(() => {
      if (this.isAlertVisible()) {
        if (!interval) this.forceUpdate()
        this.startTimeout()
      }
    }, 0)
  }

  componentWillUnmount() {
    EQState.removeListener(EQState.UserSelection, this)
  }

  isSessionReady() {
    let tk = EQService.getForcedSessionId()
    if (
      (!tk && !EQState.UserSelection) ||
      !EQState.UserSelection.GruppoServizio
    ) {
      EQState.retrieve(EQState.UserSelection)
    }

    let sessionReady = true
    let pn = window.location.pathname
    if (!tk) {
      if (
        pn === '/book/success' ||
        pn === '/book/error' ||
        pn === '/book/retry-pay'
      ) {
        EQState.Booking = EQState.retrieve(EQState.Booking)
        if (!EQState.Booking) sessionReady = false
      } else {
        if (!EQState.UserSelection || !EQState.UserSelection.GruppoServizio) {
          sessionReady = false
        }
      }
    }
    return sessionReady
  }

  /* start timeout imposta lo state.timer a 5 e poi crea un intervallo che decrementa lo state.timer di 1 ogni minuto
     se lo state.timer arriva a 0, allora viene chiamata la funzione di clearTimeout e viene chiamato un redirect a /book/dates
     */
  startTimeout() {
    if (interval) return
    interval = setInterval(() => {
      let ddstart = Formats.asDate(EQState.UserSelection.MarkerRichiesta)
      if (!ddstart) {
        console.warn('No marker inizio ricerca')
        return
      }
      let diff = Math.floor((new Date() - ddstart) / (10000 * 60))
      if (diff > EQBusinessRules.getTimeoutPrenotazione()) {
        clearInterval(interval)
        interval = null
        document.location.href = '/book/dates'
      } else {
        this.forceUpdate()
      }
    }, 60 * 1000)
  }

  isAlertVisible() {
    const path = document.location.pathname
    const check =
      path === '/book/cart' ||
      path === '/book/recap' ||
      path === '/book/caregivers' ||
      path === '/book/doBook' ||
      path === '/book/otp'
    this.toggleTop10Header(check)
    return check
  }

  toggleTop10Header(show) {
    const el = document.getElementById('toolbar')
    if (el) {
      if (show) {
        el.classList.add('top-10')
      } else if (!show) {
        el.classList.remove('top-10')
      }
    }
  }

  render() {
    let pn = window.location.pathname
    let sessionReady = this.isSessionReady()
    if (!sessionReady) return null

    let duringBooking = false
    //if(pn=="/book/doBook") duringBooking=true;
    if (pn === '/book/success') duringBooking = true
    if (pn === '/book/error') duringBooking = true
    if (pn === '/book/retry-pay') duringBooking = true
    if (pn === '/book/pay') duringBooking = true
    if (pn === '/book/send-pay-link') duringBooking = true

    let timeoutValue = '-'
    if (this.isAlertVisible()) {
      if (!EQState.UserSelection.MarkerRichiesta)
        console.warn('No MarkerRichiesta')
      let diff = Math.floor(
        (new Date() - Formats.asDate(EQState.UserSelection.MarkerRichiesta)) /
          (1000 * 60)
      )
      timeoutValue = EQBusinessRules.getTimeoutPrenotazione() - diff
      if (timeoutValue <= 0) document.location.href = '/book/dates'
    }

    return (
      <MuiThemeProvider>
        {this.isAlertVisible() && (
          <div id="red-banner">
            hai ancora {timeoutValue} minuti per prenotare
          </div>
        )}
        <div className="book">
          <Box className="main-content">
            <EQHeaderBook></EQHeaderBook>
            {!duringBooking && (
              <Box mt={9}>
                <EQStepBar></EQStepBar>
              </Box>
            )}

            <Container className="container">
              <Router>
                <Switch>
                  <Route path="/book/advisory">
                    <EQBookStep_Advisory />
                  </Route>
                  <Route path="/book/advisory_setup">
                    <EQBookStep_Do_Setup />
                  </Route>
                  <Route path="/book/person">
                    <EQBookStep_Person />
                  </Route>
                  <Route path="/book/medical">
                    <EQBookStep_Medical />
                  </Route>
                  <Route path="/book/location">
                    <EQBookStep_Location />
                  </Route>
                  <Route path="/book/dates">
                    <EQBookStep_Dates />
                  </Route>
                  <Route path="/book/caregivers">
                    <EQBookStep_Caregivers />
                  </Route>
                  <Route path="/book/cart">
                    <EQBookStep_Cart />
                  </Route>
                  <Route path="/book/otp">
                    <EQBookStep_Otp />
                  </Route>
                  <Route path="/book/recap">
                    <EQBookStep_Recap />
                  </Route>
                  <Route path="/book/doBook">
                    <EQBookStep_DoBook />
                  </Route>
                  <Route path="/book/success">
                    <EQBookStep_Success />
                  </Route>
                  <Route path="/book/error">
                    <EQBookStep_Error />
                  </Route>
                  <Route path="/book/retry-pay">
                    <EQBookStep_RetryPayment />
                  </Route>
                  <Route path="/book/pay">
                    <EQBookStep_RetryPayment />
                  </Route>
                  <Route path="/book/send-pay-link">
                    <EQBook_SendPayLink />
                  </Route>
                </Switch>
              </Router>
            </Container>
          </Box>
          <EQFooter></EQFooter>
        </div>
      </MuiThemeProvider>
    )
  }
}
