import Utils from '../libs/Utils'

const LOG = true
const TRACE = false

class EQState {
  static PersistentInfo = {
    name: 'PersistentInfo',
  }

  static SessionInfo = {
    name: 'SessionInfo',
  }

  static UserSelection = {
    name: 'UserSelection',
  }

  static Booking = {
    name: 'Booking',
  }

  static Contents = {
    name: 'Contents',
  }

  static CaregiverHome = {
    name: 'CaregiverHome',
  }

  static toJson(state) {
    let saved = state._listeners
    delete state._listeners
    let result = JSON.stringify(state)
    state._listeners = saved
    return result
  }

  static reset(state) {
    if (LOG) {
      console.groupCollapsed('EQState.reset')
      console.warn('EQState.reset', state.name, EQState.toJson(state))
      if (TRACE) console.trace()
    }

    // Alcuni campi che devono essere conservati
    let IsLogged = state.IsLogged
    let _loggedTime = state._loggedTime
    let _idSessioneWeb = state._idSessioneWeb

    if (LOG) {
      console.log('saved fields', {
        IsLogged: IsLogged,
        _loggedTime: _loggedTime,
        _idSessioneWeb: _idSessioneWeb,
      })
    }

    Utils.refill(state, {
      name: state.name,
    })

    if (IsLogged) state.IsLogged = IsLogged
    if (_loggedTime) state._loggedTime = _loggedTime
    if (_idSessioneWeb) state._idSessioneWeb = _idSessioneWeb

    if (LOG) {
      console.log('at end', state)
    }

    if (LOG) {
      console.groupEnd()
    }

    return state
  }

  static change(state, newState, component, noPropagate) {
    if (LOG) {
      console.groupCollapsed('EQState.change')
      console.warn('EQState.change', state.name, newState)
      if (TRACE) console.trace()
      console.groupEnd()
    }

    state._undefined = false
    Object.assign(state, newState)
    EQState.persist(state)
    if (component) {
      component.setState(newState)
    }
    if (!noPropagate) {
      if (state._listeners) {
        state._listeners.forEach((listener) => {
          if (listener.reloadState) listener.reloadState()
          if (listener.state) listener.setState(listener.state)
        })
      }
    }
  }

  static notifyChange(state, component) {
    component.setState(state)
  }

  static addListener(state, component) {
    //console.log("Adding listener on state",state,component);
    if (!state._listeners) state._listeners = []
    var index = state._listeners.indexOf(component)
    if (index === -1) state._listeners.push(component)
  }

  static removeListener(state, component) {
    //console.log("Removing listener on state",state,component);
    if (!state._listeners) state._listeners = []
    var index = state._listeners.indexOf(component)
    if (index > -1) {
      state._listeners.splice(index, 1)
    }
  }

  static isDefined(state) {
    return state && !state._undefined
  }

  static persist(state, where) {
    if (!where) {
      where = window.localStorage
    }
    if (LOG) {
      console.groupCollapsed('EQState.persist')
      console.warn('EQState.persist', state.name, state)
      if (TRACE) console.trace()
      console.groupEnd()
    }

    let s = Object.assign({}, state)
    delete s._listeners
    console.log("Persisting state '" + state.name + "'", s)
    where.setItem('EQState_' + state.name, EQState.toJson(s))
  }

  static isPersisted(state, where) {
    if (!where) {
      where = window.localStorage
    }
    let s = where.getItem('EQState_' + state.name)
    console.log('isersistend?', state.name, s)
    return s !== null
  }

  static retrieve(state, where) {
    if (!where) {
      where = window.localStorage
    }
    if (LOG) {
      console.groupCollapsed('EQState.retrieve')
      console.warn('EQState.retrieve', state.name)
      if (TRACE) console.trace()
      console.groupEnd()
    }

    let s = where.getItem('EQState_' + state.name)
    if (s) {
      let decoded = JSON.parse(s)
      Object.assign(state, decoded)
      console.log("Retrieving state '" + state.name + "'", state)
      return state
    } else {
      console.log("Default state for '" + state.name + "'")
      return null
    }
  }
}

export default EQState
