import React from 'react'
import { Box, Modal, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import EQState from '../../services/EQState'
import Formats from '../../libs/Formats'
import EQLoading from '../EQLoading'
import EQServiceBook from '../../services/EQServiceBook'
import EventIcon from '@mui/icons-material/Event'
import AddCircleIcon from '@mui/icons-material/AddCircle'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  width: '90%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 1,
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
}

export default class EQAlternatives extends React.Component {
  constructor(props) {
    super(props)
    console.log(props.caregiver, props.solution)
    this.state = {
      loading: true,
    }
  }

  componentDidMount() {
    this.loadAlternatives()
  }

  closeModal() {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  loadAlternatives() {
    console.log('caregiver', this.props.caregiver)
    console.log('solution', this.props.solution)
    EQServiceBook.cercaAlternative(
      this.props.caregiver,
      this.props.solution
    ).then((r) => {
      console.log('alternative', r)
      this.setState({
        alternatives: r,
        loading: false,
      })
    })
  }

  selectSolution(sp) {
    console.log('alterativa selezionata', sp)
    this.props.onSelect(sp)
  }

  render() {
    const caregiver = this.props.caregiver
    let preferito = false
    if (
      EQState.UserSelection.PreferredCaregivers_Map.indexOf(
        caregiver.idProfessionista
      ) >= 0
    )
      preferito = true

    let tipoProfessionista = { nome: '' }
    if (EQState.UserSelection.map_tipiProfessionista)
      tipoProfessionista =
        EQState.UserSelection.map_tipiProfessionista[
          caregiver.idTipoProfessionista
        ]

    let cooperativa = { nome: '' }
    if (EQState.UserSelection.map_cooperative)
      cooperativa =
        EQState.UserSelection.map_cooperative[caregiver.idCooperativa]

    return (
      <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box
          sx={style}
          className="helpModal caregiver-info alternatives">
          <button
            onClick={() => this.closeModal()}
            className="iconClose clickable">
            <CloseIcon></CloseIcon>
            <br />
            CHIUDI
          </button>
          <div className="info-box caregiver-body">
            <Typography
              className="playFair"
              id="modal-modal-title"
              variant="h5"
              component="h2"
              mb={2}
              mt={1}>
              Altre disponibilità
            </Typography>
            <div className="card-body">
              <div className="row">
                <div className="col1">
                  <div className="img-outer">
                    <img
                      src={caregiver.urlImmagineCropped}
                      alt={caregiver.nome}
                    />
                  </div>
                </div>
                <div className="col2">
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="name playFair">
                    {caregiver.nome}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    className="role">
                    {tipoProfessionista.nome}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    className="company">
                    {cooperativa.nome}
                    <br />
                    da {Formats.formatPrice(caregiver._prezzoTotale)} &euro;
                  </Typography>
                </div>
              </div>

              {this.state.loading && (
                <Box p={4}>
                  <EQLoading message="Ricerca disponibilità..." />
                </Box>
              )}

              {!this.state.loading && (
                <div className="solution-list-container">
                  <div className="solution-list">
                    {this.state.alternatives.map((sp, i) => {
                      let dateFrom = new Date(sp.dal)
                      let dateTo = new Date(sp.al)
                      let day = Formats.dateToDayMonth(dateFrom)
                      let shours = Formats.dateToHourMinutes(dateFrom)
                      let ehours = Formats.dateToHourMinutes(dateTo)

                      let prezzo = sp.prezzo

                      let css = 'solution solution-available'
                      if (sp._selected) {
                        css = 'solution solution-confirmed'
                      }

                      return (
                        <div
                          className={css}
                          key={i}
                          onClick={() => this.selectSolution(sp)}>
                          {sp._selected && (
                            <div className="message">
                              <div>Data selezionata</div>
                            </div>
                          )}
                          {!sp._selected && (
                            <div className="message row">
                              <div>Data disponibile</div>
                            </div>
                          )}

                          <div className="explanation row">
                            <div className="col1">
                              <EventIcon className="large-icon" />
                            </div>
                            <div className="col2">
                              <div className="col2l">
                                <div className="main-info">
                                  <div style={{ fontWeight: 'bold' }}>
                                    {day}
                                  </div>
                                  <div>
                                    dalle ore{' '}
                                    <span style={{ fontWeight: 'bold' }}>
                                      {shours}
                                    </span>
                                  </div>
                                  <div>
                                    alle ore{' '}
                                    <span style={{ fontWeight: 'bold' }}>
                                      {ehours}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col2r">
                                <div className="add-icon">
                                  <AddCircleIcon className="green-icon" />
                                </div>
                                <div className="price">
                                  {Formats.formatPrice(prezzo)} &euro;
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
              <Box sx={{ textAlign: 'center' }}>
                <a
                  className="inline"
                  onClick={() => this.closeModal()}>
                  <u>Chiudi finestra</u>
                </a>
              </Box>
            </div>
          </div>
        </Box>
      </Modal>
    )
  }
}
