import { Box } from '@mui/material'
import EQEventList from './EQEventList'
import EQState from '../../services/EQState'
import React from 'react'

export class EQCaregiver_ToBeConfirmed extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      CaregiverHome: EQState.CaregiverHome,
    }
  }

  reloadState() {
    this.forceUpdate()
  }

  componentDidMount() {
    EQState.addListener(EQState.CaregiverHome, this)
  }

  componentWillUnmount() {
    EQState.removeListener(EQState.CaregiverHome, this)
  }

  render() {
    return (
      <Box
        style={{
          marginTop: '70px',
          paddingLeft: '10px',
          paddingRight: '10px',
        }}>
        <h2 className="playFair textCenter normalWeight">
          Richieste in attesa
        </h2>
        {/*<p className='textCenter'>Lorem ipsum sit dolora amen et nullula est tam bona quam bella. Omnia Gallia.</p>*/}
        {EQState.CaregiverHome._daConfermare.length > 0 && (
          <EQEventList events={EQState.CaregiverHome._daConfermare} />
        )}
        {EQState.CaregiverHome._daConfermare.length === 0 && (
          <p className="textCenter">
            <strong>Non ci sono nuove richieste in attesa</strong>
          </p>
        )}
      </Box>
    )
  }
}
