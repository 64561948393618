import { Box, Container } from '@mui/material'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import EQFooter from '../components/EQFooter'
import EQHeaderBook from '../components/EQHeaderBook'
import EQLoading from '../components/EQLoading'
import EQMyBooks from '../components/user/EQMyBooks'
import EQMyFavCaregivers from '../components/user/EQMyFavCaregivers'
import EQMyPersons from '../components/user/EQMyPersons'
import EQMySettings from '../components/user/EQMySettings'
import EQService from '../services/EQService'
import EQState from '../services/EQState'
import { MuiThemeProvider } from 'material-ui/styles'
import Pages from '../services/Pages'
import React from 'react'

export default class PageUser extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showFooter: false,
    }
  }

  componentDidMount() {
    EQService.mapContentsOnCurrentPage().then((m) => {
      EQState.change(EQState.Contents, {
        [Pages.current().cms]: m,
      })
      this.forceUpdate()
    })

    // Visualizziamo il footer dopo un po', così evitiamo uno sfarfallio
    setTimeout(() => {
      /*
            if(!EQService.isLogged()){
                console.log("Not logged, going home");
                document.location.href="/";
            }
            */

      console.log('showing footer...')
      this.setState({
        showFooter: true,
      })
    }, 1000)
  }

  getPageCode() {
    let cmsArea = window.location.pathname.replace('/user/', '')
    if (cmsArea === 'caregivers') return 'CAREGIVERS'
    else if (cmsArea === 'books') return 'PRENOTAZIONI'
    else if (cmsArea === 'persons') return 'ASSISTITI'
    else return null
  }

  cc(code, field) {
    const pageCode = this.getPageCode()
    const C = EQState.Contents[pageCode]
    return C[pageCode + '_' + code][field]
  }

  render() {
    const pageCode = this.getPageCode()
    let C = {}
    if (pageCode) {
      C = EQState.Contents[pageCode]
      if (!C) {
        return (
          <div style={{ paddingTop: '200px' }}>
            <EQLoading />
          </div>
        )
      }
    }

    return (
      <MuiThemeProvider>
        <div className="userpage">
          <Box className="editorial">
            <EQHeaderBook></EQHeaderBook>
            {pageCode && (
              <Box mt={7}>
                <div className="header">
                  <div>{this.cc('VISUAL', 'SottoTitolo')}</div>
                  <img
                    src={this.cc('VISUAL', 'urlImmagine')}
                    alt={this.cc('VISUAL', 'SottoTitolo')}
                    title={this.cc('VISUAL', 'SottoTitolo')}
                  />
                </div>
              </Box>
            )}

            <Container
              px={0}
              className="container">
              <Box mt={0}>
                <Router>
                  <Switch>
                    <Route path="/user/books">
                      <EQMyBooks />
                    </Route>
                    <Route path="/user/caregivers">
                      <EQMyFavCaregivers />
                    </Route>
                    <Route path="/user/persons">
                      <EQMyPersons />
                    </Route>
                    <Route path="/user/settings">
                      <EQMySettings />
                    </Route>
                  </Switch>
                </Router>
              </Box>
            </Container>

            {this.state.showFooter && <EQFooter></EQFooter>}
          </Box>
        </div>
      </MuiThemeProvider>
    )
  }
}
