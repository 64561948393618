/* eslint-disable react/jsx-pascal-case */
// ! remove above lines to enable eslint for anchor-is-valid and jsx-pascal-case
import { Box, Button, FormControl, Grid } from '@mui/material'
import React, { Fragment } from 'react'

import AccessTimeIcon from '@mui/icons-material/WatchLaterTwoTone'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CF from '../../libs/CF'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import EQBook_Info from './EQBook_Info'
import EQBusinessRules from '../../libs/EQBusinessRules'
import EQFaqBox from '../EQFaqBox'
import EQFloatingFormIcon from '../core/EQFloatingFormIcon'
import EQLoading from '../EQLoading'
import EQService from '../../services/EQService'
import EQServiceBook from '../../services/EQServiceBook'
import EQState from '../../services/EQState'
import EQTextField from '../core/EQTextField'
import Formats from '../../libs/Formats'
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone'
import Pages from '../../services/Pages'
import PersonOutlineIcon from '@mui/icons-material/PersonOutlineTwoTone'
import RoomIcon from '@mui/icons-material/FmdGoodTwoTone'
import SubtitlesIcon from '@mui/icons-material/CallToActionTwoTone'
import { withRouter } from 'react-router-dom'

class EQBookStep_Recap extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showInfo: false,
      isAdvisory: false,
    }
    if (!EQState.UserSelection.InvoiceData) {
      EQState.UserSelection.InvoiceData = {
        sameAddresses: true,
        nome: '',
        cognome: '',
        indirizzo: '',
        codiceFiscale: '',
        cap: '',
        comune: '',
        provincia: '',
      }
    }

    // bugid#8576 - servizi di consulenza
    if (
      EQBusinessRules.isServiceAdvisory(EQState.UserSelection.GruppoServizio)
    ) {
      this.state.isAdvisory = true
      EQState.UserSelection.InvoiceData = {
        sameAddresses: false,
        nome: EQState.UserSelection.SelectedRarentalRelation.nome,
        cognome: EQState.UserSelection.SelectedRarentalRelation.cognome,
        indirizzo: '',
        codiceFiscale:
          EQState.UserSelection.SelectedRarentalRelation.codiceFiscale,
        cap: '',
        comune: '',
        provincia: '',
      }
    }
  }

  componentDidMount() {
    if (!EQState.UserSelection.Solutions_Caregivers) {
      document.location.href = '/'
      return
    }

    if (!EQService.sessioneWeb || !EQService.sessioneWeb.user) {
      EQService.getSessione(EQState.UserSelection._idSessioneWeb).then(() => {
        if (!EQService.sessioneWeb.user.isOTPOK) {
          document.location.href = '/book/otp'
          return
        } else {
          this.forceUpdate()
        }
      })
    } else {
      if (!EQService.sessioneWeb.user.isOTPOK) {
        document.location.href = '/book/otp'
        return
      }
    }

    const page = Pages.current()
    document.title = page.title
    window.scrollTo(0, 0)

    EQServiceBook.mapComuni().then((m) => {
      this.setState({ comuni: m })
    })
  }

  goBack() {
    EQState.change(EQState.UserSelection, {
      BookPage: `/book/cart`,
    })
    this.props.history.push({
      pathname: `/book/cart`,
    })
  }

  toggleSameAddress() {
    EQState.UserSelection.InvoiceData.sameAddresses =
      !EQState.UserSelection.InvoiceData.sameAddresses
    this.forceUpdate()
  }

  isInvoiceDataOk() {
    if (!EQState.UserSelection.InvoiceData) return false
    if (!EQState.UserSelection.InvoiceData.cognome) return false
    if (!EQState.UserSelection.InvoiceData.nome) return false
    if (!EQState.UserSelection.InvoiceData.indirizzo) return false
    if (!EQState.UserSelection.InvoiceData.codiceFiscale) return false
    if (!EQState.UserSelection.InvoiceData.cap) return false
    if (!EQState.UserSelection.InvoiceData.comune) return false
    if (!EQState.UserSelection.InvoiceData.provincia) return false
    if (!CF.validate(EQState.UserSelection.InvoiceData.codiceFiscale))
      return false
    return true
  }

  setInvoice(e, key) {
    EQState.UserSelection.InvoiceData[key] = e.target.value
    this.forceUpdate()
  }

  validateCF() {
    return CF.validate(EQState.UserSelection.InvoiceData.codiceFiscale)
  }

  cfHelperText() {
    if (!EQState.UserSelection.InvoiceData.codiceFiscale) {
      return 'Informazione necessaria'
    }
    if (!CF.validate(EQState.UserSelection.InvoiceData.codiceFiscale)) {
      return 'Codice fiscale non valido'
    }
    return null
  }

  goForward() {
    if (EQService.sessioneWeb.isJolly) {
      this.setState({
        showInfo: true,
      })
    } else this.goBook()
  }

  goBook() {
    EQState.change(EQState.UserSelection, {
      BookPage: `/book/doBook`,
    })
    this.props.history.push({
      pathname: `/book/doBook`,
    })
  }

  render() {
    if (!EQService.sessioneWeb || !EQService.sessioneWeb.user) {
      return null
    }

    if (!EQService.sessioneWeb.user.isOTPOK) {
      return null
    }

    if (!EQState.UserSelection.Solutions_Caregivers) {
      return null
    }

    if (!this.state.comuni) {
      return <EQLoading />
    }

    let pname =
      EQState.UserSelection.SelectedRarentalRelation.nome +
      ' ' +
      EQState.UserSelection.SelectedRarentalRelation.cognome
    let city = ''
    if (this.state.comuni) {
      const found = this.state.comuni.find(
        (x) => x.idComune === EQState.UserSelection.Location.city
      )
      if (found) {
        city = found.cap + ' - ' + found.nome
        EQState.UserSelection.CityName = found.nome
        EQState.UserSelection.CAP = found.cap
        EQState.UserSelection.Province = found.provincia
      }
    }

    let canGoForward = true
    if (!EQState.UserSelection.InvoiceData.sameAddresses) {
      canGoForward = this.isInvoiceDataOk()
    }

    let aday = ''
    let shours = ''
    let ehours = ''
    if (this.state.isAdvisory) {
      let dateFrom = Formats.fromServerDate(
        EQState.UserSelection.Solutions_Results.servizioPrenotaziones[0]
          .richiestoDal
      )
      let dateTo = Formats.fromServerDate(
        EQState.UserSelection.Solutions_Results.servizioPrenotaziones[0]
          .richiestoAl
      )
      console.log('dates', { dateFrom, dateTo })
      aday = Formats.dateToDayMonth(dateFrom)
      shours = Formats.dateToHourMinutes(dateFrom)
      ehours = Formats.dateToHourMinutes(dateTo)
    }

    console.log('EQState.UserSelection.InvoiceData', EQState.UserSelection)

    return (
      <Fragment>
        <Box>
          <p className="playFair normalWeight textCenter bookstep-start-label">
            Prenotazione per
          </p>
          <Box
            pl={3}
            pr={3}
            mt={2}>
            <EQFloatingFormIcon>
              <PersonOutlineIcon nostyle={{ fontSize: '2rem' }} />
            </EQFloatingFormIcon>
            <Box pl={2}>
              <FormControl fullWidth>
                <EQTextField
                  label=""
                  disabled
                  defaultValue={pname}
                  inputProps={{ style: { textTransform: 'uppercase' } }}
                />
              </FormControl>
            </Box>
          </Box>
          <Box
            pl={3}
            pr={3}
            mt={0}>
            <EQFloatingFormIcon>
              <SubtitlesIcon nostyle={{ fontSize: '2rem' }} />
            </EQFloatingFormIcon>
            <Box pl={2}>
              <FormControl fullWidth>
                <EQTextField
                  label="Codice fiscale"
                  disabled
                  defaultValue={
                    EQState.UserSelection.SelectedRarentalRelation.codiceFiscale
                  }
                  inputProps={{ style: { textTransform: 'uppercase' } }}
                />
              </FormControl>
            </Box>
          </Box>

          {!this.state.isAdvisory && (
            <Box
              pl={3}
              pr={3}>
              <EQFloatingFormIcon>
                <HomeTwoToneIcon nostyle={{ fontSize: '2rem' }} />
              </EQFloatingFormIcon>

              <Box pl={2}>
                <p style={{ paddingLeft: '5px', marginLeft: '5px' }}>
                  L'assistenza si svolgerà in:
                </p>
                <Box
                  pl={2}
                  style={{ lineHeight: '1px' }}>
                  <p>{EQState.UserSelection.Location.address}</p>
                  <p>{city}</p>
                </Box>
              </Box>
            </Box>
          )}
        </Box>

        {!this.state.isAdvisory && (
          <Box
            pl={3}
            pr={3}>
            {EQState.UserSelection.InvoiceData.sameAddresses ? (
              <EQFloatingFormIcon>
                <CheckBoxIcon onClick={() => this.toggleSameAddress()} />
              </EQFloatingFormIcon>
            ) : (
              <EQFloatingFormIcon>
                <CheckBoxOutlineBlankIcon
                  onClick={() => this.toggleSameAddress()}
                />
              </EQFloatingFormIcon>
            )}
            <Box
              pl={3}
              pt={1}>
              <div onClick={() => this.toggleSameAddress()}>
                L'indirizzo di fatturazione coincide con l'indirizzo di
                destinazione per l'erogazione del servizio
              </div>
            </Box>
          </Box>
        )}

        {this.state.isAdvisory && (
          <Box
            pl={3}
            pr={3}>
            <EQFloatingFormIcon>
              <AccessTimeIcon nostyle={{ fontSize: '2rem' }} />
            </EQFloatingFormIcon>
            <Box
              pl={3}
              pt={1}
              mb={2}>
              <div style={{ fontWeight: 'bold' }}>{aday}</div>
              <div>
                dalle ore <span style={{ fontWeight: 'bold' }}>{shours}</span>
              </div>
              <div>
                alle ore <span style={{ fontWeight: 'bold' }}>{ehours}</span>
              </div>
            </Box>
          </Box>
        )}

        {this.state.isAdvisory && (
          <Box
            pl={3}
            pr={3}>
            <EQFloatingFormIcon></EQFloatingFormIcon>
            <Box
              pl={3}
              pt={1}
              mb={2}>
              <b>Specifica i dati per la fatturazione del servizio</b>
            </Box>
          </Box>
        )}

        {!EQState.UserSelection.InvoiceData.sameAddresses && (
          <Fragment>
            <Box
              pl={3}
              pr={3}
              pt={2}>
              <EQFloatingFormIcon>
                <PersonOutlineIcon />
              </EQFloatingFormIcon>
              <Box pl={2}>
                <FormControl fullWidth>
                  <EQTextField
                    label="Cognome"
                    defaultValue={EQState.UserSelection.InvoiceData.cognome}
                    error={!EQState.UserSelection.InvoiceData.cognome}
                    helperText="Informazione necessaria"
                    onChange={(e) => this.setInvoice(e, 'cognome')}
                  />
                </FormControl>
              </Box>
              <Box
                pl={2}
                pt={1}>
                <FormControl fullWidth>
                  <EQTextField
                    label="Nome"
                    defaultValue={EQState.UserSelection.InvoiceData.nome}
                    error={!EQState.UserSelection.InvoiceData.nome}
                    helperText="Informazione necessaria"
                    onChange={(e) => this.setInvoice(e, 'nome')}
                  />
                </FormControl>
              </Box>
            </Box>
            <Box
              pl={3}
              pr={3}
              pt={2}>
              <EQFloatingFormIcon>
                <RoomIcon />
              </EQFloatingFormIcon>
              <Box pl={2}>
                <FormControl fullWidth>
                  <EQTextField
                    label="Indirizzo"
                    multiline
                    rows={3}
                    defaultValue={EQState.UserSelection.InvoiceData.indirizzo}
                    error={!EQState.UserSelection.InvoiceData.indirizzo}
                    helperText="Informazione necessaria"
                    onChange={(e) => this.setInvoice(e, 'indirizzo')}
                  />
                </FormControl>
              </Box>
              <Box
                pl={2}
                pt={1}>
                <FormControl fullWidth>
                  <EQTextField
                    label="Comune"
                    rows={3}
                    defaultValue={EQState.UserSelection.InvoiceData.comune}
                    error={!EQState.UserSelection.InvoiceData.comune}
                    helperText="Informazione necessaria"
                    onChange={(e) => this.setInvoice(e, 'comune')}
                  />
                </FormControl>
              </Box>
              <Box
                pl={2}
                pt={1}>
                <FormControl fullWidth>
                  <EQTextField
                    inputProps={{ maxLength: 5 }}
                    label="CAP"
                    rows={3}
                    defaultValue={EQState.UserSelection.InvoiceData.cap}
                    error={!EQState.UserSelection.InvoiceData.cap}
                    helperText="Informazione necessaria"
                    onChange={(e) => this.setInvoice(e, 'cap')}
                  />
                </FormControl>
              </Box>
              <Box
                pl={2}
                pt={1}>
                <FormControl fullWidth>
                  <EQTextField
                    inputProps={{ maxLength: 2 }}
                    label="Sigla provincia"
                    rows={3}
                    defaultValue={EQState.UserSelection.InvoiceData.provincia}
                    error={!EQState.UserSelection.InvoiceData.provincia}
                    helperText="Informazione necessaria"
                    onChange={(e) => this.setInvoice(e, 'provincia')}
                  />
                </FormControl>
              </Box>
            </Box>
            <Box
              pl={3}
              pr={3}
              pt={2}>
              <EQFloatingFormIcon>
                <SubtitlesIcon />
              </EQFloatingFormIcon>
              <Box pl={2}>
                <FormControl fullWidth>
                  <EQTextField
                    label="Codice fiscale"
                    defaultValue={
                      EQState.UserSelection.InvoiceData.codiceFiscale
                    }
                    error={!this.validateCF()}
                    helperText={this.cfHelperText()}
                    inputProps={{ style: { textTransform: 'uppercase' } }}
                    onChange={(e) => this.setInvoice(e, 'codiceFiscale')}
                  />
                </FormControl>
              </Box>
            </Box>
          </Fragment>
        )}

        <Grid
          container
          className="book-navigation shrink"
          mt={0}
          mb={0}>
          <Grid
            item
            xs={6}>
            <Button
              className="lnkButton lnkBack"
              startIcon={<ArrowBackIcon />}
              onClick={() => this.goBack()}>
              Indietro
            </Button>
          </Grid>
          <Grid
            item
            sx={{ textAlign: 'right' }}
            xs={6}>
            {canGoForward && (
              <a
                className="buttonLink lnkForward bg-green text-white"
                onClick={() => this.goForward()}>
                PROCEDI
              </a>
            )}
            {!canGoForward && (
              <a className="buttonLink lnkForward bg-green text-white disabled">
                PROCEDI
              </a>
            )}
          </Grid>
        </Grid>

        <Box>
          <EQFaqBox category="InfoFatturazione"></EQFaqBox>
        </Box>

        {this.state.showInfo && (
          <EQBook_Info
            onClose={() => this.goBook()}
            showBookButton={true}
          />
        )}
      </Fragment>
    )
  }
}

export default withRouter(EQBookStep_Recap)
