// withLocation.js

import React from 'react'
import { withRouter } from 'react-router-dom'

const withLocation = (WrappedComponent) => {
  class WithLocation extends React.Component {
    render() {
      const { location } = this.props
      return (
        <WrappedComponent
          location={location}
          {...this.props}
        />
      )
    }
  }
  return withRouter(WithLocation)
}

export default withLocation
