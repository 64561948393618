import './EQStepBar.scss'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import EQState from '../services/EQState'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import React from 'react'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'

const steps = ['INFORMAZIONI', 'CALENDARIO', 'CAREGIVER', 'CHECK-OUT']

class CustomStepIcon extends React.Component {
  render() {
    const { active, completed } = this.props
    let st = ''
    if (active) st = st + ' eq-step-active'
    if (completed) st = st + ' eq-step-completed'
    let comp = <RadioButtonUncheckedIcon className={st} />
    if (completed || active) comp = <CheckCircleIcon className={st} />
    return <div>{comp}</div>
  }
}

export default class EQStepBar extends React.Component {
  reloadState() {
    this.setState({
      UserSelection: EQState.UserSelection,
    })
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    EQState.addListener(EQState.UserSelection, this)
  }

  componentWillUnmount() {
    EQState.removeListener(EQState.UserSelection, this)
  }

  render() {
    const pn = EQState.UserSelection.BookPage
    let activeStep = 0
    if (pn === '/book/person' || pn === '/book/location') activeStep = 0
    else if (pn === '/book/dates') activeStep = 1
    else if (pn === '/book/caregivers') activeStep = 2
    else if (
      pn === '/book/cart' ||
      pn === '/book/recap' ||
      pn === '/book/otp' ||
      pn === '/book/doBook'
    )
      activeStep = 3

    return (
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        className="eq-stepper">
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={CustomStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    )
  }
}
