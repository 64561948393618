import EQState from '../../services/EQState'
import PageEditorial from '../PageEditorial'

export default class PageFaq extends PageEditorial {
  getPageCode() {
    return 'CONDIZIONI'
  }

  getChildrenParts() {
    const pageCode = this.getPageCode()
    const C = EQState.Contents[pageCode]

    return (
      <div>
        <div className="condizioni-container">
          {[2, 3].map((n, index) => {
            return (
              <div className="folder">
                <p className={'title' + (index % 2 === 0 ? '' : '-right')}>
                  {this.cc(`DESCRIPTION${n}`, 'Titolo')}
                </p>
                <p
                  style={{ wordBreak: 'break-word' }}
                  className={'body' + (index % 2 === 0 ? '' : '-right')}
                  dangerouslySetInnerHTML={{
                    __html: this.cc(`DESCRIPTION${n}`, 'Contenuto'),
                  }}></p>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}
