import React from 'react'
import { AppBar, Box, Toolbar } from '@mui/material'
import EQCaregiverMenu from './EQCaregiverMenu'
import EQState from '../../services/EQState'
import EQCalendarStates from '../../libs/EQCalendarStates'

export default class EQHeaderCaregiver extends React.Component {
  constructor(props) {
    super(props)
  }

  reloadState() {
    this.forceUpdate()
  }

  componentDidMount() {
    EQState.addListener(EQState.CaregiverHome, this)
  }

  componentWillUnmount() {
    EQState.removeListener(EQState.CaregiverHome, this)
  }

  goHome() {
    document.location.href = '/caregiver/login'
  }

  showToBeConfirmed() {
    document.location.href = '/caregiver/toBeConfirmed'
  }

  render() {
    let st = { visibility: 'hidden' }
    let daConfermare = []
    if (!EQState.CaregiverHome) {
      return null
    }

    if (EQState.CaregiverHome._daConfermare) {
      EQState.CaregiverHome._daConfermare.forEach((c) => {
        if (!EQCalendarStates.isToBeConfirmed(c)) return
        daConfermare.push(c)
      })
    }

    if (daConfermare.length > 0) st = { visibility: 'visible' }
    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          style={{ background: '#FFF' }}
          position="fixed">
          <Toolbar>
            <div
              className="caregiver-alert-badge"
              style={st}
              onClick={() => this.showToBeConfirmed()}>
              {daConfermare.length}
            </div>
            <Box
              pt={1}
              sx={{ flexGrow: 1, textAlign: 'center' }}
              className="menuImageContainer">
              <img
                onClick={() => this.goHome()}
                src="/img/logo-header.svg"
                alt="Logo EqueCure"
              />
            </Box>
            <EQCaregiverMenu></EQCaregiverMenu>
          </Toolbar>
        </AppBar>
      </Box>
    )
  }
}
