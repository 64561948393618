import { Redirect, withRouter } from 'react-router-dom'

import EQLoading from '../EQLoading'
import EQService from '../../services/EQService'
import EQServiceBook from '../../services/EQServiceBook'
import EQState from '../../services/EQState'
import Formats from '../../libs/Formats'
import React from 'react'

class EQBookStep_Do_Search extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)

    console.log('EQState.UserSelection', EQState.UserSelection)

    // Aggiorniamo i servizi / date agganciati alla prenotazione
    let serviziAPacchetto = {}
    let idServizio = 0
    if (EQState.UserSelection.GruppoServizio.servizios.length === 1)
      idServizio = EQState.UserSelection.GruppoServizio.servizios[0].idServizio
    let mode = EQState.UserSelection.selectedRanges_mode
    let servizi = EQState.UserSelection.selectedRanges.map((r) => {
      let rangeStart
      let rangeEnd
      if (mode === 'day-based') {
        let startOfDay = new Date(r.day)
        /*
                console.warn("startOfDay",{
                    "rday": r.day,
                    "startOfDay": startOfDay
                });
                */
        r.from = Formats.ensureDate(r.from)
        r.to = Formats.ensureDate(r.to)
        startOfDay.setHours(0, 0, 0, 0)
        rangeStart = new Date(startOfDay)

        rangeStart.setHours(r.from.getHours())
        rangeEnd = new Date(startOfDay)
        if (r.to.getHours() < r.from.getHours())
          rangeEnd.setDate(rangeEnd.getDate() + 1)
        rangeEnd.setHours(r.to.getHours())
      } else if (mode === 'from-to') {
        rangeStart = new Date(r.from)
        rangeEnd = new Date(r.to)
      }

      let res = {
        idServizio: idServizio,
        dal: Formats.toServerDate(rangeStart),
        al: Formats.toServerDate(rangeEnd),
        idMansioni: EQState.UserSelection.mansioni,
      }
      if (r.packet) {
        res.idPacchetto = Number(r.packet)
        let pkey = idServizio + '_' + Formats.toServerDate(rangeStart)
        serviziAPacchetto[pkey] = res.idPacchetto
      }
      /* non serve - il bakend già ha questa informazione
            if(r.was_packetAPrestazione) {
                res.aPrestazione=true;
                console.error("A PRESTAZIONE", res);
            }*/

      return res
    })

    // Marchiamo il momento di ricerca - per supportare il conteggio 'quanto manca per la prenotazione'
    EQState.change(EQState.UserSelection, {
      MarkerRichiesta: new Date(),
    })
    EQState.persist(EQState.UserSelection)

    // Memorizziamo eventuali pacchetti agganciati ai servizi - ci serve in conferma acquisto
    // console.log("memorizziamo pacchetti", serviziAPacchetto);
    EQState.UserSelection.ServiziAPacchetto = serviziAPacchetto

    // console.error("SERVIZI",servizi)

    EQServiceBook.setServizi(servizi).then((rpaziente) => {
      // Ricerca
      EQServiceBook.cercaSoluzioni(servizi).then((rsoluzioni) => {
        // Troviamo tutti i professionisti referenziati
        let ids = []
        EQState.UserSelection.map_professionisti = {}
        rsoluzioni.servizioPrenotaziones.forEach((sp) => {
          sp.soluzioneTrovatas.forEach((st) => {
            ids.push(st.idProfessionista)
          })
        })
        console.log('professionisti referenziati', ids)

        EQService.getProfessionistiByIds(ids).then((prfs) => {
          EQState.UserSelection.map_professionisti = {}
          prfs.forEach(
            (x) =>
              (EQState.UserSelection.map_professionisti[x.idProfessionista] = x)
          )
          console.log(
            'professionisti mappati',
            EQState.UserSelection.map_professionisti
          )

          // Ristrutturiamo la risposta della ricerca inserendo i dati dei professionisti
          let pset = {}
          let coverageTot = 0
          rsoluzioni.servizioPrenotaziones.forEach((sp) => {
            console.log('sp', sp)
            sp.soluzioneTrovatas.forEach((st) => {
              console.log('st', st)
              let pf = pset[st.idProfessionista]
              if (!pf) {
                let prof =
                  EQState.UserSelection.map_professionisti[st.idProfessionista]
                if (!prof) {
                  console.warn(
                    'Professionista non mappato',
                    st.idProfessionista
                  )
                  return
                }
                pset[st.idProfessionista] = prof
                prof._coverageCounter = 1
                prof._prezzoTotale = st.prezzo
                prof._soluzioni = [st]
                prof._serviziPrenotazione = [sp]
                //console.log("Imposto il prezzo di " + prof.nome + " a " + st.prezzo)
              } else {
                const pexisting = pset[st.idProfessionista]
                pexisting._coverageCounter++
                //console.log("Aggiungo al prezzo di " + pexisting.nome + "che è " + pset[pexisting.idProfessionista]._prezzoTotale + " il valore " + st.prezzo)
                pexisting._prezzoTotale += st.prezzo
                pexisting._soluzioni.push(st)
                pexisting._serviziPrenotazione.push(sp)
              }
            })
            coverageTot++
          })
          console.log('professionisti', pset)

          // Calcoliamo per ogni professionista la copertura
          let plist = []
          for (let key in pset) {
            pset[key]._coverage =
              parseFloat(pset[key]._coverageCounter) / parseFloat(coverageTot)
            plist.push(pset[key])
          }
          console.log('professionisti', plist)

          // Ordiniamo i professionisti rispetto alla copertura
          plist.sort((a, b) => {
            return b._coverage - a._coverage
          })
          console.log('professionisti', plist)

          // Recuperiamo la lista dei caregiver preferiti
          EQService.getProfessionistiPreferiti().then((pps) => {
            EQState.change(EQState.UserSelection, {
              Solutions_Results: rsoluzioni,
              Solutions_Caregivers: plist,
              Solutions_ConfermedCnt: 0,
              PreferredCaregivers: pps,
              PreferredCaregivers_Map: pps.map((x) => x.idProfessionista),
              BookPage: `/book/caregivers`,
            })
            this.props.history.push({
              pathname: `/book/caregivers`,
            })
          })
        })
      })
    })
  }

  render() {
    if (!EQState.UserSelection || !EQState.UserSelection.GruppoServizio) {
      return <Redirect to="/" />
    }

    return (
      <EQLoading
        type="boxBianco"
        message="Ricerca..."
      />
    )
  }
}

export default withRouter(EQBookStep_Do_Search)
