import './PageChat.scss'

import { Box, InputAdornment, TextField, Typography } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

import EQService from '../services/EQService'
import EQTextField from '../components/core/EQTextField'
import { Fragment } from 'react'
import { IconButton } from 'material-ui'
import { MuiThemeProvider } from 'material-ui/styles'
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone'
import React from 'react'
import SendIcon from '@mui/icons-material/Send'
import Utils from '../libs/Utils'
import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone'
import withLocation from '../services/withLocation'

// http://localhost:3000/reply-msg?msgid=4a79db7d-c975-4384-94ae-ac67bd647a59
class PageChat extends React.Component {
  //base
  constructor(props) {
    super(props)
    this.state = {
      msgID: null,
      typeUser: null,
      isLoggingNeeded: true,

      username: '',
      password: '',
      passwordShown: false,

      isChatting: true,

      messageToReplyText: '',
    }
  }

  componentDidMount() {
    const searchParams = new URLSearchParams(this.props.location.search)
    const someParam = searchParams.get('msgid')
    console.log('someParam:', someParam)
    EQService.GetMessaggioById(someParam).then((m) => {
      console.log('m: ', m)
      this.setState({ messageToReplyText: m.messaggio })
    })
    this.setState({ msgID: someParam })
  }

  //methods

  updateInputValue(evt) {
    const val = evt.target.value
    const field = evt.target.id
    console.log('field: ', field)

    this.setState((prevState) => {
      return {
        ...prevState,
        [field]: val,
      }
    })
    console.log(this.state.user)
  }

  togglePassword() {
    this.setState({
      passwordShown: !this.state.passwordShown,
    })
  }

  doLogin() {
    const { username, password } = this.state
    if (username === '' || password === '') {
      Utils.notifyError('Specificare username e password')
      return
    }

    EQService.creaSessione().then(() => {
      EQService.loginPerson(username, password).then((m) => {
        if (m.statusString === 'KO') {
          Utils.notifyError('Login errato')
          return
        } else {
          console.log('login ok')
          const { idSessioneWeb } = m.sessioneWeb
          console.log('idSessioneWeb: ', idSessioneWeb)
          EQService.isProfessionista(idSessioneWeb).then((res) => {
            if (res) {
              console.log('isProfessionista: ', res)
              this.setState({ typeUser: 'professionista' })
            } else {
              console.log('isProfessionista: ', res)
              this.setState({ typeUser: 'cliente' })
            }
          })
          this.setState({ isLoggingNeeded: false })
        }
      })
    })
  }

  goHome(user) {
    if (user === 'cliente') {
      document.location.href = '/'
    } else if (user === 'professionista') {
      document.location.href = '/caregiver/counters'
    }
  }

  sendText() {
    const textArea = document.getElementById('textArea')
    const textMessage = textArea.value
    console.log('textMessage: ', textMessage)
    if (textMessage) {
      EQService.sendReply(this.state.msgID, textMessage)
    }

    this.setState((prevState) => {
      return {
        ...prevState,
        textChat: textMessage,
        isChatting: false,
      }
    })
    textArea.value = ''
  }
  textAreaAdjust() {
    const element = document.getElementById('textArea')

    element.style.height = '1px'
    element.style.height = 25 + element.scrollHeight + 'px'
  }

  render() {
    return (
      <MuiThemeProvider>
        <section>
          {this.state.isLoggingNeeded ? (
            <Fragment>
              <Box className="login-box font-size-13">
                <Box className="pb-10">
                  <Typography
                    px={3}
                    mb={2}
                    align="center"
                    className="playFair"
                    variant="h5"
                    sx={{ fontSize: '15pt', color: '#fff' }}>
                    accedi alla tua area riservata per rispondere al messaggio
                  </Typography>
                  <Typography
                    px={3}
                    align="center"
                    className="playFair"
                    variant="h5"
                    sx={{ fontSize: '15pt', color: '#fff' }}>
                    Chi sei?
                  </Typography>
                </Box>
                <Box className="loginForm">
                  <Box
                    mt={3}
                    className="flex-row justify-start items-center">
                    <PersonOutlineTwoToneIcon style={{ marginRight: '5px' }} />
                    <EQTextField
                      onChange={(evt) => this.updateInputValue(evt)}
                      fullWidth
                      id="username"
                      label="Username"
                      placeholder="Username"></EQTextField>
                  </Box>
                  <Box
                    my={2}
                    className="flex-row justify-start items-center">
                    <VpnKeyTwoToneIcon style={{ marginRight: '5px' }} />
                    <TextField
                      className="inputPassword"
                      onChange={(evt) => this.updateInputValue(evt)}
                      type={this.state.passwordShown ? 'text' : 'password'}
                      fullWidth
                      id="password"
                      label="Password"
                      placeholder="****"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        // <-- This is where the toggle button is added.
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => this.togglePassword()}
                              onMouseDown={() => this.togglePassword()}>
                              {this.state.passwordShown ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}></TextField>
                  </Box>
                  <Box
                    sx={{ color: '#fff' }}
                    className="ml-5 flex-row justify-around  items-center mb-10">
                    <button
                      className="lost-password pt-0"
                      onClick={() => this.showResetPassword()}>
                      Password dimenticata
                    </button>
                  </Box>
                </Box>
                <Box
                  sx={{ textAlign: 'center' }}
                  className="needlesButtons"
                  mb={4}>
                  <button
                    className="buttonGreen text-white"
                    onClick={() => this.doLogin()}>
                    ACCEDI
                  </button>
                </Box>
              </Box>
            </Fragment>
          ) : (
            <Box className="login-box font-size-13">
              <Box className="pb-10">
                <Typography
                  px={3}
                  mb={2}
                  align="center"
                  className="playFair"
                  variant="h5"
                  sx={{ fontSize: '15pt', color: '#fff' }}>
                  Ecco la conversazione avuta con il{' '}
                  {this.state.typeUser === 'cliente'
                    ? 'professionista'
                    : 'cliente'}
                </Typography>
              </Box>
              <Box className="loginForm">
                <Box>
                  {this.state.messageToReplyText ? (
                    <Fragment>
                      <div style={{ display: 'flex' }}>
                        <div className="bubble-green">
                          {this.state.messageToReplyText}
                        </div>
                      </div>
                    </Fragment>
                  ) : null}
                </Box>
                {this.state.isChatting ? (
                  <div
                    id="chat-container"
                    style={{ display: 'flex' }}>
                    <div className="bubble">
                      <textarea
                        onChange={(evt) => this.updateInputValue(evt)}
                        id="textArea"
                        onKeyUp={() => this.textAreaAdjust()}
                        style={{ overflow: 'hidden' }}
                        rows="2"
                        cols="20"
                        placeholder="inserisci il messaggio qui..."
                      />
                      <button
                        className="send-button"
                        onClick={() => this.sendText()}>
                        {' '}
                        <SendIcon />{' '}
                      </button>
                    </div>
                  </div>
                ) : (
                  <Fragment>
                    <div
                      style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <div className="bubble">{this.state.textArea}</div>
                    </div>
                  </Fragment>
                )}

                <Box
                  sx={{ textAlign: 'center' }}
                  className="needlesButtons"
                  mt={4}
                  mb={4}>
                  <button
                    className="buttonGreen text-white"
                    onClick={() => this.goHome(this.state.typeUser)}>
                    torna alla home
                  </button>
                </Box>
              </Box>
            </Box>
          )}
        </section>
      </MuiThemeProvider>
    )
  }
}

export default withLocation(PageChat)
