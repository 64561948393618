/* eslint-disable react/jsx-pascal-case */
import { Box, Button, FormControl, Grid } from '@mui/material'
import React, { Fragment } from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CachedTwoToneIcon from '@mui/icons-material/CachedTwoTone'
import { Card } from 'material-ui'
import CardGiftcardIcon from '@mui/icons-material/CardGiftcardTwoTone'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import EQBook_Info from './EQBook_Info'
import EQBusinessRules from '../../libs/EQBusinessRules'
import EQCaregiverInCart from '../core/EQCaregiverInCart'
import EQFaqBox from '../EQFaqBox'
import EQFloatingFormIcon from '../core/EQFloatingFormIcon'
import EQService from '../../services/EQService'
import EQServiceBook from '../../services/EQServiceBook'
import EQState from '../../services/EQState'
import EQTextField from '../core/EQTextField'
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone'
import EventIcon from '@mui/icons-material/Event'
import Formats from '../../libs/Formats'
import Pages from '../../services/Pages'
import PersonOutlineIcon from '@mui/icons-material/PersonOutlineTwoTone'
import Utils from '../../libs/Utils'
import { withRouter } from 'react-router-dom'

let tout = null

class EQBookStep_Cart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      couponWrong: false,
      couponChecking: false,
      showDaSapere: false,
    }
    if (EQState.UserSelection) {
      if (EQState.UserSelection.CouponCode && !EQState.UserSelection.Coupon) {
        this.state.couponWrong = true
      }
    }
  }

  componentDidMount() {
    if (!EQState.UserSelection.Solutions_Caregivers) {
      document.location.href = '/'
      return
    }
    const page = Pages.current()
    document.title = page.title
    window.scrollTo(0, 0)
  }

  goBack() {
    EQState.change(EQState.UserSelection, {
      BookPage: `/book/caregivers`,
    })
    this.props.history.push({
      pathname: `/book/caregivers`,
    })
  }

  goForward() {
    let targetPage = '/book/recap'
    if (!EQService.sessioneWeb.user.isOTPOK) targetPage = '/book/otp'
    EQState.change(EQState.UserSelection, {
      BookPage: targetPage,
    })
    this.props.history.push({
      pathname: targetPage,
    })
  }

  verifyCoupon(e) {
    let couponInserted = e.target.value

    this.setState({
      couponChecking: true,
      couponWrong: false,
    })
    EQState.change(EQState.UserSelection, {
      Coupon: null,
    })

    EQServiceBook.getCoupon(couponInserted)
      .then((c) => {
        if (c === null) {
          //Utils.notifyError('Coupon non valido');
          EQState.change(EQState.UserSelection, {
            Coupon: null,
          })
          this.setState({
            couponChecking: false,
            couponWrong: true,
          })
        } else {
          EQState.change(EQState.UserSelection, {
            Coupon: c,
          })
          this.setState({
            couponChecking: false,
            couponWrong: false,
          })
        }
      })
      .catch((e) => {
        Utils.notifyError('Coupon non valido')
        EQState.change(EQState.UserSelection, {
          Coupon: null,
        })
        this.setState({
          couponChecking: false,
          couponWrong: true,
        })
      })
  }

  handleCouponChange(e) {
    //let couponInserted = e.target.value

    //if(couponInserted.length>EQBusinessRules.lunghezzaCoupon()) {
    //    return;
    //}

    this.setState({
      couponChecking: true,
      couponWrong: false,
    })

    //if(couponInserted.length==EQBusinessRules.lunghezzaCoupon())  {
    if (tout !== null) clearTimeout(tout)
    tout = setTimeout(() => {
      console.log('verifying', e)
      this.verifyCoupon(e)
    }, 300)
    //}

    this.setState({
      couponChecking: false,
      couponWrong: e.target.value.length > 0,
    })
    EQState.change(EQState.UserSelection, {
      CouponCode: e.target.value,
      Coupon: null,
    })
  }

  computeCartTotal_withoutCoupon() {
    let totale = 0

    const isEmergenza = EQBusinessRules.isEmergenza(
      EQState.UserSelection.idTipologiaServizio
    )
    if (isEmergenza) {
      totale = EQState.UserSelection._maxPrice
      return totale
    }

    EQState.UserSelection.Solutions_Caregivers.forEach((caregiver) => {
      caregiver._serviziPrenotazione.forEach((sp) => {
        if (sp._confirmed && sp._confirmedFor === caregiver.idProfessionista) {
          let prezzo = 0
          sp.soluzioneTrovatas.forEach((st) => {
            if (st.idProfessionista !== caregiver.idProfessionista) return
            prezzo += parseFloat(st.prezzo)
          })
          totale += prezzo
        }
      })
    })
    return totale
  }

  showDaSapere() {
    this.setState({
      showDaSapere: true,
    })
  }

  closeDaSapere() {
    this.setState({
      showDaSapere: false,
    })
  }

  render() {
    if (!EQState.UserSelection.Solutions_Caregivers) {
      return null
    }

    const pname =
      EQState.UserSelection.SelectedRarentalRelation.nome +
      ' ' +
      EQState.UserSelection.SelectedRarentalRelation.cognome
    const isEmergenza = EQBusinessRules.isEmergenza(
      EQState.UserSelection.idTipologiaServizio
    )

    let canGoForward = false
    if (isEmergenza && EQState.UserSelection.Solutions_Caregivers.length > 0)
      canGoForward = true
    else if (EQState.UserSelection.Solutions_ConfermedCnt > 0)
      canGoForward = true

    let total_withoutCoupon = this.computeCartTotal_withoutCoupon()
    let coupon_value = 0
    if (EQState.UserSelection.Coupon) {
      if (EQState.UserSelection.Coupon.scontoPercentuale !== 0) {
        coupon_value =
          total_withoutCoupon * EQState.UserSelection.Coupon.scontoPercentuale
      } else {
        coupon_value = EQState.UserSelection.Coupon.scontoValore
      }
    }
    let total = total_withoutCoupon - coupon_value

    console.log('EQState', EQState.UserSelection)
    let rowEmergenza = null
    if (isEmergenza) {
      const sp =
        EQState.UserSelection.Solutions_Results.servizioPrenotaziones[0]

      let infoMansioni = EQState.UserSelection.mansioni.map((m) => {
        let mansione =
          EQState.UserSelection.GruppoServizio.servizios[0].mansiones.filter(
            (om) => om.idMansione == m
          )
        return <span key={m}>{mansione[0].nome}</span>
      })

      let dateFrom = Formats.fromServerDate(sp.richiestoDal)
      let dateTo = Formats.fromServerDate(sp.richiestoAl)
      let day = Formats.dateToDayMonth(dateFrom)
      let shours = Formats.dateToHourMinutes(dateFrom)
      let ehours = Formats.dateToHourMinutes(dateTo)

      rowEmergenza = (
        <div className="solution solution-in-cart">
          <div className="explanation row">
            <div
              className="col1"
              style={{ paddingTop: '12px' }}>
              <EventIcon />
            </div>
            <div className="col2">
              <div className="col2l">
                <div className="main-info">
                  <div style={{ fontWeight: 'bold' }}>
                    {EQState.UserSelection.GruppoServizio.nome}
                  </div>
                  <div style={{ fontWeight: 'bold' }}>
                    {infoMansioni}&nbsp;
                    <div className="badge emergency">
                      Richiesta in emergenza
                    </div>
                  </div>
                  <div style={{ fontWeight: 'bold' }}>{day}</div>
                  <div>
                    dalle ore{' '}
                    <span style={{ fontWeight: 'bold' }}>{shours}</span>
                  </div>
                  <div>
                    alle ore{' '}
                    <span style={{ fontWeight: 'bold' }}>{ehours}</span>
                  </div>
                </div>
              </div>
              <div className="col2r">
                <div
                  className="price"
                  style={{ top: '8px' }}>
                  {Formats.formatPrice(EQState.UserSelection._maxPrice)} &euro;
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    let servizioParts =
      EQState.UserSelection.GruppoServizio.nomeCompleto.split('>')
    let titoloServizio = servizioParts[0]
    let sottotitoloServizio = servizioParts[1]
    return (
      <Fragment>
        <Box className="cart">
          <p className="playFair normalWeight textCenter bookstep-start-label">
            Prenotazione per
          </p>
          <div className="row">
            <div className="col1-icon">
              <PersonOutlineIcon />
            </div>
            <div className="col2-icon">
              <h3 className="textCenter patient-name">{pname}</h3>
            </div>
          </div>

          <div
            className="caregiver-card in-cart"
            style={{ marginTop: '20px' }}>
            <div className="card-body">
              <div className="row">
                <div className="col1"></div>
                <div className="col2">
                  <p className="playFair normalWeight bookstep-start-label no-y-m">
                    Stima costi per:
                  </p>
                  <div>{titoloServizio}</div>
                  <div>{sottotitoloServizio}</div>
                </div>
              </div>
            </div>
          </div>

          {EQState.UserSelection.Solutions_Caregivers.map((c, i) => {
            return (
              <EQCaregiverInCart
                style={{ marginTop: '16px', marginBottom: '16px' }}
                caregiver={c}
                key={i}
              />
            )
          })}

          {isEmergenza && (
            <div
              className="in-cart"
              style={{ margin: '12px' }}>
              <div className="card-body">
                <div className="solution-list-container">
                  <div className="solution-list">{rowEmergenza}</div>
                </div>
              </div>
            </div>
          )}

          <div className="caregiver-card in-cart">
            <div className="card-body">
              <div className="row">
                <div className="col1"></div>
                <div className="col2">
                  <p className="playFair normalWeight textCenter bookstep-start-label no-y-m">
                    Hai un coupon?
                  </p>
                </div>
              </div>
            </div>
          </div>

          <Box
            className="boxBianco large-side-padding"
            style={{ marginBottom: '20px' }}>
            <EQFloatingFormIcon>
              <CardGiftcardIcon className={'mr-5'} />
            </EQFloatingFormIcon>
            <Box pl={2}>
              <FormControl fullWidth>
                {EQState.UserSelection.Coupon && (
                  <div className="coupon-ok">
                    <CheckCircleOutlineIcon />
                  </div>
                )}
                {this.state.couponWrong && (
                  <div className="otp-wrong">
                    <ErrorTwoToneIcon />
                  </div>
                )}
                {this.state.couponChecking && (
                  <div className="otp-checking">
                    <CachedTwoToneIcon
                      sx={{
                        animation: 'spin 2s linear infinite',
                        '@keyframes spin': {
                          '0%': {
                            transform: 'rotate(360deg)',
                          },
                          '100%': {
                            transform: 'rotate(0deg)',
                          },
                        },
                      }}
                    />
                  </div>
                )}
                <EQTextField
                  label="Codice coupon"
                  maxLength={EQBusinessRules.lunghezzaCoupon()}
                  onChange={(e) => this.handleCouponChange(e)}
                  value={EQState.UserSelection.CouponCode}
                  onBlur={(e) => this.verifyCoupon(e)}
                />
                {EQState.UserSelection.Coupon && (
                  <div
                    style={{
                      textAlign: 'right',
                      marginTop: '8px',
                      fontWeight: 'bold',
                      fontSize: '110%',
                    }}>
                    <div className="price">
                      -{Formats.formatPrice(coupon_value)} &euro;
                    </div>
                  </div>
                )}
              </FormControl>
            </Box>
          </Box>

          <Card
            sx={{ display: 'flex' }}
            className="in-cart total">
            <div className="card-body">
              <div className="solution-list-container">
                <div className="solution-list">
                  <div className="solution solution-in-cart">
                    <div className="explanation row">
                      <div className="col1"></div>
                      <div className="col2">
                        <div className="col2l">
                          <div className="main-info">
                            <div style={{ fontWeight: 'bold' }}>TOTALE</div>
                          </div>
                        </div>
                        <div className="col2r">
                          <div
                            className="price total"
                            style={{ top: 0 }}>
                            {Formats.formatPrice(total)} &euro;
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>

          <Box style={{ textAlign: 'center' }}>
            <div>
              {canGoForward && (
                <a
                  className="buttonLink lnkForward bg-green text-white w-100"
                  onClick={() => this.goForward()}>
                  PROCEDI
                </a>
              )}
              {!canGoForward && (
                <a className="buttonLink lnkForward bg-green text-white disabled">
                  PROCEDI
                </a>
              )}
            </div>
            <p
              className="textCenter"
              style={{
                margin: '50px 20px 0 20px',
                color: '#48d700',
                fontWeight: 'bold',
              }}>
              Non addebiteremo niente finchè non inizieremo il servizio. Potrai
              disdire la prenotazione.
            </p>
            <p
              className="textCenter"
              style={{
                margin: '10px 20px 0 20px',
                color: '#b8b8b8',
                fontSize: '12px',
              }}>
              I servizi sono erogati da cooperative, società e liberi
              professionisti.
            </p>
          </Box>

          <Grid
            className="book-navigation shrink"
            container
            mt={0}
            mb={0}>
            <Grid
              item
              xs={6}>
              <Button
                className="lnkButton lnkBack"
                startIcon={<ArrowBackIcon />}
                onClick={() => this.goBack()}>
                Indietro
              </Button>
            </Grid>
            <Grid
              item
              sx={{ textAlign: 'right', paddingRight: '20px' }}
              xs={6}></Grid>
          </Grid>
        </Box>

        <Box>
          <EQFaqBox category="Carrello"></EQFaqBox>
        </Box>

        {this.state.showDaSapere && (
          <EQBook_Info onClose={() => this.closeDaSapere()} />
        )}
      </Fragment>
    )
  }
}

export default withRouter(EQBookStep_Cart)
