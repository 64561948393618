/* eslint-disable react/no-direct-mutation-state */

import { Alert, Box, FormControl, Grid, Modal } from '@mui/material'
import React, { Fragment } from 'react'

import EQAdditionalInfoTypes from '../../libs/EQAdditionlInfoTypes'
import EQCheckbox from '../core/EQCheckbox'
import EQLoading from '../EQLoading'
import EQService from '../../services/EQService'
import EQServiceCaregiver from '../../services/EQServiceCaregiver'
import EQTextField from '../core/EQTextField'
import Formats from '../../libs/Formats'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  width: '80%',
  bgcolor: '#ffe371',
  border: '0',
  boxShadow: 24,
  p: 2,
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
}

export default class EQServiceReport_Edit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      gruppi: null,
      report: {},
      ro: false,
      hideInternal: false,
      serviceChecked: {},
      answers: {},
      difficulties: [
        {
          code: 'AssistitoAggressivo',
          label: 'Assistito/a fisicamente aggressivo/a',
        },
        { code: 'Animali', label: 'Animali non segnalati' },
        {
          code: 'RichiestaInfermiere',
          label: 'Richiesta di prestazioni infermieristiche',
        },
        { code: 'Ausilii', label: 'Mancanza di ausili idonei' },
        { code: 'Info', label: 'Mancanza di info adeguate' },
        {
          code: 'CompSessuali',
          label: 'Comportamenti sessuali inappropriati dell’assistito/a',
        },
        { code: 'Rischi', label: 'Rischi da segnalare' },
        { code: 'VerbaVolant', label: 'Assistito/a verbalmente aggressivo/a' },
        { code: 'Rifiuto', label: 'Rifiuto dell’assistito/a' },
        { code: 'Tempo', label: 'Tempo non sufficiente' },
        { code: 'Domicilio', label: 'Difficoltà ad accedere al domicilio' },
        { code: 'Altro', label: 'Altro' },
      ],
    }
    this.reportStyle =
      '<style>' +
      'p { margin: 0; padding: 0; }' +
      'strong{font-weight: normal !important;}' +
      '.r-title{font-size: large ; font-weight: bold ; text-transform: uppercase ; margin-bottom: 10px}' +
      '.r-subtitle{font-size: medium ; font-weight: bold ; padding : 15px 0 10px 0; margin-top: 15px; border-top: 1px solid black;}' +
      '.r-subtitle-tip{font-size: medium ; font-weight: bold ; padding : 15px 0 0 0; margin-top: 15px; border-top: 1px solid black;text-transform: uppercase ;}' +
      '.r-data,.r-data-label{padding-left: 5px}' +
      '.r-sub-data{padding-left: 10px}' +
      '.make-strong{font-weight: bold !important;}' +
      '.r-difficulties{margin-bottom: 5px}' +
      '</style>'

    props.selectedEvent.servizioPrenotazione.mansioneServizioPrenotaziones.forEach(
      (m) => {
        this.state.serviceChecked[m.idMansione] = true
      }
    )
  }

  componentDidMount() {
    this.loadServices()
  }

  ensureTwoDigits(s) {
    s = '' + s
    if (s.length < 2) s = '0' + s
    return s
  }

  formatHHMM(dd) {
    let hh = this.ensureTwoDigits(dd.getHours())
    let mm = this.ensureTwoDigits(dd.getMinutes())
    if (hh === '00' && mm === '00') return '' // giornate complete di ferie non hanno indicazione dell'ora
    return hh + ':' + mm
  }

  loadServices() {
    EQService.getServizioById(
      this.props.selectedEvent.servizioPrenotazione.idServizio
    ).then((ss) => {
      this.setState({ servizio: ss })
      EQService.getGruppiServizio(
        this.state.servizio.gruppoServizio.idTipologiaServizio
      ).then((gg) => {
        // fisio, masso, infermieri
        if (
          ss.idGruppoServizio === 3 ||
          ss.idGruppoServizio === 4 ||
          ss.idGruppoServizio === 5
        ) {
          let filteredGroups = gg.filter(
            (g) => g.idGruppoServizio === ss.idGruppoServizio
          )
          this.setState({ gruppi: filteredGroups })
        } else {
          let filteredGroups = gg.filter(
            (g) =>
              g.idGruppoServizio !== 3 &&
              g.idGruppoServizio !== 4 &&
              g.idGruppoServizio !== 5
          )
          this.setState({ gruppi: filteredGroups })
        }
      })
    })
  }

  handleServiceChange(e, m) {
    this.state.serviceChecked[m.idMansione] =
      !this.state.serviceChecked[m.idMansione]
    this.forceUpdate()
  }

  fieldChanged(e, key) {
    this.state.answers[key] = e.target.value
    this.forceUpdate()
  }

  checkChanged(e, key, val) {
    if (e.target.checked) this.state.answers[key] = val
    else delete this.state.answers[key]
    this.forceUpdate()
  }

  bool_nn(v) {
    if (!v) return false
    else return true
  }

  askSaveConfirm() {
    this.setState({
      confirm_open: true,
    })
  }

  cancelSave() {
    this.setState({
      confirm_open: false,
    })
  }

  actuallySave() {
    this.actuallySave_actual()
  }

  actuallySave_fake() {
    this.setState({
      ro: true,
    })
    //console.log("%cthis.state.answers", "color: white ; background-color: blue", this.state.answers);
    setTimeout(() => {
      const htmlContents = document.getElementById(
        'post-service-report-contents'
      )
      const win = window.open('', 'Complete')
      win.document.write(
        `${this.reportStyle}<div>${htmlContents.innerHTML}</div>`
      )

      this.setState({
        hideInternal: true,
      })
      setTimeout(() => {
        const win2 = window.open('', 'Partial')
        win2.document.write(
          `${this.reportStyle}<div>${htmlContents.innerHTML}</div>`
        )

        this.setState({
          hideInternal: false,
        })
      }, 0)
    }, 0)
    this.cancelSave()
    console.log({
      answers: this.state.answers,
      serviceChecked: this.state.serviceChecked,
      report: this.state.report,
    })
  }

  actuallySave_actual() {
    this.setState({
      ro: true,
    })

    //console.log("%cthis.state.answers", "color: white ; background-color: blue", this.state.answers);
    const structure = {
      answers: this.state.answers,
      serviceChecked: this.state.serviceChecked,
      report: this.state.report,
    }
    let idCalendario = this.props.selectedEvent.idCalendario
    let payload = JSON.stringify(structure)
    //console.log("Sending",payload)
    EQServiceCaregiver.addAdditionalInfo(
      idCalendario,
      EQAdditionalInfoTypes.JSON_SOURCE, // struttura originale
      payload
    ).then(() => {
      setTimeout(() => {
        const htmlContents = document.getElementById(
          'post-service-report-contents'
        )
        const completePresentation = `${this.reportStyle}<div>${htmlContents.innerHTML}</div>`
        //console.log("Sending",completePresentation)

        EQServiceCaregiver.addAdditionalInfo(
          idCalendario,
          EQAdditionalInfoTypes.HTML_COMPLETE, // HTML completo
          completePresentation
        ).then(() => {
          this.setState({
            hideInternal: true,
          })
          setTimeout(() => {
            const partialPresentation = `${this.reportStyle}<div>${htmlContents.innerHTML}</div>`
            //console.log("Sending",partialPresentation)
            EQServiceCaregiver.addAdditionalInfo(
              idCalendario,
              EQAdditionalInfoTypes.HTML_PARTIAL, // HTML parziale, vista cliente
              partialPresentation
            ).then(() => {
              this.setState({
                hideInternal: false,
              })
            })
          }, 0)
        })
      }, 0)
    })

    this.cancelSave()
    console.log({
      answers: this.state.answers,
      serviceChecked: this.state.serviceChecked,
      report: this.state.report,
    })
  }

  render() {
    const e = this.props.selectedEvent
    const ro = this.state.ro

    let dayNames = Formats.dayNames()
    let monthNames = Formats.monthNames()

    let dateDivs = []
    let fromDate = Formats.fromServerDate(e.dal)
    let toDate = Formats.fromServerDate(e.al)
    let fd = new Date(fromDate.getTime())
    fd.setHours(0, 0, 0, 0)
    let td = new Date(toDate.getTime())
    td.setHours(0, 0, 0, 0)
    //let dayDiff = false
    if (fd.getTime() === td.getTime()) {
      // evento in un solo giorno
      //console.log("evento in solo giorno")
      let datePresentation =
        dayNames[fromDate.getDay()] +
        ' ' +
        fromDate.getDate() +
        ' ' +
        monthNames[fromDate.getMonth()] +
        ' ' +
        this.formatHHMM(fromDate) +
        ' / ' +
        this.formatHHMM(toDate)
      dateDivs.push(<div key={1}>{datePresentation}</div>)
    } else {
      // evento in un giorni differenti
      //console.log("evento in giorni differenti")
      //  dayDiff = true
      let shhmm = this.formatHHMM(fromDate)
      let datePresentation =
        'Da ' +
        dayNames[fromDate.getDay()] +
        ' ' +
        fromDate.getDate() +
        ' ' +
        monthNames[fromDate.getMonth()] +
        ' ' +
        shhmm
      dateDivs.push(<div key={1}>{datePresentation}</div>)

      let ehhmm = this.formatHHMM(toDate)
      if ((shhmm === '') & (ehhmm === '')) {
        // caso particolare: evento su giorni interi
        // il punto finale è il giorno escluso
        toDate.setDate(toDate.getDate() - 1)
      }
      datePresentation =
        'A ' +
        dayNames[toDate.getDay()] +
        ' ' +
        toDate.getDate() +
        ' ' +
        monthNames[toDate.getMonth()] +
        ' ' +
        this.formatHHMM(toDate)
      dateDivs.push(<div key={2}>{datePresentation}</div>)
    }

    let grpDivs = []
    if (this.state.gruppi) {
      grpDivs = this.state.gruppi.map((g) => {
        let mDivs = g.servizios[0].mansiones.map((m) => {
          if (
            this.state.ro &&
            !this.bool_nn(this.state.serviceChecked[m.idMansione])
          )
            return null
          if (this.state.ro) {
            return (
              <div
                className="r-data"
                key={m.idMansione}>
                <label htmlFor={'m' + m.idMansione}>{m.nome}</label>
              </div>
            )
          } else {
            return (
              <div
                className="check-container"
                key={m.idMansione}>
                <div>
                  <EQCheckbox
                    id={'m' + m.idMansione}
                    onChange={(e) => this.handleServiceChange(e, m)}
                    disabled={this.state.ro}
                    checked={this.bool_nn(
                      this.state.serviceChecked[m.idMansione]
                    )}
                  />
                </div>
                <div>
                  <label htmlFor={'m' + m.idMansione}>{m.nome}</label>
                </div>
              </div>
            )
          }
        })
        mDivs = mDivs.filter((x) => x !== null)
        if (mDivs.length === 0) return null
        return (
          <Box
            pt={2}
            key={g.idGruppoServizio}>
            <b>{g.nome}</b>
            {mDivs}
          </Box>
        )
      })
    }

    //console.log("ser", this.state.servizio)
    return (
      <div id="post-service-report-contents">
        <Box
          className="bookDetailInfo"
          pt={1}>
          <Box pt={1}>
            <label className="make-strong">CODICE DI PRENOTAZIONE</label>
            <Box className="r-data kill-strong">
              <strong>{e.servizioPrenotazione.idPrenotazione}</strong>
            </Box>
          </Box>
          <Box pt={1}>
            <label className="make-strong">DATA</label>
            <Box className="r-data kill-strong">
              <strong>{dateDivs}</strong>
            </Box>
          </Box>

          <Box
            pt={3}
            className="r-subtitle">
            <label className="area">AREA 1 - SERVIZI RESI</label>
            <Box className="r-data-label">
              Quali attività ti sono state richieste?
            </Box>
          </Box>
          <Box pt={2}>
            {!this.state.gruppi && <EQLoading />}
            {this.state.gruppi && grpDivs}
          </Box>

          <Box
            pt={3}
            className=" r-subtitle">
            <label className="area">AREA 2 - COMUNICAZIONI</label>
          </Box>
          <Box pt={2}>
            {this.state.ro ? (
              <div>
                <label>
                  <strong>Imprevisti durante il servizio</strong>
                </label>
                <p className="r-data m-0">
                  {this.state.answers.WARNINGS || 'No'}
                </p>
              </div>
            ) : (
              <FormControl fullWidth>
                <label>Imprevisti durante il servizio</label>
                <EQTextField
                  defaultValue={this.state.answers.WARNINGS}
                  multiline
                  readOnly={this.state.ro}
                  label={this.state.ro ? ' ' : 'Dettaglio'}
                  onChange={(e) =>
                    this.fieldChanged(e, 'WARNINGS')
                  }></EQTextField>
              </FormControl>
            )}
          </Box>
          <Box pt={2}>
            {this.state.ro ? (
              <div>
                <label>
                  <strong>Segnalazioni alla famiglia</strong>
                </label>
                <p className="r-data m-0">
                  {this.state.answers.INFO_FAMILY || 'No'}
                </p>
              </div>
            ) : (
              <FormControl fullWidth>
                <label>Segnalazioni alla famiglia</label>
                <EQTextField
                  defaultValue={this.state.answers.INFO_FAMILY}
                  multiline
                  readOnly={this.state.ro}
                  label={this.state.ro ? ' ' : 'Dettaglio'}
                  onChange={(e) =>
                    this.fieldChanged(e, 'INFO_FAMILY')
                  }></EQTextField>
              </FormControl>
            )}
            {this.state.ro === false ? (
              <Alert
                severity="info"
                className="compilation_hints">
                <b>
                  <u>Informazioni utili da segnalare:</u>
                </b>
                <div>
                  <b>SUGGERIMENTI</b> - Idee o circostanze che hanno reso
                  possibile aggirare un rifiuto o calmare una ribellione -
                  Piccoli alert rispetto a cose che si è osservato che lo fanno
                  innervosire
                </div>
                <div>
                  <b>SUCCESSI/TRAGUARDI</b> dell’assistito/a
                </div>
                <div>
                  <b>DETTAGLI SIGNIFICATIVI</b> delle attività svolte (Se ha
                  segnato sopra "stimolazione cognitiva"”" inserire il modo:
                  parole crociate, giochi ad hoc per la stimolazione cognitiva,
                  scarabeo... Se ha segnato sopra “stimolazione sensoriale”
                  inserire il modo: assaggio di diversi cibi, esercitazione
                  olfattiva coi profumi... etc etc)
                </div>
                <div>
                  <b>AUSILI</b> – Guanti monouso, deambulatore, solleva tore,
                  letto ortopedico, maniglioni, scorrimano
                </div>
                <div>
                  <b>PERICOLI</b> - Tappeti, sporgenze instabili, spigoli,
                  passaggi ingombrati, Illuminazione insufficiente, detersivi a
                  portata di mano, oggetti taglienti-contundenti
                </div>
              </Alert>
            ) : null}
          </Box>

          {!this.state.hideInternal && (
            <Box
              pt={3}
              className="report_internal r-subtitle">
              <label className="area">REPORT INTERNO</label>
            </Box>
          )}

          {/* fisioterapia, massoterapia, infermieri */}
          {/*                        {this.state.servizio && (this.state.servizio.gruppoServizio.idGruppoServizio === 4 || this.state.servizio.gruppoServizio.idGruppoServizio === 5 || this.state.servizio.gruppoServizio.idGruppoServizio === 3) &&
                            <Fragment>
                                <Box pt={2}>
                                    <FormControl fullWidth>
                                        <label>Suggerimenti per eventuali colleghi</label>
                                        <EQTextField defaultValue={this.state.answers.HINTS_OTHERS} multiline
                                                     label="Dettaglio"
                                                     onChange={(e) => this.fieldChanged(e, 'HINTS_OTHERS')}>
                                        </EQTextField>
                                    </FormControl>
                                </Box>
                            </Fragment>
                        }*/}
          {!this.state.hideInternal &&
            this.state.servizio &&
            this.state.servizio.gruppoServizio.idGruppoServizio !== 4 &&
            this.state.servizio.gruppoServizio.idGruppoServizio !== 5 &&
            this.state.servizio.gruppoServizio.idGruppoServizio !== 3 && (
              <div className="report_internal">
                <Box pt={2}>
                  <label>
                    <b>Ruolo caregiver famigliare presente</b>
                  </label>
                  <div className="check-container">
                    {this.state.ro ? null : (
                      <div>
                        <EQCheckbox
                          id={'coniuge'}
                          disabled={ro}
                          onChange={(e) =>
                            this.checkChanged(e, 'ROLE1', 'Coniuge')
                          }
                          checked={this.state.answers['ROLE1'] === 'Coniuge'}
                        />
                      </div>
                    )}
                    <div className="r-data">
                      {(this.state.ro === false ||
                        this.state.answers['ROLE1'] === 'Coniuge') && (
                        <label htmlFor={'coniuge'}>Coniuge</label>
                      )}
                    </div>
                  </div>
                  <div className="check-container">
                    {this.state.ro === true ? null : (
                      <div>
                        <EQCheckbox
                          id={'figlio'}
                          disabled={ro}
                          onChange={(e) =>
                            this.checkChanged(e, 'ROLE2', 'Figlio')
                          }
                          checked={this.state.answers['ROLE2'] === 'Figlio'}
                        />
                      </div>
                    )}
                    <div className="r-data">
                      {(this.state.ro === false ||
                        this.state.answers['ROLE2'] === 'Figlio') && (
                        <label htmlFor={'figlio'}>Figlio/a</label>
                      )}
                    </div>
                  </div>
                  <div className="check-container">
                    {this.state.ro === true ? null : (
                      <div>
                        <EQCheckbox
                          id={'badante'}
                          disabled={ro}
                          onChange={(e) =>
                            this.checkChanged(e, 'ROLE3', 'Badante/Colf')
                          }
                          checked={
                            this.state.answers['ROLE3'] === 'Badante/Colf'
                          }
                        />
                      </div>
                    )}
                    <div className="r-data">
                      {(this.state.ro === false ||
                        this.state.answers['ROLE3'] === 'Badante/Colf') && (
                        <label htmlFor={'badante'}>Badante/Colf</label>
                      )}
                    </div>
                  </div>
                  <div className="check-container">
                    {this.state.ro === true ? null : (
                      <div>
                        <EQCheckbox
                          id={'altro'}
                          disabled={ro}
                          onChange={(e) =>
                            this.checkChanged(e, 'ROLE4', 'Altro')
                          }
                          checked={this.state.answers['ROLE4'] === 'Altro'}
                        />
                      </div>
                    )}
                    <div className="r-data">
                      {(this.state.ro === false ||
                        this.state.answers['ROLE4'] === 'Altro') && (
                        <label htmlFor={'altro'}>Altro</label>
                      )}
                    </div>
                  </div>
                  <div className="check-container">
                    {this.state.ro === true ? null : (
                      <div>
                        <EQCheckbox
                          id={'no_cgv'}
                          disabled={ro}
                          onChange={(e) =>
                            this.checkChanged(e, 'ROLE5', 'Nessun CgvFam')
                          }
                          checked={
                            this.state.answers['ROLE5'] === 'Nessun CgvFam'
                          }
                        />
                      </div>
                    )}
                    <div className="r-data">
                      {(this.state.ro === false ||
                        this.state.answers['ROLE5'] === 'Nessun CgvFam') && (
                        <label htmlFor={'no_cgv'}>Nessun CgvFam</label>
                      )}
                    </div>
                  </div>
                  {this.state.ro ? (
                    <div className="check-container">
                      <div className="r-data">
                        {this.state.answers['ROLE1'] !== 'Coniuge' &&
                          this.state.answers['ROLE2'] !== 'Figlio' &&
                          this.state.answers['ROLE3'] !== 'Badante/Colf' &&
                          this.state.answers['ROLE4'] !== 'Altro' &&
                          this.state.answers['ROLE5'] !== 'Nessun CgvFam' && (
                            <label>Nessun Caregiver familiare presente</label>
                          )}
                      </div>
                    </div>
                  ) : null}
                </Box>
                <Box pt={2}>
                  <label>
                    <b>Il caregiver famigliare ha creato problemi?</b>
                  </label>
                  <div className="check-container">
                    {this.state.ro === true ? null : (
                      <div>
                        <EQCheckbox
                          id={'prb_yes'}
                          disabled={ro}
                          onChange={(e) =>
                            this.checkChanged(e, 'PROBLEMS', 'Si')
                          }
                          checked={this.state.answers['PROBLEMS'] === 'Si'}
                        />
                      </div>
                    )}
                    <div className="r-data">
                      {(this.state.ro === false ||
                        this.state.answers['PROBLEMS'] === 'Si') && (
                        <label htmlFor={'prb_yes'}>Si</label>
                      )}
                      {this.state.ro === true &&
                        this.state.answers['PROBLEMS'] !== 'Si' && (
                          <label htmlFor={'prb_yes'}>No</label>
                        )}
                    </div>
                  </div>
                </Box>
                <Box pt={2}>
                  <label>
                    <b>Difficoltà nello svolgere il servizio </b>
                  </label>
                  {this.state.ro ? null : (
                    <div className="check-container">
                      <div>
                        <EQCheckbox
                          id={'diff_yes'}
                          disabled={ro}
                          onChange={(e) =>
                            this.checkChanged(e, 'DIFFICULTIES', 'Si')
                          }
                          checked={this.state.answers['DIFFICULTIES'] === 'Si'}
                        />
                      </div>
                      <div>
                        <label htmlFor={'diff_yes'}>Si</label>
                      </div>
                    </div>
                  )}
                </Box>
                {this.state.answers['DIFFICULTIES'] === 'Si' && (
                  <Box>
                    {!this.state.ro && (
                      <label>
                        <b>Specificare</b>
                      </label>
                    )}
                    {this.state.difficulties.map((d) => {
                      return (
                        <Fragment key={d.code}>
                          {this.state.ro ? (
                            <>
                              {this.state.answers[d.code] === 'Si' && (
                                <>
                                  {d.name && (
                                    <Box>
                                      <label
                                        className="r-data r-difficulties "
                                        htmlFor={d.code}>
                                        {d.nome}
                                      </label>
                                    </Box>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <Box pt={2}>
                              <div className="check-container">
                                <div>
                                  <EQCheckbox
                                    id={d.code}
                                    disabled={ro}
                                    onChange={(e) =>
                                      this.checkChanged(e, d.code, 'Si')
                                    }
                                    checked={
                                      this.state.answers[d.code] === 'Si'
                                    }
                                  />
                                </div>
                                <div>
                                  <label htmlFor={d.code}>{d.label}</label>
                                </div>
                              </div>
                            </Box>
                          )}
                          {this.state.answers[d.code] === 'Si' && (
                            <Box>
                              {' '}
                              {this.state.ro ? (
                                <div className="r-data r-difficulties ">
                                  <p>
                                    {d.label +
                                      (this.state.answers[d.code + '_TXT']
                                        ? ': '
                                        : '')}
                                  </p>
                                  <p className="r-sub-data pl-5 italic">
                                    {this.state.answers[d.code + '_TXT'] || ''}
                                  </p>
                                </div>
                              ) : (
                                <FormControl fullWidth>
                                  <EQTextField
                                    defaultValue={
                                      this.state.answers[d.code + '_TXT']
                                    }
                                    multiline
                                    readOnly={ro}
                                    label={ro ? ' ' : 'Dettagli'}
                                    onChange={(e) =>
                                      this.fieldChanged(e, d.code + '_TXT')
                                    }></EQTextField>
                                </FormControl>
                              )}
                            </Box>
                          )}
                        </Fragment>
                      )
                    })}
                  </Box>
                )}
                {this.state.ro &&
                  this.state.answers['DIFFICULTIES'] !== 'Si' && (
                    <div className="r-data">No</div>
                  )}
              </div>
            )}

          {!this.state.hideInternal && (
            <div className="report_internal">
              <Box pt={2}>
                <label>
                  <b>L'alloggio era sufficientemente pulito?</b>
                </label>
                {this.state.ro ? null : (
                  <div className="check-container">
                    <div>
                      <EQCheckbox
                        id={'pulizia'}
                        disabled={ro}
                        onChange={(e) => this.checkChanged(e, 'CLEAN', 'No')}
                        checked={this.state.answers['CLEAN'] === 'No'}
                      />
                    </div>
                    <div>
                      <label htmlFor={'pulizia'}>No</label>
                    </div>
                  </div>
                )}
              </Box>
              {this.state.answers['CLEAN'] === 'No' && (
                <Box pt={2}>
                  {' '}
                  {this.state.ro ? (
                    <div className="r-data">
                      No, {this.state.answers.CLEAN_TXT}
                    </div>
                  ) : (
                    <FormControl fullWidth>
                      <EQTextField
                        defaultValue={this.state.answers.CLEAN_TXT}
                        multiline
                        readOnly={ro}
                        label={ro ? ' ' : 'Spiega'}
                        onChange={(e) =>
                          this.fieldChanged(e, 'CLEAN_TXT')
                        }></EQTextField>
                    </FormControl>
                  )}
                </Box>
              )}
              {this.state.ro && this.state.answers['CLEAN'] !== 'No' && (
                <div className="r-data">Si</div>
              )}

              <Box pt={2}>
                <label>
                  <b>Ho trovato barriere architettoniche?</b>
                </label>
                {this.state.ro ? null : (
                  <div className="check-container">
                    <div>
                      <EQCheckbox
                        id={'barriere'}
                        disabled={ro}
                        onChange={(e) => this.checkChanged(e, 'BARRIER', 'Si')}
                        checked={this.state.answers['BARRIER'] === 'Si'}
                      />
                    </div>
                    <div>
                      <label htmlFor={'barriere'}>Si</label>
                    </div>
                  </div>
                )}
              </Box>
              {this.state.answers['BARRIER'] === 'Si' && (
                <Box pt={2}>
                  {this.state.ro ? (
                    <div className="r-data">
                      Si, {this.state.answers.BARRIER_TXT || ''}
                    </div>
                  ) : (
                    <FormControl fullWidth>
                      <EQTextField
                        defaultValue={this.state.answers.BARRIER_TXT}
                        multiline
                        readOnly={ro}
                        label={ro ? ' ' : 'Spiega'}
                        onChange={(e) =>
                          this.fieldChanged(e, 'BARRIER_TXT')
                        }></EQTextField>
                    </FormControl>
                  )}
                </Box>
              )}
              {this.state.ro && this.state.answers['BARRIER'] !== 'Si' && (
                <div className="r-data">No</div>
              )}
              <Box pt={2}>
                {this.state.ro ? (
                  <>
                    <div className=" r-subtitle-tip">
                      <label>
                        <b>Suggerimenti per eventuali colleghi</b>
                      </label>
                    </div>
                    <div className="r-data">
                      {this.state.answers.HINTS_OTHERS || 'No'}
                    </div>
                  </>
                ) : (
                  <FormControl fullWidth>
                    <label>Suggerimenti per eventuali colleghi</label>
                    <EQTextField
                      defaultValue={this.state.answers.HINTS_OTHERS}
                      multiline
                      readOnly={ro}
                      label={ro ? ' ' : 'Dettaglio'}
                      onChange={(e) =>
                        this.fieldChanged(e, 'HINTS_OTHERS')
                      }></EQTextField>
                  </FormControl>
                )}
              </Box>
            </div>
          )}
        </Box>

        {this.state.ro === false ? (
          <Grid
            container
            mt={0}
            mb={4}>
            <Grid
              item
              sx={{ textAlign: 'center' }}
              xs={12}>
              <a
                className="buttonLink bg-green lnkForward"
                onClick={() => this.askSaveConfirm()}>
                SALVA
              </a>
            </Grid>
          </Grid>
        ) : null}

        {this.state.confirm_open && (
          <Modal
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={modalStyle}>
              <Box
                mt={0}
                p={0}
                pt={2}
                pb={2}>
                <div className="textCenter playFair app-text modal-title">
                  <b>Sei sicuro di voler confermare il report?</b>
                </div>

                <Grid
                  container
                  mt={0}
                  mb={2}
                  className="flex-row-center xs-column">
                  <Grid
                    item
                    sx={{ textAlign: 'left' }}
                    mt={3}
                    xs={6}>
                    <div className="flex-row-center">
                      <a
                        className="buttonLink bg-red lnkForward"
                        onClick={() => this.cancelSave()}>
                        ANNULLA
                      </a>
                    </div>
                  </Grid>
                  <Grid
                    item
                    sx={{ textAlign: 'right' }}
                    mt={3}
                    xs={6}>
                    <div className="flex-row-center">
                      <a
                        className="buttonLink bg-green lnkForward"
                        onClick={() => this.actuallySave()}>
                        CONFERMO
                      </a>
                    </div>
                  </Grid>
                </Grid>

                <Box mt={6}>
                  <div className="textCenter playFair app-text">
                    Una volta confermata l'operazione non sarà più possibile
                    modificare i dati
                  </div>
                </Box>
              </Box>
            </Box>
          </Modal>
        )}
      </div>
    )
  }
}
