import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  TextField,
} from '@mui/material'
import React, { Fragment } from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Box from '@mui/material/Box'
import EQBookStep_Do_Setup from './EQBookStep_Do_Setup'
import EQBookStep_GenderSelection from './EQBookStep_GenderSelection'
import EQBusinessRules from '../../libs/EQBusinessRules'
import EQCheckbox from '../core/EQCheckbox'
import EQFaqBox from '../EQFaqBox'
import EQSelect from '../core/EQSelect'
import EQServiceBook from '../../services/EQServiceBook'
import EQState from '../../services/EQState'
import EQTextField from '../core/EQTextField'
import { HomeTwoTone } from '@mui/icons-material'
import { MenuItem } from 'material-ui'
import Pages from '../../services/Pages'
import PersonOutlineIcon from '@mui/icons-material/PersonOutlineTwoTone'
import PetsTwoToneIcon from '@mui/icons-material/PetsTwoTone'
import SmokingRoomsTwoToneIcon from '@mui/icons-material/SmokingRoomsTwoTone'
import { withRouter } from 'react-router-dom'

class EQBookStep_Location extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      inSetup: false,
      UserSelection: EQState.UserSelection,
      helpCode: null,
    }

    if (!this.state.UserSelection.Location) {
      if (
        this.state.UserSelection.SelectedRarentalRelation &&
        this.state.UserSelection.SelectedRarentalRelation.prenotaziones &&
        this.state.UserSelection.SelectedRarentalRelation.prenotaziones.length >
          0
      ) {
        let lastPreno =
          this.state.UserSelection.SelectedRarentalRelation.prenotaziones[
            this.state.UserSelection.SelectedRarentalRelation.prenotaziones
              .length - 1
          ]
        console.log('lastPreno', lastPreno)
        this.state.UserSelection.Location = {
          home: lastPreno.idTipoLuogo === 0 ? true : false,
          rsa: lastPreno.idTipoLuogo === 0 ? false : true,
          city: lastPreno.idComune,
          CAP: lastPreno.CAP,
          address: lastPreno.indirizzo,
          notes:
            lastPreno.commentoRichiedente === '.'
              ? ''
              : lastPreno.commentoRichiedente, // TODO: parametri opzionali
        }
      } else {
        this.state.UserSelection.Location = {
          home: true,
          city: '',
          address: '',
          notes: '',
        }
      }
    }

    // forzatura a casa per ora - bugid#8188
    EQState.UserSelection.Location.home = true
    EQState.UserSelection.Location.rsa = false
  }

  reloadState() {
    this.setState({
      UserSelection: EQState.UserSelection,
    })
  }

  componentDidMount() {
    if (
      !EQState.UserSelection ||
      !EQState.UserSelection.GruppoServizio ||
      !EQState.UserSelection.SelectedRarentalRelation
    ) {
      document.location.href = '/'
      return
    }

    const page = Pages.current()
    document.title = page.title
    window.scrollTo(0, 0)
    EQState.addListener(EQState.UserSelection, this)
    EQServiceBook.mapCAP().then((m) => {
      this.setState({ CAP: m })
    })
    EQServiceBook.mapComuni().then((m) => {
      this.setState({ comuni: m })
    })
  }

  componentWillUnmount() {
    EQState.removeListener(EQState.UserSelection, this)
  }

  handleLocationTypeChange(event, key) {
    let loc = EQState.UserSelection.Location
    loc['home'] = false
    loc['rsa'] = false
    loc[key] = true
    this.state.changing = true
    EQState.change(EQState.UserSelection, {
      Location: loc,
    })
    setTimeout(() => {
      this.setState({ changing: false })
    }, 0)
  }

  handleLocationInfoChange(event, key) {
    let loc = EQState.UserSelection.Location
    loc[key] = event.target.value
    EQState.change(EQState.UserSelection, {
      Location: loc,
    })
  }

  handleCAPChange(event, value) {
    let loc = EQState.UserSelection.Location
    loc.CAP = value
    if (value) loc.city = value.idComune
    EQState.change(EQState.UserSelection, {
      Location: loc,
    })
  }

  handleInfoToggle(event, key) {
    let pr = EQState.UserSelection.SelectedRarentalRelation
    pr[key] = !pr[key]
    EQState.change(EQState.UserSelection, {
      SelectedRarentalRelation: pr,
    })
  }

  openHelp(helpCode) {
    this.setState({ helpCode: helpCode })
  }

  goBack() {
    if (this.state.inGenderSelection) {
      // Se stiamo scegliendo il sesso, ritorniamo solo un passo indietro
      this.setState({
        inGenderSelection: false,
      })
      return
    }

    EQState.change(EQState.UserSelection, {
      BookPage: `/book/medical`,
    })
    this.props.history.push({
      pathname: `/book/medical`,
    })
  }

  goForward() {
    // Reset delle date di prenotazione in modo che vengano ricalcolate opportunamente
    // sulla base dei vincoli attuali (al prossimo passo)
    EQState.UserSelection.selectedRanges = null

    if (this.state.inGenderSelection) {
      // Se stiamo scegliendo il sesso, proseguiamo oltre
      this.setState({
        inSetup: true,
        inGenderSelection: false,
      })
    } else {
      if (
        EQBusinessRules.isEmergenza(
          this.state.UserSelection.idTipologiaServizio
        )
      ) {
        // Se in emergenza mettiamo in asta più caregiver, quindi forse vogliamo suggerire il sesso
        this.setState({
          inGenderSelection: true,
          inSetup: false,
        })
      } else {
        // Altrimenti alla fine proponiamo i risultati in chiaro, l'utente sceglierà chi vuole
        this.setState({
          inGenderSelection: false,
          inSetup: true,
        })
      }
    }
  }

  getBgCode() {
    if (this.state.UserSelection.idTipologiaServizio == 100) {
      return 'EMERGENZA'
    } else if (this.state.UserSelection.idTipologiaServizio == 2) {
      return 'FAMILIARE'
    } else {
      return 'DOMICILIARE'
    }
  }

  render() {
    if (
      !EQState.UserSelection ||
      !EQState.UserSelection.GruppoServizio ||
      !EQState.UserSelection.SelectedRarentalRelation
    ) {
      return null
    }

    if (this.state.inGenderSelection) {
      return (
        <EQBookStep_GenderSelection
          onGoForward={() => {
            this.goForward()
          }}
          onGoBack={() => {
            this.goBack()
          }}
        />
      )
    }

    if (this.state.inSetup) {
      return <EQBookStep_Do_Setup />
    }

    let canGoForward = false
    if (EQState.UserSelection.Location) {
      if (
        EQState.UserSelection.Location.address &&
        EQState.UserSelection.Location.CAP &&
        EQState.UserSelection.Location.city &&
        EQState.UserSelection.Location.city !== '0'
      ) {
        canGoForward = true
      }
    }

    let pname =
      EQState.UserSelection.SelectedRarentalRelation.nome +
      ' ' +
      EQState.UserSelection.SelectedRarentalRelation.cognome
    let cssBox = 'boxCeleste'
    if (
      EQBusinessRules.isEmergenza(EQState.UserSelection.idTipologiaServizio)
    ) {
      cssBox = 'boxCeleste EMERGENZA'
    }
    if (EQState.UserSelection.idTipologiaServizio === 2) {
      cssBox = 'boxCeleste FAMILIARE'
    }

    return (
      <Box
        mr={0}
        ml={0}>
        <Box
          pl={0}
          pr={0}>
          {!EQBusinessRules.isEmergenza(
            EQState.UserSelection.idTipologiaServizio
          ) && (
            <p className="playFair normalWeight textCenter bookstep-start-label">
              Prenotazione per
            </p>
          )}
          {EQBusinessRules.isEmergenza(
            EQState.UserSelection.idTipologiaServizio
          ) && (
            <p className="playFair normalWeight textCenter bookstep-start-label">
              Prenotazione urgente per
            </p>
          )}
          <div className="row">
            <div className="col1-icon">
              <PersonOutlineIcon style={{ fontSize: '1.8rem' }} />
            </div>
            <div className="col2-icon">
              <h3 className="patient-name">{pname}</h3>
            </div>
          </div>

          <div className="row">
            <div className="col1-icon"></div>
            <div className="col2-icon"></div>
          </div>

          <Box className={cssBox}>
            {/* 
                        // forzatura a casa per ora - bugid#8188 e bugid#8488
                        // vedi anche forzatura all'inizio di EQState.UserSelection.Location.home=true 
                        */}

            {/*
                        <div className="row" style={{minHeight: '50px', height: '50px'}}>
                            <div className="col1-icon" style={{paddingTop: '12px'}}>
                                <HomeTwoTone style={{fontSize: '1.8rem'}}/>
                            </div>
                            <div>
                                <p className='playFair app-text' style={{marginBottom: 0}}>Dove si svolgerà
                                    l'assistenza?</p>
                                <div className="col2-icon"
                                    style={{fontWeight: 500, marginLeft: '-8px', minHeight: '50px', height: '50px'}}>
                                    {!this.state.changing &&
                                        <Fragment>
                                            <EQCheckbox typecode={this.getBgCode()} id={"aCasa"}
                                                        onChange={(e) => this.handleLocationTypeChange(e, 'home')}
                                                        defaultChecked={EQState.UserSelection.Location.home}/>
                                            <label htmlFor={"aCasa"}>Assistenza a casa</label>
                                        </Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                        */}

            {/*                                 
                        <div className="row" style={{minHeight:'50px',height:'50px'}}>
                            <div className="col1-icon" style={{paddingTop:'12px'}}>
                                <RoomIcon />
                            </div>
                            <div className="col2-icon" style={{fontWeight:500,marginLeft:'-8px',minHeight:'50px',height:'50px'}}>
                                {!this.state.changing && 
                                    <Fragment>
                                        <EQCheckbox id={"inStruttura"} onChange={(e) => this.handleLocationTypeChange(e,'rsa')} defaultChecked={EQState.UserSelection.Location.rsa} />
                                        <label htmlFor={"inStruttura"}>In una struttura</label>
                                    </Fragment>
                                }
                            </div>                        
                        </div>
                        */}

            <div className="row">
              <div className="col1-icon">
                <HomeTwoTone style={{ fontSize: '1.8rem' }} />
              </div>
              <div className="col2-icon">
                <p
                  className="playFair app-text"
                  style={{ marginBottom: 0 }}>
                  Dove si svolgerà l'assistenza?
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col1-icon"></div>
              <div
                className="col2-icon"
                style={{ width: 'calc(100% - 70px)' }}>
                <div>
                  <FormControl fullWidth>
                    {this.state.CAP && (
                      <Autocomplete
                        defaultValue={EQState.UserSelection.Location.CAP}
                        options={this.state.CAP}
                        groupBy={(option) => option.comune}
                        getOptionLabel={(o) => o.cap + ' - ' + o.comune}
                        renderInput={(params) => (
                          <EQTextField
                            {...params}
                            label="CAP"
                          />
                        )}
                        onChange={(e, v) => this.handleCAPChange(e, v)}
                        renderOption={(props, option) => (
                          <div {...props}>{option.cap}</div>
                        )}
                        renderGroup={(params) => (
                          <span
                            key={params.key}
                            className="cap_opt_container">
                            <span className="cap_group_title">
                              {params.group}
                            </span>
                            <span className="cap_children">
                              {params.children}
                            </span>
                          </span>
                        )}
                      />
                    )}
                  </FormControl>
                </div>
              </div>
            </div>

            <div
              className="row"
              style={{ marginTop: '8px' }}>
              <div className="col1-icon"></div>
              <div
                className="col2-icon"
                style={{ width: 'calc(100% - 70px)' }}>
                <div>
                  <FormControl fullWidth>
                    <EQTextField
                      label="Via e numero civivo"
                      onChange={(e) =>
                        this.handleLocationInfoChange(e, 'address')
                      }
                      defaultValue={EQState.UserSelection.Location.address}
                    />
                  </FormControl>
                </div>
              </div>
            </div>

            <div
              className="row"
              style={{ marginTop: '24px' }}>
              <div className="col1-icon"></div>
              <div className="col2-icon">
                <p
                  className="playFair app-text"
                  style={{ marginBottom: 0 }}>
                  Altre informazioni utili
                </p>
              </div>
            </div>
            <div className="row">
              <div
                className="col1-icon"
                style={{ paddingTop: '12px' }}>
                <PetsTwoToneIcon style={{ fontSize: '1.8rem' }} />
              </div>
              <div
                className="col2-icon"
                style={{ fontWeight: 500, marginLeft: '-8px' }}>
                <EQCheckbox
                  typecode={this.getBgCode()}
                  id={'haAnimali'}
                  onChange={(e) => this.handleInfoToggle(e, 'haAnimali')}
                  defaultChecked={
                    EQState.UserSelection.SelectedRarentalRelation.haAnimali
                  }
                />
                <label htmlFor={'haAnimali'}>Sono presenti animali</label>
              </div>
            </div>
            <div className="row">
              <div
                className="col1-icon"
                style={{ paddingTop: '12px' }}>
                <SmokingRoomsTwoToneIcon style={{ fontSize: '1.8rem' }} />
              </div>
              <div
                className="col2-icon"
                style={{ fontWeight: 500, marginLeft: '-8px' }}>
                <EQCheckbox
                  typecode={this.getBgCode()}
                  id={'fumatore'}
                  onChange={(e) => this.handleInfoToggle(e, 'fumatore')}
                  defaultChecked={
                    EQState.UserSelection.SelectedRarentalRelation.fumatore
                  }
                />
                <label htmlFor={'fumatore'}>L'assistito è fumatore</label>
              </div>
            </div>

            <div
              className="row"
              style={{ marginTop: '20px' }}>
              <div className="col1-icon"></div>
              <div
                className="col2-icon"
                style={{ width: 'calc(100% - 64px)' }}>
                <EQTextField
                  multiline
                  label="Ci sono altre cose che vorresti farci sapere?"
                  minRows={3}
                  sx={{ width: '100%' }}
                  placeholder=""
                  defaultValue={EQState.UserSelection.Location.notes}
                  onBlur={(e) => this.handleLocationInfoChange(e, 'notes')}
                />
              </div>
            </div>
          </Box>
        </Box>
        <Grid
          className="book-navigation shrink"
          container
          mt={0}
          mb={0}>
          <Grid
            item
            xs={6}>
            <Button
              className="lnkButton lnkBack"
              startIcon={<ArrowBackIcon />}
              onClick={() => this.goBack()}>
              Indietro
            </Button>
          </Grid>
          <Grid
            item
            sx={{ textAlign: 'right' }}
            xs={6}>
            {canGoForward && (
              <a
                className={`buttonLink lnkForward  button-${this.getBgCode()}`}
                onClick={() => this.goForward()}>
                AVANTI
              </a>
            )}
            {!canGoForward && (
              <a
                className={`buttonLink lnkForward disabled  button-${this.getBgCode()}`}>
                AVANTI
              </a>
            )}
          </Grid>
        </Grid>

        <Box>
          <EQFaqBox category="LuogoAssistenza"></EQFaqBox>
        </Box>
      </Box>
    )
  }
}

export default withRouter(EQBookStep_Location)
