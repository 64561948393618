import { Box, Grid } from '@mui/material'

import React from 'react'

export default class EQFooter extends React.Component {
  openDrawer() {
    const el = document.getElementById('openMenu')
    el.click()
  }

  candidati() {
    try {
      window.gtag('event', 'clickCandidati', {})
    } catch (error) {}
    window.open('https://forms.gle/jk8MP4yJD2HLr1Xv6', '_blank')
  }

  render() {
    return (
      <div
        id="footer"
        data-html2canvas-ignore="true">
        <div className="footer-header">
          <img
            src="/img/logoapp.svg"
            style={{ height: '70px', width: '70px' }}
            alt=""
          />
          <div className="text-semibold">
            Attualmente i servizi EqueCure sono disponibili nell'area della
            Provincia di Milano.
          </div>
        </div>
        <Grid
          className="links"
          container
          pt={3}>
          <Grid
            item
            xs={6}>
            <Box
              pt={0}
              className="title">
              Area utenti
            </Box>
            <Box pt={0}>
              <a href="/">Pagina principale</a>
            </Box>
            <Box>
              <a href="/i-nostri-servizi">I nostri servizi</a>
            </Box>
            <Box>
              <a href="/siamo-diversi">Perch&eacute; siamo diversi</a>
            </Box>
            <Box>
              <a href="/servizio-clienti">Servizio Clienti</a>
            </Box>
            <Box>
              <a href="/faq">Domande e risposte</a>
            </Box>
            <Box>
              <a
                style={{ cursor: 'pointer' }}
                onClick={() => this.openDrawer()}>
                Accedi o Registrati
              </a>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}>
            <Box
              pt={0}
              className="title">
              Area Specialisti
            </Box>
            {/* bugid#8964 <Box><a style={{cursor: 'pointer'}} onClick={() => this.openDrawer()}>Accedi</a></Box> */}
            <Box>
              <a href="/caregiver/login">Accedi</a>
            </Box>
            {/* era https://docs.google.com/forms/d/e/1FAIpQLScYpfcH_7cAGIr8LlmXzK0cANn-e8TH3sWQf0AQD2WhLKcihg/viewform */}
            <Box>
              <a
                href="#"
                onClick={() => this.candidati()}>
                Candidati
              </a>
            </Box>
            <Box
              pt={2}
              className="title">
              Area Partner{' '}
            </Box>
            <Box>
              <a href="mailto:info@equecure.com?subject=richiesta informazioni partner">
                Contattaci
              </a>
            </Box>
          </Grid>
          <Grid
            item
            pt={2}
            xs={12}>
            <Box
              pt={0}
              className="title">
              Aspetti legali
            </Box>
            <Box>
              <a href="/condizioni">
                Condizioni d'uso EqueCure e Prestatori di Servizi
              </a>
            </Box>
            <Box>
              <a href="/cookie-policy">Cookie Policy</a>
            </Box>
            <Box>
              <a href="/privacy">Privacy Policy</a>
            </Box>
          </Grid>
        </Grid>

        <Box
          pt={3}
          className="info">
          <p className="text">EqueCure srl</p>
          <p className="text">via Cosimo Del Fante,21 - 20122 Milano</p>
          <p className="text">P.IVA 11662390969</p>
          <p> &#169; 2021-2022 - Tutti i diritti riservati</p>
        </Box>
      </div>
    )
  }
}
