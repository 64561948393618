import { Box, Typography } from '@mui/material'
import React, { Fragment } from 'react'

import EQService from '../../services/EQService'
import EQState from '../../services/EQState'
import TextField from '@mui/material/TextField'
import Utils from '../../libs/Utils'
import validator from 'validator'

export default class EQResetPassword extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      error: false,
      errorMessage: {},
      showFields: true,
      showLinguetta: true,
    }
  }

  reloadState() {
    this.setState({
      UserSelection: EQState.UserSelection,
    })
  }

  componentDidMount() {
    EQState.addListener(EQState.UserSelection, this)
    console.log(this.props.showLinguetta)
    if ('showLinguetta' in this.props) {
      this.setState({
        showLinguetta: this.props.showLinguetta,
      })
    }
  }

  componentWillUnmount() {
    EQState.removeListener(EQState.UserSelection, this)
  }

  closeModal() {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  doReset() {
    if (this.state.email === '') {
      Utils.notifyError('Specificare una email')
      return
    }
    if (!validator.isEmail(this.state.email)) {
      Utils.notifyError('Specificare una email valida')
      return
    }

    EQService.isEmailInUse(this.state.email).then((r) => {
      if (!r) {
        Utils.notifyError('Email non presente nei nostri sistemi')
        return
      }
    })
    EQService.resetPassword(this.state.email).then((r) => {
      Utils.notifyConfirm(
        'Ti è stata inviata una email per resettare la password'
      )
      this.setState({
        showFields: false,
      })
    })
  }

  closeResetPassword() {
    if (this.props.onBack) {
      this.props.onBack()
    }
  }

  updateInputValue(evt) {
    const val = evt.target.value
    const field = evt.target.id
    this.setState({
      [field]: val,
    })
  }

  render() {
    let content
    let linguetta

    if (this.state.showLinguetta) {
      linguetta = (
        <div className="linguetta">
          <span>
            Fino
            <br />
            al 2023
            <br />
            solo a Milano
            <br />e Sesto S. Giovanni
          </span>
        </div>
      )
    }

    if (this.state.showFields) {
      content = (
        <Fragment>
          <Typography
            px={3}
            mb={2}
            align="center"
            className="playFair"
            variant="h5"
            sx={{ fontSize: '15pt', color: '#fff' }}>
            Inserisci la email con cui ti sei registrato e ti invieremo un link
            con cui resettare la password
          </Typography>

          <Box className="loginForm">
            <Box
              mt={3}
              className="login-box-menu-dx">
              <TextField
                onChange={(evt) => this.updateInputValue(evt)}
                fullWidth
                id="email"
                label="Email"
                placeholder="email"
                InputLabelProps={{ shrink: true }}></TextField>
            </Box>
            <Box
              pt={2}
              pb={6}
              sx={{ textAlign: 'center' }}>
              <Box>
                <button
                  className="buttonWhite"
                  onClick={() => this.doReset()}>
                  INVIA
                </button>
              </Box>
              <a
                href="#"
                onClick={() => this.closeResetPassword()}
                style={{ position: 'relative', top: '20px' }}
                className="text-white">
                Torna al login
              </a>
            </Box>
          </Box>
        </Fragment>
      )
    } else {
      content = (
        <Fragment>
          <Typography
            px={3}
            mb={2}
            align="center"
            className="playFair"
            variant="h5"
            sx={{ fontSize: '15pt', color: '#fff' }}>
            Ti abbiamo inviato una email con il link per resettare la password,
            controlla la tua casella email.
          </Typography>
        </Fragment>
      )
    }
    return (
      <Box className="resetPasswordBox">
        {linguetta}
        <Box
          px={2}
          py={8}>
          {content}
        </Box>
      </Box>
    )
  }
}
