import { Box, Grid, Modal } from '@mui/material'

import Card from '@mui/material/Card'
import DeleteIcon from '@mui/icons-material/HighlightOffTwoTone'
import EQService from '../../services/EQService'
import EQState from '../../services/EQState'
import EventIcon from '@mui/icons-material/CalendarMonthTwoTone'
import Formats from '../../libs/Formats'
import React from 'react'
import WatchLaterTwoToneIcon from '@mui/icons-material/WatchLaterTwoTone'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  width: '80%',
  bgcolor: '#ffe371',
  border: '0',
  boxShadow: 24,
  p: 2,
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
}

export default class EQCaregiverInCart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: true,
    }
  }

  reloadState() {
    this.setState({
      UserSelection: EQState.UserSelection,
    })
  }

  componentDidMount() {
    EQState.addListener(EQState.UserSelection, this)
  }

  componentWillUnmount() {
    EQState.removeListener(EQState.UserSelection, this)
  }

  setPreferred(flag) {
    if (flag) {
      EQService.setProfessionistaPreferito(this.props.caregiver).then((pps) => {
        EQState.change(EQState.UserSelection, {
          PreferredCaregivers: pps,
          PreferredCaregivers_Map: pps.map((x) => x.idProfessionista),
        })
      })
    } else {
      EQService.unsetProfessionistaPreferito(this.props.caregiver).then(
        (pps) => {
          EQState.change(EQState.UserSelection, {
            PreferredCaregivers: pps,
            PreferredCaregivers_Map: pps.map((x) => x.idProfessionista),
          })
        }
      )
    }
  }

  unconfirmServizio(sp) {
    this.setState({
      servizioToRemove: sp,
    })
  }

  cancelRemove() {
    this.setState({
      servizioToRemove: null,
    })
  }

  actuallyDoRemove() {
    let sp = this.state.servizioToRemove
    sp._confirmed = false
    sp._confirmedFor = null
    EQState.change(EQState.UserSelection, {
      Solutions_ConfermedCnt: Math.max(
        0,
        EQState.UserSelection.Solutions_ConfermedCnt - 1
      ),
    })
  }

  render() {
    const { caregiver, sx, className, ...others } = this.props
    const cn = className
      ? 'caregiver-card in-cart ' + className
      : 'caregiver-card in-cart'

    // Verifichiamo se il caregiver ha servizi confermati
    let confermati = []
    caregiver._serviziPrenotazione.forEach((sp) => {
      if (sp._confirmed && sp._confirmedFor === caregiver.idProfessionista) {
        confermati.push(sp)
      }
    })
    if (confermati.length === 0) return null

    return (
      <Card
        {...others}
        sx={{ display: 'flex' }}
        className={cn}>
        <div className="card-body">
          {this.state.expanded && (
            <div className="solution-list-container">
              <div className="solution-list">
                {confermati.map((sp, i) => {
                  if (
                    !sp._confirmed ||
                    sp._confirmedFor !== caregiver.idProfessionista
                  ) {
                    return null
                  }

                  let dateFrom = Formats.fromServerDate(sp.richiestoDal)
                  let dateTo = Formats.fromServerDate(sp.richiestoAl)
                  let day = Formats.dateToDayMonth(dateFrom)
                  let eday = Formats.dateToDayMonth(dateTo)
                  let s_day = Formats.dateToDayMonthShort(dateFrom)
                  let s_eday = Formats.dateToDayMonthShort(dateTo)
                  let shours = Formats.dateToHourMinutes(dateFrom)
                  let ehours = Formats.dateToHourMinutes(dateTo)

                  let prezzo = 0
                  sp.soluzioneTrovatas.forEach((st) => {
                    if (st.idProfessionista !== caregiver.idProfessionista)
                      return
                    prezzo += parseFloat(st.prezzo)
                  })

                  let css = 'solution solution-in-cart'
                  return (
                    <div
                      className={css}
                      key={i}>
                      <div className="explanation row">
                        <div
                          className="col1"
                          style={{ paddingTop: '12px' }}>
                          <EventIcon />
                          <WatchLaterTwoToneIcon
                            style={{ marginTop: '12px' }}
                          />
                        </div>
                        <div className="col2">
                          <div className="col2l">
                            {day === eday && (
                              <div className="main-info">
                                <div style={{ fontWeight: 'bold' }}>
                                  {caregiver.nome}
                                </div>
                                <div style={{ fontWeight: 'bold' }}>{day}</div>
                                <div>
                                  dalle ore{' '}
                                  <span style={{ fontWeight: 'bold' }}>
                                    {shours}
                                  </span>
                                </div>
                                <div>
                                  alle ore{' '}
                                  <span style={{ fontWeight: 'bold' }}>
                                    {ehours}
                                  </span>
                                </div>
                              </div>
                            )}
                            {day !== eday && (
                              <div className="main-info">
                                <div style={{ fontWeight: 'bold' }}>
                                  {caregiver.nome}
                                </div>
                                <div>
                                  dalle{' '}
                                  <span style={{ fontWeight: 'bold' }}>
                                    {shours}
                                  </span>
                                  &nbsp; del{' '}
                                  <span style={{ fontWeight: 'bold' }}>
                                    {s_day}
                                  </span>
                                </div>
                                <div>
                                  alle{' '}
                                  <span style={{ fontWeight: 'bold' }}>
                                    {ehours}
                                  </span>
                                  &nbsp; del{' '}
                                  <span style={{ fontWeight: 'bold' }}>
                                    {s_eday}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="col2r">
                            <div
                              className="textRight"
                              style={{ paddingTop: '12px' }}>
                              <DeleteIcon
                                className="red-icon"
                                onClick={() => this.unconfirmServizio(sp)}
                              />
                            </div>
                            <div className="price">
                              {Formats.formatPrice(prezzo)} &euro;
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>

        {this.state.servizioToRemove && (
          <Modal
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={modalStyle}>
              <Box
                mt={0}
                p={0}
                pt={2}
                pb={2}>
                <div className="textCenter playFair app-text modal-title">
                  <b>Vuoi cancellare la data?</b>
                </div>

                <Grid
                  container
                  mt={0}
                  mb={2}>
                  <Grid
                    item
                    sx={{ textAlign: 'left' }}
                    xs={6}>
                    <a
                      className="buttonLink bg-red text-white lnkForward"
                      onClick={() => this.cancelRemove()}>
                      NO
                    </a>
                  </Grid>
                  <Grid
                    item
                    sx={{ textAlign: 'right' }}
                    xs={6}>
                    <a
                      className="buttonLink bg-green text-white lnkForward"
                      onClick={() => this.actuallyDoRemove()}>
                      SI
                    </a>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Modal>
        )}
      </Card>
    )
  }
}
