import { Box, Modal, Typography } from '@mui/material'

import EQService from '../../services/EQService'
import EQServiceBook from '../../services/EQServiceBook'
import EQState from '../../services/EQState'
import React from 'react'
import Utils from '../../libs/Utils'

const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -40%)',
  maxWidth: 400,
  width: '86%',
  bgcolor: '#ffe371',
  border: '0',
  boxShadow: 24,
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  outline: 'none',
}

export default class EQBook_SendPayLink extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      content: null,
    }

    let booking = EQState.retrieve(EQState.Booking)
    console.log('retrieving BookingResult', booking.BookingResult)
    this.state.booking = booking
  }

  doLogout() {
    EQService.creaSessione().then((x) => {
      EQState.change(
        EQState.UserSelection,
        { IsLogged: false, isJollyWorking: false },
        null,
        true
      )
      EQState.persist(EQState.UserSelection)
      document.location = '/'
    })
  }

  sendLink() {
    EQServiceBook.sendPayLink().then(() => {
      Utils.notifyConfirm('Link inviato con successo')
    })
  }

  forcePaymentDone() {
    EQServiceBook.forcePaymentDone(
      this.state.booking.BookingResult.idPrenotazione
    ).then((res) => {
      if (res.messaggioErrore) {
        console.error(res)
        Utils.notifyError('Si è verificato un errore')
      } else {
        Utils.notifyConfirm('Prenotazione conclusa con successo')
        setTimeout(() => {
          this.doLogout()
        }, 2000)
      }
    })
  }

  render() {
    return (
      <Modal
        open={true}
        className="yellow-modal"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box
          sx={style}
          pt={2}
          pb={8}
          px={2}
          className="helpModal">
          <Box
            mt={2}
            sx={{ textAlign: 'center' }}>
            <img
              style={{ maxWidth: '100px' }}
              src="/img/pay_logos.png"
              alt="pay logos"
            />
            <Typography
              className="playFair"
              id="pay-info-modal-modal-title"
              variant="h5"
              component="h2">
              La prenotazione è pronta per il pagamento.
            </Typography>
            <p>
              Istruisci correttamente il cliente, quindi premi il pulsante per
              inviargli il link di pagamento. Alla fine della operazione, premi
              il pulsante "LOGOUT"
            </p>
          </Box>

          <Box
            mt={0}
            sx={{ textAlign: 'center', position: 'relative' }}>
            <a
              className="buttonLink lnkForward bg-green text-white with-shadow"
              onClick={() => this.sendLink()}>
              INVIA LINK AL CLIENTE
            </a>
          </Box>
          <Box
            mt={2}
            sx={{ textAlign: 'center', position: 'relative', top: '10px' }}>
            <a
              className="buttonLink lnkForward bg-red text-white with-shadow"
              onClick={() => this.doLogout()}>
              LOGOUT
            </a>
          </Box>

          <Box
            mt={8}
            sx={{ textAlign: 'center' }}>
            <Typography
              className="playFair"
              id="pay-info-modal-modal-title"
              variant="h5"
              component="h2">
              Pagamento con bonifico anticipato.
            </Typography>
            <p>
              Se invece il cliente ha pagato con bonifico anticipato, premi il
              pulsante sotto. Il pagamento verr&agrave; considerato effettuato e
              la sessione verr&agrave; conclusa.
            </p>
          </Box>

          <Box
            mt={0}
            sx={{ textAlign: 'center', position: 'relative' }}>
            <a
              className="buttonLink lnkForward bg-green text-white with-shadow"
              onClick={() => this.forcePaymentDone()}>
              BONIFICO ANTICIPATO
            </a>
          </Box>
        </Box>
      </Modal>
    )
  }
}
