import AlternateEmailIcon from '@mui/icons-material/AlternateEmailTwoTone'
import { Button } from '@mui/material'
import EQFaqBox from '../../components/EQFaqBox'
import EQService from '../../services/EQService'
//import EQState from '../../services/EQState'
import ModeCommentIcon from '@mui/icons-material/ModeCommentTwoTone'
import PageEditorial from '../PageEditorial'
import PersonIcon from '@mui/icons-material/PersonOutlineTwoTone'

export default class PageContatti extends PageEditorial {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      mail: '',
      messaggio: '',
      privacyOk: false,
      marketingOk: false,
    }
  }

  getPageCode() {
    // TODO: cancellare SERVIZIO_CLIENTI__ da admin
    return 'SERVIZIO_CLIENTI'
  }

  call() {
    EQService.phoneCallBy('client')
  }

  handleChange(event) {
    const {
      target: { name, value },
    } = event
    this.setState({
      [name]: value,
    })
  }

  handleSubmit(event) {
    event.preventDefault()
    const { name: nome, mail: email, messaggio: body } = this.state
    try {
      fetch('https://app.equecure.com/api/Cms/SendEMailToCustCare', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nome,
          email,
          body,
        }),
      })
    } catch (error) {
      console.error(error)
    }
  }

  checkPrivacy(e) {
    this.setState({
      privacyOk: e.target.checked,
    })
  }

  checkMarketing(e) {
    this.setState({
      marketingOk: e.target.checked,
    })
  }

  checkIfSend() {
    const { name, mail, messaggio, privacyOk, marketingOk } = this.state

    return !(
      name &&
      mail.includes('.', mail.indexOf('@') !== -1 ? mail.indexOf('@') : 1000) &&
      messaggio &&
      privacyOk &&
      marketingOk
    )
  }

  getChildrenParts() {
    //const pageCode = this.getPageCode()
    //const C = EQState.Contents[pageCode]
    console.table(this.state)
    return (
      <div>
        <form
          className="servizio-cliente-form"
          onSubmit={(event) => this.handleSubmit(event)}>
          <div className="section">
            <PersonIcon style={{ paddingTop: '10px' }} />
            <div className="subsection">
              <label htmlFor="name">Nome</label>
              <input
                onChange={(event) => this.handleChange(event)}
                type="text"
                name="name"
                placeholder="Scrivi il tuo nome"
              />
            </div>
          </div>
          <div className="section">
            <AlternateEmailIcon style={{ paddingTop: '10px' }} />
            <div className="subsection">
              <label htmlFor="mail">Email</label>
              <input
                onChange={(event) => this.handleChange(event)}
                type="email"
                name="mail"
                placeholder="Scrivi la tua mail"
              />
            </div>
          </div>
          <div className="section">
            <ModeCommentIcon style={{ paddingTop: '10px' }} />
            <div className="subsection ">
              <label htmlFor="messaggio">Messaggio</label>
              <textarea
                onChange={(event) => this.handleChange(event)}
                rows={5}
                name="messaggio"
                className="message"
                placeholder="Scrivi il tuo messaggio"
              />
            </div>
          </div>
          <label className="servizio-clienti-checkbox">
            <input
              name="privacy"
              type="checkbox"
              onChange={(e) => this.checkPrivacy(e)}
            />{' '}
            Accetto{' '}
            <a
              href="/privacy"
              target="_blank">
              {' '}
              privacy policy
            </a>
          </label>
          <label className="servizio-clienti-checkbox">
            <input
              name="marketing"
              type="checkbox"
              onChange={(e) => this.checkMarketing(e)}
            />{' '}
            Accetto{' '}
            <a
              href="/marketing"
              target="_blank">
              {' '}
              marketing e comunicazione
            </a>
          </label>

          <Button
            type="submit"
            disabled={this.checkIfSend()}
            sx={{
              width: '100%',
              marginTop: '10px',
              backgroundColor: '#00d600',
              borderRadius: '15px',
              ':hover': { backgroundColor: '#00d600' },
            }}
            variant="contained">
            Invia
          </Button>
        </form>
        <EQFaqBox category={'ServizioClienti'} />
        <div className="servizio-clienti-container">
          <p>Chiamaci</p>
          <p>Dal lunedì al venerdì </p>{' '}
          <p> dalle 9 alle 13 e dalle 14 alle 18</p>
          <div>
            <img
              onClick={this.call}
              src="/img/telefono.svg"
              alt="phone clickable"
              className="servizio-clienti-call svg-style"
            />
          </div>
          <p>
            o scrivici a <a href="mailto:clienti@equecure.com"> questa mail</a>
          </p>
        </div>
      </div>
    )
  }
}
