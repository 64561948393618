import { Box, Container, Link, ThemeProvider } from '@mui/material'

import EQCareGiversSlider from '../components/EQCareGiversSlider'
import EQEditorialBox from '../components/core/EQEditorialBox'
import EQFooter from '../components/EQFooter'
import EQHeader from '../components/EQHeader'
import EQHomeModal from '../components/EQHomeModal'
import EQInfoBox from '../components/EQInfoBox'
import EQLoading from '../components/EQLoading'
import EQLoggedUserSummary from '../components/user/EQLoggedUserSummary'
import EQNeedles from '../components/EQNeedles'
import EQNewsletter from '../components/EQNewsletter'
import EQService from '../services/EQService'
import EQState from '../services/EQState'
import EQTheme from '../components/EQTheme'
import HomeLoader from '../components/Loader/HomeLoader'
import Pages from '../services/Pages'
import React from 'react'
import Utils from '../libs/Utils'

export default class PageHome extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      imgLoaded: false,
      modal: true,
    }
    EQState.retrieve(EQState.SessionInfo, window.sessionStorage)
  }

  closeModal() {
    this.setState({ modal: null })
  }

  loadingImg() {
    this.setState({ imgLoaded: true })
  }

  componentDidMount() {
    // Resettiamo la sessione
    EQService.removeCachedContents()

    if (EQState.UserSelection && EQState.Booking.BookingResult) {
      // siamo tornati alla home dopo aver fatto una prenotazione
      console.warn('Reset sessione utente e sessione prenotazione')
      EQState.reset(EQState.UserSelection)
      EQState.persist(EQState.UserSelection)
      EQState.reset(EQState.Booking)
      EQState.persist(EQState.Booking)
    }

    // Recuperiamo un eventuale id tipologia servizio passato su url
    const addressParts = Utils.sliceAddress()
    //console.log("addressParts",addressParts);
    if (addressParts.length === 3) {
      const addressPartParts = Utils.sliceAddressPart(addressParts[2])
      //console.log("addressPartParts",addressPartParts);
      EQState.change(
        EQState.UserSelection,
        {
          idTipologiaServizio: addressPartParts[0],
          showGroupServices: true,
        },
        null,
        true
      )
    }

    EQService.mapContentsOnCurrentPage().then((m) => {
      EQState.change(EQState.Contents, {
        [Pages.current().cms]: m,
      })
      this.forceUpdate()
    })
  }

  render() {
    console.log('SessionInfo', EQState.SessionInfo)

    if (!EQState.Contents.HOME)
      return (
        <div
          className="home"
          style={{ marginTop: '200px' }}>
          <EQHeader></EQHeader>
          <div
            className="logo"
            style={{ marginBottom: '80px' }}>
            <img
              src="/img/logo_big.svg"
              alt="Logo EqueCure"
            />
          </div>
          <EQLoading message="Ci stiamo preparando per te..." />
        </div>
      )

    const MC = EQState.Contents.HOME

    return (
      <div className="home">
        <EQHeader></EQHeader>
        <Box mt={7}>
          <div className="header">
            <img
              onLoad={() => this.loadingImg()}
              style={{ display: `${this.state.imgLoaded ? 'unset' : 'none'}` }}
              src={MC.VISUAL.urlImmagine}
              alt={MC.VISUAL.SottoTitolo}
              title={MC.VISUAL.SottoTitolo}
            />
            {!this.state.imgLoaded && <HomeLoader />}
            <div className="linguetta">
              <span>
                Siamo
                <br />
                a Milano
                <br />e provincia
              </span>
            </div>
          </div>
          <div className="logo">
            <img
              style={{ marginLeft: '2.1 em' }}
              src="/img/logo_big.svg"
              alt="Logo EqueCure"
            />
          </div>
        </Box>

        <div className="main-content">
          <ThemeProvider
            children={EQTheme}
            theme={EQTheme}>
            <Container disableGutters>
              <EQLoggedUserSummary />

              <div className="container">
                <EQNeedles></EQNeedles>

                <Box className="textCenter">
                  <Link
                    href="/faq"
                    className="playFair linkblack">
                    {MC.FAQLINK.SottoTitolo}
                  </Link>
                </Box>

                <Box
                  mt={4}
                  className="separatorContainer">
                  <img
                    src="/img/separator.png"
                    alt="Separatore"
                  />
                </Box>

                <Box
                  mt={4}
                  px={3}
                  className="textCenter">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: MC.TREPASSIEQUECURE.Contenuto,
                    }}></div>

                  <EQInfoBox
                    image="true"
                    className="infoBox1"
                    code="TREPASSI1"></EQInfoBox>
                  <EQInfoBox
                    image="true"
                    className="infoBox1"
                    code="TREPASSI2"></EQInfoBox>
                  <EQInfoBox
                    image="true"
                    className="infoBox1"
                    code="TREPASSI3"></EQInfoBox>
                  <Box my={4}>
                    <a
                      className="buttonBlue modal-button"
                      href={MC.TREPASSIEQUECURE.Link}>
                      {MC.TREPASSIEQUECURE.EtichettaLink}
                    </a>
                  </Box>
                </Box>

                <Box
                  mt={4}
                  className="separatorContainer">
                  <img
                    src="/img/separator.png"
                    alt="Separatore"
                  />
                </Box>

                <Box
                  className="topBox"
                  alignItems="center">
                  <Box className="textCenter">
                    <h4
                      className="playFair"
                      dangerouslySetInnerHTML={{
                        __html: MC.SIAMODIVERSI.Contenuto,
                      }}></h4>

                    <div>
                      <img
                        src={MC.SIAMODIVERSI.urlImmagine}
                        alt="Cuore"
                      />
                    </div>
                  </Box>

                  <EQInfoBox code="SIAMODIVERSI1"></EQInfoBox>
                  <EQInfoBox code="SIAMODIVERSI2"></EQInfoBox>
                  <EQInfoBox code="SIAMODIVERSI3"></EQInfoBox>

                  <Box
                    mt={4}
                    mb={2}>
                    <a
                      className="buttonBlue modal-button"
                      href="/siamo-diversi">
                      {MC.SIAMODIVERSI.EtichettaLink}
                    </a>
                  </Box>
                </Box>
                <Box
                  mt={4}
                  className="separatorContainer"
                  my={6}>
                  <img
                    src="/img/separator.png"
                    alt="Separatore"
                  />
                </Box>

                <EQCareGiversSlider></EQCareGiversSlider>
              </div>
              <Box mt={5}>
                <EQEditorialBox
                  boxNumber="1"
                  content={MC.HP_EDITORIAL1.Contenuto}></EQEditorialBox>
                <EQEditorialBox
                  boxNumber="2"
                  content={MC.HP_EDITORIAL2.Contenuto}></EQEditorialBox>
              </Box>
              <div className="container">
                <Box mb={3}>
                  <EQNewsletter></EQNewsletter>
                </Box>
              </div>
            </Container>
          </ThemeProvider>
        </div>

        <EQFooter></EQFooter>

        {this.state.modal && (
          <EQHomeModal onClose={this.closeModal.bind(this)}></EQHomeModal>
        )}
      </div>
    )
  }
}
