import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material'
import React from 'react'
import { Dialog } from 'material-ui'

export default class EQConfirmDialog extends React.Component {
  constructor(props) {
    super(props)
  }

  handleClose(ret) {
    if (this.props.onClose) {
      this.props.onClose(ret)
    }
  }

  render() {
    const { open, message, ...others } = this.props
    return (
      <Dialog
        open={open}
        {...others}>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleClose(true)}>Si</Button>
          <Button
            onClick={() => this.handleClose(false)}
            autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
