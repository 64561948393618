import { Box, Button, Grid, Link } from '@mui/material'
/* eslint-disable react/no-direct-mutation-state */
import React, { Fragment } from 'react'

import AccessTimeIcon from '@mui/icons-material/WatchLaterTwoTone'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DateAdapter from '@mui/lab/AdapterDateFns'
import DeleteIcon from '@mui/icons-material/DeleteTwoTone'
import EQBookStep_Do_Search from './EQBookStep_Do_Search'
import EQBusinessRules from '../../libs/EQBusinessRules'
import EQCheckbox from '../core/EQCheckbox'
import EQFaqBox from '../EQFaqBox'
import EQSelect from '../core/EQSelect'
import EQState from '../../services/EQState'
import EQWeekendDatePicker from '../core/EQWeekendDatePicker'
import EventIcon from '@mui/icons-material/CalendarMonthTwoTone'
import Formats from '../../libs/Formats'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { MenuItem } from 'material-ui'
import MobileDatePicker from '@mui/lab/MobileDatePicker'
import Pages from '../../services/Pages'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import TextField from '@mui/material/TextField'
import TimePicker from '@mui/lab/TimePicker'
import { addDays } from 'date-fns'
import it from 'date-fns/locale/it'
import { withRouter } from 'react-router-dom'
/* eslint-disable no-labels */
/* eslint-disable no-label-var */
/* eslint-disable no-unused-labels */
/* eslint-disable react/jsx-pascal-case */

class EQBookStep_Dates extends React.Component {
  constructor(props) {
    super(props)
    let emergencyLimit = EQBusinessRules.ultimoGiornoPerEmergenza()
    let numeroMinimoOre = 0
    if (EQState.UserSelection.GruppoServizio.servizios.length === 1) {
      numeroMinimoOre =
        EQState.UserSelection.GruppoServizio.servizios[0].numeroMinimoOre
    }
    // console.log("ss", EQState.UserSelection)

    this.state = {
      calendarChoice: EQState.UserSelection.oldCalendarChoice,
      selectedRanges: EQState.UserSelection.selectedRanges,
      recurring: EQState.UserSelection.recurring,
      weekends: EQState.UserSelection.weekends,
      searching: false,
      step: EQState.UserSelection.oldCalendarStep,
      minServiceHours: numeroMinimoOre,
      packets_aPrestazione: EQBusinessRules.packets_aPrestazione(),
    }

    if (!this.state.selectedRanges) {
      this.state.selectedRanges = this.defaultRanges()
    }
    if (!this.state.recurring) {
      this.state.recurring = this.defaultRecurring()
    }
    if (!this.state.weekends) {
      this.state.weekends = this.defaultWeekends()
    }

    if (
      EQBusinessRules.isEmergenza(EQState.UserSelection.idTipologiaServizio)
    ) {
      this.state.minDay = EQBusinessRules.primaDataPerPrenotazioneInEmergenza()
      this.state.maxDay = emergencyLimit
      console.groupCollapsed('EMERGENZA')
      console.log('minDay', this.state.minDay)
      console.log('maxDay', this.state.maxDay)
      console.groupEnd()
    } else {
      this.state.minDay = EQBusinessRules.primaDataPerPrenotazioneNormale()
      let now = new Date()
      this.state.maxDay = new Date(
        now.setMonth(
          now.getMonth() + EQBusinessRules.maxFutureMonthsForBooking()
        )
      ) // possiamo prenotare fino a N mesi da oggi
      console.groupCollapsed('NON EMERGENZA')
      console.log('minDay', this.state.minDay)
      console.log('maxDay', this.state.maxDay)
      console.groupEnd()
    }

    // Se stiamo prenotando un serizio di consulenza, la maschera cambia
    if (
      EQBusinessRules.isServiceAdvisory(EQState.UserSelection.GruppoServizio)
    ) {
      this.state.calendarChoice = 1
    }
  }

  limit_pacchettiAPrestazione() {
    let pps = EQBusinessRules.packets_aPrestazione()
    let res = []

    pps.packets.forEach((c) => {
      let cstart = new Date()
      cstart.setHours(c.fromHours)
      cstart.setMinutes(c.fromMinutes)
      let now = new Date()
      if ((cstart.getTime() - now.getTime()) / (1000 * 60 * 60) > 2) res.push(c)
    })

    return {
      packets: res,
    }
  }

  defaultRanges() {
    let pk = 0
    if (EQBusinessRules.isAPrestazione(EQState.UserSelection.GruppoServizio)) {
      // if(this.state.packets_aPrestazione.packets.length>0)
      // pk=this.state.packets_aPrestazione.packets[0].id;
    }
    if (
      EQBusinessRules.isEmergenza(EQState.UserSelection.idTipologiaServizio)
    ) {
      let start = EQBusinessRules.primaDataPerPrenotazioneInEmergenza()
      let res = {
        day: new Date(start),
        from: new Date(
          start.getFullYear(),
          start.getMonth(),
          start.getDate(),
          start.getHours(),
          0,
          0
        ),
        to: new Date(
          start.getFullYear(),
          start.getMonth(),
          start.getDate(),
          start.getHours() + this.state.minServiceHours,
          0,
          0
        ),
        packet: pk,
      }
      this.setupAvailableEndHours(res)
      return [res]
    } else {
      let limit = EQBusinessRules.primaDataPerPrenotazioneNormale()
      let start = new Date(
        limit.getFullYear(),
        limit.getMonth(),
        limit.getDate(),
        8,
        0,
        0
      )
      let res = {
        day: new Date(limit),
        from: start,
        to: new Date(
          limit.getFullYear(),
          limit.getMonth(),
          limit.getDate(),
          8 + this.state.minServiceHours,
          0,
          0
        ),
        packet: pk,
      }
      this.setupAvailableEndHours(res)
      return [res]
    }
  }

  setupAvailableEndHours(rr) {
    rr.availableEndHours = this.computeAvailableEndHours(rr.from)
    if (rr.availableEndHours.dates.length > 0) {
      rr.to = rr.availableEndHours.dates[0]
    }
    console.log('--', rr)
  }

  setupAvailableRecurringEndHours(rr) {
    rr.availableEndHours = this.computeAvailableEndHours(rr.fromHour)
    if (rr.availableEndHours.dates.length > 0) {
      rr.toHour = rr.availableEndHours.dates[0]
    }
    // console.log("--",rr);
  }

  computeAvailableEndHours(start) {
    if (!start)
      return {
        hours: [],
        messages: {},
      }
    let numeroMassimoOre = 12
    let numeroMinimoOre = 1
    if (EQState.UserSelection.GruppoServizio.servizios.length === 1) {
      numeroMassimoOre =
        EQState.UserSelection.GruppoServizio.servizios[0].numeroMassimoOre
      numeroMinimoOre =
        EQState.UserSelection.GruppoServizio.servizios[0].numeroMinimoOre
    }
    let avail = []
    let dfs = []
    let msgs = {}
    for (let h = numeroMinimoOre; h <= numeroMassimoOre; h++) {
      let hh = (start.getHours() + h) % 24
      if (hh > EQBusinessRules.inizioNotturno()) continue
      if (hh < EQBusinessRules.fineNotturno()) continue
      avail.push(hh)
      if (hh < start.getHours()) {
        msgs[hh] = 'Giorno successivo'
        dfs.push(
          new Date(
            start.getFullYear(),
            start.getMonth(),
            start.getDate() + 1,
            hh,
            0,
            0
          )
        )
      } else {
        dfs.push(
          new Date(
            start.getFullYear(),
            start.getMonth(),
            start.getDate(),
            hh,
            0,
            0
          )
        )
        msgs[hh] = ''
      }
    }
    return {
      hours: avail,
      dates: dfs,
      messages: msgs,
    }
  }

  defaultWeekends() {
    const weekendPackets = EQBusinessRules.weekendsPackets()
    return {
      dates: [],
      weekendPackets: weekendPackets,
      selectedPacket: {},
    }
  }

  defaultRecurring() {
    let firstDate = EQBusinessRules.primaDataPerPrenotazioneNormale()
    let end = new Date(firstDate.getTime())
    end.setDate(firstDate.getDate() + 7)
    let res = {
      fromDay: firstDate,
      toDay: end,
      weekdays: {},
      hours: {},
    }
    for (let w = 0; w < 7; w++) {
      res.weekdays[w] = false
      let rr = {
        fromHour: new Date(
          firstDate.getFullYear(),
          firstDate.getMonth(),
          firstDate.getDate(),
          8,
          0,
          0
        ),
        toHour: new Date(
          firstDate.getFullYear(),
          firstDate.getMonth(),
          firstDate.getDate(),
          8 + this.state.minServiceHours,
          0,
          0
        ),
        packet: 0,
      }
      // console.log("w",w);
      this.setupAvailableRecurringEndHours(rr)
      res.hours[w] = rr
    }
    return res
  }

  componentDidMount() {
    if (
      !EQState.UserSelection ||
      !EQState.UserSelection.GruppoServizio ||
      !EQState.UserSelection.SelectedRarentalRelation ||
      !EQState.UserSelection.Location
    ) {
      document.location.href = '/'
      return
    }
    const page = Pages.current()
    document.title = page.title
    window.scrollTo(0, 0)
  }

  goBack() {
    // bugid#8576 - servizi di consulenza
    if (
      EQBusinessRules.isServiceAdvisory(EQState.UserSelection.GruppoServizio)
    ) {
      this.props.history.push({
        pathname: `/book/advisory`,
      })
    } else if (
      this.state
        .calendarChoice /*&& !EQBusinessRules.isEmergenza(EQState.UserSelection.idTipologiaServizio)*/
    ) {
      this.setState({
        calendarChoice: null,
      })
    } else {
      EQState.change(EQState.UserSelection, {
        BookPage: `/book/location`,
        selectedRanges: this.state.selectedRanges,
        recurring: this.state.recurring,
        oldCalendarChoice: this.state.calendarChoice,
      })
      this.props.history.push({
        pathname: `/book/location`,
      })
    }
  }

  openCalendarChoice(which, step) {
    window.scrollTo(0, 0)
    if (!step) step = 0

    let ranges = this.state.selectedRanges
    if (EQState.UserSelection.oldCalendarChoice) {
      if (EQState.UserSelection.oldCalendarChoice !== which) {
        ranges = this.defaultRanges()
        EQState.UserSelection.oldCalendarChoice = null
      }
    }
    if (this.state.calendarChoice !== which) {
      ranges: this.defaultRanges()
    }
    this.setState({
      calendarChoice: which,
      step: step,
      selectedRanges: ranges,
    })
  }

  handleDayChange(newValue, rr) {
    rr.day = newValue
    rr.from = new Date(
      rr.day.getFullYear(),
      rr.day.getMonth(),
      rr.day.getDate(),
      rr.from.getHours(),
      rr.from.getMinutes()
    )
    rr.to = new Date(
      rr.day.getFullYear(),
      rr.day.getMonth(),
      rr.day.getDate(),
      rr.to.getHours(),
      rr.to.getMinutes()
    )
    this.forceUpdate()
  }

  handleRecurringDayChange(newValue, which) {
    this.state.recurring[which] = newValue
    this.forceUpdate()
  }

  handleTimeChange(newValue, key, rr) {
    rr[key] = newValue
    if (key === 'from') {
      this.setupAvailableEndHours(rr)
    } else if (key === 'to') {
      if (rr.availableEndHours && rr.availableEndHours.hours) {
        let idx = rr.availableEndHours.hours.indexOf(newValue.getHours())
        rr[key] = rr.availableEndHours.dates[idx]
      }
    }
    this.forceUpdate()
  }

  addRange() {
    let tomorrow = this.state.minDay
    let start = new Date(
      tomorrow.getFullYear(),
      tomorrow.getMonth(),
      tomorrow.getDate(),
      8,
      0,
      0
    )
    let res = {
      day: new Date(tomorrow),
      from: start,
      to: new Date(
        tomorrow.getFullYear(),
        tomorrow.getMonth(),
        tomorrow.getDate(),
        8 + this.state.minServiceHours,
        0,
        0
      ),
      packet: 0,
    }
    this.setupAvailableEndHours(res)
    this.state.selectedRanges.push(res)
    this.forceUpdate()
  }

  deleteRange(i) {
    this.state.selectedRanges.splice(i, 1)
    this.forceUpdate()
  }

  searchPacketById(packets, id) {
    return packets.find((x) => x.id === id)
  }

  goForward() {
    let goon = false
    if (this.state.calendarChoice === 1) {
      console.groupCollapsed('OCCASIONALE')
      console.log('At start', this.state.selectedRanges)

      // Se il servizio è a prestazione dobbiamo recuperare i pacchetti scelti e riportare a
      // standard inizio e fine periodo
      if (
        EQBusinessRules.isAPrestazione(EQState.UserSelection.GruppoServizio)
      ) {
        console.groupCollapsed('Translating packets in hour ranges')
        const packets = EQBusinessRules.packets_aPrestazione().packets
        this.state.selectedRanges.forEach((rr) => {
          let dd = new Date(rr.day.getTime())
          let pk = this.searchPacketById(packets, rr.packet)
          dd.setHours(pk.fromHours)
          dd.setMinutes(pk.fromMinutes)
          rr.from = dd

          dd = new Date(rr.day.getTime())
          dd.setHours(pk.toHours)
          dd.setMinutes(pk.toMinutes)
          rr.to = dd

          rr.packet = 0
          rr.was_packetAPrestazione = true

          console.log('Translated', rr)
        })
        console.groupEnd()
      }

      console.groupEnd()
      console.log('At end', this.state.selectedRanges)

      EQState.change(EQState.UserSelection, {
        selectedRanges: this.state.selectedRanges,
        selectedRanges_mode: 'day-based',
        recurring: null,
        weekends: null,
        oldCalendarChoice: this.state.calendarChoice,
      })
      goon = true
    } else if (this.state.calendarChoice === 2) {
      // Dobbiamo riportare la selezione dei periodi in modo canonico
      // ad un elenco di date
      console.groupCollapsed('RICORRENTE')
      console.log('At start', this.state.recurring)

      let startDate = new Date(this.state.recurring.fromDay)
      startDate.setHours(0, 0, 0, 0)
      let ranges = []
      for (
        let d = startDate;
        d <= this.state.recurring.toDay;
        d.setDate(d.getDate() + 1)
      ) {
        console.log('d', d)
        let dow = d.getDay()
        dow = dow - 1 // sfasamento giorni, getDay() produce domenica=0,lunedi=1,...
        if (dow < 0) dow = 6
        console.log('dow', dow)
        if (this.state.recurring.weekdays[dow]) {
          //console.log("considering the day");
          if (!this.state.recurring.hours[dow]) return
          //console.log("hours[dow]",this.state.recurring.hours[dow]);

          if (
            EQBusinessRules.isAPrestazione(EQState.UserSelection.GruppoServizio)
          ) {
            // Se il servizio è a prestazione dobbiamo recuperare i pacchetti scelti
            const packets = EQBusinessRules.packets_aPrestazione().packets

            let rr = this.state.recurring.hours[dow]
            console.log('rr', dow, this.state.recurring.hours, rr)
            let pk = this.searchPacketById(packets, rr.packet)
            if (!pk) {
              console.warn('Unable to find packet ' + rr.packet, rr)
              continue
            }

            //console.log("Day of week",rr,pk);
            let dd = new Date(d.getTime())
            dd.setHours(pk.fromHours)
            dd.setMinutes(pk.fromMinutes)
            let startRange = dd

            dd = new Date(d.getTime())
            dd.setHours(pk.toHours)
            dd.setMinutes(pk.toMinutes)
            let endRange = dd

            // cosa serve??? errato visto che possiamo riutilizzare le impostazioni sul giorno della settimana per settimane successive
            //rr.packet=0;

            ranges.push({
              day: new Date(d),
              from: startRange,
              to: endRange,
            })
          } else {
            // Se il servizio NON è a prestazione dobbiamo recuperare esattamente le ore inizio e fine

            let fromHourStart = new Date(
              this.state.recurring.hours[dow].fromHour
            )
            fromHourStart.setHours(0, 0, 0, 0)
            let diff1 =
              this.state.recurring.hours[dow].fromHour.getTime() -
              fromHourStart.getTime()
            let startRange = new Date(d.getTime() + diff1)

            let toHourStart = new Date(this.state.recurring.hours[dow].toHour)
            toHourStart.setHours(0, 0, 0, 0)
            let diff2 =
              this.state.recurring.hours[dow].toHour.getTime() -
              toHourStart.getTime()
            let endRange = new Date(d.getTime() + diff2)

            ranges.push({
              day: new Date(d),
              from: startRange,
              to: endRange,
            })
          }
        }
      }
      console.log('At end ', ranges)
      console.groupEnd()

      goon = true
      EQState.change(EQState.UserSelection, {
        selectedRanges: ranges,
        selectedRanges_mode: 'day-based',
        recurring: this.state.recurring,
        weekends: null,
        oldCalendarChoice: this.state.calendarChoice,
        oldCalendarStep: this.state.step,
      })
    } else if (this.state.calendarChoice === 3) {
      // Dobbiamo riportare la selezione dei periodi in modo canonico
      // ad un elenco di date
      console.groupCollapsed('WEEKEND')
      console.log('At start', this.state.weekends)

      let ranges = []
      let wps = EQBusinessRules.weekendsPackets().packets
      this.state.weekends.dates.forEach((ww) => {
        let pp = this.state.weekends.selectedPacket[ww.getTime()]
        let selp = wps[pp - 1]

        let startRange = new Date(ww)
        startRange.setHours(0, 0, 0, 0)
        startRange.setHours(startRange.getHours() + selp.fromHours)

        let endRange = new Date(ww)
        endRange.setHours(0, 0, 0, 0)
        endRange.setHours(endRange.getHours() + selp.toHours)

        ranges.push({
          packet: selp.code,
          day: new Date(ww),
          from: startRange,
          to: endRange,
        })
      })
      console.log('At end', ranges)
      console.groupEnd()

      goon = true
      EQState.change(EQState.UserSelection, {
        selectedRanges: ranges,
        selectedRanges_mode: 'from-to',
        weekends: this.state.weekends,
        recurring: null,
        oldCalendarChoice: this.state.calendarChoice,
        oldCalendarStep: this.state.step,
      })
    }
    if (goon) {
      this.setState({
        searching: true,
      })
    }
  }

  goNextStep() {
    this.setState({
      step: this.state.step + 1,
    })
    //console.log("position", this.state.calendarChoice, this.state.step)
  }

  goPrevStep() {
    let targetStep = this.state.step - 1
    if (
      EQBusinessRules.isEmergenza(EQState.UserSelection.idTipologiaServizio) &&
      this.state.calendarChoice === 3 &&
      this.state.step === 1
    ) {
      this.setState({
        calendarChoice: null, // emergenza salta un passo in questo caso
      })
      return
    }
    this.setState({
      step: targetStep,
    })
  }

  handleWeekDayChange(w, e) {
    this.state.recurring.weekdays[w.code] = e.target.checked
    this.forceUpdate()
  }

  deleteWeekend(w) {
    let newList = []
    let newPackets = {}
    for (let i = 0; i < this.state.weekends.dates.length; i++) {
      let item = this.state.weekends.dates[i]
      if (item !== w) {
        newList.push(item)
        newPackets[item.getTime()] =
          this.state.weekends.selectedPacket[item.getTime()]
      }
    }
    this.state.weekends.dates = newList
    this.state.weekends.selectedPacket = newPackets
    this.forceUpdate()
  }

  deleteWeekday(w) {
    this.state.recurring.weekdays[w.code] = false
    this.forceUpdate()
  }

  handleRecurrentTimeChange(v, key, ww) {
    console.log('*', v, key, ww, this.state.recurring.hours)
    this.state.recurring.hours[ww.code][key] = v
    let rr = this.state.recurring.hours[ww.code]
    if (key === 'fromHour') {
      this.setupAvailableRecurringEndHours(rr)
    } else if (key === 'toHour') {
      if (rr.availableEndHours && rr.availableEndHours.hours) {
        let idx = rr.availableEndHours.hours.indexOf(v.getHours())
        rr[key] = rr.availableEndHours.dates[idx]
      }
    }
    console.log(key, rr[key], v, rr)
    this.forceUpdate()
  }

  isWeekEnd(d) {
    let dayOfWeek = d.getDay()
    return dayOfWeek === 6 || dayOfWeek === 0
  }

  findIndexDate(date, dates) {
    return dates.findIndex((x) => {
      return x.valueOf() === date.valueOf()
    })
  }

  handleWeekendPeriodChange(e, ww) {
    this.state.weekends.selectedPacket[ww.getTime()] = e.target.value
    console.log('sel', this.state.weekends.selectedPacket[ww.getTime()])
    this.forceUpdate()
  }

  handleTimeChangeFromSelector(e, rr) {
    rr.packet = e.target.value
    this.forceUpdate()
  }

  resetRanges() {
    if (
      window.confirm(
        'Reimpostare tutte le date ritornando alla selezione iniziale?'
      )
    ) {
      this.state.selectedRanges = this.defaultRanges()
      this.forceUpdate()
    }
  }

  disableTime(timeValue, clockType) {
    if (clockType === 'minutes' && timeValue !== 0) return true
    return false
  }

  disableStartTime(timeValue, clockType, rr) {
    if (clockType === 'minutes' && timeValue !== 0) return true
    if (clockType === 'hours') {
      return timeValue < 7 || timeValue > 22
    }
    return false
  }

  disableEndTime(timeValue, clockType, rr) {
    if (clockType === 'minutes' && timeValue !== 0) return true
    if (clockType === 'hours') {
      if (rr.availableEndHours && rr.availableEndHours.hours) {
        if (rr.availableEndHours.hours.indexOf(timeValue) >= 0) {
          return false
        } else {
          return true
        }
      }
    }
    return false
  }

  disableReccuringEndTime(timeValue, clockType, rr) {
    if (clockType === 'minutes' && timeValue !== 0) return true
    if (clockType === 'hours') {
      if (rr.availableEndHours && rr.availableEndHours.hours) {
        if (rr.availableEndHours.hours.indexOf(timeValue) >= 0) {
          return false
        } else {
          return true
        }
      }
    }
    return false
  }

  openWeekendInEmergenza() {
    let d = new Date()
    while (d.getDay() !== 6) {
      d = addDays(d, 1)
    }
    this.state.weekends.dates = [d]
    this.openCalendarChoice(3, 1)
  }

  getBgCode() {
    if (EQState.UserSelection.idTipologiaServizio == 100) {
      return 'EMERGENZA'
    } else if (EQState.UserSelection.idTipologiaServizio == 2) {
      return 'FAMILIARE'
    } else {
      return 'DOMICILIARE'
    }
  }

  isCalendarNotAcceptable(elem, cal, index) {
    console.groupCollapsed('isCalendarNotAcceptable')
    let res = cal.some((e, i) => {
      if (i === index) return false
      console.log('cfr', index, elem, e)
      if (e.day.getTime() === elem.day.getTime()) {
        let efrom = e.from.getTime() - EQBusinessRules.getCalendarBookDelta()
        let eto = e.to.getTime() + EQBusinessRules.getCalendarBookDelta()
        console.log('same day', { efrom: new Date(efrom), eto: new Date(eto) })
        if (efrom < elem.from.getTime() && eto > elem.to.getTime()) {
          console.log('1')
          return true // e contiene elem
        } else if (elem.from.getTime() < efrom && elem.to.getTime() > eto) {
          console.log('2')
          return true // elem contiene e
        } else if (efrom < elem.from.getTime() && eto > elem.from.getTime()) {
          console.log('3')
          return true // collisione
        } else if (eto > elem.to.getTime() && efrom < elem.to.getTime()) {
          console.log('4')
          return true // collisione
        }

        return false
      } else return false
    })
    console.log('RESULT', { elem, cal, index, res })
    console.groupEnd()
    return res
  }

  render() {
    if (
      !EQState.UserSelection ||
      !EQState.UserSelection.GruppoServizio ||
      !EQState.UserSelection.SelectedRarentalRelation ||
      !EQState.UserSelection.Location
    ) {
      return null
    }

    let canGoPrevStep = false
    let canGoBack = true
    if (this.state.step > 0) {
      canGoBack = false
      canGoPrevStep = true
    }

    let canGoForward = false
    if (this.state.selectedRanges && this.state.selectedRanges.length > 0)
      canGoForward = true

    //console.log("check",this.state.calendarChoice,this.state.step,this.state.recurring.weekdays)
    let canNextStep = false
    if (this.state.calendarChoice === null) {
      canGoForward = false
      canNextStep = false
    } else if (this.state.calendarChoice === 1) {
      if (
        EQBusinessRules.isAPrestazione(EQState.UserSelection.GruppoServizio)
      ) {
        canGoForward = true
        canNextStep = false
        // Vediamo se è selezionato un pacchetto
        this.state.selectedRanges.forEach((rr) => {
          if (!rr.packet) {
            canGoForward = false
          }
        })
      }
    } else if (this.state.calendarChoice === 2) {
      if (this.state.step === 1) {
        canGoForward = false
        canNextStep = false
        for (let k in Object.keys(this.state.recurring.weekdays)) {
          if (this.state.recurring.weekdays[k]) {
            canGoForward = false
            canNextStep = true
            break
          }
        }
      } else if (this.state.step === 2) {
        if (
          EQBusinessRules.isAPrestazione(EQState.UserSelection.GruppoServizio)
        ) {
          canGoForward = true
          canNextStep = false
          for (let k in Object.keys(this.state.recurring.weekdays)) {
            if (this.state.recurring.weekdays[k]) {
              if (
                !this.state.recurring.hours[k] ||
                !this.state.recurring.hours[k].packet
              ) {
                canGoForward = false
                canNextStep = false
                break
              }
            }
          }
        } else {
          canGoForward = true
          canNextStep = false
        }
      } else {
        canGoForward = false
        canNextStep = true
      }
    } else if (this.state.calendarChoice === 3) {
      if (this.state.step < 1) {
        /*
        if (this.state.weekends.dates.length === 0) {
            canGoForward = false;
            canNextStep = false;
        } else {
            canGoForward = false;
            canNextStep = true;
        }
        */
        canGoForward = false
        canNextStep = true
      } else if (this.state.step < 2) {
        if (
          Object.keys(this.state.weekends.selectedPacket).length !==
          this.state.weekends.dates.length
        ) {
          canGoForward = false
          canNextStep = false
        }
      }
    }
    if (this.state.searching) {
      canGoForward = false
    }

    let weekdays = [
      {
        label: 'LU',
        code: '0',
        name: 'Lunedì',
      },
      {
        label: 'MA',
        code: '1',
        name: 'Martedì',
      },
      {
        label: 'ME',
        code: '2',
        name: 'Mercoledì',
      },
      {
        label: 'GI',
        code: '3',
        name: 'Giovedì',
      },
      {
        label: 'VE',
        code: '4',
        name: 'Venerdì',
      },
      {
        label: 'SA',
        code: '5',
        name: 'Sabato',
      },
      {
        label: 'DO',
        code: '6',
        name: 'Domenica',
      },
    ]

    let pname =
      EQState.UserSelection.SelectedRarentalRelation.nome +
      ' ' +
      EQState.UserSelection.SelectedRarentalRelation.cognome

    let period = ''
    if (this.state.calendarChoice === 2 && this.state.step > 0) {
      period =
        'Dal ' +
        Formats.dateToDayMonth(this.state.recurring.fromDay) +
        ' al ' +
        Formats.dateToDayMonth(this.state.recurring.toDay)
    }

    let faqCat = 'DateAssistenza'
    if (EQBusinessRules.isEmergenza(EQState.UserSelection.idTipologiaServizio))
      faqCat = 'DateAssistenzaEmergenza'
    if (this.state.calendarChoice === 1) faqCat = faqCat + 'Occasionale'
    if (this.state.calendarChoice === 2) faqCat = faqCat + 'Ricorrente'
    if (this.state.calendarChoice === 3) faqCat = faqCat + 'Weekend'

    let cssBox = 'boxCeleste needlesButtons large-side-padding'
    if (
      EQBusinessRules.isEmergenza(EQState.UserSelection.idTipologiaServizio)
    ) {
      cssBox = 'boxCeleste  needlesButtons large-side-padding EMERGENZA'
    }
    if (EQState.UserSelection.idTipologiaServizio === 2) {
      cssBox = 'boxCeleste  needlesButtons large-side-padding FAMILIARE'
    }

    let futureLimit = new Date(
      new Date().setMonth(
        new Date().getMonth() + EQBusinessRules.maxFutureMonthsForBooking()
      )
    ) // possiamo prenotare fino a N3 mesi da oggi

    let anyNotAcceptable = false

    return (
      <Fragment>
        <Box>
          {!EQBusinessRules.isEmergenza(
            EQState.UserSelection.idTipologiaServizio
          ) && (
            <p className="playFair normalWeight textCenter bookstep-start-label">
              Prenotazione per
            </p>
          )}
          {EQBusinessRules.isEmergenza(
            EQState.UserSelection.idTipologiaServizio
          ) && (
            <p className="playFair normalWeight textCenter bookstep-start-label">
              Prenotazione urgente per
            </p>
          )}
          <div className="row">
            <div className="col1-icon">
              <PersonOutlineIcon />
            </div>
            <div className="col2-icon">
              <h3 className="textCenter patient-name">{pname}</h3>
            </div>
          </div>

          {this.state.searching && (
            <EQBookStep_Do_Search></EQBookStep_Do_Search>
          )}

          {!this.state.searching &&
            !this.state.calendarChoice &&
            !EQBusinessRules.isEmergenza(
              EQState.UserSelection.idTipologiaServizio
            ) && (
              <Box
                className={cssBox}
                mt={2}
                mb={4}>
                <p className="textCenter playFair app-text">
                  Con quale frequenza hai bisogno?
                </p>
                <Grid container>
                  <Grid
                    item
                    xs={12}>
                    <p className="textCenter">Solo di tanto in tanto</p>
                    <button
                      onClick={() => this.openCalendarChoice(1)}
                      className={`btnText buttonBlue button-${this.getBgCode()}`}>
                      OCCASIONALE
                    </button>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    pt={2}>
                    <p className="textCenter">
                      Periodicamente in alcuni giorni della settimana
                    </p>
                    <button
                      onClick={() => this.openCalendarChoice(2)}
                      className={`btnText buttonBlue button-${this.getBgCode()}`}>
                      RICORRENTE
                    </button>
                  </Grid>
                  {!EQBusinessRules.isAPrestazione(
                    EQState.UserSelection.GruppoServizio
                  ) && (
                    <Grid
                      item
                      xs={12}
                      pt={2}>
                      <p className="textCenter">
                        Nei fine settimana e vuoi assicurarti la disponibilità
                      </p>
                      <button
                        onClick={() => this.openCalendarChoice(3)}
                        className={`btnText buttonBlue button-${this.getBgCode()}`}>
                        SOLO NEI FINE SETTIMANA
                      </button>
                    </Grid>
                  )}
                </Grid>
              </Box>
            )}

          {!this.state.searching &&
            !this.state.calendarChoice &&
            EQBusinessRules.isEmergenza(
              EQState.UserSelection.idTipologiaServizio
            ) && (
              <Box
                className={cssBox}
                mt={2}
                mb={4}>
                <p className="textCenter playFair app-text">
                  Hai bisogno di assistenza nelle prossime 72 ore?
                </p>
                <Grid container>
                  <Grid
                    item
                    xs={12}>
                    <p className="textCenter">
                      Ho bisogno di scegliere un giorno preciso
                    </p>
                    <button
                      onClick={() => this.openCalendarChoice(1)}
                      className={`btnText button-${this.getBgCode()}`}>
                      SCEGLI DATA E ORA
                    </button>
                  </Grid>
                  {!EQBusinessRules.isAPrestazione(
                    EQState.UserSelection.GruppoServizio
                  ) &&
                    EQBusinessRules.isWeekendAvailableForEmergenza() && (
                      <Grid
                        item
                        xs={12}
                        pt={2}>
                        <p className="textCenter">
                          Ho bisogno nel prossimo fine settimana
                        </p>
                        <button
                          onClick={() => this.openWeekendInEmergenza()}
                          className={`btnText button-${this.getBgCode()}`}>
                          PACCHETTO WEEK-END
                        </button>
                      </Grid>
                    )}
                </Grid>
              </Box>
            )}

          {!this.state.searching && this.state.calendarChoice === 1 && (
            <LocalizationProvider
              dateAdapter={DateAdapter}
              locale={it}>
              <Box
                className={cssBox}
                mt={2}
                mb={4}>
                {/*
                                <div style={{float:'right'}}>
                                    <RotateLeft className="large-icon" onClick={() => this.resetRanges() }/>
                                </div>
                                */}
                <p className="textCenter playFair app-text">Scegli la data</p>
                {this.state.selectedRanges.map((rr, i) => {
                  // Data
                  if (rr.day < this.state.minDay)
                    rr.day = new Date(this.state.minDay.getTime())
                  if (this.state.maxDay !== null && rr.day > this.state.maxDay)
                    rr.day = new Date(this.state.minDay.getTime())

                  // Ora
                  rr.to = Formats.asDate(rr.to)
                  rr.from = Formats.asDate(rr.from)

                  // Se la data selezionata è oggi (emergenza) gestiamo l'ora minima...
                  console.groupCollapsed('CALENDAR')
                  let from_min = null
                  let todayDate = new Date()
                  if (
                    rr.from.getDate() === todayDate.getDate() &&
                    rr.from.getMonth() === todayDate.getMonth() &&
                    rr.from.getFullYear() === todayDate.getFullYear()
                  ) {
                    // Situazione possibile in emergenza, sto cercando di prenotare per oggi
                    // A questo punto non tutte le ore sono possibili
                    const limitOre = EQBusinessRules.limiteOrePerPrenotazione()
                    console.log('Is today')
                    from_min = new Date(
                      todayDate.getFullYear(),
                      todayDate.getMonth(),
                      todayDate.getDate(),
                      todayDate.getHours() + limitOre + 1,
                      0,
                      0
                    )
                  }
                  console.log('from_min', from_min)
                  if (rr.from < from_min) {
                    console.log('adjusting rr.from')
                    rr.from = new Date(from_min)
                  }
                  let from_max = null
                  let to_min = new Date(
                    rr.from.getFullYear(),
                    rr.from.getMonth(),
                    rr.from.getDate(),
                    rr.from.getHours() + this.state.minServiceHours,
                    0,
                    0
                  )
                  if (rr.to < to_min) {
                    console.log('adjusting rr.to')
                    rr.to = new Date(to_min)
                  }
                  console.log('from_max', from_max)
                  console.log('from_min', from_min)
                  console.log('rr', rr)
                  console.groupEnd()

                  let isNotAcceptable = this.isCalendarNotAcceptable(
                    rr,
                    this.state.selectedRanges,
                    i
                  )
                  if (isNotAcceptable) anyNotAcceptable = true

                  return (
                    <div key={i}>
                      {isNotAcceptable && (
                        <div className="alert alert-danger smalltext">
                          Appuntamento troppo vicino o sovrapposto ad altri
                        </div>
                      )}
                      <Grid
                        container
                        mb={2}
                        style={{ marginLeft: '-26px' }}>
                        <Grid
                          item
                          xs={2}
                          className="textRight"
                          pr={1}
                          pb={1}
                          style={{ paddingTop: '12px' }}>
                          <EventIcon />
                        </Grid>
                        <Grid
                          item
                          xs={9}
                          pb={1}>
                          <MobileDatePicker
                            disabled={false}
                            label="Quando hai bisogno"
                            value={rr.day}
                            onChange={(v) => this.handleDayChange(v, rr)}
                            renderInput={(params) => <TextField {...params} />}
                            minDate={this.state.minDay}
                            maxDate={this.state.maxDay}
                            inputFormat="dd/MM/yyyy"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          className="textLeft"
                          pl={1}
                          pb={1}
                          style={{ paddingTop: '12px' }}>
                          {i > 0 && (
                            <DeleteIcon
                              className="red-icon"
                              onClick={() => this.deleteRange(i)}
                            />
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          className="textRight"
                          pr={1}
                          pb={1}
                          style={{ paddingTop: '12px' }}>
                          <AccessTimeIcon />
                        </Grid>
                        {!EQBusinessRules.isAPrestazione(
                          EQState.UserSelection.GruppoServizio
                        ) && (
                          <Fragment>
                            <Grid
                              item
                              xs={9}>
                              <div
                                className=""
                                style={{
                                  width: '48%',
                                  display: 'inline-block',
                                  backgroundColor: 'white',
                                }}>
                                <TimePicker
                                  sx={{ paddingRight: '10px' }}
                                  ampm={false}
                                  views={['hours']}
                                  disabled={false}
                                  label="Dalle ore"
                                  value={rr.from}
                                  onChange={(v) =>
                                    this.handleTimeChange(v, 'from', rr)
                                  }
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                  maxTime={from_max}
                                  minTime={from_min}
                                  shouldDisableTime={(timeValue, clockType) =>
                                    this.disableStartTime(timeValue, clockType)
                                  }
                                  inputFormat="HH:mm"
                                />
                              </div>
                              <div
                                style={{
                                  width: '48%',
                                  float: 'right',
                                  display: 'inline-block',
                                  backgroundColor: 'white',
                                }}>
                                <TimePicker
                                  ampm={false}
                                  views={['hours']}
                                  disabled={false}
                                  label="Alle ore"
                                  value={rr.to}
                                  onChange={(v) =>
                                    this.handleTimeChange(v, 'to', rr)
                                  }
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                  shouldDisableTime={(timeValue, clockType) =>
                                    this.disableEndTime(
                                      timeValue,
                                      clockType,
                                      rr
                                    )
                                  }
                                  inputFormat="HH:mm"
                                />

                                {rr.availableEndHours &&
                                  rr.availableEndHours.messages &&
                                  rr.availableEndHours.messages[
                                    rr.to.getHours()
                                  ] && (
                                    <Grid item>
                                      <div
                                        style={{
                                          width: '100%',
                                        }}
                                        className="next-day-badge">
                                        {
                                          rr.availableEndHours.messages[
                                            rr.to.getHours()
                                          ]
                                        }
                                      </div>
                                    </Grid>
                                  )}
                              </div>
                            </Grid>
                          </Fragment>
                        )}
                        {EQBusinessRules.isAPrestazione(
                          EQState.UserSelection.GruppoServizio
                        ) && (
                          <Fragment>
                            <Grid
                              item
                              xs={9}>
                              <EQSelect
                                label="Periodo"
                                onChange={(e) =>
                                  this.handleTimeChangeFromSelector(e, rr)
                                }
                                defaultValue={1}
                                value={rr.packet}>
                                <MenuItem
                                  key={0}
                                  value={0}></MenuItem>
                                {this.state.packets_aPrestazione.packets.map(
                                  (c) => {
                                    let now = new Date()
                                    if (
                                      rr.day.toDateString() ===
                                      now.toDateString()
                                    ) {
                                      // caso emergenza: possiamo essere sul giorno stesso, non tutti i pacchetti
                                      // sono selezionabili
                                      let cstart = new Date()
                                      cstart.setHours(c.fromHours)
                                      cstart.setMinutes(c.fromMinutes)
                                      let diff =
                                        (cstart.getTime() - now.getTime()) /
                                        (1000 * 60 * 60)
                                      if (diff < 2) return null
                                    }
                                    return (
                                      <MenuItem
                                        key={c.id}
                                        value={c.id}>
                                        <span>{c.firstLabel}</span>{' '}
                                        <span className="second-label">
                                          {c.secondLabel}
                                        </span>
                                      </MenuItem>
                                    )
                                  }
                                )}
                              </EQSelect>
                            </Grid>
                          </Fragment>
                        )}
                      </Grid>
                    </div>
                  )
                })}

                {!EQBusinessRules.isEmergenza(
                  EQState.UserSelection.idTipologiaServizio
                ) &&
                  !EQBusinessRules.isServiceAdvisory(
                    EQState.UserSelection.GruppoServizio
                  ) && (
                    <div
                      style={{
                        marginTop: '20px',
                        textAlign: 'center',
                        marginBottom: '20px',
                      }}>
                      <Link
                        onClick={() => this.addRange()}
                        className="playFair linkblack">
                        Aggiungi un'altra data
                      </Link>
                    </div>
                  )}
              </Box>
            </LocalizationProvider>
          )}

          {!this.state.searching && this.state.calendarChoice === 2 && (
            <LocalizationProvider
              dateAdapter={DateAdapter}
              locale={it}>
              {this.state.step === 0 && (
                <Box
                  className={cssBox}
                  mt={2}
                  mb={4}>
                  <p className="textCenter playFair app-text">
                    Passo 1 - Scegli il periodo
                  </p>
                  <p className="textCenter playFair">
                    Indica il periodo in cui vuoi ricevere assistenza
                  </p>
                  <Grid
                    container
                    mb={2}>
                    <Grid
                      item
                      xs={1}
                      className="textRight"
                      pr={1}>
                      <EventIcon className="large-icon" />
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      ml={1}>
                      <MobileDatePicker
                        disabled={false}
                        label="Dal giorno"
                        value={this.state.recurring.fromDay}
                        onChange={(v) =>
                          this.handleRecurringDayChange(v, 'fromDay')
                        }
                        renderInput={(params) => <TextField {...params} />}
                        minDate={this.state.minDay}
                        maxDate={this.state.recurring.toDay}
                        inputFormat="dd/MM/yyyy"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      ml={1}>
                      <MobileDatePicker
                        disabled={false}
                        label="Al giorno"
                        value={this.state.recurring.toDay}
                        onChange={(v) =>
                          this.handleRecurringDayChange(v, 'toDay')
                        }
                        renderInput={(params) => <TextField {...params} />}
                        minDate={this.state.recurring.fromDay}
                        maxDate={this.state.maxDay}
                        inputFormat="dd/MM/yyyy"
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
              {this.state.step === 1 && (
                <Fragment>
                  <div className="row">
                    <div className="col1-icon">
                      <EventIcon />
                    </div>
                    <div className="col2-icon">
                      <h3 className="textCenter patient-name">{period}</h3>
                    </div>
                  </div>
                  <Box
                    className={cssBox}
                    mt={2}
                    mb={4}
                    pt={0}>
                    <p className="textCenter playFair app-text">
                      Passo 2 - Scegli i giorni
                    </p>
                    <p className="textCenter playFair">
                      Indica in quali giorni della settimana vuoi ricevere
                      assistenza
                    </p>
                    <Box mx={0}>
                      <table style={{ margin: 'auto', width: '100%' }}>
                        <tbody>
                          <tr>
                            {weekdays.map((w) => {
                              return (
                                <td
                                  style={{ textAlign: 'center' }}
                                  key={w.code}>
                                  {w.label}
                                  <br />
                                  <EQCheckbox
                                    style={{
                                      width: '32px',
                                      height: '32px',
                                      paddingRight: 0,
                                      paddingLeft: 0,
                                    }}
                                    checked={
                                      this.state.recurring.weekdays[w.code]
                                    }
                                    onChange={(e) =>
                                      this.handleWeekDayChange(w, e)
                                    }
                                  />
                                </td>
                              )
                            })}
                          </tr>
                        </tbody>
                      </table>
                    </Box>
                  </Box>
                </Fragment>
              )}
              {this.state.step === 2 && (
                <Fragment>
                  <div className="row">
                    <div className="col1-icon">
                      <EventIcon />
                    </div>
                    <div className="col2-icon">
                      <h3 className="textCenter patient-name">{period}</h3>
                    </div>
                  </div>
                  <Box
                    className={cssBox}
                    mt={2}
                    mb={4}>
                    <p className="textCenter playFair app-text">
                      Passo 3 - Scegli gli orari
                    </p>
                    <p className="textCenter playFair">
                      Indica in quali orari vuoi ricevere assistenza
                    </p>
                    <Box
                      mt={4}
                      mb={3}>
                      {weekdays.map((ww) => {
                        if (!this.state.recurring.weekdays[ww.code]) return null
                        let rhh = this.state.recurring.hours[ww.code]

                        // I selettori delle ore sono differenziati nel caso a prestazione, permettendo solo
                        // la selezione di fasce orarie

                        if (
                          EQBusinessRules.isAPrestazione(
                            EQState.UserSelection.GruppoServizio
                          )
                        ) {
                          return (
                            <Grid
                              container
                              mb={2}
                              key={ww.code}>
                              <Grid
                                item
                                xs={1}
                                className="textRight"
                                pr={1}>
                                <br />
                                <AccessTimeIcon className="large-icon" />
                              </Grid>
                              <Grid
                                item
                                xs={9}
                                pl={1}>
                                <span style={{ fontWeight: 'bold' }}>
                                  {weekdays[ww.code].name}
                                </span>
                                <br />
                                <EQSelect
                                  label="Periodo"
                                  onChange={(e) =>
                                    this.handleTimeChangeFromSelector(e, rhh)
                                  }
                                  defaultValue={1}
                                  value={rhh.packet}>
                                  {this.state.packets_aPrestazione.packets.map(
                                    (c) => (
                                      <MenuItem
                                        key={c.id}
                                        value={c.id}>
                                        <span>{c.firstLabel}</span>
                                        <span className="second-label">
                                          {c.secondLabel}
                                        </span>
                                      </MenuItem>
                                    )
                                  )}
                                </EQSelect>
                              </Grid>
                            </Grid>
                          )
                        } else {
                          // Nel caso NON a prestazione, la selezione ore è libera

                          let hasMessage =
                            this.state.recurring.hours[ww.code]
                              .availableEndHours.messages &&
                            this.state.recurring.hours[ww.code]
                              .availableEndHours.messages[
                              this.state.recurring.hours[
                                ww.code
                              ].toHour.getHours()
                            ]
                          return (
                            <Fragment key={ww.code}>
                              <Grid
                                container
                                mb={hasMessage ? 0 : 2}>
                                <Grid
                                  item
                                  xs={1}
                                  className="textRight"
                                  pr={1}>
                                  <br />
                                  <AccessTimeIcon className="large-icon" />
                                </Grid>
                                <Grid
                                  item
                                  xs={5}
                                  pl={1}>
                                  <span style={{ fontWeight: 'bold' }}>
                                    {weekdays[ww.code].name}
                                  </span>
                                  <br />
                                  <TimePicker
                                    ampm={false}
                                    views={['hours']}
                                    disabled={false}
                                    label="Dalle ore"
                                    value={
                                      this.state.recurring.hours[ww.code]
                                        .fromHour
                                    }
                                    onChange={(v) =>
                                      this.handleRecurrentTimeChange(
                                        v,
                                        'fromHour',
                                        ww
                                      )
                                    }
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                    shouldDisableTime={(timeValue, clockType) =>
                                      this.disableStartTime(
                                        timeValue,
                                        clockType
                                      )
                                    }
                                    inputFormat="HH:mm"
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={5}
                                  pl={1}>
                                  <br />
                                  <TimePicker
                                    ampm={false}
                                    views={['hours']}
                                    disabled={false}
                                    label="Dalle ore"
                                    value={
                                      this.state.recurring.hours[ww.code].toHour
                                    }
                                    onChange={(v) =>
                                      this.handleRecurrentTimeChange(
                                        v,
                                        'toHour',
                                        ww
                                      )
                                    }
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                    shouldDisableTime={(timeValue, clockType) =>
                                      this.disableReccuringEndTime(
                                        timeValue,
                                        clockType,
                                        this.state.recurring.hours[ww.code]
                                      )
                                    }
                                    inputFormat="HH:mm"
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={1}
                                  className="textLeft"
                                  pl={1}>
                                  <br />
                                  <DeleteIcon
                                    className="large-icon red-icon"
                                    onClick={() => this.deleteWeekday(ww)}
                                  />
                                </Grid>
                              </Grid>
                              {hasMessage && (
                                <Grid
                                  item
                                  style={{
                                    marginLeft: '54px',
                                    marginTop: '4px',
                                  }}
                                  mb={2}>
                                  <div
                                    style={{
                                      width: '48%',
                                      display: 'inline-block',
                                    }}>
                                    &nbsp;
                                  </div>
                                  <div
                                    style={{
                                      width: '48%',
                                      float: 'right',
                                      display: 'inline-block',
                                    }}
                                    className="next-day-badge">
                                    {
                                      this.state.recurring.hours[ww.code]
                                        .availableEndHours.messages[
                                        this.state.recurring.hours[
                                          ww.code
                                        ].toHour.getHours()
                                      ]
                                    }
                                  </div>
                                </Grid>
                              )}
                            </Fragment>
                          )
                        }
                      })}
                    </Box>
                  </Box>
                </Fragment>
              )}
            </LocalizationProvider>
          )}

          {!this.state.searching && this.state.calendarChoice === 3 && (
            <LocalizationProvider
              dateAdapter={DateAdapter}
              locale={it}>
              {this.state.step === 0 && (
                <Box
                  className={cssBox}
                  mt={2}
                  mb={4}>
                  <p className="textCenter playFair app-text">
                    Passo 1 - Scegli i fine settimana
                  </p>
                  <p className="textCenter playFair">
                    Indica in quali fine settimana vuoi ricevere assistenza
                  </p>
                  <div>
                    <Grid
                      container
                      mb={2}>
                      <Grid
                        item
                        xs={1}
                        className="textRight"
                        pr={1}>
                        <AccessTimeIcon className="large-icon" />
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        pl={1}>
                        <EQWeekendDatePicker
                          minDate={EQBusinessRules.primaDataPerPrenotazioneNormale()}
                          maxDate={futureLimit}
                          value={this.state.weekends.dates}
                          disablePast
                          onChange={(date) => {
                            let model = this.state.weekends.dates
                            const index = this.findIndexDate(date, model)
                            if (index >= 0) {
                              model.splice(index, 1)
                            } else {
                              model.push(date)
                            }
                            //this.forceUpdate();
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </Box>
              )}
              {this.state.step === 1 && (
                <Fragment>
                  {/*<h3 className='textCenter patient-name'><EventIcon /> <span>{period}</span></h3>*/}
                  <Box
                    className={cssBox}
                    mt={2}
                    mb={4}>
                    {!EQBusinessRules.isEmergenza(
                      EQState.UserSelection.idTipologiaServizio
                    ) && (
                      <p className="textCenter playFair app-text">
                        Passo 2 - Scegli gli orari
                      </p>
                    )}
                    {EQBusinessRules.isEmergenza(
                      EQState.UserSelection.idTipologiaServizio
                    ) && (
                      <p className="textCenter playFair app-text">
                        Passo 1 - Scegli gli orari
                      </p>
                    )}
                    <p className="textCenter playFair">
                      Puoi scegliere tra diversi pacchetti, a seconda di quando
                      vuoi che duri il fine settimana. Seleziona quello che
                      preferisci.
                    </p>
                    <Box
                      mt={4}
                      mb={1}>
                      {this.state.weekends.dates.map((wwi, j) => {
                        let ww = new Date(wwi)
                        return (
                          <Grid
                            container
                            mb={2}
                            key={j}>
                            <Grid
                              item
                              xs={1}
                              className="textCenter">
                              <br />
                            </Grid>
                            <Grid
                              item
                              xs={11}
                              pl={2}
                              pb={1}>
                              <span style={{ fontWeight: 'bold' }}>
                                Fine settimana del {Formats.dateToDayMonth(ww)}
                              </span>
                              <br />
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              className="textRight"
                              pr={1}
                              style={{ paddingTop: '12px' }}>
                              <AccessTimeIcon />
                            </Grid>
                            <Grid
                              item
                              xs={10}
                              pl={1}>
                              <EQSelect
                                label="Pacchetto orario"
                                onChange={(e) =>
                                  this.handleWeekendPeriodChange(e, ww)
                                }
                                defaultValue={
                                  this.state.weekends.selectedPacket[
                                    ww.getTime()
                                  ]
                                }>
                                {this.state.weekends.weekendPackets.packets.map(
                                  (c) => (
                                    <MenuItem
                                      key={c.id}
                                      value={c.id}>
                                      <span>{c.firstLabel}</span>
                                      <span className="second-label">
                                        {c.secondLabel}
                                      </span>
                                    </MenuItem>
                                  )
                                )}
                              </EQSelect>
                            </Grid>
                            {this.state.weekends.dates.length > 1 && (
                              <Grid
                                item
                                xs={1}
                                className="textLeft"
                                pl={1}
                                style={{ paddingTop: '12px' }}>
                                <DeleteIcon
                                  className="red-icon"
                                  onClick={() => this.deleteWeekend(wwi)}
                                />
                              </Grid>
                            )}
                          </Grid>
                        )
                      })}
                    </Box>

                    {!EQBusinessRules.isEmergenza(
                      EQState.UserSelection.idTipologiaServizio
                    ) && (
                      <Box
                        mt={4}
                        mb={6}
                        className="textCenter">
                        <a
                          className="buttonLink lnkForward"
                          onClick={() => this.goPrevStep()}>
                          AGGIUNGI FINE SETTIMANA
                        </a>
                      </Box>
                    )}
                  </Box>
                </Fragment>
              )}
            </LocalizationProvider>
          )}
        </Box>

        <Grid
          className="book-navigation shrink"
          container
          mt={0}
          mb={0}>
          <Grid
            item
            xs={6}>
            {canGoBack && (
              <Button
                className="lnkButton lnkBack"
                startIcon={<ArrowBackIcon />}
                onClick={() => this.goBack()}>
                Indietro
              </Button>
            )}
            {canGoPrevStep && (
              <Button
                className="lnkButton lnkBack"
                startIcon={<ArrowBackIcon />}
                onClick={() => this.goPrevStep()}>
                Indietro
              </Button>
            )}
          </Grid>
          <Grid
            item
            sx={{ textAlign: 'right' }}
            xs={6}>
            {canNextStep && !anyNotAcceptable && (
              <a
                className={`buttonLink lnkForward  button-${this.getBgCode()}`}
                onClick={() => this.goNextStep()}>
                AVANTI
              </a>
            )}
            {canGoForward && !anyNotAcceptable && (
              <a
                className={`buttonLink lnkForward  button-${this.getBgCode()}`}
                onClick={() => this.goForward()}>
                AVANTI
              </a>
            )}
            {(!canGoForward && !canNextStep) ||
              (anyNotAcceptable && (
                <a
                  className={`buttonLink lnkForward disabled  button-${this.getBgCode()}`}>
                  AVANTI
                </a>
              ))}
          </Grid>
        </Grid>

        <Box>
          <EQFaqBox category={faqCat}></EQFaqBox>
        </Box>
      </Fragment>
    )
  }
}

export default withRouter(EQBookStep_Dates)
