import React from 'react'

export default class EQFormGroup extends React.Component {
  render() {
    const { label, ...others } = this.props
    return (
      <div
        className="eq-form-group"
        {...others}>
        <div className="label-outer">
          <label>{label}</label>
        </div>
        {this.props.children}
      </div>
    )
  }
}
