import { Box, Button, ButtonGroup, Grid, styled } from '@mui/material'
import React, { Fragment } from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import EQBusinessRules from '../../libs/EQBusinessRules'
import EQDaSapere from '../core/EQDaSapere'
import EQHeader from '../EQHeader'
import EQLoading from '../EQLoading'
import EQService from '../../services/EQService'
import EQServiceBook from '../../services/EQServiceBook'
import EQState from '../../services/EQState'
import Formats from '../../libs/Formats'
import Pages from '../../services/Pages'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import PictureAsPdfIcon from '@mui/icons-material/DocumentScannerTwoTone'
import PrintIcon from '@mui/icons-material/LocalPrintshopTwoTone'
import html2pdf from 'html2pdf.js/src'
import { withRouter } from 'react-router-dom'

const BarthelButton = styled(Button)({
  backgroundColor: '#ddd',

  textTransform: 'none',
  fontFamily: 'Raleway',
  width: '110px',
  boxShadow: 'none',
  borderRadius: '20px',
  color: 'black',
})

const ActiveBarthelButton = styled(Button)({
  backgroundColor: '#0082B1',

  textTransform: 'none',
  fontFamily: 'Raleway',
  width: '110px',
  boxShadow: 'none',
  borderRadius: '20px',
  color: 'white',
})

class EQBookStep_Success extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      confirmed: false,
      error: false,
      answers: {},
      changing: {},
    }
  }

  componentDidMount() {
    // commentare per evitare il redirect
    let tk = EQService.getForcedSessionId()
    if (!tk) {
      if (!EQState.Booking || !EQState.UserSelection.servizi) {
        document.location.href = '/'
        return
      }
    }

    const page = Pages.current()
    document.title = page.title
    window.scrollTo(0, 0)

    EQService.mapContentsOnCurrentPage().then((m) => {
      EQState.change(EQState.Contents, {
        [Pages.current().cms]: m,
      })
      this.forceUpdate()
    })

    /* simulazione
        this.setState({
            error: true
        })
        */

    this.setState({
      confirmed: true,
    })

    // Verifichiamo i dati Stripe passati come query string
    const urlParams = new URLSearchParams(window.location.search)
    const stripeClientSecret = urlParams.get('setup_intent_client_secret')

    EQServiceBook.confermaPagamentoPrenotazione(stripeClientSecret)
      .then((b) => {
        EQState.reset(EQState.UserSelection) // reset della ricerca in modo che l'utente non riesca a prenotare due volte con il back del browser
        EQState.persist(EQState.UserSelection)

        EQState.change(EQState.Booking, {
          BookingResult: b,
        })
        this.setState({
          confirmed: true,
        })
      })
      .catch((e) => {
        console.error(e)
        this.setState({
          error: true,
          exception: e,
        })
      })
  }

  openQuestions() {
    this.setState({
      showBarthel: true,
      barthelLoaded: false,
    })
    EQServiceBook.getQuestionarioBarthel(
      EQState.Booking.BookingResult.idPaziente
    ).then((x) => {
      if (x.length) {
        for (let i = 0; i < x.length; i++) {
          let q = x[i]
          this.state.answers[q.domanda] = {
            label: q.risposta,
            value: q.punteggio,
          }
        }
      }
      this.setState({
        barthelLoaded: true,
      })
    })
  }

  selectAnswer(o, q) {
    this.state.answers[q.key] = o
    this.state.changing[q.key] = true
    this.forceUpdate()

    setTimeout(() => {
      this.setState({
        changing: {},
      })
    }, 0)
  }

  cancelBarthel() {
    this.setState({
      showBarthel: false,
    })
    window.scrollTo(0, 0)
  }

  saveBarthel() {
    this.state.questionCounter = 0
    this.setState({
      saving: true,
    })
    this.saveSingleBarthelQuestion()
  }

  saveSingleBarthelQuestion() {
    let questions = EQBusinessRules.barthel_questions()
    if (this.state.questionCounter >= questions.length) {
      this.saveSingleBarthelDate()
      return
    }
    let qq = questions[this.state.questionCounter]
    let aa = this.state.answers[qq.key]
    EQServiceBook.inserisciRispostaBarthel(
      EQState.Booking.BookingResult.idPaziente,
      qq.key,
      aa.label,
      aa.value
    ).then(() => {
      this.state.questionCounter++
      this.saveSingleBarthelQuestion()
    })
  }

  saveSingleBarthelDate() {
    let d = new Date()
    let fd =
      d.getDate() +
      '/' +
      (d.getMonth() + 1) +
      '/' +
      d.getFullYear() +
      ' ' +
      Formats.dateToHourMinutes(d)
    EQServiceBook.inserisciRispostaBarthel(
      EQState.Booking.BookingResult.idPaziente,
      '_update',
      fd,
      -1
    ).then(() => {
      this.setState({
        saving: false,
      })
      this.cancelBarthel()
      return
    })
  }

  savePdf() {
    let element = document.getElementById('print-root')
    element.style.height = '400px' // hack to force only one page
    let opt = {
      filename: 'prenotazione_equecure.pdf',
      margin: 1,
      html2canvas: { scale: 1 },
      pagebreak: { mode: 'avoid-all' },
      jsPDF: { unit: 'cm', format: 'A6', orientation: 'portrait' },
    }
    //window.html2pdf(element,opt);
    const pdf = html2pdf().set(opt).from(element).save()
    setTimeout(() => {
      element.style.height = null
    }, 1500)
  }

  render() {
    if (!EQState.Booking || !EQState.Booking.BookingResult) {
      return null
    }

    if (
      !EQState.Contents.BOOK ||
      (!this.state.confirmed && !this.state.error)
    ) {
      return (
        <div style={{ marginTop: '120px', marginBottom: '80px' }}>
          <EQLoading message="Verifica pagamento..." />
        </div>
      )
    }

    const MC = EQState.Contents.BOOK
    let booking = EQState.Booking.BookingResult
    let pname = booking.paziente.nome + ' ' + booking.paziente.cognome

    let questions = []
    let canSaveBarthel = false

    if (this.state.showBarthel) {
      let answered = 0
      let barthelQuestions = EQBusinessRules.barthel_questions()
      questions = barthelQuestions.map((q, qi) => {
        if (this.state.answers[q.key] && this.state.answers[q.key].value >= 0) {
          answered++
        }
        return (
          <Box
            mt={3}
            mb={1}>
            <Box mb={1}>{q.key}</Box>
            <Box>
              <ButtonGroup>
                {q.options.map((o, oi) => {
                  if (
                    this.state.answers[q.key] &&
                    this.state.answers[q.key].value === o.value
                  ) {
                    return (
                      <ActiveBarthelButton
                        variant="contained"
                        size="small"
                        onClick={() => this.selectAnswer(o, q)}>
                        {o.label}
                      </ActiveBarthelButton>
                    )
                  } else {
                    return (
                      <BarthelButton
                        variant="contained"
                        size="small"
                        onClick={() => this.selectAnswer(o, q)}>
                        {o.label}
                      </BarthelButton>
                    )
                  }
                })}
              </ButtonGroup>
            </Box>
          </Box>
        )
      })

      if (answered === barthelQuestions.length) canSaveBarthel = true
    }
    this.state.error = false
    console.clear()
    console.log('🔵🔵🔵 EQState.Booking', EQState.Booking)

    if (this.state.error) {
      return (
        <div className="home">
          <EQHeader></EQHeader>
          <Box mt={7}>
            <div className="header">
              <img
                src={MC.BOOK_ERROR_VISUAL.urlImmagine}
                alt={MC.BOOK_ERROR_VISUAL.SottoTitolo}
                title={MC.BOOK_ERROR_VISUAL.SottoTitolo}
              />
            </div>
            <div
              className="logo"
              style={{ marginLeft: '10px' }}>
              <img
                src="/img/logo_big.svg"
                alt="Logo EqueCure"
              />
            </div>
          </Box>
          <Box mt={0}>
            <p className="playFair normalWeight textCenter bookstep-start-label">
              <b className="text-danger">Si &egrave; verificato un errore.</b>
              <br />
              Contatta il supporto tecnico di EQUECURE.
              <br />
            </p>

            <Grid container>
              <Grid
                item
                xs={6}>
                <div className="codice-prenotazione">
                  <div className="label">IL TUO CODICE DI PRENOTAZIONE</div>
                  <div className="code">{booking.codicePratica}</div>
                </div>
              </Grid>
              <Grid
                item
                xs={6}>
                <div className="codice-prenotazione">
                  <div className="label">CODICE ERRORE</div>
                  <div className="code">ERR_CNF</div>
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
      )
    }

    if (!this.state.showBarthel) {
      return (
        <div className="home">
          <EQHeader></EQHeader>
          <div
            id="print-root"
            className="success-page">
            <Box mt={7}>
              <div
                className="header"
                data-html2canvas-ignore="true">
                <img
                  src={MC.BOOK_SUCCESS_VISUAL.urlImmagine}
                  alt={MC.BOOK_SUCCESS_VISUAL.SottoTitolo}
                  title={MC.BOOK_SUCCESS_VISUAL.SottoTitolo}
                />
              </div>
              <div className="logo">
                <img
                  src="/img/logo_big.svg"
                  alt="Logo EqueCure"
                />
              </div>
            </Box>
            <Box mt={0}>
              <p className="playFair normalWeight textCenter bookstep-start-label">
                Ci stiamo preparando per aiutarti.
                <br />
                La tua richiesta è stata inviata!
                <br />
                <b>A breve riceverai la conferma.</b>
              </p>
              <Grid
                item
                xs={12}
                mb={4}
                data-html2canvas-ignore="true">
                <Box mt={1}>
                  <EQDaSapere />
                </Box>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={6}>
                  <div className="codice-prenotazione first">
                    <div className="label">PRENOTAZIONE</div>
                    <div className="code">{booking.codicePratica}</div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={6}>
                  <div
                    className="voucher-icons"
                    data-html2canvas-ignore="true">
                    <div
                      className="codice-prenotazione"
                      onClick={(e) => {
                        window.print()
                      }}>
                      <div className="label">STAMPA</div>
                      <PrintIcon className="large-icon" />
                    </div>
                    <div
                      className="codice-prenotazione"
                      onClick={() => this.savePdf()}>
                      <div className="label">SCARICA</div>
                      <PictureAsPdfIcon className="large-icon" />
                    </div>
                  </div>
                </Grid>
              </Grid>

              <div data-html2canvas-ignore="true">
                <div className="bandaGialla">
                  <Box
                    mr={3}
                    ml={3}>
                    <p className="textCenter">
                      Ti suggeriamo di compilare adesso il
                    </p>
                    <p className="textCenter">
                      questionario sul grado di autonomia.
                    </p>
                    <p
                      className="textCenter needlesButtons"
                      data-html2canvas-ignore="true">
                      <button
                        onClick={() => this.openQuestions()}
                        className="btnText buttonBlue">
                        {' '}
                        COMPILA IL QUESTIONARIO
                      </button>
                    </p>
                  </Box>
                </div>
              </div>
            </Box>
          </div>
        </div>
      )
    } else {
      return (
        <div className="home">
          <EQHeader></EQHeader>
          <Box mt={7}>
            <div className="header">
              <img
                src={MC.BOOK_SUCCESS_VISUAL.urlImmagine}
                alt={MC.BOOK_SUCCESS_VISUAL.SottoTitolo}
                title={MC.BOOK_SUCCESS_VISUAL.SottoTitolo}
              />
            </div>
            <div className="logo">
              <img
                src="/img/logo_big.svg"
                alt="Logo EqueCure"
              />
            </div>
          </Box>

          <Box mx={2}>
            <p className="playFair normalWeight textCenter bookstep-start-label">
              Rispondi al questionario
            </p>
            <p className="normalWeight textCenter">
              Per darti il migliore servizio abbiamo bisogno di sapere il grado
              di autonomia di
            </p>
          </Box>

          <div
            className="row"
            style={{ float: 'left', marginLeft: '10px' }}>
            <div className="col1-icon">
              <PersonOutlineIcon />
            </div>
            <div className="col2-icon">
              <h3 className="textCenter patient-name">{pname}</h3>
            </div>
          </div>

          <div style={{ clear: 'both' }}></div>

          {this.state.barthelLoaded && !this.state.saving && (
            <Fragment>
              <Box mx={2}>
                <div>{questions}</div>

                {this.state.answers['_update'] && (
                  <p className="normalWeight textCenter mt-20">
                    Ultimo aggiornamento: {this.state.answers['_update'].label}
                  </p>
                )}
              </Box>

              <Grid
                container
                mt={0}
                mb={0}>
                <Grid
                  item
                  xs={6}
                  sx={{ textAlign: 'left' }}>
                  <Button
                    className="lnkButton lnkBack"
                    startIcon={<ArrowBackIcon />}
                    onClick={() => this.cancelBarthel()}>
                    Annulla
                  </Button>
                </Grid>
                <Grid
                  item
                  sx={{ textAlign: 'right' }}
                  xs={6}
                  pr={3}>
                  {canSaveBarthel && (
                    <a
                      className="buttonLink lnkForward"
                      onClick={() => this.saveBarthel()}>
                      SALVA
                    </a>
                  )}
                </Grid>
              </Grid>
            </Fragment>
          )}

          {!this.state.barthelLoaded && (
            <Box my={4}>
              <EQLoading message="Caricamento questionario" />
            </Box>
          )}

          {this.state.saving && (
            <Box my={4}>
              <EQLoading message="Salvataggio in corso" />
            </Box>
          )}
        </div>
      )
    }
  }
}

export default withRouter(EQBookStep_Success)
