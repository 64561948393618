/* eslint-disable react/no-direct-mutation-state */
import { Box, Grid } from '@mui/material'
import React, { Fragment } from 'react'

import EQLoading from '../EQLoading'
import EQService from '../../services/EQService'
import EQServiceBook from '../../services/EQServiceBook'
import EQSquareCheckbox from '../core/EQSquareCheckbox'
import EQState from '../../services/EQState'
import ErrorIcon from '@mui/icons-material/Error'
import Pages from '../../services/Pages'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import StripeConf from '../../libs/StripeConf'
import { withRouter } from 'react-router-dom'

class EQBookStep_RetryPayment extends React.Component {
  constructor(pars) {
    super(pars)
    this.state = {
      bookingStatus: 'payment',
      priceToPay: 0,
      recoveringSession: true,
      setupComplete: false,
      error: false,
    }
  }

  componentDidMount() {
    const page = Pages.current()
    document.title = page.title
    window.scrollTo(0, 0)

    let tk = EQService.getForcedSessionId()
    if (tk) {
      console.log('Checking tk...', tk)
      EQService.getSessione(tk).then(() => {
        EQServiceBook.getPrenotazioneInAttesaDiPagamento().then((p) => {
          console.log('Checking prenotazione pendente', p)
          if (p) {
            EQState.change(EQState.Booking, {
              BookingResult: p,
            })
            this.setState({
              recoveringSession: false,
            })
            this.book()
          } else {
            document.location.href = '/'
          }
        })
      })
    } else if (!EQState.Booking) {
      document.location.href = '/'
      return
    } else {
      this.state.recoveringSession = false
      this.book()
    }
  }

  book() {
    // Preparazione transazione su stripe
    let stripePars = StripeConf.create()
    EQServiceBook.generaTokenStripe(stripePars).then((res) => {
      try {
        const stripeSecret = res
        const options = {
          clientSecret: stripeSecret,
        }
        this.state.stripe = window.Stripe(StripeConf.pubkey)
        this.state.elements = this.state.stripe.elements(options)
        const paymentElement = this.state.elements.create('payment')
        paymentElement.mount('#payment-element')
        setTimeout(() => {
          this.setState({
            setupComplete: true,
          })
        }, 2000)
      } catch (e) {
        console.error('error_on_stripe', e)
        this.setState({
          bookingStatus: 'error_on_stripe',
        })
      }
    })
  }

  sendDataToStripe() {
    // Trasmissione dati transazione a Stripe
    let stripePars = StripeConf.create()
    let additionalPars = ''
    let tk = EQService.getForcedSessionId()
    if (tk) additionalPars = '?tk=' + tk
    this.state.stripe
      .confirmSetup({
        elements: this.state.elements,
        confirmParams: {
          return_url: stripePars.successEndpoint + additionalPars,
        },
      })
      .then((result) => {
        console.info('result from confirmSetup', result)
        if (result.error) {
          console.error('error_on_stripe', result.error)
          this.setState({
            bookingStatus: 'error_redirecting_stripe',
            errorMessage: '(' + result.error.message + ')',
          })
        }
      })
  }

  checkCondizioni(e) {
    this.setState({
      condizioniOk: e.target.checked,
    })
  }

  checkCondizioniPrestatori(e) {
    this.setState({
      condizioniPrestatoriOk: e.target.checked,
    })
  }

  render() {
    if (!EQState.Booking) return null
    if (this.state.recoveringSession) return null

    let booking = EQState.Booking.BookingResult
    let pname = booking.paziente.nome + ' ' + booking.paziente.cognome

    let errorMessage = ''
    let error = false
    if (this.state.bookingStatus.indexOf('error') === 0) {
      error = true
      errorMessage = this.state.errorMessage
      if (this.state.bookingStatus === 'error_on_stripe') {
        errorMessage = '(errore connessione con Stripe)'
      } else if (this.state.bookingStatus === 'error_redirecting_stripe') {
        // already set
      } else if (this.state.bookingStatus === 'error_confirming') {
        errorMessage = '(errore conferma prenotazione)'
      }
    }

    return (
      <Box mt={12}>
        <p className="playFair normalWeight textCenter bookstep-start-label">
          Prenotazione <b>{booking.codicePratica}</b> per
        </p>
        <div className="row">
          <div className="col1-icon">
            <PersonOutlineIcon />
          </div>
          <div className="col2-icon">
            <h3 className="textCenter patient-name">{pname}</h3>
          </div>
        </div>

        {error && (
          <Box
            className="boxCeleste needlesButtons"
            mt={2}
            mb={4}
            sx={{ textAlign: 'center', color: 'red' }}>
            <div style={{ marginTop: '40px', marginBottom: '40px' }}>
              <ErrorIcon className="error-icon" />
            </div>
            Si è verificato un errore tecnico.
            <br />
            {errorMessage}
            <br />
            Contatta il supporto di EQUECURE
            <br />
          </Box>
        )}

        {!error && (
          <Fragment>
            <Box
              className="boxBianco needlesButtons"
              mt={2}
              mb={4}>
              <form id="payment-form">
                <div id="payment-element"></div>
              </form>
            </Box>

            <div className="boxCondizioni">
              <Box className="squarecheckbox-on-white">
                <EQSquareCheckbox
                  id={'chCart_CondizioniEqueCure'}
                  onChange={(e) => this.checkCondizioni(e)}
                />
                <label htmlFor={'chCart_CondizioniEqueCure'}>
                  Accetto le{' '}
                  <a
                    href="/condizioni"
                    target="_blank">
                    condizioni d'uso EqueCure
                  </a>
                </label>
              </Box>
              <Box className="squarecheckbox-on-white">
                <EQSquareCheckbox
                  id={'chCart_CondizioniPrestatori'}
                  onChange={(e) => this.checkCondizioniPrestatori(e)}
                />
                <label htmlFor={'chCart_CondizioniPrestatori'}>
                  Accetto le{' '}
                  <a
                    href="/condizioni"
                    target="_blank">
                    condizioni Prestatori di servizio
                  </a>
                </label>
              </Box>
            </div>

            {this.state.setupComplete && (
              <Grid
                className="book-navigation shrink"
                container
                mt={0}
                mb={0}>
                <Grid
                  item
                  sx={{ textAlign: 'center', paddingTop: '12px' }}
                  xs={12}>
                  <div style={{ marginRight: '20px', marginLeft: '20px' }}>
                    <button
                      id="submit"
                      type="button"
                      className="buttonLink lnkForward bg-green text-white"
                      style={{
                        borderColor: 'transparent',
                        fontSize: '14px',
                        fontFamily: 'Raleway',
                        display: 'block',
                        width: '100%',
                      }}
                      disabled={
                        !this.state.condizioniOk ||
                        !this.state.condizioniPrestatoriOk
                      }
                      onClick={() => this.sendDataToStripe()}>
                      PROCEDI
                    </button>
                  </div>
                  <p
                    className="textCenter"
                    style={{
                      margin: '16px 10px 0 10px',
                      color: '#48d700',
                      fontWeight: 'bold',
                    }}>
                    Non addebiteremo niente finchè non inizieremo il servizio.
                    Potrai disdire la prenotazione.
                  </p>
                  <p
                    className="textCenter"
                    style={{
                      margin: '10px 20px 0 20px',
                      color: '#b8b8b8',
                      fontSize: '12px',
                    }}>
                    I servizi sono erogati da cooperative, società e liberi
                    professionisti.
                  </p>
                </Grid>
              </Grid>
            )}
            {!this.state.setupComplete && (
              <div
                className="textCenter"
                style={{ marginBottom: '20px', marginTop: '20px' }}>
                <EQLoading message=" " />
              </div>
            )}
          </Fragment>
        )}
      </Box>
    )
  }
}

export default withRouter(EQBookStep_RetryPayment)
