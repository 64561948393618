import { Box, Button, Grid } from '@mui/material'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import EQBusinessRules from '../libs/EQBusinessRules'
import EQService from '../services/EQService'
import EQServiceHelpModal from './EQServiceHelpModal'
import EQServicesList from './EQServicesList'
import EQState from '../services/EQState'
import React from 'react'
import { withRouter } from 'react-router-dom'

class EQServiceGroupList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      gruppi: null,
      introText: '',
      UserSelection: EQState.UserSelection,
    }
    this.goForward = this.goForward.bind(this)
  }

  reloadState() {
    this.setState({
      UserSelection: EQState.UserSelection,
    })
  }

  componentDidMount() {
    EQState.addListener(EQState.UserSelection, this)
    if (
      EQBusinessRules.isEmergenza(this.state.UserSelection.idTipologiaServizio)
    ) {
      EQService.getGruppiServizioEmergenza().then((m) => {
        this.setState({
          gruppi: m,
          introText: EQState.Contents.HOME.INTROTEXT100.Contenuto,
        })
        const autoOpenService = m.length === 1
        if (autoOpenService) {
          this.openServices(m[0], true)
        }
      })
    } else {
      EQService.getGruppiServizio(
        this.state.UserSelection.idTipologiaServizio
      ).then((m) => {
        let tempIntroText = EQState.Contents.HOME.INTROTEXT1.Contenuto
        if (this.state.UserSelection.idTipologiaServizio === 2) {
          tempIntroText = EQState.Contents.HOME.INTROTEXT2.Contenuto
        }
        this.setState({
          gruppi: m,
          introText: tempIntroText,
        })
        const autoOpenService = m.length === 1
        if (autoOpenService) {
          this.openServices(m[0], true)
        }
      })
    }
  }

  componentWillUnmount() {
    EQState.removeListener(EQState.UserSelection, this)
  }

  setStateAndRunCallback = (val) => {
    EQState.change(EQState.UserSelection, { showGroupServices: false })
    this.props.history.push({
      pathname: `/services`,
    })
  }

  openHelp(gruppo) {
    const helpText = gruppo.servizios[0].testoHelp
    this.setState({ helpCode: helpText })
  }

  closeHelp() {
    this.setState({ helpCode: null })
  }

  goForward(gruppoServizio) {
    EQState.change(EQState.UserSelection, { GruppoServizio: gruppoServizio })
    this.props.history.push({
      pathname: `/book/person`,
    })
  }

  openServices(gruppoServizio, autoOpened) {
    EQState.change(EQState.UserSelection, {
      GruppoServizio: gruppoServizio,
      showServices: true,
      autoOpened: autoOpened,
    })
  }

  childStateCallback = (childState) => {
    this.setState({
      showServices: childState.showServices,
    })
  }

  getBgCode() {
    if (EQState.UserSelection.idTipologiaServizio == 100) {
      return 'EMERGENZA'
    } else if (EQState.UserSelection.idTipologiaServizio == 2) {
      return 'FAMILIARE'
    } else {
      return 'DOMICILIARE'
    }
  }

  render() {
    if (!this.state.gruppi) {
      return null
    }

    return (
      <Box className="needlesButtons">
        {this.state.UserSelection.showServices ? (
          <EQServicesList
            toCallBack={(childState) =>
              this.childStateCallback(childState)
            }></EQServicesList>
        ) : (
          <div>
            <div
              className="playFair afterLogo"
              dangerouslySetInnerHTML={{ __html: this.state.introText }}></div>
            <p
              className="playFair"
              style={{ marginTop: '16px' }}>
              Stai cercando:
            </p>
            {this.state.gruppi &&
              this.state.gruppi.map((gruppo) => (
                <Grid
                  container
                  key={gruppo.idGruppoServizio}>
                  <Grid
                    mt={1}
                    item
                    xs={10}>
                    <button
                      step={gruppo.idGruppoServizio}
                      onClick={() => this.openServices(gruppo)}
                      className={`btnText bg${this.getBgCode()}`}>
                      {gruppo.nome}
                    </button>
                  </Grid>
                  <Grid
                    mt={1}
                    item
                    xs={2}
                    className="flex-col-center items-center">
                    <button
                      onClick={() => this.openHelp(gruppo)}
                      className={`fab bg${this.getBgCode()} `}>
                      ?
                    </button>
                  </Grid>
                </Grid>
              ))}

            <Box mt={2}></Box>
            <Grid
              container
              className="book-navigation enlarge"
              mt={0}
              mb={0}>
              <Grid
                item
                xs={6}
                style={{ textAlign: 'left' }}>
                <Button
                  className="lnkButton lnkBack"
                  startIcon={<ArrowBackIcon />}
                  onClick={() =>
                    this.setStateAndRunCallback({ showServices: false })
                  }>
                  Indietro
                </Button>
              </Grid>

              {/*                            <Grid item sx={{textAlign: 'right'}} xs={6}>
                                <a className={`buttonLink lnkForward disabled button-${this.getBgCode()}`}>AVANTI</a>
                            </Grid>*/}
            </Grid>
          </div>
        )}

        {this.state.helpCode && (
          <EQServiceHelpModal
            onClose={this.closeHelp.bind(this)}
            code={this.state.helpCode}
            color={this.getBgCode()}></EQServiceHelpModal>
        )}
      </Box>
    )
  }
}

export default withRouter(EQServiceGroupList)
