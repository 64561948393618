import React from 'react'
import { default as UUID } from 'node-uuid'
import TextField from '@mui/material/TextField'

export default class EQTextField extends React.Component {
  constructor(props) {
    super(props)
    this.id = 'eq-select-' + UUID.v4()
  }

  render() {
    let ip = { readOnly: this.props.readOnly, maxLength: this.props.maxLength }
    if (this.props.InputProps) ip = this.props.InputProps
    return (
      <TextField
        id={this.id}
        {...this.props}
        InputLabelProps={{ shrink: true }}
        InputProps={ip}>
        {this.props.children}
      </TextField>
    )
  }
}
