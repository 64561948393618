import React from 'react'
import EQCareGiverSlider from './EQCaregiverSlider'
import Carousel from 'react-material-ui-carousel'
import EQService from '../services/EQService'
import { Box, Typography } from '@mui/material'
import { ArrowBack, ArrowForward } from '@mui/icons-material'

export default class EQCareGiversSlider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      careGivers: [],
    }
  }

  componentDidMount() {
    EQService.getProfessionistiInHome().then((res) => {
      const shuffled = [...res].sort(() => 0.5 - Math.random());
      const careGivers = shuffled.slice(0,Math.min(8,shuffled.length)); // <===== qui se vogliamo cambiare il numero di card visualizzate
      this.setState({ careGivers: careGivers });
    })
  }

  render() {
    return (
      <Box mt={3}>
        <Typography variant="h4">
          Scopri alcuni dei nostri professionisti.
        </Typography>
        {this.state.careGivers.length > 0 && (
          <Carousel
            className="carousel favCaregivers"
            animation="slide"
            cycleNavigation={false}
            autoPlay={false}
            navButtonsAlwaysVisible={true}
            NextIcon={<ArrowForward />}
            navButtonsProps={{
              className: 'navButtons',
              style: {
                backgroundColor: 'transparent',
                color: '#444',
                borderRadius: 0,
              },
            }}
            PrevIcon={<ArrowBack />}>
            {this.state.careGivers.map((careGiver) => (
              <EQCareGiverSlider
                careGiver={careGiver}
                key={careGiver.idProfessionista}
              />
            ))}
          </Carousel>
        )}
      </Box>
    )
  }
}
