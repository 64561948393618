import React from 'react'
import { Box, Grid, Modal } from '@mui/material'
import EQServiceCaregiver from '../../services/EQServiceCaregiver'
import Utils from '../../libs/Utils'
import EQState from '../../services/EQState'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  width: '80%',
  bgcolor: '#ffe371',
  border: '0',
  boxShadow: 24,
  p: 2,
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
}

export default class EQCaregiver_DeleteHoliday extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  closeModal() {
    if (this.props.onDone) this.props.onDone()
  }

  cancelOperation() {
    if (this.props.onCancel) this.props.onCancel()
  }

  actuallyDoOperation() {
    //console.log("idCalendario",this.props.selectedEvent.idCalendario);
    EQServiceCaregiver.cancellaRichiestaFerie(
      this.props.selectedEvent.idCalendario
    )
      .then((y) => {
        EQServiceCaregiver.getAppuntamento(
          this.props.selectedEvent.idCalendario
        ).then((x) => {
          Utils.refill(this.props.selectedEvent, x.data)
          this.closeModal()
          // Refresh della pagina
          setTimeout(() => {
            EQState.change(EQState.CaregiverHome, {})
          }, 500)
        })
      })
      .finally((x) => {
        // In caso di errore ricarichiamo l'evento a calendario
        EQServiceCaregiver.getAppuntamento(
          this.props.selectedEvent.idCalendario
        ).then((x) => {
          Utils.refill(this.props.selectedEvent, x.data)
          this.closeModal()
          // Refresh della pagina
          setTimeout(() => {
            EQState.change(EQState.CaregiverHome, {})
          }, 500)
        })
      })
  }

  render() {
    //console.log("selectedEvent",this.props.selectedEvent);
    return (
      <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box
            mt={0}
            p={0}
            pt={2}
            pb={2}>
            <div className="textCenter playFair app-text modal-title">
              <b>Sei sicuro di voler cancellare l'indisponibilità?</b>
            </div>

            <Grid
              container
              mt={0}
              mb={2}>
              <Grid
                item
                sx={{ textAlign: 'left' }}
                xs={6}>
                <a
                  className="buttonLink bg-red lnkForward"
                  onClick={() => this.cancelOperation()}>
                  ANNULLA
                </a>
              </Grid>
              <Grid
                item
                sx={{ textAlign: 'right' }}
                xs={6}>
                <a
                  className="buttonLink bg-green lnkForward"
                  onClick={() => this.actuallyDoOperation()}>
                  SI, CANCELLA
                </a>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    )
  }
}
