import { Box, Modal } from '@mui/material'

import EQServiceCaregiver from '../../services/EQServiceCaregiver'
import React from 'react'
import Utils from '../../libs/Utils'

export default class EQInLateModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = { stage: 'delay' }
    if (props.isCall) {
      this.state.stage = 'call'
    }
  }

  closeModal() {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  decideOperation(type) {
    if (type === 'call') {
      console.log('📞 calling callcenter ...')
      this.callCenter()
    } else {
      console.log('⏰ delay applied ...')
      this.confirmInLate(30)
    }
  }

  confirmInLate(minuti) {
    EQServiceCaregiver.dichiaraRitardo(this.props.selectedEvent, minuti).then(
      (x) => {
        EQServiceCaregiver.getAppuntamento(
          this.props.selectedEvent.idCalendario
        ).then((x) => {
          Utils.refill(this.props.selectedEvent, x.data)
          this.closeModal()
        })
      }
    )
  }

  callCenter() {
    EQServiceCaregiver.dichiaraRitardo(this.props.selectedEvent, 60).then(
      (x) => {
        EQServiceCaregiver.getAppuntamento(
          this.props.selectedEvent.idCalendario
        ).then((x) => {
          Utils.refill(this.props.selectedEvent, x.data)
          this.closeModal()
        })
      }
    )
    window.open('tel:' + process.env.REACT_APP_PHONENUMBER, '_self')
  }

  render() {
    let e = this.props.selectedEvent

    return (
      <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box className="modal-are-you-sure">
          <Box
            mt={0}
            p={2}
            className="flex-col-center">
            <p className="textCenter playFair app-text modal-title text-white">
              <b>Sei sicuro?</b>
            </p>
            <div className="flex-row-center">
              <button
                className={'button-red'}
                onClick={() => this.decideOperation(this.state.stage)}>
                SI
              </button>
              <button
                className={'button-green'}
                onClick={() => this.closeModal()}>
                NO
              </button>
            </div>
          </Box>
        </Box>
      </Modal>
    )
  }
}
