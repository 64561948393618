import React, { Fragment } from 'react'

import { Box } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import EQCalendarStates from '../../libs/EQCalendarStates'
import EQState from '../../services/EQState'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Formats from '../../libs/Formats'
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges'
import TimeIcon from '@mui/icons-material/HistoryToggleOff'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'

export default class EQNextEventAlert extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: true,
    }
  }

  componentDidMount() {
    const chatContainerElement = document.getElementById('chat-container')

    if (chatContainerElement) {
      chatContainerElement.style.bottom = '95px'
    }
  }

  openDrawer() {
    this.setState({ open: !this.state.open })
    const chatContainerElement = document.getElementById('chat-container')

    if (chatContainerElement) {
      chatContainerElement.style.bottom = this.state.open ? '15px' : '95px'
    }
  }

  showEvent(evt) {
    EQState.change(EQState.CaregiverHome, {
      _selectedEvent: evt,
    })
  }

  render() {
    let mode = 'user'
    if (this.props.mode) mode = this.props.mode

    const evt = this.props.selectedEvent
    if (evt === null) return null

    const estate = evt.tipoOccupazioneCalendario.idTipoOccupazione

    if (
      estate === EQCalendarStates.ServizioInAttesaDiPagamento ||
      estate === EQCalendarStates.ServizioRifiutatoDalProfessionista ||
      estate === EQCalendarStates.RichiestaFerie ||
      estate === EQCalendarStates.FerieConfermate
    ) {
      return null
    }

    if (mode === 'user') {
      if (
        estate === EQCalendarStates.ServizioInAttesaDiConfermaProfessionista ||
        estate === EQCalendarStates.ServizioInAstaAttesaDiConfermaProfessionista
      ) {
        return null
      }
    }

    let ritardo = evt.ritardoDichiarato
    if (!ritardo) ritardo = 0
    // ritardo=30; // TEST TODO ///////////////////////////////

    let css = 'footer-banner logged-user-alert alert-' + estate
    let title = ''
    let isNext = false
    let time = ''
    let timeLegend = ''
    let firstDate = Formats.fromServerDate(evt.dal)
    if (estate === EQCalendarStates.ServizioInAttesaDiConfermaProfessionista) {
      //let limitDate=Formats.fromServerDate(evt.dal);
      title = 'In attesa di conferma'
    }
    if (estate === EQCalendarStates.ServizioConfermatoDalProfessionista) {
      if (new Date() > firstDate) {
        time = Formats.formatDateSpanShort(firstDate, new Date())
        css = css + ' running-event'
        timeLegend = 'Durata'
      } else {
        time = Formats.formatDateSpanShort(new Date(), firstDate)
        title = 'Appuntamento imminente'
        css = css + ' next-event'
        timeLegend = 'Inizia'
        isNext = true /////////////////////////////
      }
      if (ritardo > 0) css = css + ' in-late'
    }

    return (
      <Fragment>
        <Box
          className={css}
          onClick={() => this.showEvent(evt)}
          style={
            this.state.open ? { height: 'fit-content' } : { height: '0%' }
          }>
          <div className="flex-row-center">
            <div
              style={{
                borderRadius: '30px',
                width: 'fit-content',
                zIndex: '1000',
              }}
              onClick={() => this.openDrawer()}>
              {this.state.open ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </div>
          </div>
          <div>
            <div
              className="title Raleway"
              style={{ paddingBottom: '5px' }}>
              {title}
            </div>
            <div className="boxes">
              {time && (
                <div className="box-information">
                  <div
                    className="info flex-row-center"
                    style={{ fontSize: '0.8em' }}>
                    {time}
                  </div>
                  <div className="legend uppercase">{timeLegend}</div>
                </div>
              )}
              <div className="box-information">
                <div className="info">
                  {evt.servizioPrenotazione.prenotazione.parolaOrdine}
                </div>
                <div className="legend uppercase">Password</div>
              </div>

              {estate ===
                EQCalendarStates.ServizioInAttesaDiConfermaProfessionista && (
                <div className="state box-information">
                  <div className="info text-black sand">
                    <TimeIcon />
                  </div>
                  <div className="legend  uppercase text-black">
                    Da confermare
                  </div>
                </div>
              )}
              {estate === EQCalendarStates.ServizioIniziato && (
                <div className="state box-information sand">
                  <div className="info text-black sand">
                    <PublishedWithChangesIcon />
                  </div>
                  <div className="legend uppercase text-black">In corso</div>
                </div>
              )}
              {estate ===
                EQCalendarStates.ServizioConfermatoDalProfessionista &&
                ritardo !== 0 && (
                  <div className="state box-information">
                    <div className="info sand text-black">
                      <WarningAmberIcon />
                    </div>
                    <div className="legend uppercase  text-black">Ritardo</div>
                  </div>
                )}
              {estate ===
                EQCalendarStates.ServizioConfermatoDalProfessionista &&
                ritardo === 0 && (
                  <div className="state box-information bg-green">
                    <div className="info text-black ">
                      <EventAvailableIcon />
                    </div>
                    <div className="legend uppercase text-black">
                      Confermato
                    </div>
                  </div>
                )}
              {estate === EQCalendarStates.ServizioFinito && (
                <div className="state box-information green">
                  <div className="info green">
                    <CheckCircleOutlineIcon />
                  </div>
                  <div className="legend uppercase">Terminato</div>
                </div>
              )}
            </div>
          </div>
        </Box>
      </Fragment>
    )
  }
}
