//import Formats from './Formats'

export default class EQCalendarStates {
  static ServizioInAttesaDiPagamento = 1
  static ServizioConfermatoDalProfessionista = 2
  static ServizioInAttesaDiConfermaProfessionista = 5
  static ServizioInAstaAttesaDiConfermaProfessionista = 9
  static ServizioRifiutatoDalProfessionista = 6
  static ServizioIniziato = 7
  static ServizioFinito = 8
  static RichiestaFerie = 3
  static FerieConfermate = 4
  static ServizioConfermatoDaAltroProfessionista = 102
  static CancellatoDalSistema = 103
  static CancellatoDaAdmin = 105
  static CancellatoDalCliente = 101
  static IndisponibilitaContrattuale = 1000

  static _labels = {
    [EQCalendarStates.ServizioInAttesaDiPagamento]:
      'Appuntamento in attesa di pagamento',
    [EQCalendarStates.ServizioConfermatoDalProfessionista]:
      'Appuntamento accettato',
    [EQCalendarStates.ServizioInAttesaDiConfermaProfessionista]:
      'Appuntamento da confermare',
    [EQCalendarStates.ServizioInAstaAttesaDiConfermaProfessionista]:
      'Appuntamento da confermare',
    [EQCalendarStates.ServizioRifiutatoDalProfessionista]:
      'Appuntamento rifiutato',
    [EQCalendarStates.ServizioIniziato]: 'Appuntamento in corso',
    [EQCalendarStates.ServizioFinito]: 'Appuntamento concluso',
    [EQCalendarStates.RichiestaFerie]: 'Richiesta ferie e permessi',
    [EQCalendarStates.FerieConfermate]: 'Ferie e permessi',
    [EQCalendarStates.ServizioConfermatoDaAltroProfessionista]:
      'Appuntamento annullato',
    [EQCalendarStates.CancellatoDalSistema]: 'Appuntamento annullato',
    [EQCalendarStates.IndisponibilitaContrattuale]:
      'Indisponibilità contrattuale',
    [EQCalendarStates.CancellatoDaAdmin]: 'Appuntamento annullato',
    [EQCalendarStates.CancellatoDalCliente]: 'Appuntamento annullato',
  }

  static _getState(e) {
    let s = e
    if (e.tipoOccupazioneCalendario) {
      s = e.tipoOccupazioneCalendario.idTipoOccupazione
    }
    return s
  }

  static canStateBeCancelled(e) {
    let s = EQCalendarStates._getState(e)
    return (
      s === EQCalendarStates.ServizioInAttesaDiConfermaProfessionista ||
      s === EQCalendarStates.ServizioInAstaAttesaDiConfermaProfessionista ||
      s === EQCalendarStates.ServizioConfermatoDalProfessionista
    )
  }

  static isToBeConfirmed(e) {
    let s = EQCalendarStates._getState(e)
    return (
      s === EQCalendarStates.ServizioInAttesaDiConfermaProfessionista ||
      s === EQCalendarStates.ServizioInAstaAttesaDiConfermaProfessionista
    )
  }

  static isHoliday(e) {
    let s = EQCalendarStates._getState(e)
    return (
      s === EQCalendarStates.RichiestaFerie ||
      s === EQCalendarStates.FerieConfermate ||
      s === EQCalendarStates.IndisponibilitaContrattuale
    )
  }

  static isWaitingPayment(e) {
    let s = EQCalendarStates._getState(e)
    return s === EQCalendarStates.ServizioInAttesaDiPagamento
  }

  static isRefused(e) {
    let s = EQCalendarStates._getState(e)
    return s === EQCalendarStates.ServizioRifiutatoDalProfessionista
  }

  static isAcceptedByOther(e) {
    let s = EQCalendarStates._getState(e)
    return s === EQCalendarStates.ServizioConfermatoDaAltroProfessionista
  }

  static isAcceptOutdated(e) {
    let s = EQCalendarStates._getState(e)
    if (s !== EQCalendarStates.ServizioInAttesaDiConfermaProfessionista)
      return false

    /*
        let canAccept=false;
        let dataLimite=e.dataLimiteConfermaProfessionista;
        if(dataLimite) {
            let limitDate=Formats.fromServerDate(dataLimite);
            let lp=Formats.formatDateSpan(new Date(),limitDate);
            if(lp!=null) {
                canAccept=true;
            }
        }
        */
    let canAccept = true
    return !canAccept
  }

  static isLinkedToASingleCaregiver(e) {
    if (!e.servizioPrenotazione.isAsta) return true
    let s = EQCalendarStates._getState(e)
    if (s !== EQCalendarStates.ServizioInAstaAttesaDiConfermaProfessionista)
      return true
    return false
  }

  static labelForEvent(e) {
    let res =
      EQCalendarStates._labels[e.tipoOccupazioneCalendario.idTipoOccupazione]
    if (!res) return e.tipoOccupazioneCalendario.nome
    else return res
  }
}
