import React, { Fragment } from 'react'
import { Box, Typography } from '@mui/material'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import FavoriteIcon from '@mui/icons-material/Favorite'
import EQService from '../services/EQService'
import EQState from '../services/EQState'
import EQLoginModal from './user/EQLoginModal'

export default class EQCareGiverSlider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showLogin: false,
    }
  }

  reloadState() {
    this.setState({
      UserSelection: EQState.UserSelection,
    })
  }

  componentDidMount() {
    EQState.addListener(EQState.UserSelection, this)
  }

  componentWillUnmount() {
    EQState.removeListener(EQState.UserSelection, this)
  }

  closeLogin() {
    this.setState({ showLogin: null })
  }

  togglePreferred(flag) {
    if (EQService.isLogged()) {
      if (flag) {
        EQService.setProfessionistaPreferito(this.props.careGiver).then(
          (pps) => {
            EQState.change(EQState.UserSelection, {
              PreferredCaregivers: pps,
              PreferredCaregivers_Map: pps.map((x) => x.idProfessionista),
            })
          }
        )
      } else {
        EQService.unsetProfessionistaPreferito(this.props.careGiver).then(
          (pps) => {
            EQState.change(EQState.UserSelection, {
              PreferredCaregivers: pps,
              PreferredCaregivers_Map: pps.map((x) => x.idProfessionista),
            })
          }
        )
      }
    } else {
      this.setState({ showLogin: true })
    }
  }

  render() {
    const { careGiver } = this.props
    let preferito = false

    if (
      EQState.UserSelection.PreferredCaregivers_Map &&
      EQState.UserSelection.PreferredCaregivers_Map.indexOf(
        careGiver.idProfessionista
      ) >= 0
    )
      preferito = true

    return (
      <div className="caregiver-box">
        <Typography variant="h3">{careGiver.nome}</Typography>
        <Typography mb={2}>{careGiver.tipoProfessionista.nome}</Typography>
        <div className="imgContainer">
          <Fragment>
            <button
              onClick={() => this.togglePreferred(!preferito)}
              className="heart">
              {preferito ? <FavoriteIcon /> : <FavoriteBorderIcon />}
            </button>
          </Fragment>
          <Box px={6}>
            <img
              className="circularImg"
              alt={careGiver.nome}
              src={careGiver.urlImmagine + '&crop=1&width=225&height=225'}
            />
          </Box>
        </div>
        <div
          className="bioContainer"
          dangerouslySetInnerHTML={{ __html: careGiver.schedaBio }}></div>
        {this.state.showLogin && (
          <EQLoginModal
            onClose={this.closeLogin.bind(this)}
            goToAfterLogin="book"></EQLoginModal>
        )}
      </div>
    )
  }
}
