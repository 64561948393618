/* eslint-disable react/no-direct-mutation-state */
import { FormControl, Grid, Link } from '@mui/material'

import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone'
import { Box } from '@mui/system'
import CF from '../../libs/CF'
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone'
import EQConfirmDialog from '../core/EQConfirmDialog'
import EQFaqBox from '../EQFaqBox'
import EQFloatingFormIcon from '../core/EQFloatingFormIcon'
import EQFormGroup from '../core/EQFormGroup'
import EQSelect from '../core/EQSelect'
import EQServiceBook from '../../services/EQServiceBook'
import EQTextField from '../core/EQTextField'
import EQToast from '../core/EQToast'
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone'
import Formats from '../../libs/Formats'
import HeightTwoToneIcon from '@mui/icons-material/HeightTwoTone'
import { MenuItem } from 'material-ui'
import { MuiThemeProvider } from 'material-ui/styles'
import PersonAddAltTwoToneIcon from '@mui/icons-material/PersonAddAltTwoTone'
import PersonOutlineIcon from '@mui/icons-material/PersonOutlineTwoTone'
import React from 'react'
import ScaleTwoToneIcon from '@mui/icons-material/ScaleTwoTone'
import SubtitlesIcon from '@mui/icons-material/CallToActionTwoTone'
import TodayIcon from '@mui/icons-material/CalendarMonthTwoTone'
import Utils from '../../libs/Utils'

export default class EQMyPersons extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      assistiti: null,
      helpCode: null,
    }
  }

  componentDidMount() {
    this.listPazienti()
  }

  listPazienti() {
    EQServiceBook.listPazienti().then((l) => {
      let nonElimiati = []
      l.forEach((x) => {
        if (!x.eliminatoDaCliente) nonElimiati.push(x)
      })
      this.setState({
        assistiti: nonElimiati,
      })
    })
  }

  openPerson(a) {
    this.setState({
      assistitoSelezionato: a,
    })
  }

  openList() {
    this.setState({
      assistitoSelezionato: null,
    })
  }

  dataChanged(what, e) {
    this.state.assistitoSelezionato.Changed = true
    if (what === 'height')
      this.state.assistitoSelezionato.altezzaInCM = e.target.value
    else if (what === 'weight')
      this.state.assistitoSelezionato.pesoInKG = e.target.value
    else if (what === 'cf') {
      this.state.assistitoSelezionato.codiceFiscale = e.target.value
      if (CF.validate(this.state.assistitoSelezionato.codiceFiscale)) {
        let bd = CF.birthDateFrom(this.state.assistitoSelezionato.codiceFiscale)
        let dd = new Date(bd.year, bd.month + 1, bd.day)
        this.state.assistitoSelezionato.dataNascita = dd
        this.state.birthDateHidden = true
        setTimeout(() => {
          this.setState({
            birthDateHidden: false,
          })
        }, 0)
      }
    } else if (what === 'name')
      this.state.assistitoSelezionato.nome = e.target.value
    else if (what === 'surname')
      this.state.assistitoSelezionato.cognome = e.target.value
    else if (what === 'bday') {
      let dd = new Date(this.state.assistitoSelezionato.dataNascita)
      if (!dd) dd = new Date()
      dd.setDate(e.target.value)
      this.state.assistitoSelezionato.dataNascita = dd
    } else if (what === 'bmon') {
      let dd = new Date(this.state.assistitoSelezionato.dataNascita)
      if (!dd) dd = new Date()
      dd.setMonth(e.target.value)
      this.state.assistitoSelezionato.dataNascita = dd
    } else if (what === 'byear') {
      let dd = new Date(this.state.assistitoSelezionato.dataNascita)
      if (!dd) dd = new Date()
      dd.setFullYear(e.target.value)
      this.state.assistitoSelezionato.dataNascita = dd
    }
    this.forceUpdate()
  }

  dataOk() {
    if (!this.state.assistitoSelezionato.nome) return false
    if (!this.state.assistitoSelezionato.cognome) return false
    if (!this.state.assistitoSelezionato.codiceFiscale) return false
    if (!this.validateCF()) return false
    return true
  }

  validateCF() {
    return CF.validate(this.state.assistitoSelezionato.codiceFiscale)
  }

  cfHelperText() {
    if (!this.state.assistitoSelezionato.codiceFiscale) {
      return 'Informazione necessaria'
    }
    if (!CF.validate(this.state.assistitoSelezionato.codiceFiscale)) {
      return 'Codice fiscale non valido'
    }
    return null
  }

  genericValidate(key) {
    if (!this.state.assistitoSelezionato[key]) return false
    if (this.state.assistitoSelezionato[key].length === 0) return false
    return true
  }

  genericHelperText(key, errorHelp) {
    if (!this.state.assistitoSelezionato[key]) return null // all'inizio nessun messaggio di errore
    if (this.state.assistitoSelezionato[key].length === 0) return errorHelp
    return null
  }

  validateBirthYear() {
    try {
      let dd = new Date(this.state.assistitoSelezionato.dataNascita)
      let bd = CF.birthDateFrom(this.state.assistitoSelezionato.codiceFiscale)
      if (dd.getFullYear() !== bd.getFullYear()) return false
      return true
    } catch (e) {
      return false
    }
  }

  yearHelperText() {
    if (!this.validateBirthYear()) return 'Invalido'
    return ''
  }

  buildOptions(min, max) {
    var arr = []
    for (let i = min; i <= max; i++) {
      arr.push(
        <MenuItem
          key={i}
          value={i}>
          {i}
        </MenuItem>
      )
    }
    return arr
  }

  openEdit() {
    // copia usata per eventuale annulla
    const copied = Object.assign({}, this.state.assistitoSelezionato)
    this.setState({
      editOpen: true,
      original_assistitoSelezionato: copied,
    })
  }

  cancelEdit() {
    // eslint-disable-next-line
    this.state.editOpen = false
    // eslint-disable-next-line
    this.state.inTransition = true
    this.forceUpdate()

    if (
      this.state.original_assistitoSelezionato &&
      this.state.original_assistitoSelezionato.idPaziente
    ) {
      // eslint-disable-next-line
      Utils.refill(
        this.state.assistitoSelezionato,
        this.state.original_assistitoSelezionato
      )
    } else {
      // eslint-disable-next-line
      this.state.assistitoSelezionato = null
    }
    window.setTimeout(() => {
      // eslint-disable-next-line
      this.state.inTransition = false
      this.forceUpdate()
    }, 0)
  }

  openHelp(helpCode) {
    this.setState({ helpCode: helpCode })
  }

  closeHelp() {
    this.setState({ helpCode: null })
  }

  saveData() {
    if (!this.dataOk()) return
    EQServiceBook.editPaziente(this.state.assistitoSelezionato)
      .then((p) => {
        if (this.state.assistitoSelezionato.idPaziente === '0')
          this.state.assistiti.push(p)
        this.state.assistitoSelezionato = p
        this.setState({
          editOpen: false,
        })
      })
      .catch((e) => {
        console.error('Errore nel salvataggio dati', e)
        this.state.inTransition = false
        EQToast.open(
          this,
          {
            errorMessage: 'Errore (codice fiscale duplicato?)',
          },
          {
            errorMessage: null,
          }
        )
      })
  }

  newPerson() {
    // eslint-disable-next-line
    this.state.original_assistitoSelezionato = null
    this.state.assistitoSelezionato = null
    window.setTimeout(() => {
      // eslint-disable-next-line
      this.state.editOpen = true
      this.setState({
        assistitoSelezionato: {
          Changed: true,
          idPaziente: '0',
          nome: '',
          cognome: '',
          dataNascita: new Date(),
          pesoInKG: 50,
          altezzaInCM: 130,
          codiceFiscale: '',
          haAnimali: false,
          fumatore: false,
        },
      })
    }, 100)
  }

  deletePerson(assistito) {
    this.setState({
      delete_open: true,
      personToDelete: assistito,
    })
  }

  actuallyDeletePerson(confirmed) {
    if (!confirmed) {
      this.setState({
        delete_open: false,
        personToDelete: null,
      })
      return
    }
    EQServiceBook.deletePaziente(this.state.personToDelete.idPaziente).then(
      (p) => {
        this.setState({
          delete_open: false,
          personToDelete: null,
        })
        this.listPazienti()
      }
    )
  }

  render() {
    let ro = true
    if (this.state.editOpen) ro = false

    const MESI = Formats.monthNames()
    let defaultBDay = 0
    let defaultBMon = 0
    let defaultBMonName = ''
    let defaultBYear = 0
    if (
      this.state.assistitoSelezionato &&
      this.state.assistitoSelezionato.codiceFiscale
    ) {
      let bd = CF.birthDateFrom(this.state.assistitoSelezionato.codiceFiscale)
      let dd = new Date(bd.year, bd.month, bd.day)
      if (dd && dd.getDate) {
        defaultBDay = dd.getDate()
        defaultBMon = dd.getMonth()
        defaultBMonName = MESI[defaultBMon]
        defaultBYear = dd.getFullYear()
      }
    }

    let defaultW = 30
    let defaultH = 1
    if (this.state.assistitoSelezionato) {
      defaultW = this.state.assistitoSelezionato.pesoInKG
      defaultH = this.state.assistitoSelezionato.altezzaInCM
    }

    return (
      <div className="description">
        {!this.state.assistitoSelezionato && (
          <span>Gestisci le schede degli assistiti</span>
        )}
        {this.state.assistitoSelezionato && (
          <span>
            Scheda dell'assistito <br />
            <b>
              {this.state.assistitoSelezionato.nome}{' '}
              {this.state.assistitoSelezionato.cognome}
            </b>
          </span>
        )}

        {this.state.delete_open && (
          <EQConfirmDialog
            className="yellow_confirm_dialog"
            message="Cancellare la scheda?"
            open={true}
            onClose={(x) => this.actuallyDeletePerson(x)}
          />
        )}

        <Box
          px={1}
          py={1}
          pt={2}>
          {this.state.assistitoSelezionato && !this.state.inTransition && (
            <MuiThemeProvider>
              <Box className="boxBianco">
                <EQFloatingFormIcon>
                  <PersonOutlineIcon />
                </EQFloatingFormIcon>
                <Box
                  pb={2}
                  pl={2}>
                  <FormControl fullWidth>
                    <EQTextField
                      defaultValue={this.state.assistitoSelezionato.nome}
                      error={!this.genericValidate('nome')}
                      helperText={this.genericHelperText(
                        'nome',
                        'Informazione necessaria'
                      )}
                      label="Nome"
                      onChange={(e) => this.dataChanged('name', e)}
                      disabled={ro}></EQTextField>
                  </FormControl>
                </Box>
                <Box
                  pb={2}
                  pl={2}>
                  <FormControl fullWidth>
                    <EQTextField
                      defaultValue={this.state.assistitoSelezionato.cognome}
                      error={!this.genericValidate('cognome')}
                      helperText={this.genericHelperText(
                        'cognome',
                        'Informazione necessaria'
                      )}
                      label="Cognome"
                      onChange={(e) => this.dataChanged('surname', e)}
                      disabled={ro}></EQTextField>
                  </FormControl>
                </Box>
                <EQFloatingFormIcon>
                  <SubtitlesIcon />
                </EQFloatingFormIcon>
                <Box
                  pb={2}
                  pl={2}>
                  <FormControl fullWidth>
                    <EQTextField
                      defaultValue={
                        this.state.assistitoSelezionato.codiceFiscale
                      }
                      error={!this.validateCF()}
                      helperText={this.cfHelperText()}
                      inputProps={{ style: { textTransform: 'uppercase' } }}
                      label="Codice Fiscale"
                      onChange={(e) => this.dataChanged('cf', e)}
                      disabled={ro}></EQTextField>
                  </FormControl>
                </Box>
                <EQFloatingFormIcon>
                  <TodayIcon />
                </EQFloatingFormIcon>
                <Box
                  pb={2}
                  pl={2}>
                  <EQFormGroup label="Data di nascita">
                    {!this.validateCF() || this.state.birthDateHidden ? (
                      <div className="group-contents">Non definito</div>
                    ) : (
                      <div className="group-contents">
                        {defaultBDay} {defaultBMonName} {defaultBYear}
                      </div>
                    )}
                  </EQFormGroup>
                </Box>
                <EQFloatingFormIcon>
                  <ScaleTwoToneIcon />
                </EQFloatingFormIcon>
                <Box pl={2}>
                  <Grid container>
                    <Grid
                      xs={4}
                      pr={2}
                      item>
                      <FormControl fullWidth>
                        <EQSelect
                          defaultValue={defaultW}
                          label="Peso"
                          onChange={(e) => this.dataChanged('weight', e)}
                          disabled={ro}>
                          <MenuItem value=""></MenuItem>
                          {this.buildOptions(30, 150)}
                        </EQSelect>
                      </FormControl>
                    </Grid>
                    <Grid
                      xs={1}
                      style={{ paddingTop: '12px' }}
                      item>
                      <HeightTwoToneIcon />
                    </Grid>
                    <Grid
                      xs={4}
                      pr={2}
                      item>
                      <FormControl fullWidth>
                        <EQSelect
                          defaultValue={defaultH}
                          label="Altezza"
                          onChange={(e) => this.dataChanged('height', e)}
                          disabled={ro}>
                          <MenuItem value=""></MenuItem>
                          {this.buildOptions(130, 210)}
                        </EQSelect>
                      </FormControl>
                    </Grid>
                    <Grid
                      xs={2}
                      item
                      pt={0}>
                      <button
                        onClick={() => this.openHelp('HELP_ALTEZZA_PESO')}
                        className={`fab bgNero`}>
                        ?
                      </button>
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  pl={2}
                  pb={3}
                  pt={2}>
                  <div className="small-note">
                    Puoi controllare{' '}
                    <a
                      href="/privacy"
                      target="_blank">
                      qui
                    </a>{' '}
                    l'informativa sulla privacy
                  </div>
                </Box>
                {!this.state.editOpen && (
                  <div style={{ marginTop: '20px', textAlign: 'center' }}>
                    <Link
                      onClick={() => this.openEdit()}
                      className="playFair linkblack app-text">
                      Modifica scheda
                    </Link>
                    <Link
                      onClick={() => this.openList()}
                      className="playFair linkblack app-text"
                      ml={2}>
                      Chiudi scheda
                    </Link>
                  </div>
                )}
                {this.state.editOpen && (
                  <div style={{ marginTop: '20px', textAlign: 'center' }}>
                    <Link
                      onClick={() => this.cancelEdit()}
                      className="playFair linkblack app-text">
                      Annulla
                    </Link>
                    <Link
                      onClick={() => this.saveData()}
                      className="playFair linkblack app-text"
                      ml={2}>
                      Salva scheda
                    </Link>
                  </div>
                )}
              </Box>
            </MuiThemeProvider>
          )}

          {this.state.assistiti && !this.state.assistitoSelezionato && (
            <MuiThemeProvider>
              {this.state.assistiti.map((assistito) => {
                return (
                  <Box
                    className="boxBianco"
                    style={{ borderBottom: '1px solid black' }}
                    key={assistito.idPaziente}>
                    <EQFloatingFormIcon>
                      <PersonOutlineIcon />
                    </EQFloatingFormIcon>
                    <Box
                      pb={2}
                      pl={2}>
                      <Grid container>
                        <Grid
                          item
                          xs={8}>
                          <FormControl fullWidth>
                            <EQTextField
                              label="Nome"
                              defaultValue={
                                assistito.cognome + ' ' + assistito.nome
                              }
                              disabled={true}></EQTextField>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          style={{ textAlign: 'right' }}
                          onClick={() => this.openPerson(assistito)}>
                          <EditTwoToneIcon className="large-icon" />
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          style={{ textAlign: 'right' }}>
                          <CancelTwoToneIcon
                            className="large-icon red-icon"
                            onClick={() => this.deletePerson(assistito)}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                )
              })}
              <Grid
                container
                px={1}>
                <Grid
                  item
                  xs={2}
                  style={{ textAlign: 'left' }}>
                  <EQFloatingFormIcon>
                    <PersonAddAltTwoToneIcon style={{ marginLeft: '14px' }} />
                  </EQFloatingFormIcon>
                </Grid>
                <Grid
                  item
                  xs={8}
                  pl={2}
                  pt={1}
                  className="textCenter">
                  <Link
                    onClick={() => this.newPerson()}
                    className="playFair linkblack app-text">
                    Aggiungi scheda
                  </Link>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ textAlign: 'right' }}>
                  <div>
                    <AddCircleTwoToneIcon className="large-icon green-icon" />
                  </div>
                </Grid>
              </Grid>
            </MuiThemeProvider>
          )}
        </Box>

        <Box>
          <EQFaqBox category="MieiAssistiti"></EQFaqBox>
        </Box>
      </div>
    )
  }
}
