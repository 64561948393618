import React from 'react'

export default class EQServiceReport_View extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    //const e = this.props.selectedEvent
    return (
      <div>
        <div
          dangerouslySetInnerHTML={{
            __html:
              this.props.additionalInfos[this.props.infoType].additionalInfo,
          }}></div>
      </div>
    )
  }
}
