import React, { Fragment } from 'react'
import { Box, Modal, Typography } from '@mui/material'
import CancelIcon from '@mui/icons-material/Close'
import EQFloatingFormIcon from './EQFloatingFormIcon'
import SchoolIcon from '@mui/icons-material/School'
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism'
import ForumIcon from '@mui/icons-material/Forum'
import SmokeFreeIcon from '@mui/icons-material/SmokeFree'
import SmokingRoomsIcon from '@mui/icons-material/SmokingRooms'
import PetsIcon from '@mui/icons-material/Pets'
import PaletteIcon from '@mui/icons-material/Palette'

const style = {
  position: 'absolute',
  maxWidth: '470px',
  width: '95%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,

  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
}

export default class EQCaregiverModal extends React.Component {
  constructor(props) {
    super(props)
  }

  closeModal() {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  render() {
    console.log(this.props.caregiver)
    return (
      <div className="flex-row-center">
        <Modal
          open={true}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Box
            sx={style}
            className="helpModal caregiver-info pb-10 border-full-lg">
            <button
              onClick={() => this.closeModal()}
              className="iconClose clickable">
              <CancelIcon></CancelIcon>
              <br />
              CHIUDI
            </button>
            <div
              className="info-box"
              style={{
                paddingLeft: '6px',
                paddingRight: '6px',
                paddingBottom: '5px',
              }}>
              <Typography
                className="playFair"
                id="modal-modal-title"
                variant="h5"
                component="h2"
                pt={2}
                pb={2}>
                L'esperienza
              </Typography>
              <Box
                pl={2}
                pr={2}
                pb={2}
                sx={{ overflow: 'auto', maxHeight: '20em' }}>
                <Box>
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: this.props.caregiver.schedaBio,
                    }}
                    sx={{ my: 2 }}></Typography>
                </Box>
                <EQFloatingFormIcon>
                  <SchoolIcon />
                </EQFloatingFormIcon>
                <Box
                  pb={0}
                  pl={4}>
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: this.props.caregiver.schedaCorsi,
                    }}
                    sx={{ my: 2 }}></Typography>
                </Box>
                <EQFloatingFormIcon>
                  <VolunteerActivismIcon />
                </EQFloatingFormIcon>
                <Box
                  pb={0}
                  pl={4}>
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: this.props.caregiver.schedaCosaPiace,
                    }}
                    sx={{ my: 2 }}></Typography>
                </Box>
                <EQFloatingFormIcon>
                  <PaletteIcon />
                </EQFloatingFormIcon>
                <Box
                  pb={0}
                  pl={4}>
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: this.props.caregiver.schedaHobbies,
                    }}
                    sx={{ my: 2 }}></Typography>
                </Box>
                <EQFloatingFormIcon>
                  <ForumIcon />
                </EQFloatingFormIcon>
                <Box
                  pb={0}
                  pl={4}>
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: this.props.caregiver.schedaLingue,
                    }}
                    sx={{ my: 2 }}></Typography>
                </Box>
                {this.props.caregiver.dispoFumatori ? (
                  <Fragment>
                    <EQFloatingFormIcon>
                      <SmokingRoomsIcon />
                    </EQFloatingFormIcon>
                    <Box
                      pb={0}
                      pl={4}>
                      <Typography sx={{ my: 2 }}>Anche fumatori</Typography>
                    </Box>
                  </Fragment>
                ) : (
                  <Fragment>
                    <EQFloatingFormIcon>
                      <SmokeFreeIcon />
                    </EQFloatingFormIcon>
                    <Box
                      pb={0}
                      pl={4}>
                      <Typography sx={{ my: 2 }}>
                        Preferisce <b>non</b> fumatori
                      </Typography>
                    </Box>
                  </Fragment>
                )}
                {this.props.caregiver.dispoAnimali ? (
                  <Fragment>
                    <EQFloatingFormIcon>
                      <PetsIcon />
                    </EQFloatingFormIcon>
                    <Box
                      pb={0}
                      pl={4}>
                      <Typography sx={{ my: 2 }}>Ama gli animali</Typography>
                    </Box>
                  </Fragment>
                ) : (
                  <Fragment>
                    <EQFloatingFormIcon>
                      <PetsIcon />
                    </EQFloatingFormIcon>
                    <Box
                      pb={0}
                      pl={4}>
                      <Typography sx={{ my: 2 }}>
                        Preferisce assistiti <b>senza</b> animali
                      </Typography>
                    </Box>
                  </Fragment>
                )}
              </Box>
              <Box sx={{ textAlign: 'center' }}>
                <a
                  className="linkunderlined"
                  onClick={() => this.closeModal()}>
                  Chiudi Finestra
                </a>
              </Box>
            </div>
          </Box>
        </Modal>
      </div>
    )
  }
}
