import React from 'react'
import { Box, Grid } from '@mui/material'
import EQService from '../services/EQService'
import EQHelpModal from './EQHelpModal'
import EQServiceGroupList from './EQServiceGroupList'
import EQState from '../services/EQState'
import EQBusinessRules from '../libs/EQBusinessRules'
import Utils from '../libs/Utils'
import './EQNeedles.scss'

export default class EQNeedles extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tipologieServizio: null,
      introText: '',
      UserSelection: EQState.UserSelection,
    }
  }

  reloadState() {
    this.setState({ UserSelection: EQState.UserSelection })
  }

  componentDidMount() {
    EQState.addListener(EQState.UserSelection, this)
    EQService.mapTipologieServizio().then((m) => {
      this.setState({
        tipologieServizio: m,
        introText: EQState.Contents.HOME.INTROTEXT.Contenuto,
      })
    })
  }

  componentWillUnmount() {
    EQState.removeListener(EQState.UserSelection, this)
  }

  openHelp(helpCode, col) {
    this.setState({
      helpCode: helpCode,
      modalColor: col,
    })
  }

  openGroupServices(idTipologia, url) {
    try {
      window.gtag('event', 'clickServizio', {
        value: url,
      })
    } catch (error) {}

    EQState.change(
      EQState.UserSelection,
      {
        idTipologiaServizio: idTipologia,
        showGroupServices: true,
      },
      null,
      true
    )
    window.location.href = '/services/' + idTipologia + '-' + url
  }

  childStateCallback = (childState) => {
    this.setState({
      showGroupServices: childState.showGroupServices,
    })
  }

  closeHelp() {
    this.setState({ helpCode: null })
  }

  render() {
    const MC = EQState.Contents.HOME
    return (
      <div>
        {this.state.UserSelection.showGroupServices ? (
          <div>
            <EQServiceGroupList
              toCallBack={(childState) =>
                this.childStateCallback(childState)
              }></EQServiceGroupList>
          </div>
        ) : (
          <div>
            {!EQService.isLogged() && (
              <div
                className="playFair afterLogo"
                dangerouslySetInnerHTML={{
                  __html: this.state.introText,
                }}></div>
            )}
            <Box
              className="needlesButtons"
              mt={2}
              mb={4}>
              {!EQService.isLogged() && (
                <p
                  className="playFair afterLogo"
                  style={{ marginBottom: '10px', marginTop: '10px' }}>
                  Hai bisogno di:
                </p>
              )}
              <Grid container>
                <Grid
                  item
                  xs={10}>
                  <a
                    onClick={() =>
                      this.openGroupServices(
                        100,
                        Utils.urlify(MC.SERVIZIO1.SottoTitolo)
                      )
                    }
                    href={
                      '/services/100-' + Utils.urlify(MC.SERVIZIO1.SottoTitolo)
                    }
                    className="aButton btnText bgEMERGENZA">
                    {MC.SERVIZIO1.SottoTitolo}
                  </a>
                </Grid>
                <Grid
                  item
                  xs={2}>
                  <button
                    onClick={() =>
                      this.openHelp('HP_HELP_SERVIZIO1', 'EMERGENZA')
                    }
                    className="fab bgEMERGENZA">
                    ?
                  </button>
                </Grid>
              </Grid>

              {this.state.tipologieServizio &&
                this.state.tipologieServizio.map((tipologiaServizio) => {
                  let disabled = false
                  if (EQBusinessRules.isServiceGroupDisabled(tipologiaServizio))
                    disabled = true
                  return (
                    <Grid
                      container
                      key={tipologiaServizio.idTipologiaServizio}>
                      <Grid
                        mt={1}
                        item
                        xs={10}>
                        <a
                          disabled={disabled}
                          href={
                            '/services/' +
                            tipologiaServizio.idTipologiaServizio +
                            '-' +
                            Utils.urlify(tipologiaServizio.nome)
                          }
                          onClick={() =>
                            this.openGroupServices(
                              tipologiaServizio.idTipologiaServizio,
                              Utils.urlify(tipologiaServizio.nome)
                            )
                          }
                          className={
                            `aButton btnText bg${tipologiaServizio.codice}` +
                            (disabled ? ' service-not-used' : '')
                          }>
                          {tipologiaServizio.nome}
                        </a>
                      </Grid>
                      <Grid
                        mt={1}
                        item
                        xs={2}
                        className="flex-col-center items-center">
                        <button
                          disabled={disabled}
                          onClick={() =>
                            this.openHelp(
                              tipologiaServizio.testoHelp,
                              tipologiaServizio.codice
                            )
                          }
                          className={
                            `fab bg${tipologiaServizio.codice}` +
                            (disabled ? ' service-not-used' : '')
                          }>
                          ?
                        </button>
                      </Grid>
                    </Grid>
                  )
                })}
            </Box>
          </div>
        )}
        {this.state.helpCode && (
          <EQHelpModal
            onClose={this.closeHelp.bind(this)}
            code={this.state.helpCode}
            color={this.state.modalColor}></EQHelpModal>
        )}
      </div>
    )
  }
}
