import React from 'react'

export default class AnimalNotAllowedIcon extends React.Component {
  render() {
    return (
      <svg
        className="animal-not-allowed"
        viewBox="1.32 1.199 22.275 21.646"
        width="22.275"
        height="21.646">
        <path
          fill="red"
          d="M 2.135 2.609 L 3.543 1.199 L 23.595 21.424 L 22.197 22.845 L 19.995 20.625"></path>
        <path
          d="M 18.849 21.135 L 18.482 21.66 L 18.421 21.718 C 16.513 22.601 14.028 20.263 11.906 20.263 C 9.784 20.263 7.503 22.17 5.628 21.211 C 4.562 20.682 3.826 19.281 3.965 18.119 C 4.155 16.511 6.065 15.649 7.193 14.475 C 7.983 13.687 8.654 12.523 9.412 11.586 L 9.456 11.535 M 8.133 2.08 C 9.39 1.897 10.721 3.286 11.108 5.205 C 11.298 6.121 11.234 6.993 10.956 7.673 L 6.725 3.371 C 7.003 2.672 7.493 2.186 8.133 2.08 M 15.754 2.08 C 17.021 2.285 17.727 3.987 17.353 5.895 C 16.949 7.813 15.616 9.204 14.357 9.011 C 13.089 8.816 12.395 7.112 12.791 5.205 C 13.174 3.286 14.506 1.897 15.754 2.08 M 2.429 7.038 C 3.645 6.508 5.297 7.469 6.161 9.14 C 6.971 10.843 6.692 12.631 5.489 13.159 C 4.285 13.687 2.643 12.739 1.8 11.047 C 0.958 9.355 1.257 7.555 2.429 7.038 M 21.616 7.038 C 22.789 7.555 23.088 9.355 22.245 11.047 C 21.403 12.739 19.761 13.687 18.557 13.159 C 17.353 12.631 17.075 10.843 17.886 9.14 C 18.749 7.469 20.402 6.508 21.616 7.038 Z"
          stroke="black"></path>
      </svg>
    )
  }
}
