import './EQHomeModal.scss'

import { Box, Modal } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import EQService from '../services/EQService'
import EQState from '../services/EQState'
import React from 'react'

export default class EQHomeModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      content: null,
      visible: false,
    }
  }

  componentDidMount() {
    console.log('MODALE_HOME is opening')
    EQService.getContent('MODALE_HOME').then((m) => {
      if (m['OnLine']) {
        console.log(
          'MODALE_HOME is not empty and not hidden therefore is visible',
          m
        )
        const key = 'homePageModalHidden'
        if (!EQState.SessionInfo[key]) {
          this.setState({
            visible: true,
            content: m,
          })
        } else {
          console.log(
            'MODALE_HOME is not empty and not hidden therefore is visible... but the user closed it'
          )
        }
      }
    })
  }

  closeModal() {
    // let pi=EQState.SessionInfo;
    EQState.change(EQState.SessionInfo, {
      homePageModalHidden: true,
    })
    EQState.persist(EQState.SessionInfo, window.sessionStorage)
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  render() {
    console.log('render', this.state)
    if (!this.state.content) return null
    //const { SottoTitolo, Contenuto } = this.state.content
    return (
      <Modal
        open={this.state.visible}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box className="home-modal helpModal">
          <button
            onClick={() => this.closeModal()}
            className="iconClose clickable">
            <Box>
              <CloseIcon></CloseIcon>
            </Box>
            <div>CHIUDI</div>
          </button>
          <Box
            mt={2}
            sx={{ textAlign: 'center' }}>
            <img
              src="/logoapp_big.png"
              className=" h-50 "
              alt="logo"
              title="logo"
            />
          </Box>
          <Box
            pl={2}
            pr={2}
            pb={2}>
            {/*
            <Typography className='playFair' id='modal-modal-title' variant='h5' component='h2'>
              {SottoTitolo}
            </Typography>
            <Typography dangerouslySetInnerHTML={{ __html: Contenuto }} id='modal-modal-description'
                        sx={{ mt: 2, overflow: 'auto' }}></Typography>
            */}

            {this.state.content['Link'] && (
              <a href={this.state.content['Link']}>
                <img
                  src={this.state.content['urlImmagine']}
                  alt="immagine"
                />
              </a>
            )}
            {!this.state.content['Link'] && (
              <img
                src={this.state.content['urlImmagine']}
                alt="immagine"
              />
            )}

            <Box sx={{ textAlign: 'center' }}>
              <small>
                <a
                  className="linkunderlined"
                  onClick={() => this.closeModal()}>
                  Chiudi finestra
                </a>
              </small>
            </Box>
          </Box>
        </Box>
      </Modal>
    )
  }
}
