import { Checkbox } from '@mui/material'
import React, { Fragment } from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Circle } from '@mui/icons-material'

export default class EQCheckbox extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { ...others } = this.props
    return (
      <Fragment>
        <Checkbox
          sx={{ width: '50px', height: '50px' }}
          className="eq-checkbox"
          {...others}
          icon={<Circle className="not-checked" />}
          checkedIcon={
            <div
              style={{
                marginLeft: '2px',
                width: '26px',
                height: '26px',
                borderRadius: '15px',
                backgroundColor: 'black',
              }}>
              <CheckCircleIcon
                sx={{ margin: '-3px', maxWidth: '125%' }}
                className={
                  'checked' +
                  ` ${
                    this.props.typecode
                      ? this.props.typecode.toLowerCase()
                      : 'white'
                  }`
                }
              />
            </div>
          }></Checkbox>
      </Fragment>
    )
  }
}
