import { Button, Grid } from '@mui/material'
import React, { Fragment } from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Box from '@mui/material/Box'
import EQBusinessRules from '../../libs/EQBusinessRules'
import EQCheckbox from '../core/EQCheckbox'
import EQFaqBox from '../EQFaqBox'
import EQLoading from '../EQLoading'
import EQServiceBook from '../../services/EQServiceBook'
import EQState from '../../services/EQState'
import { withRouter } from 'react-router-dom'

class EQBookStep_Medical extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      allPatologies: null,
      UserSelection: EQState.UserSelection,
    }
    if (
      this.state.UserSelection &&
      this.state.UserSelection.SelectedRarentalRelation &&
      !this.state.UserSelection.Patologies
    ) {
      if (
        this.state.UserSelection.SelectedRarentalRelation.pazientePatologias
      ) {
        let pids =
          this.state.UserSelection.SelectedRarentalRelation.pazientePatologias.map(
            (p) => p.idPatologia
          )
        this.state.UserSelection.Patologies = pids
      } else {
        this.state.UserSelection.Patologies = []
      }
    }
  }

  componentDidMount() {
    if (
      !EQState.UserSelection ||
      !EQState.UserSelection.GruppoServizio ||
      !EQState.UserSelection.SelectedRarentalRelation
    ) {
      document.location.href = '/'
      return
    }

    window.scrollTo(0, 0)
    EQServiceBook.listPatologie().then((ps) => {
      this.setState({
        allPatologies: ps,
      })
    })
  }

  goBack() {
    EQState.change(EQState.UserSelection, {
      BookPage: `/book/person`,
    })
    this.props.history.push({
      pathname: `/book/person`,
    })
  }

  goForward() {
    // TODO: chiamare funzione lato server per azzerare e reimpostare le patologie
    EQState.change(EQState.UserSelection, {
      BookPage: `/book/location`,
    })
    this.props.history.push({
      pathname: `/book/location`,
    })
  }

  isPatologiaSelected(p) {
    return this.state.UserSelection.Patologies.indexOf(p.idPatologia) >= 0
  }

  changeMedical(e, p) {
    if (this.isPatologiaSelected(p)) {
      let idx = this.state.UserSelection.Patologies.indexOf(p.idPatologia)
      this.state.UserSelection.Patologies.splice(idx, 1)
    } else {
      this.state.UserSelection.Patologies.push(p.idPatologia)
    }
    this.forceUpdate()
  }

  getBgCode() {
    if (this.state.UserSelection.idTipologiaServizio == 100) {
      return 'EMERGENZA'
    } else if (this.state.UserSelection.idTipologiaServizio == 2) {
      return 'FAMILIARE'
    } else {
      return 'DOMICILIARE'
    }
  }

  render() {
    if (
      !EQState.UserSelection ||
      !EQState.UserSelection.GruppoServizio ||
      !EQState.UserSelection.SelectedRarentalRelation
    ) {
      return null
    }

    // let pname =
    //   EQState.UserSelection.SelectedRarentalRelation.nome +
    //   ' ' +
    //   EQState.UserSelection.SelectedRarentalRelation.cognome

    let cssBox = 'boxCeleste large-side-padding'
    if (
      EQBusinessRules.isEmergenza(EQState.UserSelection.idTipologiaServizio)
    ) {
      cssBox = 'boxCeleste large-side-padding EMERGENZA'
    }
    if (EQState.UserSelection.idTipologiaServizio === 2) {
      cssBox = 'boxCeleste large-side-padding FAMILIARE'
    }

    return (
      <Box
        mr={0}
        ml={0}>
        <Box
          mr={1}
          ml={1}>
          <p className="playFair normalWeight textCenter bookstep-start-label">
            Inserisci i dati salute
          </p>
          <p className="textCenter">
            Ogni dettaglio è importante.
            <br />
            Per piacere sii preciso.
          </p>
        </Box>

        {!this.state.allPatologies && (
          <EQLoading
            type="boxBianco"
            message="Caricamento dati..."
          />
        )}

        {this.state.allPatologies && (
          <Box className={cssBox}>
            <Grid container>
              {this.state.allPatologies.map((p) => (
                <Fragment key={p.idPatologia}>
                  <Grid
                    item
                    xs={2}
                    mt={1}>
                    <EQCheckbox
                      typecode={this.getBgCode()}
                      id={'' + p.idPatologia}
                      onChange={(e) => this.changeMedical(e, p)}
                      defaultChecked={this.isPatologiaSelected(p)}
                    />
                  </Grid>
                  <Grid
                    mt={1}
                    pt={2}
                    item
                    xs={10}
                    sx={{ verticalAlign: 'middle' }}>
                    <label
                      className="check-label"
                      htmlFor={p.idPatologia}>
                      {p.nome}
                    </label>
                  </Grid>
                </Fragment>
              ))}
            </Grid>
          </Box>
        )}

        <Grid
          className="book-navigation shrink"
          container
          mt={0}
          mb={0}>
          <Grid
            item
            xs={6}>
            <Button
              className="lnkButton lnkBack"
              startIcon={<ArrowBackIcon />}
              onClick={() => this.goBack()}>
              Indietro
            </Button>
          </Grid>
          <Grid
            item
            sx={{ textAlign: 'right' }}
            xs={6}>
            <a
              className={`buttonLink lnkForward  button-${this.getBgCode()}`}
              onClick={() => this.goForward()}>
              AVANTI
            </a>
          </Grid>
        </Grid>

        <Box>
          <EQFaqBox category="Patologie"></EQFaqBox>
        </Box>
      </Box>
    )
  }
}

export default withRouter(EQBookStep_Medical)
