import EQService from '../services/EQService'

export default class EQBusinessRules {
  static isEmergenza(idTipologiaServizio) {
    return idTipologiaServizio === '100'
  }

  static limiteGiorniPerEmergenza() {
    return 3 // 72 ore, vedi anche isWeekendAvailableForEmergenza
  }

  static limiteOrePerPrenotazione() {
    return 4 // la prenotazione deve essere almeno 4 ore prima della assistenza
  }

  static inizioNotturno() {
    return 22 // inizio ore notturne
  }

  static fineNotturno() {
    return 6 // fine ore notturne
  }

  static ultimoGiornoPerEmergenza() {
    let emergencyLimit = new Date(
      new Date().getTime() +
        EQBusinessRules.limiteGiorniPerEmergenza() * 24 * 60 * 60 * 1000
    )
    return new Date(
      emergencyLimit.getFullYear(),
      emergencyLimit.getMonth(),
      emergencyLimit.getDate(),
      emergencyLimit.getHours() + 1,
      0,
      0
    )
  }

  static isWeekendAvailableForEmergenza() {
    return new Date().getDay() >= 3
  }

  static durataMinimaServiziOre() {
    return 3
  }

  static primaDataPerPrenotazioneNormale() {
    return EQBusinessRules.ultimoGiornoPerEmergenza()
  }

  static primaDataPerPrenotazioneInEmergenza() {
    let start = new Date()
    start.setHours(
      start.getHours() + EQBusinessRules.limiteOrePerPrenotazione() + 1
    )
    return new Date(
      start.getFullYear(),
      start.getMonth(),
      start.getDate(),
      start.getHours(),
      0,
      0
    )
  }

  static ultimaDataPerPrenotazioneInEmergenza() {
    let end = EQBusinessRules.ultimoGiornoPerEmergenza()
    end.setHours(end.getHours() - EQBusinessRules.durataMinimaServiziOre())
    return new Date(
      end.getFullYear(),
      end.getMonth(),
      end.getDate(),
      end.getHours() - 1,
      0,
      0
    )
  }

  static isAPrestazione(gruppoServizio) {
    if (gruppoServizio.servizios.length === 1) {
      if (gruppoServizio.servizios[0].aPrestazione === true) {
        return true
      }
    }
    return false
  }

  static weekendsPackets() {
    return {
      packets: [
        {
          id: 1,
          firstLabel: '21 di ven > 21 di sab',
          code: '24',
          secondLabel: '',
          fromHours: -3,
          toHours: 21,
        },
        {
          id: 2,
          firstLabel: '21 di sab > 21 di dom',
          code: '24',
          secondLabel: '',
          fromHours: 21,
          toHours: 24 + 21,
        },
        {
          id: 3,
          firstLabel: '21 di sab > 08 di lun',
          code: '35',
          secondLabel: '',
          fromHours: 21,
          toHours: 24 + 24 + 8,
        },
        {
          id: 4,
          firstLabel: '21 di ven > 08 di dom',
          code: '35',
          secondLabel: '',
          fromHours: -3,
          toHours: 24 + 8,
        },
        {
          id: 5,
          firstLabel: '21 di ven > 21 di dom',
          code: '48',
          secondLabel: '',
          fromHours: -3,
          toHours: 24 + 21,
        },
      ],
    }
  }

  static packets_aPrestazione() {
    return {
      packets: [
        {
          id: 1,
          firstLabel: 'Dalle 08:00 alle 09:00',
          secondLabel: '',
          fromHours: 8,
          fromMinutes: 0,
          toHours: 9,
          toMinutes: 0,
          code: '',
        },
        {
          id: 2,
          firstLabel: 'Dalle 09:00 alle 10:00',
          secondLabel: '',
          fromHours: 9,
          fromMinutes: 0,
          toHours: 10,
          toMinutes: 0,
          code: '',
        },
        {
          id: 3,
          firstLabel: 'Dalle 10:00 alle 11:00',
          secondLabel: '',
          fromHours: 10,
          fromMinutes: 0,
          toHours: 11,
          toMinutes: 0,
          code: '',
        },
        {
          id: 4,
          firstLabel: 'Dalle 11:00 alle 12:00',
          secondLabel: '',
          fromHours: 11,
          fromMinutes: 0,
          toHours: 12,
          toMinutes: 0,
          code: '',
        },
        {
          id: 5,
          firstLabel: 'Dalle 12:00 alle 13:00',
          secondLabel: '',
          fromHours: 12,
          fromMinutes: 0,
          toHours: 13,
          toMinutes: 0,
          code: '',
        },
        {
          id: 6,
          firstLabel: 'Dalle 13:00 alle 14:00',
          secondLabel: '',
          fromHours: 13,
          fromMinutes: 0,
          toHours: 14,
          toMinutes: 0,
          code: '',
        },
        {
          id: 7,
          firstLabel: 'Dalle 14:00 alle 15:00',
          secondLabel: '',
          fromHours: 14,
          fromMinutes: 0,
          toHours: 15,
          toMinutes: 0,
          code: '',
        },
        {
          id: 8,
          firstLabel: 'Dalle 15:00 alle 16:00',
          secondLabel: '',
          fromHours: 15,
          fromMinutes: 0,
          toHours: 16,
          toMinutes: 0,
          code: '',
        },
        {
          id: 9,
          firstLabel: 'Dalle 16:00 alle 17:00',
          secondLabel: '',
          fromHours: 16,
          fromMinutes: 0,
          toHours: 17,
          toMinutes: 0,
          code: '',
        },
        {
          id: 10,
          firstLabel: 'Dalle 17:00 alle 18:00',
          secondLabel: '',
          fromHours: 17,
          fromMinutes: 0,
          toHours: 18,
          toMinutes: 0,
          code: '',
        },
        {
          id: 11,
          firstLabel: 'Dalle 18:00 alle 19:00',
          secondLabel: '',
          fromHours: 18,
          fromMinutes: 0,
          toHours: 19,
          toMinutes: 0,
          code: '',
        },
        {
          id: 12,
          firstLabel: 'Dalle 19:00 alle 20:00',
          secondLabel: '',
          fromHours: 19,
          fromMinutes: 0,
          toHours: 20,
          toMinutes: 0,
          code: '',
        },
      ],
    }
  }

  static barthel_questions() {
    return [
      {
        key: 'Alimentazione',
        options: [
          { label: 'autonomo/a', value: 10 },
          { label: 'con aiuto', value: 5 },
          { label: 'dipendente', value: 0 },
        ],
      },
      {
        key: 'Abbigliamento',
        options: [
          { label: 'autonomo/a', value: 10 },
          { label: 'con aiuto', value: 5 },
          { label: 'dipendente', value: 0 },
        ],
      },
      {
        key: 'Toilette personale',
        options: [
          { label: 'autonomo/a', value: 10 },
          { label: 'con aiuto', value: 5 },
          { label: 'dipendente', value: 0 },
        ],
      },
      {
        key: 'Fare il bagno',
        options: [
          { label: 'autonomo/a', value: 10 },
          { label: 'con aiuto', value: 5 },
          { label: 'dipendente', value: 0 },
        ],
      },
      {
        key: 'Continenza intestinale',
        options: [
          { label: 'autonomo/a', value: 10 },
          { label: 'con aiuto', value: 5 },
          { label: 'dipendente', value: 0 },
        ],
      },
      {
        key: 'Continenza urinaria',
        options: [
          { label: 'autonomo/a', value: 10 },
          { label: 'con aiuto', value: 5 },
          { label: 'dipendente', value: 0 },
        ],
      },
      {
        key: 'Uso dei servizi igienici',
        options: [
          { label: 'autonomo/a', value: 10 },
          { label: 'con aiuto', value: 5 },
          { label: 'dipendente', value: 0 },
        ],
      },
      {
        key: 'Trasferimento letto/sedia',
        options: [
          { label: 'autonomo/a', value: 10 },
          { label: 'con aiuto', value: 5 },
          { label: 'dipendente', value: 0 },
        ],
      },
      {
        key: 'Camminare in piano',
        options: [
          { label: 'autonomo/a', value: 10 },
          { label: 'con aiuto', value: 5 },
          { label: 'dipendente', value: 0 },
        ],
      },
      {
        key: 'Salire-Scendere le scale',
        options: [
          { label: 'autonomo/a', value: 10 },
          { label: 'con aiuto', value: 5 },
          { label: 'dipendente', value: 0 },
        ],
      },
    ]
  }

  static lunghezzaOtp() {
    return 5
  }

  static lunghezzaCoupon() {
    return 19
  }

  static isServiceGroupDisabled(service) {
    //inseisci nell'array disabledServices gli id dei servizi che non devono essere visibili se non in preprod e dev
    const disabledServices = []
    if (disabledServices.includes(service.idTipologiaServizio)) {
      if (
        EQService.isPreProduction() === false &&
        EQService.isDevelopment() === false
      ) {
        return true
      }
    }

    return false
  }

  static isServiceAdvisory(service) {
    if (service.idTipologiaServizio === 2) {
      return true
    }
    return false
  }

  static getTimeoutPrenotazione() {
    return 10 // minuti
  }

  static getCalendarBookDelta() {
    return 2 * 60 * 60 * 1000 // millisecondi di distanza tra gli appuntamenti in prenotazione
  }

  static getMonthsDxExtremeToLoad() {
    const from = new Date()
    const numberOfMonths = 3
    return new Date().setMonth(from.getMonth() + numberOfMonths) // 3 mesi
  }

  static maxFutureMonthsForBooking() {
    return 3 // possiamo prenotare fino a 3 mesi da oggi
  }
}
