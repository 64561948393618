import { format, utcToZonedTime } from 'date-fns-tz'

export default class Formats {
  static dayLetters() {
    return ['DO', 'LU', 'MA', 'ME', 'GI', 'VE', 'SA']
  }

  static dayNames() {
    return [
      'Domenica',
      'Lunedì',
      'Martedì',
      'Mercoledì',
      'Giovedì',
      'Venerdì',
      'Sabato',
    ]
  }

  static monthNames() {
    return [
      'Gennaio',
      'Febbraio',
      'Marzo',
      'Aprile',
      'Maggio',
      'Giugno',
      'Luglio',
      'Agosto',
      'Settembre',
      'Ottobre',
      'Novembre',
      'Dicembre',
    ]
  }

  static toServerDate(d) {
    const timeZone = 'Europe/Rome'
    const zonedDate = utcToZonedTime(d, timeZone)
    const pattern = 'yyyy-M-d HH:mm:ss'
    const output = format(zonedDate, pattern, { timeZone: 'Europe/Rome' })
    return output
  }

  static ensureDate(d) {
    if (d === null) return null
    if (typeof d === 'string') return new Date(d)
    else return d
  }

  static fromServerDate(s) {
    var b = s.split(/\D/)
    return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5])
  }

  static startOfDay(d) {
    return new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0, 0)
  }

  static dateToPresentation(d) {
    let day =
      d.getDate() +
      ' ' +
      Formats.monthNames()[d.getMonth()] +
      ' ' +
      d.getFullYear()
    return day
  }

  static dateToDayMonth(d) {
    let day = d.getDate() + ' ' + Formats.monthNames()[d.getMonth()]
    return day
  }

  static dateToDayMonthShort(d) {
    let day = d.getDate() + '/' + (d.getMonth() + 1)
    return day
  }

  static dateToHourMinutes(d) {
    let mm = '' + d.getMinutes()
    if (mm.length < 2) mm = '0' + mm
    let hhmm = d.getHours() + ':' + mm
    return hhmm
  }

  static formatter = new Intl.NumberFormat('it-IT', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  static formatPrice(v) {
    return Formats.formatter.format(v)
  }

  static formatQuantity(v) {
    return Formats.formatter.format(v)
  }

  static asDate(x) {
    if (typeof x === 'string') return new Date(x)
    else return x
  }

  static formatDateSpan(ds, de, mode) {
    let diffMinutes = (de.getTime() - ds.getTime()) / (1000 * 60)
    if (diffMinutes < 0) {
      return null
    } else if (diffMinutes > 60) {
      if (mode === 'accept') return "Hai ancora più di un'ora per accettare"
      else if (mode === 'next') return "Manca più di un'ora all'inizio"
      else if (mode === 'running') return "Tempo residuo: più di un'ora"
      else if (mode === 'outdate') return "Ritardo: più di un'ora"
    } else {
      if (diffMinutes < 1) {
        if (mode === 'accept') return 'Hai ancora pochi secondi per accettare'
        else if (mode === 'next') return "Mancano pochi secondi all'inizio"
        else if (mode === 'running') return 'Tempo residuo: pochi secondi'
        else if (mode === 'outdate') return 'Ritardo: pochi secondi'
      } else {
        diffMinutes = Math.floor(diffMinutes)
        if (diffMinutes === 1) {
          if (mode === 'accept') return 'Hai ancora un minuto per accettare'
          else if (mode === 'next') return "Manca un minuto all'inizio"
          else if (mode === 'running') return 'Tempo residuo: un minuto'
          else if (mode === 'outdate') return 'Ritardo: un minuto'
        }
        if (mode === 'accept')
          return 'Hai ancora ' + diffMinutes + ' minuti per accettare'
        else if (mode === 'next')
          return 'Mancano ' + diffMinutes + " minuti all'inizio"
        else if (mode === 'running')
          return 'Tempo residuo: ' + diffMinutes + ' minuti'
        else if (mode === 'outdate')
          return 'Ritardo: ' + diffMinutes + ' minuti'
      }
    }
  }

  static formatDateSpanShort(ds, de) {
    let diffMinutes = (de.getTime() - ds.getTime()) / (1000 * 60)
    if (diffMinutes < 0) {
      return null
    } else if (diffMinutes > 60) {
      return this.editTime(diffMinutes)
    }
  }

  static editTime(time) {
    console.log('🤠 -> time', time)
    if (time >= 60) {
      return (
        Math.floor(time / 60) +
        'h ' +
        (Math.floor(time % 60) === 0 ? '' : (Math.floor(time % 60) % 60) + 'm ')
      )
    } else {
      return time + 'm'
    }
  }
}
