/* eslint-disable react/no-direct-mutation-state */
import {
  FormControlLabel,
  FormGroup,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import React, { Fragment } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'

import Box from '@mui/material/Box'
import EQResetPassword from '../user/EQResetPassword'
import EQService from '../../services/EQService'
import EQServiceCaregiver from '../../services/EQServiceCaregiver'
import EQSquareCheckbox from '../core/EQSquareCheckbox'
import EQState from '../../services/EQState'
import EQTextField from '../core/EQTextField'
import { IconButton } from 'material-ui'
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone'
import Utils from '../../libs/Utils'
import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone'
import { withRouter } from 'react-router-dom'

class EQCaregiver_Login extends React.Component {
  constructor(props) {
    super(props)
    EQState.retrieve(EQState.CaregiverHome)
    this.state = {
      CaregiverHome: EQState.CaregiverHome,
      username: '',
      password: '',
      passwordShown: false,
      rememberMe: false,
      showResetPassword: false,
      sessionValidated: false,
    }
  }

  togglePassword() {
    this.setState({
      passwordShown: !this.state.passwordShown,
    })
  }

  toggleRememberMe() {
    this.setState({
      rememberMe: !this.state.rememberMe,
    })
  }

  updateInputValue(evt) {
    const val = evt.target.value
    const field = evt.target.id
    if (field === 'username') {
      this.setState({
        username: val,
      })
    } else {
      this.setState({
        password: val,
      })
    }
  }

  componentDidMount() {
    if (EQState.CaregiverHome._rememberMe || EQState.CaregiverHome.IsLogged) {
      this.alreadySignedIn()
    } else {
      this.state.sessionValidated = true
      this.resetLogin()
    }

    EQService.mapContents('HOME').then((m) => {
      EQState.change(EQState.Contents, {
        HOME: m,
      })
      this.forceUpdate()
    })
  }

  resetLogin() {
    EQState.CaregiverHome._idSessioneWeb = EQService.sessionId()
    EQState.CaregiverHome._loggedTime = new Date()
    EQState.CaregiverHome._rememberMe = false
    EQState.CaregiverHome._daConfermare = []
    this.state.rememberMe = false
    this.state.sessionValidated = true
    EQState.persist(EQState.CaregiverHome)
    this.forceUpdate()
  }

  alreadySignedIn() {
    console.warn(
      'Already signed in',
      EQState.CaregiverHome,
      EQState.UserSelection,
      EQState.Booking
    )
    EQServiceCaregiver.getProfessionista().then((prfs) => {
      if (!prfs) {
        this.resetLogin()
      } else {
        // verifichiamo se session è valida
        EQServiceCaregiver.checkSession()
          .then((ll) => {
            EQState.change(EQState.CaregiverHome, { Professionista: prfs.data })
            this.goForward()
          })
          .catch((e) => {
            console.warn('Sessione CareGiver non valida in Login')
            this.resetLogin()
          })
      }
    })
  }

  doLogin() {
    if (this.state.username === '' || this.state.password === '') {
      Utils.notifyError('Specificare username e password')
      return
    }

    EQService.creaSessione().then(() => {
      EQService.loginProfessionista(
        this.state.username,
        this.state.password
      ).then((m) => {
        if (m.statusString === 'KO') {
          Utils.notifyError('Login errato')
          return
        } else {
          EQServiceCaregiver.getProfessionista().then((prfs) => {
            if (!prfs) {
              this.resetLogin()
            } else {
              EQState.change(EQState.CaregiverHome, {
                Professionista: prfs.data,
              })
              this.goForward()
            }
          })
        }
      })
    })
  }

  goForward() {
    EQState.change(EQState.CaregiverHome, {
      IsLogged: true,
      _idSessioneWeb: EQService.sessionId(),
      _loggedTime: new Date(),
      _justLogged: true,
    })
    if (this.state.rememberMe) {
      EQState.CaregiverHome._rememberMe = true
    }
    EQState.persist(EQState.CaregiverHome)
    this.props.history.push({
      pathname: `/caregiver/counters`,
    })
  }

  sendCurriculum() {
    /* era https://docs.google.com/forms/d/e/1FAIpQLScYpfcH_7cAGIr8LlmXzK0cANn-e8TH3sWQf0AQD2WhLKcihg/viewform */
    try {
      window.gtag('event', 'clickCandidati', {})
    } catch (error) {}

    const win = window.open(`https://forms.gle/jk8MP4yJD2HLr1Xv6`, '_blank')
    win.focus()
  }

  showResetPassword() {
    this.setState({
      showResetPassword: true,
    })
  }

  closeResetPassword() {
    this.setState({
      showResetPassword: false,
    })
  }

  gotoUserLogin() {
    EQState.change(EQState.UserSelection, { UserLoginOpen: true })
    EQState.persist(EQState.UserSelection)
    document.location.href = '/services'
  }

  render() {
    if (!this.state.sessionValidated) return null

    let content
    if (!this.state.showResetPassword) {
      content = (
        <Fragment>
          <Box className="login-box font-size-13">
            <Box className="pb-10">
              <Typography
                px={3}
                mb={2}
                align="center"
                className="playFair"
                variant="h5"
                sx={{ fontSize: '15pt', color: '#fff' }}>
                Se sei un professionista, puoi
                <br />
                accedere alla tua area riservata.
              </Typography>
              <Typography
                px={3}
                align="center"
                className="playFair"
                variant="h5"
                sx={{ fontSize: '15pt', color: '#fff' }}>
                Chi sei?
              </Typography>
            </Box>
            <Box className="loginForm">
              <Box
                mt={3}
                className="flex-row justify-start items-center">
                <PersonOutlineTwoToneIcon style={{ marginRight: '5px' }} />
                <EQTextField
                  onChange={(evt) => this.updateInputValue(evt)}
                  fullWidth
                  id="username"
                  label="Username"
                  placeholder="Username"></EQTextField>
              </Box>
              <Box
                my={2}
                className="flex-row justify-start items-center">
                <VpnKeyTwoToneIcon style={{ marginRight: '5px' }} />
                <TextField
                  className="inputPassword"
                  onChange={(evt) => this.updateInputValue(evt)}
                  type={this.state.passwordShown ? 'text' : 'password'}
                  fullWidth
                  id="password"
                  label="Password"
                  placeholder="****"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => this.togglePassword()}
                          onMouseDown={() => this.togglePassword()}>
                          {this.state.passwordShown ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}></TextField>
              </Box>
              <Box
                sx={{ color: '#fff' }}
                className="ml-5 flex-row justify-around  items-center mb-10">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <EQSquareCheckbox
                        sx={{ '&.Mui-checked': { color: '#fff' } }}
                      />
                    }
                    label="Resta connesso"
                    onChange={() => this.toggleRememberMe()}
                  />
                </FormGroup>

                <a
                  className="lost-password pt-0"
                  onClick={() => this.showResetPassword()}>
                  Password dimenticata
                </a>
              </Box>
            </Box>
            <Box
              sx={{ textAlign: 'center' }}
              className="needlesButtons"
              mb={4}>
              <a
                className="buttonGreen text-white"
                onClick={() => this.doLogin()}>
                ACCEDI
              </a>
            </Box>
            <a
              onClick={() => this.gotoUserLogin()}
              className="text-white block mb-20 text-underline">
              Sei un cliente?
            </a>
          </Box>

          <Box className="boxBianco caregiver-new">
            <Box py={2}>
              <div className="textCenter playFair app-text">
                <b>Vuoi lavorare con noi?</b>
              </div>
            </Box>
            <Box
              sx={{ textAlign: 'center' }}
              className="needlesButtons"
              mb={4}
              px={2}>
              <a
                className="textCenter buttonBlue"
                onClick={() => this.sendCurriculum()}>
                CANDIDATI
              </a>
            </Box>
          </Box>
        </Fragment>
      )
    } else if (this.state.showResetPassword) {
      content = (
        <Box className="login-box">
          <EQResetPassword
            onBack={() => this.closeResetPassword()}
            showLinguetta={false}></EQResetPassword>
        </Box>
      )
    }

    return <Box style={{ marginTop: '70px' }}>{content}</Box>
  }
}

export default withRouter(EQCaregiver_Login)
