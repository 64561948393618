import {
  AccountCircleTwoTone,
  DialpadTwoTone,
  EmailTwoTone,
  LocalPhoneTwoTone,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material'
import { FormControl, InputAdornment } from '@mui/material'

import Box from '@mui/material/Box'
import EQFaqBox from '../EQFaqBox'
import EQFloatingFormIcon from '../core/EQFloatingFormIcon'
import EQService from '../../services/EQService'
import EQState from '../../services/EQState'
import EQTextField from '../core/EQTextField'
import { IconButton } from 'material-ui'
import { MuiThemeProvider } from 'material-ui/styles'
import PersonOutlineIcon from '@mui/icons-material/PersonOutlineTwoTone'
import React from 'react'
import Utils from '../../libs/Utils'
import validator from 'validator'
import { withRouter } from 'react-router-dom'

class EQCaregiver_Account extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      CaregiverHome: EQState.CaregiverHome,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      passwordShown: false,
    }
  }

  updateInputValue(evt, key) {
    const val = evt.target.value
    const field = evt.target.id
    let st = {}
    st[key] = val
    this.setState(st)
  }

  componentDidMount() {
    if (!EQState.CaregiverHome || !EQState.CaregiverHome.IsLogged) {
      EQState.retrieve(EQState.CaregiverHome)
    }
    if (!EQState.CaregiverHome || !EQState.CaregiverHome.IsLogged) {
      console.warn('Session is not ready in Account')
      document.location.href = '/caregiver/login'
      return
    }
  }

  changePassword() {
    if (this.state.newPassword.trim() === '') {
      Utils.notifyError('Specificare la nuova password')
      return
    }
    if (this.state.confirmPassword.trim() === '') {
      Utils.notifyError('Specificare la nuova password')
      return
    }
    if (!validator.isStrongPassword(this.state.newPassword)) {
      Utils.notifyError(
        'La password deve contenere almeno: - una lettera maiuscola - una lettera minuscola - un numero - un simbolo ed avere una lunghezza di almeno 8 caratteri'
      )
      return
    }
    if (this.state.confirmPassword !== this.state.newPassword) {
      Utils.notifyError('Le password indicate non coincidono')
      return
    }
    EQService.cambiaPassword(
      EQState.CaregiverHome.Professionista.user.userName,
      this.state.oldPassword,
      this.state.newPassword
    ).then((res) => {
      Utils.notifyConfirm('Password modificata')
    })
  }

  togglePassword() {
    this.setState({
      passwordShown: !this.state.passwordShown,
    })
  }

  render() {
    const p = EQState.CaregiverHome.Professionista
    let name = p.nome + ' ' + p.cognome
    let email = p.user.email
    let phone = p.user.mobilePhone

    return (
      <Box style={{ marginTop: '10px', padding: '18px' }}>
        <Box className="boxBianco">
          <Box pb={2}>
            <h2
              className="playFair normalWeight textCenter bookstep-start-label"
              style={{ marginBottom: '0' }}>
              Impostazioni
            </h2>
            <p
              className="textCenter"
              style={{ marginTop: '0', fontStyle: 'italic' }}>
              Di seguito puoi cambiare la password. Per mail e telefono chiama
              il tuo gestore.
            </p>
          </Box>
          <MuiThemeProvider>
            <Box className="boxBianco">
              <EQFloatingFormIcon>
                <AccountCircleTwoTone />
              </EQFloatingFormIcon>
              <Box
                pb={2}
                pl={2}>
                <FormControl fullWidth>
                  <EQTextField
                    readOnly
                    fullWidth
                    label="Username"
                    defaultValue={p.user.userName}></EQTextField>
                </FormControl>
              </Box>
              <EQFloatingFormIcon>
                <PersonOutlineIcon />
              </EQFloatingFormIcon>
              <Box
                pb={2}
                pl={2}>
                <FormControl fullWidth>
                  <EQTextField
                    readOnly
                    fullWidth
                    label="Nome e cognome"
                    defaultValue={name}></EQTextField>
                </FormControl>
              </Box>
              <EQFloatingFormIcon>
                <EmailTwoTone />
              </EQFloatingFormIcon>
              <Box
                pb={2}
                pl={2}>
                <FormControl fullWidth>
                  <EQTextField
                    readOnly
                    fullWidth
                    label="E-mail"
                    defaultValue={email}></EQTextField>
                </FormControl>
              </Box>
              <EQFloatingFormIcon>
                <LocalPhoneTwoTone />
              </EQFloatingFormIcon>
              <Box
                pb={2}
                pl={2}>
                <FormControl fullWidth>
                  <EQTextField
                    readOnly
                    fullWidth
                    label="Telefono"
                    defaultValue={phone}></EQTextField>
                </FormControl>
              </Box>
              <EQFloatingFormIcon>
                <DialpadTwoTone />
              </EQFloatingFormIcon>
              <Box
                pb={2}
                pl={2}>
                <FormControl fullWidth>
                  <EQTextField
                    className="inputPassword"
                    onChange={(evt) =>
                      this.updateInputValue(evt, 'oldPassword')
                    }
                    type={this.state.passwordShown ? 'text' : 'password'}
                    fullWidth
                    id="oldPassword"
                    label="Vecchia password"
                    placeholder="****"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => this.togglePassword()}
                            onMouseDown={() => this.togglePassword()}>
                            {this.state.passwordShown ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}></EQTextField>
                </FormControl>
              </Box>
              <Box
                pb={2}
                pl={2}>
                <FormControl fullWidth>
                  <EQTextField
                    className="inputPassword"
                    onChange={(evt) =>
                      this.updateInputValue(evt, 'newPassword')
                    }
                    type={this.state.passwordShown ? 'text' : 'password'}
                    fullWidth
                    id="newPassword"
                    label="Nuova password"
                    placeholder="****"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => this.togglePassword()}
                            onMouseDown={() => this.togglePassword()}>
                            {this.state.passwordShown ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}></EQTextField>
                </FormControl>
              </Box>
              <Box
                pb={2}
                pl={2}>
                <FormControl fullWidth>
                  <EQTextField
                    className="inputPassword"
                    onChange={(evt) =>
                      this.updateInputValue(evt, 'confirmPassword')
                    }
                    type={this.state.passwordShown ? 'text' : 'password'}
                    fullWidth
                    id="confirmPassword"
                    label="Conferma password"
                    placeholder="****"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => this.togglePassword()}
                            onMouseDown={() => this.togglePassword()}>
                            {this.state.passwordShown ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}></EQTextField>
                </FormControl>
              </Box>
              <Box className="flex-row-center">
                <a
                  className="buttonLink lnkForward button-DOMICILIARE"
                  onClick={() => this.changePassword()}>
                  CAMBIA PASSWORD
                </a>
              </Box>
            </Box>
          </MuiThemeProvider>
        </Box>

        <EQFaqBox category="ProfessionistiSicurezza"></EQFaqBox>
      </Box>
    )
  }
}

export default withRouter(EQCaregiver_Account)
