/* eslint-disable react/jsx-pascal-case */

// ! delete above lines to enable eslint jsx-a11y/anchor-is-valid and react/jsx-pascal-case
import './EQEventModal.scss'

import { Box, Grid } from '@mui/material'
import React, { Fragment } from 'react'

import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone'
import AnimalNotAllowedIcon from '../../svg/AnimalNotAllowedIcon'
import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone'
import CloseIcon from '@mui/icons-material/HighlightOff'
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn'
import EQCalendarStates from '../../libs/EQCalendarStates'
import EQCaregiver_DeleteHoliday from './EQCaregiver_DeleteHoliday'
import EQConfirmModal from './EQConfirmModal'
import EQEndModal from './EQEndModal'
import EQInLateModal from './EQInLateModal'
import EQPatientDiary from './EQPatientDiary'
import EQProfTypes from '../../libs/EQProfTypes'
import EQRefuseModal from './EQRefuseModal'
import EQService from '../../services/EQService'
import EQServiceBook from '../../services/EQServiceBook'
import EQServiceCaregiver from '../../services/EQServiceCaregiver'
import EQServiceReport from './EQServiceReport'
import EQStartModal from './EQStartModal'
import EQState from '../../services/EQState'
import EventIcon from '@mui/icons-material/CalendarMonthTwoTone'
import Formats from '../../libs/Formats'
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone'
import KeyIcon from '@mui/icons-material/Key'
import MessageIcon from '@mui/icons-material/Message'
import PersonOutlineIcon from '@mui/icons-material/PersonOutlineTwoTone'
import PetsIcon from '@mui/icons-material/Pets'
import PhoneTwoToneIcon from '@mui/icons-material/PhoneTwoTone'
import QuestionAnswerTwoToneIcon from '@mui/icons-material/QuestionAnswerTwoTone'
import SendIcon from '@mui/icons-material/Send'
import SmokeNotAllowedIcon from '../../svg/SmokeNotAllowedIcon'
import SmokingRoomsIcon from '@mui/icons-material/SmokingRooms'
import SummarizeIcon from '@mui/icons-material/Summarize'

let tout = null

export default class EQEventModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      comuni: null,
      chatText: false,
      mansioniList: null,
    }
  }

  componentDidMount() {
    tout = setInterval(() => {
      this.forceUpdate()
    }, 30000)
    EQServiceBook.mapComuni().then((m) => {
      setTimeout(() => {
        console.log(m)
        this.setState({ comuni: m })
      }, 200)
    })
    EQServiceCaregiver.getAllMansioni().then((m) => {
      setTimeout(() => {
        this.setState({ mansioniList: m })
      }, 200)
    })
  }

  componentWillUnmount() {
    if (tout) {
      clearInterval(tout)
      tout = null
    }
  }

  closeModal() {
    EQState.change(EQState.CaregiverHome, {
      _selectedEvent: null,
    })
  }

  ensureTwoDigits(s) {
    s = '' + s
    if (s.length < 2) s = '0' + s
    return s
  }

  formatHHMM(dd) {
    let hh = this.ensureTwoDigits(dd.getHours())
    let mm = this.ensureTwoDigits(dd.getMinutes())
    if (hh === '00' && mm === '00') return '' // giornate complete di ferie non hanno indicazione dell'ora
    return hh + ':' + mm
  }

  calculateAge(birthday) {
    let ageDifMs = Date.now() - birthday
    let ageDate = new Date(ageDifMs) // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970)
  }

  formatIMC(imc) {
    let x = Math.trunc(imc * 100)
    return Formats.formatQuantity(x / 100)
  }

  manageChat() {
    this.setState({ chatText: !this.state.chatText })
  }

  refuseEvent() {
    this.setState({
      refuse_open: true,
    })
  }

  cancelRefuseEvent() {
    this.setState({
      refuse_open: false,
    })
  }

  acceptEvent() {
    this.setState({
      accept_open: true,
    })
  }

  cancelAcceptEvent() {
    this.setState({
      accept_open: false,
    })
  }

  startEvent() {
    this.setState({
      start_open: true,
    })
  }

  cancelStartEvent() {
    this.setState({
      start_open: false,
    })
  }

  endEvent() {
    this.setState({
      end_open: true,
    })
  }

  cancelEndEvent() {
    this.setState({
      end_open: false,
    })
  }

  inLate(type) {
    this.setState({
      inLate_open: true,
      isCall: type === 'call',
    })
  }

  cancelInLate() {
    this.setState({
      inLate_open: false,
    })
  }

  writeReport() {
    this.setState({
      report_open: true,
    })
  }

  cancelReport() {
    this.setState({
      report_open: false,
    })
  }

  showPatientDiary() {
    this.setState({
      patientDiary_open: true,
    })
  }

  closePatientDiary() {
    this.setState({
      patientDiary_open: false,
    })
  }

  showDeleteHoliday() {
    this.setState({
      deleteHoliday_open: true,
    })
  }

  cancelDeleteHoliday() {
    this.setState({
      deleteHoliday_open: false,
    })
  }

  doneDeleteHoliday() {
    this.closeModal()
  }

  searchAddress(indirizzo, nomeComune, cap, provincia) {
    let url = 'https://www.google.com/maps/place/'
    url += encodeURIComponent(indirizzo)
    url += encodeURIComponent(', ' + nomeComune)
    url += encodeURIComponent(', ' + cap)
    url += encodeURIComponent(', ' + provincia)
    window.open(url, '_blank')
  }

  sendText() {
    const textArea = document.getElementById('textArea')
    const textMessage = textArea.value
    const clientID =
      this.props.selectedEvent.servizioPrenotazione.prenotazione.paziente
        .cliente.idUser
    if (textMessage) {
      EQService.sendMessage(
        clientID,
        this.props.selectedEvent.idCalendario,
        textMessage
      )
    }
    textArea.value = ''
    this.manageChat()
  }
  textAreaAdjust() {
    const element = document.getElementById('textArea')

    element.style.height = '1px'
    element.style.height = 25 + element.scrollHeight + 'px'
  }

  getMansioni(mansioni) {
    let valToReturn = ''
    console.log(mansioni, this.state.mansioniList)
    this.state.mansioniList
      .filter((elem) => {
        return mansioni.some((ele) => {
          return ele.idMansione === elem.idMansione
        })
      })
      .forEach((element, index) => {
        valToReturn += element.nome + (index < mansioni.length - 1 ? '-' : '')
      })

    console.log(`🐢 -> ${valToReturn}`)
    return valToReturn
  }

  render() {
    let dayNames = Formats.dayNames()
    let monthNames = Formats.monthNames()

    const e = this.props.selectedEvent
    console.log('e', e)

    const estate = e.tipoOccupazioneCalendario.idTipoOccupazione

    if (this.state.report_open) {
      // (true) { //  { ///////////////// TODO
      return (
        <EQServiceReport
          onClose={() => this.cancelReport()}
          selectedEvent={e}
        />
      )
    }

    if (this.state.patientDiary_open) {
      ///////////////// TODO (true)
      return (
        <EQPatientDiary
          selectedEvent={e}
          onClose={() => this.closePatientDiary()}
        />
      )
    }

    let p = null
    if (!EQCalendarStates.isHoliday(e)) {
      p = e.servizioPrenotazione.prenotazione
    }

    let comune = null
    if (p && this.state.comuni) {
      comune = this.state.comuni.find((x) => x.idComune === p.idComune)
    }

    let dataNascitaPaziente = ''
    let etaPaziente = 0
    if (p) {
      dataNascitaPaziente = Formats.fromServerDate(p.paziente.dataNascita)
      etaPaziente = this.calculateAge(dataNascitaPaziente)
    }

    let appointmentDateStart = null
    let appointmentDateEnd = null
    if (p) {
      appointmentDateStart = Formats.fromServerDate(
        e.servizioPrenotazione.richiestoDal
      )
      appointmentDateEnd = Formats.fromServerDate(
        e.servizioPrenotazione.richiestoAl
      )
    }

    let mansioni = null

    let patologie = null
    if (p && p.paziente.pazientePatologias) {
      patologie = p.paziente.pazientePatologias
        .map(({ patologia }) => patologia.nome)
        .join(', ')
    }

    let cap = ''
    let nomeComune = ''
    let provincia = ''
    if (comune) {
      cap = comune.cap
      nomeComune = comune.nome
      provincia = comune.provincia
    }

    let titleHoliday = ''
    if (EQCalendarStates.isHoliday(e)) {
      if (
        EQState.CaregiverHome.Professionista.idInquadramento !==
        EQProfTypes.Dipendente
      ) {
        titleHoliday = 'Indisponibilità'
      } else {
        titleHoliday = 'Ferie o permessi confermati'
      }
    }

    let dateDivs = []
    let fromDate = Formats.fromServerDate(e.dal)
    let toDate = Formats.fromServerDate(e.al)
    let fd = new Date(fromDate.getTime())
    fd.setHours(0, 0, 0, 0)
    let td = new Date(toDate.getTime())
    td.setHours(0, 0, 0, 0)
    let dayDiff = false
    if (fd.getTime() === td.getTime()) {
      // evento in un solo giorno
      //
      let datePresentation =
        dayNames[fromDate.getDay()] +
        ' ' +
        fromDate.getDate() +
        ' ' +
        monthNames[fromDate.getMonth()] +
        ' ' +
        this.formatHHMM(fromDate) +
        ' / ' +
        this.formatHHMM(toDate)
      dateDivs.push(<div key={1}>{datePresentation}</div>)
    } else {
      // evento in un giorni differenti
      //
      dayDiff = true
      let shhmm = this.formatHHMM(fromDate)
      let datePresentation =
        'Da ' +
        dayNames[fromDate.getDay()] +
        ' ' +
        fromDate.getDate() +
        ' ' +
        monthNames[fromDate.getMonth()] +
        ' ' +
        shhmm
      dateDivs.push(<div key={1}>{datePresentation}</div>)

      let ehhmm = this.formatHHMM(toDate)
      if (shhmm === '' && ehhmm === '') {
        // caso particolare: evento su giorni interi
        // il punto finale è il giorno escluso
        toDate.setDate(toDate.getDate() - 1)
      }
      datePresentation =
        'A ' +
        dayNames[toDate.getDay()] +
        ' ' +
        toDate.getDate() +
        ' ' +
        monthNames[toDate.getMonth()] +
        ' ' +
        this.formatHHMM(toDate)
      dateDivs.push(<div key={2}>{datePresentation}</div>)
    }

    let isRefused = false
    if (estate === EQCalendarStates.ServizioRifiutatoDalProfessionista) {
      isRefused = true
    }

    let isNear = false
    let canAccept = false
    let limitPresentation = ''
    //
    if (EQCalendarStates.isToBeConfirmed(e)) {
      let dataLimite = e.dataLimiteConfermaProfessionista
      // TEST-TODO dataLimite="2022-05-02T21:55:00Z";
      if (dataLimite) {
        let limitDate = Formats.fromServerDate(dataLimite)
        let lp = Formats.formatDateSpan(new Date(), limitDate, 'accept')
        if (lp !== null) {
          limitPresentation = lp
          canAccept = true
          let diffMinutes =
            (limitDate.getTime() - new Date().getTime()) / (1000 * 60)
          if (diffMinutes < 30) {
            isNear = true
          }
        } else {
          isRefused = true
        }
      } else {
        isRefused = true
      }
    }

    let isConfirmed = false
    let isConfirmedOutdated = false
    let highlightPrenotazioneSt = {}
    let startingPresentation = ''
    let nextStarting = false
    if (estate === EQCalendarStates.ServizioConfermatoDalProfessionista) {
      let diffMinutes =
        (fromDate.getTime() - new Date().getTime()) / (1000 * 60)
      // diffMinutes=2; // TEST-TODO
      if (diffMinutes > 0 && diffMinutes < 120) {
        nextStarting = true
        let lp = Formats.formatDateSpan(new Date(), fromDate, 'next')
        if (lp !== null) {
          startingPresentation = lp
        } else {
          startingPresentation = "L'assistenza sta per iniziare"
        }
      }
      if (!nextStarting) {
        if (new Date() < fromDate) isConfirmed = true
        else {
          isConfirmedOutdated = true
          let lp = Formats.formatDateSpan(new Date(), fromDate, 'outdate')
          if (lp !== null) {
            startingPresentation = lp
          } else {
            startingPresentation = "L'assistenza è in ritardo"
          }
        }
      }
    }

    let started = false
    let startedMessage = ''
    let startedMessageSt = {}
    if (estate === EQCalendarStates.ServizioIniziato) {
      started = true
      let diffMinutes = (toDate.getTime() - new Date().getTime()) / (1000 * 60)
      if (diffMinutes > 0) {
        let lp = Formats.formatDateSpan(new Date(), toDate, 'running')
        startedMessage = lp
      } else {
        let lp = Formats.formatDateSpan(toDate, new Date(), 'outdate')
        startedMessage = 'Ritardo: ' + lp
        startedMessageSt.color = 'red'
        startedMessageSt.fontWeight = 'bold'
      }
      // Diminuiamo il riquadro - in alcuni cellulari non c'è spazio per i pulsanti sotto
      highlightPrenotazioneSt.height = '260px'
      highlightPrenotazioneSt.maxHeight = '260px'
    }

    let ended = false
    if (estate === EQCalendarStates.ServizioFinito) {
      ended = true
    }

    let st = {}
    if (canAccept && isNear) {
      st.color = 'red'
      st.fontWeight = 'bold'
    }

    // forzatura per test una alla volta
    // nextStarting = false;
    //isConfirmedOutdated = true;
    //started = true;
    //ended = true;
    //isRefused = true;
    //isConfirmed = true;
    //console.clear();
    //console.table({nextStarting, isConfirmedOutdated, started, ended, isRefused, isConfirmed})

    return (
      <div className="helpModal eventModal stats-modal">
        {estate !== EQCalendarStates.FerieConfermate &&
          estate !== EQCalendarStates.IndisponibilitaContrattuale &&
          estate !== EQCalendarStates.RichiestaFerie &&
          this.state.comuni && (
            <Box
              style={{ marginTop: '78px' }}
              px={2}
              mb={4}>
              <div className="iconClose-outer">
                <button
                  onClick={() => this.closeModal()}
                  className="iconClose clickable">
                  <Box
                    mt={1}
                    mr={0}>
                    <CloseIcon></CloseIcon>
                  </Box>
                  <div>CHIUDI</div>
                </button>
              </div>
              <div className="playFair normalWeight textCenter bookstep-start-label">
                Dettaglio Prestazione.
              </div>
              <div className="textCenter playFair ">
                {' '}
                qui trovi tutti i dettagli della prestazione che hai selezionato
              </div>

              <Box className="bookDetailContainer">
                <Box>
                  <Box className="bookDetailIconSpacer inline-block"></Box>
                  <Box className="bookDetailContent inline-block">
                    <Box>Prestazione:</Box>
                    <Box>
                      <strong>{e.servizioPrenotazione.servizio.nome}</strong>
                    </Box>

                    <Box pl={1}>
                      <em
                        id="mansioni-block"
                        className="text-black">
                        {this.state.mansioniList &&
                          this.getMansioni(
                            e.servizioPrenotazione.mansioneServizioPrenotaziones
                          )}
                      </em>
                    </Box>
                  </Box>
                </Box>

                {started && (
                  <div className="event-modal-state ">
                    <div className="serviceHeader bg-white text-black ">
                      Assistenza in corso
                    </div>
                    <div className="serviceContents">
                      <div className="appuntamento-box">
                        <div>
                          <div>
                            <KeyIcon />
                          </div>
                        </div>
                        <div
                          className="boxPass textCenter"
                          style={{
                            flexGrow: '2',
                            marginLeft: '10px',
                            marginBottom: '10px',
                            marginTop: '0px',
                          }}>
                          <label>PAROLA D'ORDINE</label>
                          {e.servizioPrenotazione.prenotazione.parolaOrdine}
                        </div>
                      </div>
                      <div className="appuntamento-box">
                        <div className="textCenter w-full">
                          <a
                            className="buttonLink late-btn white border-lg "
                            onClick={() => this.writeReport()}>
                            <div className="flex-row-center space-between text-black w-full">
                              <SummarizeIcon />
                              <div>
                                <p style={{ textAlign: 'left' }}>
                                  <b>COMPILA IL REPORT</b>
                                </p>
                              </div>
                              <ArrowForwardTwoToneIcon />
                            </div>
                          </a>
                        </div>
                      </div>
                      <div className="appuntamento-box">
                        <div
                          className="textCenter"
                          style={{ marginBottom: '20px', width: '100%' }}>
                          <a
                            className="buttonLink lnkForward start-btn"
                            onClick={() => this.endEvent()}>
                            FINE
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {ended && (
                  <div className="event-modal-state  border-lg">
                    <div
                      className="serviceHeader"
                      style={{ marginBottom: '10px' }}>
                      Assistenza terminata
                    </div>
                    <div className="serviceContents">
                      <div className="appuntamento-box  w-full">
                        <div className="textCenter w-full">
                          <a
                            className="buttonLink late-btn white border-lg "
                            onClick={() => this.writeReport()}>
                            <div className="flex-row-center space-between text-black w-full">
                              <SummarizeIcon />
                              <div>
                                <p style={{ textAlign: 'left' }}>
                                  <b>COMPILA IL REPORT</b>
                                </p>
                              </div>
                              <ArrowForwardTwoToneIcon />
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className="serviceFooter"
                      style={st}>
                      <div className="serviceFooterContainer"></div>
                    </div>
                  </div>
                )}

                {isRefused && (
                  <div className="event-modal-state  border-lg">
                    <div className="serviceHeader"></div>
                    <div className="serviceContents">
                      <div className="appuntamento-box">
                        <div>
                          <div>
                            <DoDisturbOnIcon />
                          </div>
                        </div>
                        <div
                          className="boxPass textCenter font-size-20 bg-refused border-refused text-white"
                          style={{
                            width: '80%',
                            flexGrow: '2',
                            marginLeft: '10px',
                            marginBottom: '10px',
                            marginTop: '0px',
                          }}>
                          Servizio rifiutato o non accettato nel tempo limite
                        </div>
                      </div>
                    </div>
                    <div
                      className="serviceFooter"
                      style={st}>
                      <div className="serviceFooterContainer"></div>
                    </div>
                  </div>
                )}

                {canAccept && (
                  <div className="event-modal-state bg-can-accept ">
                    <div className="serviceHeader bg-white text-black">
                      Servizio in attesa di conferma
                    </div>
                    <div className="serviceContents white">
                      <div className="appuntamento-box white">
                        <Grid
                          container
                          mt={1}
                          mb={6}
                          columnGap={1}>
                          <Grid
                            item
                            xs={3}>
                            <a
                              className="buttonLink bg-red text-white lnkForward flex-row-center"
                              onClick={() => this.refuseEvent()}>
                              RIFIUTA
                            </a>
                          </Grid>
                          <Grid
                            item
                            xs={8}>
                            <a
                              className="buttonLink bg-green text-white  lnkForward flex-row-center"
                              onClick={() => this.acceptEvent()}>
                              ACCETTA
                            </a>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </div>
                )}

                {isConfirmedOutdated && (
                  <div className="event-modal-state bg-can-accept ">
                    <div
                      className="serviceHeader bg-white text-black"
                      style={{
                        color: 'red',
                        fontWeight: 'bold',
                      }}>
                      {isConfirmedOutdated
                        ? 'Assistenza in ritardo'
                        : 'Assistenza immininete'}
                    </div>
                    <div className="serviceContents">
                      <div className="appuntamento-box">
                        <div>
                          <div>
                            <KeyIcon />
                          </div>
                        </div>
                        <div
                          className="boxPass textCenter"
                          style={{
                            flexGrow: '2',
                            marginLeft: '10px',
                            marginBottom: '10px',
                            marginTop: '0px',
                          }}>
                          <label>PAROLA D'ORDINE</label>
                          {e.servizioPrenotazione.prenotazione.parolaOrdine}
                        </div>
                      </div>

                      <div className="appuntamento-box-vertical serviceContents">
                        <div className="textCenter flex-col-center">
                          <a
                            className="buttonLink lnkForward bg-green text-white start-btn border-lg"
                            onClick={() => this.startEvent()}>
                            <div className="flex-col-center">
                              <div className="flex-row-center">
                                <p> INIZIO </p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="textCenter">
                          <a
                            className="buttonLink late-btn white border-lg "
                            onClick={() => this.inLate('delay')}>
                            <div className="flex-row-center space-between text-black">
                              <img
                                style={{
                                  width: '50px',
                                }}
                                src="/img/clock_x.svg"
                                alt="ritardo inf 30 min"
                              />
                              <div style={{ width: '60%' }}>
                                <p
                                  style={{
                                    marginBottom: '-15px',
                                    fontWeight: 'normal',
                                    textAlign: 'left',
                                  }}>
                                  Ritardo:
                                </p>
                                <p style={{ textAlign: 'left' }}>
                                  <b>inferiore a 30 minuti</b>
                                </p>
                              </div>
                              <ArrowForwardTwoToneIcon />
                            </div>
                          </a>
                        </div>
                        <div className="textCenter">
                          <a
                            className="buttonLink late-btn white  border-lg border-lg "
                            onClick={() => this.inLate('call')}>
                            <div className="flex-row-center space-between text-black">
                              <img
                                src="/img/clock_!.svg"
                                alt="clock"
                                style={{
                                  width: '50px',
                                }}
                              />
                              <div style={{ width: '60%' }}>
                                <p
                                  style={{
                                    marginBottom: '-15px',
                                    fontWeight: 'normal',
                                    textAlign: 'left',
                                  }}>
                                  Ritardo:
                                </p>
                                <p
                                  style={{
                                    marginBottom: '-15px',
                                    textAlign: 'left',
                                  }}>
                                  <b>superiore a 30 minuti</b>
                                </p>
                                <p
                                  className="PlayFair"
                                  style={{
                                    fontWeight: 'lighter',
                                    textAlign: 'left',
                                  }}>
                                  chiama il call-center di EqueCure
                                </p>
                              </div>
                              <PhoneTwoToneIcon />
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {nextStarting && !started && (
                  <div className="event-modal-state bg-can-accept">
                    <div className="serviceHeader bg-white text-black">
                      Appuntamento imminente
                    </div>
                    <div className="serviceContents">
                      <div className="appuntamento-box">
                        <div>
                          <div>
                            <KeyIcon />
                          </div>
                        </div>
                        <div
                          className="boxPass textCenter"
                          style={{
                            flexGrow: '2',
                            marginLeft: '10px',
                            marginBottom: '10px',
                            marginTop: '0px',
                          }}>
                          <label>PAROLA D'ORDINE</label>
                          {e.servizioPrenotazione.prenotazione.parolaOrdine}
                        </div>
                      </div>

                      <div className="appuntamento-box-vertical serviceContents">
                        <div className="textCenter flex-col-center">
                          <a
                            className="buttonLink lnkForward green start-btn border-lg"
                            onClick={() => this.startEvent()}>
                            <div className="flex-col-center">
                              <div className="flex-row-center">
                                <p> INIZIO </p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="textCenter">
                          <a
                            className="buttonLink late-btn white border-lg "
                            onClick={() => this.inLate('delay')}>
                            <div className="flex-row-center space-between text-black ">
                              <img
                                style={{
                                  width: '50px',
                                }}
                                src="/img/clock_x.svg"
                                alt="ritardo inf 30 min"
                              />
                              <div
                                style={{ width: '60%' }}
                                className="text-black">
                                <p
                                  style={{
                                    marginBottom: '-15px',
                                    fontWeight: 'normal',
                                    textAlign: 'left',
                                  }}>
                                  Ritardo:
                                </p>
                                <p style={{ textAlign: 'left' }}>
                                  <b>inferiore a 30 minuti</b>
                                </p>
                              </div>
                              <ArrowForwardTwoToneIcon />
                            </div>
                          </a>
                        </div>
                        <div className="textCenter">
                          <a
                            className="buttonLink late-btn white  border-lg border-lg "
                            onClick={() => this.inLate('call')}>
                            <div className="flex-row-center space-between text-black">
                              <img
                                src="/img/clock_!.svg"
                                alt="clock"
                                style={{
                                  width: '50px',
                                }}
                              />
                              <div style={{ width: '60%' }}>
                                <p
                                  style={{
                                    marginBottom: '-15px',
                                    fontWeight: 'normal',
                                    textAlign: 'left',
                                  }}>
                                  Ritardo:
                                </p>
                                <p
                                  style={{
                                    marginBottom: '-15px',
                                    textAlign: 'left',
                                  }}>
                                  <b>superiore a 30 minuti</b>
                                </p>
                                <p
                                  className="PlayFair"
                                  style={{
                                    fontWeight: 'lighter',
                                    textAlign: 'left',
                                  }}>
                                  chiama il call-center di EqueCure
                                </p>
                              </div>
                              <PhoneTwoToneIcon />
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {(!started || ended) &&
                  e.ritardoDichiarato !== null &&
                  e.ritardoDichiarato !== 0 && (
                    <div
                      className="textCenter app-text bg-in-late-message"
                      style={{ marginBottom: '5px' }}>
                      Sei in ritardo
                      {e.ritardoDichiarato > 30 ? 'di più' : ''} di 30 minuti
                    </div>
                  )}

                <Box pt={1}>
                  <Box className="bookDetailIconSpacer inline-block">
                    <EventIcon />
                  </Box>
                  <Box className="bookDetailContent inline-block">
                    {!dayDiff && (
                      <Fragment>
                        <div className="dateText">
                          {dayNames[appointmentDateStart.getDay()]}{' '}
                          {appointmentDateStart.getDate()}{' '}
                          {monthNames[appointmentDateStart.getMonth()]}
                        </div>
                        <div>
                          dalle ore:{' '}
                          <strong>
                            {('0' + appointmentDateStart.getHours()).slice(-2)}:
                            {('0' + appointmentDateStart.getMinutes()).slice(
                              -2
                            )}
                          </strong>
                        </div>
                        <div>
                          alle ore:{' '}
                          <strong>
                            {('0' + appointmentDateEnd.getHours()).slice(-2)}:
                            {('0' + appointmentDateEnd.getMinutes()).slice(-2)}
                          </strong>
                        </div>
                      </Fragment>
                    )}
                    {dayDiff && (
                      <Fragment>
                        <div>
                          Da{' '}
                          <span className="dateText">
                            {dayNames[appointmentDateStart.getDay()]}{' '}
                            {appointmentDateStart.getDate()}{' '}
                            {monthNames[appointmentDateStart.getMonth()]}
                          </span>
                        </div>
                        <div>
                          alle ore:{' '}
                          <strong>
                            {('0' + appointmentDateStart.getHours()).slice(-2)}:
                            {('0' + appointmentDateStart.getMinutes()).slice(
                              -2
                            )}
                          </strong>
                        </div>
                        <div>
                          A{' '}
                          <span className="dateText">
                            {dayNames[appointmentDateEnd.getDay()]}{' '}
                            {appointmentDateEnd.getDate()}{' '}
                            {monthNames[appointmentDateEnd.getMonth()]}
                          </span>
                        </div>
                        <div>
                          alle ore:{' '}
                          <strong>
                            {('0' + appointmentDateEnd.getHours()).slice(-2)}:
                            {('0' + appointmentDateEnd.getMinutes()).slice(-2)}
                          </strong>
                        </div>
                      </Fragment>
                    )}
                  </Box>
                </Box>
                <Box
                  className="bookDetailInfo"
                  pt={2}>
                  <Box className="textRight">
                    <label>PRENOTAZIONE</label>
                    <Box>
                      <strong>{e.servizioPrenotazione.idPrenotazione}</strong>
                    </Box>
                  </Box>
                  <Box>
                    <Box className="bookDetailIconSpacer inline-block">
                      <PersonOutlineIcon />
                    </Box>
                    <Box className="bookDetailContent inline-block">
                      <label>NOME ASSISTITO</label>
                      <Box>
                        <strong>
                          {e.servizioPrenotazione.prenotazione.paziente.nome}{' '}
                          {e.servizioPrenotazione.prenotazione.paziente.cognome}
                        </strong>
                      </Box>
                      <Box>
                        <a
                          className="linkunderlined"
                          onClick={() => this.showPatientDiary()}>
                          Diario dell'assistito
                        </a>
                      </Box>
                    </Box>
                  </Box>
                  <Box pt={2}>
                    <Box className="bookDetailIconSpacer inline-block">
                      <HomeTwoToneIcon />
                    </Box>
                    <Box
                      className="bookDetailContent inline-block"
                      onClick={() =>
                        this.searchAddress(
                          e.servizioPrenotazione.prenotazione.indirizzo,
                          nomeComune,
                          cap,
                          provincia
                        )
                      }>
                      <label>L'ATTIVIT&Agrave; SI SVOLGER&Agrave; IN</label>
                      <Box>
                        <strong>
                          {e.servizioPrenotazione.prenotazione.indirizzo}
                        </strong>
                      </Box>
                      <Box>
                        <strong>
                          {cap} {nomeComune} ({provincia})
                        </strong>
                      </Box>
                    </Box>
                  </Box>
                  <Box pt={2}>
                    <Box className="bookDetailIconSpacer inline-block">
                      <AddCircleTwoToneIcon />
                    </Box>
                    {/* <Box className="bookDetailContent inline-block">
                      <label>Mansioni</label>
                      <Box>
                        <strong>{mansioni}</strong>
                      </Box>
                    </Box> */}
                  </Box>
                  <Box pt={2}>
                    <Box className="bookDetailIconSpacer inline-block">
                      <AddCircleTwoToneIcon />
                    </Box>
                    <Box className="bookDetailContent inline-block">
                      <label>PATOLOGIE</label>
                      <Box>
                        <strong>{patologie}</strong>
                      </Box>
                    </Box>
                  </Box>
                  {p.commentoRichiedente && (
                    <Box pt={2}>
                      <Box className="bookDetailIconSpacer inline-block">
                        <MessageIcon></MessageIcon>
                      </Box>
                      <Box className="bookDetailContent inline-block">
                        <label>NOTE CLIENTE</label>
                        <Box>
                          <strong>{p.commentoRichiedente}</strong>
                        </Box>
                      </Box>
                    </Box>
                  )}
                  <Box
                    pt={2}
                    className="stats-container">
                    <Box
                      pt={1}
                      className="stats-left">
                      <Box>
                        <Box className="bookDetailContent inline-block">
                          <label>ET&Agrave; </label>
                          <Box>
                            <strong>{etaPaziente}</strong>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <Box className="bookDetailContent inline-block">
                          <label>IMC</label>
                          <Box>
                            <strong>{this.formatIMC(p.paziente.imc)}</strong>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      pt={1}
                      className="stats-right">
                      <Box>
                        <Box className="bookDetailIconSpacer inline-block">
                          {!p.paziente.haAnimali ? (
                            <PetsIcon />
                          ) : (
                            <AnimalNotAllowedIcon />
                          )}
                        </Box>
                      </Box>
                      <Box>
                        <Box className="bookDetailIconSpacer inline-block">
                          {p.paziente.fumatore ? (
                            <SmokingRoomsIcon />
                          ) : (
                            <SmokeNotAllowedIcon />
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{ textAlign: 'center' }}
                pt={2}>
                <a
                  className="linkunderlined"
                  onClick={() => this.closeModal()}>
                  CHIUDI
                </a>
              </Box>
            </Box>
          )}

        {estate === EQCalendarStates.FerieConfermate && (
          <Box
            style={{ marginTop: '80px' }}
            px={2}
            mb={4}>
            <div className="iconClose-outer">
              <button
                onClick={() => this.closeModal()}
                className="iconClose clickable">
                <Box
                  mt={1}
                  mr={0}>
                  <CloseIcon></CloseIcon>
                </Box>
                <div>CHIUDI</div>
              </button>
            </div>
            <div className="playFair normalWeight textCenter bookstep-start-label">
              {titleHoliday}
            </div>
            <div className="textCenter playFair ">
              non riceverai richieste in questo periodo
            </div>
            <Box mt={2}>
              <div className="textCenter app-text">
                <b>{dateDivs}</b>
              </div>
            </Box>

            {EQState.CaregiverHome.Professionista.idInquadramento !==
              EQProfTypes.Dipendente && (
              <Box
                mb={6}
                mt={4}>
                <div className="textCenter flex-row-center">
                  <a
                    className="buttonLink lnkForward  "
                    onClick={() => this.showDeleteHoliday()}>
                    CANCELLA INDISPONIBILIT&Agrave;
                  </a>
                </div>
              </Box>
            )}

            <Box
              sx={{ textAlign: 'center' }}
              pt={2}>
              <a
                className="linkunderlined"
                onClick={() => this.closeModal()}>
                CHIUDI
              </a>
            </Box>
          </Box>
        )}

        {estate === EQCalendarStates.IndisponibilitaContrattuale && (
          <Box
            style={{ marginTop: '80px' }}
            px={2}
            mb={4}>
            <div className="iconClose-outer">
              <button
                onClick={() => this.closeModal()}
                className="iconClose clickable">
                <Box
                  mt={1}
                  mr={0}>
                  <CloseIcon></CloseIcon>
                </Box>
                <div>CHIUDI</div>
              </button>
            </div>
            <div className="playFair normalWeight textCenter bookstep-start-label">
              Indisponibilità
            </div>
            <div className="textCenter playFair ">
              indisponibilità contrattuale: non riceverai richieste in questo
              periodo
            </div>
            <Box mt={2}>
              <div className="textCenter app-text">
                <b>{dateDivs}</b>
              </div>
            </Box>

            <Box
              sx={{ textAlign: 'center' }}
              pt={2}>
              <a
                className="linkunderlined"
                onClick={() => this.closeModal()}>
                CHIUDI
              </a>
            </Box>
          </Box>
        )}

        {estate === EQCalendarStates.RichiestaFerie && (
          <Box
            style={{ marginTop: '80px' }}
            px={2}
            mb={4}>
            <div className="iconClose-outer">
              <button
                onClick={() => this.closeModal()}
                className="iconClose clickable">
                <Box
                  mt={1}
                  mr={0}>
                  <CloseIcon></CloseIcon>
                </Box>
                <div>CHIUDI</div>
              </button>
            </div>
            <div className="playFair normalWeight textCenter bookstep-start-label">
              Richiesta di ferie / permessi
            </div>
            <div className="textCenter playFair ">
              la richiesta verrà controllata e gestita
            </div>
            <Box mt={2}>
              <div className="textCenter app-text">
                <b>{dateDivs}</b>
              </div>
            </Box>

            <Box
              sx={{ textAlign: 'center' }}
              pt={2}>
              <a
                className="linkunderlined"
                onClick={() => this.closeModal()}>
                CHIUDI
              </a>
            </Box>
          </Box>
        )}

        {this.state.refuse_open && (
          <EQRefuseModal
            onClose={() => this.cancelRefuseEvent()}
            selectedEvent={e}
          />
        )}

        {this.state.accept_open && (
          <EQConfirmModal
            onClose={() => this.cancelAcceptEvent()}
            selectedEvent={e}
          />
        )}

        {this.state.start_open && (
          <EQStartModal
            onClose={() => this.cancelStartEvent()}
            selectedEvent={e}
          />
        )}

        {this.state.end_open && (
          <EQEndModal
            onClose={() => this.cancelEndEvent()}
            selectedEvent={e}
          />
        )}

        {this.state.inLate_open && (
          <EQInLateModal
            isCall={this.state.isCall}
            onClose={() => this.cancelInLate()}
            selectedEvent={e}
          />
        )}

        {this.state.deleteHoliday_open && (
          <EQCaregiver_DeleteHoliday
            onCancel={() => this.cancelDeleteHoliday()}
            onDone={() => this.doneDeleteHoliday()}
            selectedEvent={e}
          />
        )}

        <div
          id="chat-container"
          style={{ display: 'fixed' }}>
          {this.state.chatText && (
            <div className="bubble">
              <textarea
                id="textArea"
                onKeyUp={() => this.textAreaAdjust()}
                style={{ overflow: 'hidden' }}
                rows="2"
                cols="20"
                placeholder="inserisci il messaggio qui..."
              />
              <button
                className="send-button"
                onClick={() => this.sendText()}>
                {' '}
                <SendIcon />{' '}
              </button>
            </div>
          )}
          <button
            className="chat-button"
            onClick={() => this.manageChat()}>
            <QuestionAnswerTwoToneIcon sx={{ color: 'white' }} />
          </button>
        </div>
      </div>
    )
  }
}
