import { Box } from '@mui/material'
import React from 'react'
import EQFaqBox from '../components/EQFaqBox'
import EQFooter from '../components/EQFooter'
import EQHeaderBook from '../components/EQHeaderBook'
import EQLoading from '../components/EQLoading'
import EQService from '../services/EQService'
import EQState from '../services/EQState'
import VisualLoader from '../components/Loader/VisualLoader'

export default class PageEditorial extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      imgLoaded: false,
    }
  }

  getPageCode() {
    return 'NONE' // da ridefinire
  }

  componentDidMount() {
    const pageCode = this.getPageCode()
    EQService.mapContents(pageCode, true).then((m) => {
      EQState.change(EQState.Contents, {
        [pageCode]: m,
      })
      this.forceUpdate()
    })
  }

  getChildrenParts() {
    return null // da ridefinire
  }

  cc(code, field) {
    const pageCode = this.getPageCode()
    const C = EQState.Contents[pageCode]
    return C[pageCode + '_' + code][field]
  }

  loadingImg() {
    this.setState({ imgLoaded: true })
  }

  render() {
    const pageCode = this.getPageCode()
    const C = EQState.Contents[pageCode]
    if (!C) {
      return (
        <div style={{ marginTop: '100px' }}>
          <EQLoading />
        </div>
      )
    }

    let descrizione_sottotitolo = ''
    if (
      C[pageCode + '_DESCRIPTION'] &&
      C[pageCode + '_DESCRIPTION'].SottoTitolo
    ) {
      descrizione_sottotitolo = this.cc('DESCRIPTION', 'SottoTitolo')
    }

    let descrizione_contenuto = ''
    if (
      C[pageCode + '_DESCRIPTION'] &&
      C[pageCode + '_DESCRIPTION'].Contenuto
    ) {
      descrizione_contenuto = this.cc('DESCRIPTION', 'Contenuto')
    }

    let contenuto_contenuto = ''
    if (C[pageCode + '_CONTENUTO']) {
      contenuto_contenuto = this.cc('CONTENUTO', 'Contenuto').replace(
        '$IMMAGINE$',
        "<img src='" + this.cc('CONTENUTO', 'urlImmagine') + "' />"
      )
    }

    const mainCss = 'editorial ' + pageCode
    return (
      <div className={mainCss}>
        <EQHeaderBook></EQHeaderBook>
        <Box mt={7}>
          <div className="header">
            <div
              style={
                !this.cc('VISUAL', 'urlImmagine')
                  ? { backgroundColor: 'white' }
                  : {}
              }>
              {this.cc('VISUAL', 'SottoTitolo')}
            </div>
            <img
              onLoad={() => this.loadingImg()}
              style={{ display: `${this.state.imgLoaded ? 'unset' : 'none'}` }}
              src={this.cc('VISUAL', 'urlImmagine')}
              alt={this.cc('VISUAL', 'SottoTitolo')}
              title={this.cc('VISUAL', 'SottoTitolo')}
            />
            {!this.state.imgLoaded && <VisualLoader />}
          </div>
          <div className="description">
            <div className="title">{this.cc('DESCRIPTION', 'Titolo')}</div>
            {descrizione_sottotitolo && <p>{descrizione_sottotitolo}</p>}
            {descrizione_contenuto && (
              <p
                dangerouslySetInnerHTML={{ __html: descrizione_contenuto }}></p>
            )}
          </div>
        </Box>
        {C[pageCode + '_CONTENUTO'] && (
          <div className="boxGiallo contenuto">
            <div className="title">{this.cc('CONTENUTO', 'Titolo')}</div>
            <p dangerouslySetInnerHTML={{ __html: contenuto_contenuto }}></p>
          </div>
        )}
        {this.getChildrenParts()}
        <EQFaqBox category={'FAQ_' + pageCode} />
        <EQFooter></EQFooter>
      </div>
    )
  }
}
