export default class Pages {
  static MAP = {
    '/': {
      title: 'Badanti e assistenti Alzheimer risparmiando | EqueCure',
      cms: 'HOME',
    },
    '/services': {
      title: 'Badanti e assistenti Alzheimer risparmiando | EqueCure',
      cms: 'HOME',
    },
    '/services/100-assistenza-urgente': {
      title: 'Badanti e caregiver subito: come prenotare | EqueCure',
      cms: 'HOME',
    },
    '/services/1-assistenza-programmata': {
      title: 'Hai bisogno di badanti e caregiver? | EqueCure',
      cms: 'HOME',
    },
    '/services/2-aiuto-alla-famiglia': {
      title: 'Alzheimer e fragilità: opportunità e contributi | EqueCure',
      cms: 'HOME',
    },
    '/book/person': {
      title: 'Scegli la persona da assistere | EqueCure',
      cms: '_',
    },
    '/book/medical': {
      title: 'Inserisci i dati salute della persona da assistere | EqueCure',
      cms: '_',
    },
    '/book/location': {
      title: 'Indirizzo della persona da assistere | EqueCure',
      cms: '_',
    },
    '/book/dates': {
      title: 'Quando hai bisogno di assistenza? | EqueCure',
      cms: '_',
    },
    '/book/caregivers': {
      title: 'I professionisti che rispondono alla tua ricerca | EqueCure',
      cms: '_',
    },
    '/book/cart': {
      title: 'Verifica i costi | EqueCure',
      cms: '_',
    },
    '/book/recap': {
      title: 'Controlla che tutto corrisponda | EqueCure',
      cms: '_',
    },
    '/book/doBook': {
      title: 'Pagamento prenotazione | EqueCure',
      cms: '_',
    },
    '/book/success': {
      title: 'Complimenti! La richiesta è stata inviata. | EqueCure',
      cms: 'BOOK',
    },
    '/book/error': {
      title: 'Pagamento prenotazione | EqueCure',
      cms: 'BOOK',
    },
    '/book/retry-pay': {
      title: 'Pagamento prenotazione | EqueCure',
      cms: '_',
    },
    '/book/otp': {
      title: 'Verifica accesso | EqueCure',
      cms: '_',
    },
    '/user/books': {
      title: 'Tutte le tue prenotazioni | Equecure',
      cms: 'PRENOTAZIONI',
    },
    '/user/caregivers': {
      title: 'I tuoi professionisti preferiti | EqueCure',
      cms: 'CAREGIVERS',
    },
    '/user/persons': {
      title: 'I tuoi assistiti | Equecure',
      cms: 'ASSISTITI',
    },

    DEFAULT: {
      title: 'Badanti e assistenti Alzheimer risparmiando | EqueCure',
      cms: 'HOME',
    },
  }

  static current() {
    const url = window.location.pathname
    let result = Pages.MAP[url]
    if (!result) result = Pages.MAP['DEFAULT']
    console.warn('current page', result)
    return result
  }
}
