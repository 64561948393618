import { Box, Button, Grid } from '@mui/material'
import React, { Fragment } from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import EQBusinessRules from '../../libs/EQBusinessRules'
import EQCaregiverCard from '../core/EQCaregiverCard'
import EQFaqBox from '../EQFaqBox'
import EQLoading from '../EQLoading'
import EQService from '../../services/EQService'
import EQState from '../../services/EQState'
import Formats from '../../libs/Formats'
import Pages from '../../services/Pages'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import VolunteerActivismTwoToneIcon from '@mui/icons-material/VolunteerActivismTwoTone'
import { withRouter } from 'react-router-dom'

class EQBookStep_Caregivers extends React.Component {
  constructor(props) {
    super(props)
    EQState.UserSelection.map_tipiProfessionista = null
    EQState.UserSelection.map_cooperative = null
    let minPrice = 999999999
    let maxPrice = 0
    EQState.UserSelection._caregiverCount = Object.keys(
      EQState.UserSelection.map_professionisti
    ).length
    Object.keys(EQState.UserSelection.map_professionisti).forEach((pk) => {
      const p = EQState.UserSelection.map_professionisti[pk]
      console.log('p', p._prezzoTotale, p)
      minPrice = Math.min(minPrice, p._prezzoTotale)
      maxPrice = Math.max(maxPrice, p._prezzoTotale)
    })
    EQState.UserSelection._maxPrice = maxPrice
    EQState.UserSelection._minPrice = minPrice
  }

  componentDidMount() {
    if (!EQState.UserSelection.Solutions_Caregivers) {
      document.location.href = '/'
      return
    }

    const page = Pages.current()
    document.title = page.title
    window.scrollTo(0, 0)

    EQService.getTipiProfessionista().then((x) => {
      EQState.UserSelection.map_tipiProfessionista = {}
      x.forEach(
        (x) =>
          (EQState.UserSelection.map_tipiProfessionista[
            x.idTipoProfessionista
          ] = x)
      )
      this.forceUpdate()
    })

    EQService.getCooperative().then((x) => {
      EQState.UserSelection.map_cooperative = {}
      x.forEach(
        (x) => (EQState.UserSelection.map_cooperative[x.idCooperativa] = x)
      )
      this.forceUpdate()
    })
  }

  goBack() {
    EQState.change(EQState.UserSelection, {
      BookPage: `/book/dates`,
    })
    this.props.history.push({
      pathname: `/book/dates`,
    })
  }

  goForward() {
    EQState.change(EQState.UserSelection, {
      BookPage: `/book/cart`,
    })
    this.props.history.push({
      pathname: `/book/cart`,
    })
  }

  getBgCode() {
    if (EQState.UserSelection.idTipologiaServizio == 100) {
      return 'EMERGENZA'
    } else if (EQState.UserSelection.idTipologiaServizio == 2) {
      return 'FAMILIARE'
    } else {
      return 'DOMICILIARE'
    }
  }

  render() {
    if (!EQState.UserSelection.Solutions_Caregivers) {
      return null
    }

    if (
      !EQState.UserSelection.map_cooperative ||
      !EQState.UserSelection.map_tipiProfessionista
    ) {
      return (
        <EQLoading
          type="boxBianco"
          message="Caricamento informazioni..."
        />
      )
    }

    const isEmergenza = EQBusinessRules.isEmergenza(
      EQState.UserSelection.idTipologiaServizio
    )
    const pname =
      EQState.UserSelection.SelectedRarentalRelation.nome +
      ' ' +
      EQState.UserSelection.SelectedRarentalRelation.cognome

    let canGoForward = false
    if (isEmergenza && EQState.UserSelection.Solutions_Caregivers.length > 0)
      canGoForward = true
    else if (EQState.UserSelection.Solutions_ConfermedCnt > 0)
      canGoForward = true

    return (
      <Fragment>
        <Box>
          <p className="playFair normalWeight textCenter bookstep-start-label">
            Risultato della ricerca per
          </p>
          <div className="row">
            <div className="col1-icon">
              <PersonOutlineIcon />
            </div>
            <div className="col2-icon">
              <h3 className="textCenter patient-name">{pname}</h3>
            </div>
          </div>

          {EQState.UserSelection.Solutions_Caregivers.length === 0 && (
            <>
              <Box
                my={4}
                className="text-danger">
                <p className="playFair normalWeight textCenter">
                  Sfortunatamente non abbiamo trovato professionisti disponibili
                  in questo orario
                </p>
              </Box>{' '}
              <Box my={4}>
                <p
                  className="playFair normalWeight textCenter"
                  style={{ fontSize: '20px' }}>
                  Ma non ti preoccupare.
                  <br />
                  Chiamaci e troveremo <br /> la <b>soluzione giusta per te</b>
                </p>
                <img
                  src="/img/telefono.svg"
                  alt="chiama 0283905453"
                  style={{ textAlign: 'center', width: '100%', height: '40px' }}
                />
              </Box>
            </>
          )}

          {EQState.UserSelection.Solutions_Caregivers.length > 0 && (
            <Fragment>
              {isEmergenza && (
                <div>
                  <div className="textCenter">
                    <div className="bandaGialla box-caregivers">
                      <Box
                        mr={3}
                        ml={3}>
                        <p className="textCenter">
                          <p className="big-text">
                            Abbiamo trovato <br />
                            <b>
                              {EQState.UserSelection._caregiverCount} operator
                              {EQState.UserSelection._caregiverCount > 1
                                ? 'i'
                                : 'e'}
                            </b>
                            .
                          </p>
                          <br /> <b>verifichiamo subito la disponibilità?</b>
                          <br />
                          <VolunteerActivismTwoToneIcon
                            sx={{ fontSize: '60px' }}
                          />
                          <br />
                          Gli operatori hanno tariffe diverse il costo massimo
                          sarà
                          <br />
                          {EQState.UserSelection._caregiverCount > 1 ? (
                            <>
                              tra{' '}
                              <b>
                                {Formats.formatPrice(
                                  EQState.UserSelection._minPrice
                                )}
                                &euro;
                              </b>{' '}
                              e{' '}
                              <b>
                                {Formats.formatPrice(
                                  EQState.UserSelection._maxPrice
                                )}
                                &euro;
                              </b>
                            </>
                          ) : (
                            <b>
                              {Formats.formatPrice(
                                EQState.UserSelection._maxPrice
                              )}
                              &euro;
                            </b>
                          )}
                          <br />a seconda di chi sarà disponibile
                        </p>
                      </Box>
                    </div>
                  </div>
                  <Grid
                    className="book-navigation shrink"
                    container
                    mt={-3}
                    mb={2}>
                    <Grid
                      item
                      sx={{ textAlign: 'center' }}
                      xs={12}
                      className="flex-row-center px-5">
                      {canGoForward && (
                        <a
                          className={`buttonLink lnkForward  button-${this.getBgCode()}`}
                          onClick={() => this.goForward()}>
                          OK, VERIFICATE LA DISPONIBILITÀ
                        </a>
                      )}
                    </Grid>
                  </Grid>
                </div>
              )}

              {!isEmergenza && (
                <div>
                  {EQState.UserSelection.Solutions_Caregivers.map((c) => {
                    return (
                      <EQCaregiverCard
                        style={{ marginTop: '16px', marginBottom: '40px' }}
                        caregiver={c}
                        key={c.idProfessionista}
                        handleGoForward={() => this.goForward()}
                      />
                    )
                  })}
                </div>
              )}
            </Fragment>
          )}

          <Grid
            className="book-navigation shrink"
            container
            mt={0}
            mb={0}>
            <Grid
              item
              xs={6}>
              <Button
                className="lnkButton lnkBack"
                startIcon={<ArrowBackIcon />}
                onClick={() => this.goBack()}>
                Indietro
              </Button>
            </Grid>
            <Grid
              item
              sx={{ textAlign: 'right' }}
              xs={6}>
              {canGoForward && (
                <a
                  className={`buttonLink lnkForward  button-${this.getBgCode()}`}
                  onClick={() => this.goForward()}>
                  AVANTI
                </a>
              )}
              {!canGoForward && (
                <a
                  className={`buttonLink lnkForward disabled  button-${this.getBgCode()}`}>
                  AVANTI
                </a>
              )}
            </Grid>
          </Grid>
        </Box>

        <Box>
          <EQFaqBox category="Caregivers"></EQFaqBox>
        </Box>
      </Fragment>
    )
  }
}

export default withRouter(EQBookStep_Caregivers)
