import { Typography } from '@mui/material'
import Card from '@mui/material/Card'
import React from 'react'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import EQState from '../../services/EQState'
import EQCaregiverModal from './EQCaregiverModal'
import EQService from '../../services/EQService'
import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone'

export default class EQFavCaregiverCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: true,
    }
  }

  reloadState() {
    this.setState({
      UserSelection: EQState.UserSelection,
    })
  }

  componentDidMount() {
    EQState.addListener(EQState.UserSelection, this)
  }

  componentWillUnmount() {
    EQState.removeListener(EQState.UserSelection, this)
  }

  showInfo(e) {
    e.stopPropagation()
    e.preventDefault()
    this.setState({
      showInfo: true,
    })
  }

  closeInfo() {
    this.setState({
      showInfo: false,
    })
  }

  setPreferred(flag) {
    if (flag) {
      EQService.setProfessionistaPreferito(this.props.caregiver).then((pps) => {
        EQState.change(EQState.UserSelection, {
          PreferredCaregivers: pps,
          PreferredCaregivers_Map: pps.map((x) => x.idProfessionista),
        })
      })
    } else {
      this.props.caregiver._hide = true
      EQService.unsetProfessionistaPreferito(this.props.caregiver).then(
        (pps) => {
          EQState.change(EQState.UserSelection, {
            PreferredCaregivers: pps,
            PreferredCaregivers_Map: pps.map((x) => x.idProfessionista),
          })
        }
      )
    }
  }

  render() {
    const { caregiver, sx, className, handleGoForward, ...others } = this.props
    const cn = className ? 'caregiver-card ' + className : 'caregiver-card'

    if (caregiver._hide) return null

    let preferito = true

    let tipoProfessionista = { nome: '' }
    if (EQState.UserSelection.map_tipiProfessionista)
      tipoProfessionista =
        EQState.UserSelection.map_tipiProfessionista[
          caregiver.idTipoProfessionista
        ]

    let cooperativa = { nome: '' }
    if (EQState.UserSelection.map_cooperative)
      cooperativa =
        EQState.UserSelection.map_cooperative[caregiver.idCooperativa]

    return (
      <Card
        {...others}
        sx={{ display: 'flex' }}
        className={cn}>
        {this.state.showInfo && (
          <EQCaregiverModal
            onClose={() => this.closeInfo()}
            caregiver={caregiver}></EQCaregiverModal>
        )}

        <div className="card-body">
          <div className="fav-outer">
            <div
              className="fav-inner"
              onClick={() => this.setPreferred(!preferito)}>
              {preferito ? <FavoriteIcon /> : <FavoriteBorderIcon />}
            </div>
          </div>

          <div className="row">
            <div className="col1">
              <div className="img-outer">
                <img
                  src={caregiver.urlImmagineCropped}
                  alt={caregiver.nome}
                />
              </div>
            </div>
            <div className="col2">
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                className="name playFair">
                {caregiver.nome}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                className="role">
                {tipoProfessionista.nome}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                className="company">
                Cooperativa {cooperativa.nome}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                className="company">
                da €{this.props.caregiver.fasciaTariffaria.tariffaDiurnaFeriale}
                /h
              </Typography>
            </div>
          </div>

          <div
            className="row"
            style={{ height: '20px', marginTop: '10px', marginBottom: '10px' }}>
            <div className="col1 clickable">
              <AutoAwesomeTwoToneIcon />
            </div>
            <div className="col2 clickable">
              <Typography
                variant="body2"
                color="text.secondary"
                className="textLeft underline"
                onClick={(e) => this.showInfo(e)}>
                <span>Leggi il curriculum</span>
              </Typography>
            </div>
          </div>
        </div>
      </Card>
    )
  }
}
