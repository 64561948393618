import EQFaqBox from '../../components/EQFaqBox'
//import EQState from '../../services/EQState'
import PageEditorial from '../PageEditorial'

export default class PageScopriDiPiu extends PageEditorial {
  getPageCode() {
    return 'SCOPRI_DI_PIU'
  }

  getChildrenParts() {
    //const pageCode = this.getPageCode()
    //const C = EQState.Contents[pageCode]
    return (
      <div>
        <div className="description2">
          <div className="title">{this.cc('DESCRIPTION2', 'Titolo')}</div>
          <p
            dangerouslySetInnerHTML={{
              __html: this.cc('DESCRIPTION2', 'Contenuto'),
            }}></p>
        </div>
        <EQFaqBox category={'ScopriDiPiu'} />
      </div>
    )
  }
}
