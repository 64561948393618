import { Add, Remove } from '@mui/icons-material'

import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import { Box } from '@mui/material'
import EQServiceBook from '../services/EQServiceBook'
import React from 'react'

export default class EQFaqBox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      faqs: null,
      expanded: false,
    }
  }

  componentDidMount() {
    EQServiceBook.mapFaq().then((f) => {
      this.setState({ faqs: f })
    })
  }

  handleChange = (panel) => (event, isExpanded) => {
    // setExpanded(isExpanded ? panel : false); // //previous state manager , now not needed //
    isExpanded
      ? this.setState({ expanded: panel })
      : this.setState({ expanded: false }) //new state manager//
  }

  isWhiteOrGray = () => {
    if (this.isFaqsPage() || this.isServicesPage()) {
      return 'white'
    }
    return '#eee'
  }

  isFaqsPage = () => {
    return window.location.pathname === '/faq'
  }

  isServicesPage = () => {
    return (
      window.location.pathname === '/i-nostri-servizi' && this.props.isItWhite
    )
  }

  render() {
    if (!this.state.faqs) return null

    let ffs = []
    this.state.faqs.forEach((faq) => {
      if (faq.faqCate.fldNAME !== this.props.category) return
      ffs.push(faq)
    })

    if (ffs.length === 0) {
      return <div style={{ display: 'none' }}>{this.props.category}</div>
    }

    return (
      <>
        {(this.isFaqsPage() === false || this.isServicesPage()) &&
        this.props.showSeparator ? (
          <Box
            mt={4}
            className="separatorContainer"
            my={6}>
            <img
              src="/img/separator.png"
              alt="Separatore"
            />
          </Box>
        ) : null}
        <div
          className="faq-box"
          style={
            this.isWhiteOrGray() === 'white'
              ? {
                  paddingRight: '0',
                  backgroundColor: `${this.isWhiteOrGray()}`,
                  marginBottom: '30px',
                  boxShadow: `${
                    this.isServicesPage() ? 'unset' : '0 20px 10px -10px #ccc'
                  }`,
                  padding: '10px',
                }
              : {
                  paddingRight: '0',
                  backgroundColor: `${this.isWhiteOrGray()}`,
                  margin: '10px 0',
                  marginBottom: '30px',
                  boxShadow: '0 20px 10px -10px #ccc',
                  borderRadius: '25px 25px 0 0',
                  padding: '10px',
                }
          }>
          {this.props.showTitle && (
            <h2 className="textCenter playFair normalWeight">
              Domande e risposte
            </h2>
          )}
          <div style={{ display: 'none' }}>{this.props.category}</div>
          {this.state.faqs &&
            ffs.map((faq) => {
              return (
                <Box key={faq.id}>
                  <Accordion
                    expanded={this.state.expanded === 'panel' + faq.id}
                    onChange={this.handleChange('panel' + faq.id)}
                    sx={{
                      borderColor: '#CCE5FF',
                      borderRadius: '0 !important',
                      marginBottom: '.7em',
                      borderBottom: '1px solid transparent',
                      borderImage: this.isServicesPage()
                        ? ''
                        : `linear-gradient(to right, ${this.isWhiteOrGray()} 15%, #666 15% 85% ,${this.isWhiteOrGray()} 85%)`,
                      borderImageSlice: 1,
                      boxShadow: 'none',
                      fontFamily: 'Raleway',
                    }}
                    disableGutters={true}>
                    <AccordionSummary
                      expandIcon={
                        this.state.expanded === 'panel' + faq.id ? (
                          <Remove />
                        ) : (
                          <Add />
                        )
                      }
                      sx={{
                        paddingRight: '0',
                        backgroundColor: `${this.isWhiteOrGray()}`,
                      }}
                      aria-controls={`panel${faq.id}d-content`}
                      id={`panel${faq.id}d-header`}>
                      <div
                        className="playFair question"
                        style={{ fontSize: '18px' }}
                        dangerouslySetInnerHTML={{
                          __html: faq.fldQUESTION,
                        }}></div>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        backgroundColor: `${this.isWhiteOrGray()}`,
                      }}>
                      <div
                        style={{ lineHeight: '22px' }}
                        className="answer"
                        dangerouslySetInnerHTML={{
                          __html: faq.fldSOLUTION,
                        }}></div>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              )
            })}

          {this.props.areThereOtherQuestions && !this.isFaqsPage() && (
            <Box
              mt={4}
              mb={4}
              className="textCenter playFair asLink">
              <a href="/faq">Hai altre domande? Qui trovi le risposte!</a>
            </Box>
          )}
        </div>
      </>
    )
  }
}

EQFaqBox.defaultProps = {
  showTitle: true,
  isItWhite: false,
  areThereOtherQuestions: true,
  showSeparator: true,
}
