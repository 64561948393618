export default class Utils {
  static lightenDarkenColor(col, amt) {
    var usePound = false
    if (col[0] === '#') {
      col = col.slice(1)
      usePound = true
    }

    var num = parseInt(col, 16)

    var r = (num >> 16) + amt

    if (r > 255) r = 255
    else if (r < 0) r = 0

    var b = ((num >> 8) & 0x00ff) + amt

    if (b > 255) b = 255
    else if (b < 0) b = 0

    var g = (num & 0x0000ff) + amt

    if (g > 255) g = 255
    else if (g < 0) g = 0

    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
  }

  static refill(obj, newObj) {
    Object.getOwnPropertyNames(obj).forEach(function (prop) {
      delete obj[prop]
    })
    Object.getOwnPropertyNames(newObj).forEach(function (prop) {
      obj[prop] = newObj[prop]
    })
    console.log('obj after', obj)
  }

  static notifyError(msg) {
    document.getElementById('ll_alert_msg').innerHTML = msg
    document.getElementById('ll_alert_div').style.display = 'block'
    setTimeout(() => {
      document.getElementById('ll_alert_div').style.display = 'none'
    }, 4000)
  }

  static notifyConfirm(msg) {
    document.getElementById('ll_alert_msg_confirm').innerHTML = msg
    document.getElementById('ll_alert_div_confirm').style.display = 'block'
    setTimeout(() => {
      document.getElementById('ll_alert_div_confirm').style.display = 'none'
    }, 4000)
  }

  static urlify(str) {
    return str.replace(/\s+/g, '-').toLowerCase()
  }

  static sliceAddress() {
    const parts = window.location.pathname.split('/')
    return parts
  }

  static sliceAddressPart(ps) {
    const parts = ps.split('-')
    return parts
  }
}
