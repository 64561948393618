import React from 'react'
import { Box, Typography } from '@mui/material'
import EQService from '../services/EQService'

export default class EQInfoBox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      content: null,
    }
  }

  componentDidMount() {
    EQService.getContent(this.props.code).then((m) => {
      this.setState({ content: m })
    })
  }

  render() {
    if (!this.state.content) return null

    const MC = this.state.content
    let cs = this.props.className
    let showImage = false

    if (this.props.image) {
      showImage = true
    }

    if (!cs) {
      cs = ''
    }

    return (
      <div>
        <Box
          className={cs}
          alignItems="center">
          {showImage && (
            <div>
              <img
                className="infoBox_img"
                src={MC.urlImmagine}
                alt={MC.SottoTitolo}
                title={MC.SottoTitolo}
              />
            </div>
          )}
          <Typography
            pt={2}
            variant="h5">
            {MC.Titolo}
          </Typography>
          <div
            dangerouslySetInnerHTML={{ __html: MC.Contenuto }}
            py={2}></div>
          {MC.EtichettaLink && (
            <Box
              pb={2}
              pt={2}>
              <a
                href={MC.Link}
                className="buttonLink">
                {MC.EtichettaLink}
              </a>
            </Box>
          )}
        </Box>
      </div>
    )
  }
}
