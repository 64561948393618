import { Box, Grid, Modal } from '@mui/material'
import React, { Fragment } from 'react'

import DateAdapter from '@mui/lab/AdapterDateFns'
import EQCalendarStates from '../../libs/EQCalendarStates'
import EQProfTypes from '../../libs/EQProfTypes'
import EQSelect from '../core/EQSelect'
import EQServiceCaregiver from '../../services/EQServiceCaregiver'
import EQState from '../../services/EQState'
import Formats from '../../libs/Formats'
import InfoIcon from '@mui/icons-material/Info'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { MenuItem } from 'material-ui'
import { MobileDatePicker } from '@mui/lab'
import TextField from '@mui/material/TextField'
import TimePicker from '@mui/lab/TimePicker'
import Utils from '../../libs/Utils'
import it from 'date-fns/locale/it'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  width: '80%',
  bgcolor: 'background.paper',
  border: '0',
  boxShadow: 24,
  p: 2,
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
}

export default class EQFilterCalendarModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedFilter: props.selectedFilter,
    }
    console.log('state', this.state)
  }

  closeModal(f) {
    if (this.props.onClose) {
      this.props.onClose(f)
    }
  }

  cancelOperation() {
    this.closeModal(null)
  }

  handleFilterChange(event) {
    let selector = event.target
    this.setState({
      selectedFilter: selector.value,
    })
  }

  changeFilter() {
    this.closeModal(this.state.selectedFilter)
  }

  render() {
    let professionista = EQState.CaregiverHome.Professionista

    let what = 'Ferie o permessi confermati'
    if (professionista.idInquadramento !== EQProfTypes.Dipendente) {
      what = 'Indisponibilità'
    }

    let modeOptions = [
      <MenuItem
        key={0}
        value={'none'}>
        Tutti
      </MenuItem>,
      <MenuItem
        key={1}
        value={EQCalendarStates.ServizioConfermatoDalProfessionista}>
        Servizi confermati
      </MenuItem>,
      <MenuItem
        key={2}
        value={EQCalendarStates.ServizioInAttesaDiConfermaProfessionista}>
        Servizi in attesa di conferma
      </MenuItem>,
      <MenuItem
        key={3}
        value={EQCalendarStates.ServizioIniziato}>
        Servizi iniziati
      </MenuItem>,
      <MenuItem
        key={4}
        value={EQCalendarStates.ServizioFinito}>
        Servizi terminati
      </MenuItem>,
      <MenuItem
        key={5}
        value={EQCalendarStates.RichiestaFerie}>
        Richieste ferie o permessi
      </MenuItem>,
      <MenuItem
        key={6}
        value={EQCalendarStates.FerieConfermate}>
        {what}
      </MenuItem>,
    ]

    return (
      <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box
            mt={0}
            p={2}>
            <div className="textCenter playFair app-text modal-title">
              Filtro calendario
            </div>

            <form>
              <EQSelect
                label="Mostra"
                defaultValue={
                  this.state.selectedFilter ? this.state.selectedFilter : 'none'
                }
                onChange={(e) => this.handleFilterChange(e)}>
                {modeOptions}
              </EQSelect>
            </form>

            <Grid
              container
              mt={2}
              mb={2}>
              <Grid
                item
                sx={{ textAlign: 'left' }}
                xs={6}>
                <a
                  className="buttonLink lnkForward"
                  onClick={() => this.cancelOperation()}>
                  ANNULLA
                </a>
              </Grid>
              <Grid
                item
                sx={{ textAlign: 'right' }}
                xs={6}>
                <a
                  className="buttonLink lnkForward"
                  onClick={() => this.changeFilter()}>
                  CONTINUA
                </a>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    )
  }
}
