import React, { Fragment } from 'react'
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/HighlightOff'
import EQServiceCaregiver from '../../services/EQServiceCaregiver';
import EQAdditionalInfoTypes from '../../libs/EQAdditionlInfoTypes';
import EQLoading from '../EQLoading';
import EQServiceReport_View from './EQServiceReport_View';
import EQServiceReport from './EQServiceReport';
import Formats from '../../libs/Formats';

export default class EQPatientDiary extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            additionalInfos: null
        }
    }

    closeModal() {
        if (this.props.onClose) this.props.onClose()
    }

    componentDidMount() {
        this.loadAdditionalInfos();
    }

    loadAdditionalInfos() {
        let idPaziente = this.props.selectedEvent.servizioPrenotazione.prenotazione.idPaziente
        EQServiceCaregiver.listAdditionalInfoForPatient(idPaziente,EQAdditionalInfoTypes.HTML_COMPLETE) 
          .then((ais) => {
            //console.log("ais",ais.data);
            let sorted=ais.data.sort((a,b) => {
                let d1=Formats.fromServerDate(a.calendario.servizioPrenotazione.richiestoDal);
                let d2=Formats.fromServerDate(b.calendario.servizioPrenotazione.richiestoDal);
                return d1.getTime() - d2.getTime();
            });
            this.setState({
              additionalInfos: ais.data
            })
          })
    }

    openDetail(ai) {
        this.setState({
            selectedAdditionalInfo: ai
        })
    }

    cancelReport() {
        this.setState({
            selectedAdditionalInfo: null
        })
    }
    
    render() {
        const e = this.props.selectedEvent
        //console.log("e",e)

        if(this.state.selectedAdditionalInfo) {
            return (
                <EQServiceReport
                    onClose={() => this.cancelReport()}
                    selectedEvent={e}
                    selectedAdditionalInfo={this.state.selectedAdditionalInfo}
                    />
            )
        }

        let dayNames = Formats.dayNames()
        let monthNames = Formats.monthNames()

        let rows=[]
        if(this.state.additionalInfos) {
            rows=this.state.additionalInfos.map(ai => {
                let appointmentDate = Formats.fromServerDate(ai.calendario.servizioPrenotazione.richiestoDal);
                return (
                    <Fragment key={ai.idCalendarioAdditionalInfo}>
                        <div className="bookCard mb-30 silverGray-shadow-sm" onClick={() => this.openDetail(ai)}>
                            <div className="serviceBody">
                                <div className="serviceTitle clickable" style={{paddingLeft:'16px'}}>
                                    <img className="infoIcon" src="/img/ico_i.png" alt="Dettagli" />
                                    <label>
                                        <span className="dateText">
                                            {dayNames[appointmentDate.getDay()]}{' '}
                                            {appointmentDate.getDate()}{' '}
                                            {monthNames[appointmentDate.getMonth()]}
                                        </span>
                                        &mdash; {ai.calendario.professionista.cognomeENome}
                                    </label>
                                    <div>
                                        <strong>{ai.calendario.servizioPrenotazione.servizio.nome}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )
            });
        }

        return (
            <div className="helpModal eventModal stats-modal">
                <Box style={{ marginTop: '78px' }} px={2} mb={4}>
                    <div className="iconClose-outer">
                        <button onClick={() => this.closeModal()} className="iconClose clickable">
                            <Box mt={1} mr={0}>
                                <CloseIcon></CloseIcon>
                            </Box>
                            <div>CHIUDI</div>
                        </button>
                    </div>
                    <div className="playFair normalWeight textCenter bookstep-start-label">Diario dell'assistito</div>
                    <div className="textCenter playFair ">{' '}qui trovi tutti i report post servizio relativi al paziente</div>
                    {this.state.additionalInfos &&
                        <div className="mt-40">
                            {rows}
                        </div>
                    }
                    {!this.state.additionalInfos &&
                        <EQLoading />
                    }
                </Box>
            </div>
        );
    }
}
