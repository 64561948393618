import { Box, Grid, Modal } from '@mui/material'

import EQLoading from '../EQLoading'
import EQServiceBook from '../../services/EQServiceBook'
import Formats from '../../libs/Formats'
import React from 'react'
import Utils from '../../libs/Utils'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  width: '80%',
  bgcolor: '#ffe371',
  border: '0',
  boxShadow: 24,
  p: 2,
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
}

export default class CancelBookNodal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      penali: null,
    }
  }

  componentDidMount() {
    this.loadPenali()
  }

  cancelOperation() {
    if (this.props.onCancel) {
      this.props.onCancel()
    }
  }

  loadPenali() {
    EQServiceBook.getPenali(this.props.booking.idCalendario).then((x) => {
      this.setState({
        penali: x,
      })
    })
  }

  actuallyDoOperation() {
    EQServiceBook.cancellaAppuntamento(this.props.booking.idCalendario).then(
      (x) => {
        EQServiceBook.getAppuntamento(this.props.booking.idCalendario).then(
          (x) => {
            Utils.refill(this.props.booking, x.data)
            if (this.props.onDone) {
              this.props.onDone()
            }
          }
        )
      }
    )
  }

  render() {
    console.log('booking', this.props.booking)
    return (
      <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          {!this.state.penali && (
            <EQLoading message="Caricamento politiche di cancellazione..." />
          )}

          {this.state.penali && (
            <Box
              mt={0}
              p={2}>
              <div className="textCenter playFair app-text modal-title">
                <b>Vuoi cancellare l'appuntamento?</b>
              </div>
              <div className="textCenter">
                {this.state.penali.percentualeApplicata !== 0 && (
                  <div className="cancellation-policy">
                    Nel caso di cancellazione, entro le ore&nbsp;
                    {Formats.dateToHourMinutes(
                      Formats.fromServerDate(this.state.penali.dataLimite)
                    )}
                    &nbsp;del&nbsp;
                    {Formats.dateToDayMonthShort(
                      Formats.fromServerDate(this.state.penali.dataLimite)
                    )}
                    &nbsp;verrà applicata una penale del&nbsp;
                    {this.state.penali.percentualeApplicata}% pari a&nbsp;
                    {this.state.penali.importoPenale}&euro;
                  </div>
                )}
                {this.state.penali.percentualeApplicata === 0 && (
                  <div className="cancellation-policy">
                    La cancellazione è gratuita fino alle ore&nbsp;
                    {Formats.dateToHourMinutes(
                      Formats.fromServerDate(this.state.penali.dataLimite)
                    )}
                    &nbsp;del&nbsp;
                    {Formats.dateToDayMonthShort(
                      Formats.fromServerDate(this.state.penali.dataLimite)
                    )}
                  </div>
                )}
              </div>
              <Grid
                container
                mt={0}
                mb={2}>
                <Grid
                  item
                  sx={{ textAlign: 'left' }}
                  xs={6}>
                  <a
                    className="buttonLink bg-red text-white lnkForward"
                    onClick={() => this.cancelOperation()}>
                    NO
                  </a>
                </Grid>
                <Grid
                  item
                  sx={{ textAlign: 'right' }}
                  xs={6}>
                  <a
                    className="buttonLink bg-green text-white lnkForward"
                    onClick={() => this.actuallyDoOperation()}>
                    SI
                  </a>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Modal>
    )
  }
}
