import { Box, Modal, Typography } from '@mui/material'

import React from 'react'

const style = {
  position: 'absolute',
  top: '20px',
  left: '50%',
  transform: 'translate(-50%, 0)',
  maxWidth: 400,
  width: '86%',
  bgcolor: '#ffe371',
  border: '0',
  boxShadow: 24,
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  outline: 'none',
}

export default class EQBook_Info extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      content: null,
    }
  }

  closeModal() {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  defaultClick() {
    this.closeModal()
  }

  render() {
    //const MC = {}
    return (
      <Modal
        open={true}
        className="yellow-modal"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box
          sx={style}
          pt={1}
          px={2}
          className="helpModal"
          onClick={() => this.defaultClick()}>
          <Box
            mt={1}
            sx={{ textAlign: 'center' }}>
            <img
              style={{ maxWidth: '100px' }}
              src="/img/pay_logos.png"
              alt="pay logos"
            />
            <Typography
              className="playFair"
              id="pay-info-modal-modal-title"
              variant="h5"
              component="h2">
              Non addebitiamo niente fino all’inizio del servizio. Potrai
              disdire la prenotazione.
            </Typography>
            <p>
              Fornendo i dati della tua carta, consenti a EqueCure Srl di
              addebitare su di essa i futuri pagamenti in base a quanto previsto
              dai relativi termini e condizioni.
            </p>
          </Box>
          <Box
            mt={1}
            sx={{ textAlign: 'center' }}>
            <img
              style={{ maxWidth: '50px' }}
              src="/img/pay_cross.png"
              alt="pay cross"
            />
            <Typography
              className="playFair"
              id="pay-info-modal-modal-title"
              variant="h5"
              component="h2">
              La cancellazione è gratuita fino a 3 giorni prima dell'inizio del
              servizio.
            </Typography>
            {/*}p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.</p>*/}
            <div
              className="dotted-items-table playFair"
              style={{ marginBottom: '0' }}>
              <div>
                <div
                  className="first"
                  style={{ fontWeight: 'bold' }}>
                  Fino a 3 giorni (72 ore) prima dell’appuntamento
                </div>
                <div
                  className="last"
                  style={{ fontWeight: 'bold' }}>
                  gratis
                </div>
              </div>
              <div>
                <div className="first">
                  Oltre 72 ore prima dell’appuntamento
                </div>
                <div className="last">100%</div>
              </div>
              <div></div>
            </div>
          </Box>

          {this.props.showBookButton && (
            <Box
              mt={0}
              sx={{ textAlign: 'center', position: 'relative', top: '40px' }}>
              <a
                className="buttonLink lnkForward bg-green text-white with-shadow"
                onClick={() => this.closeModal()}>
                PRENOTA
              </a>
            </Box>
          )}
          {!this.props.showBookButton && (
            <Box
              mt={0}
              sx={{ textAlign: 'center', position: 'relative', top: '40px' }}>
              <a
                className="playFair"
                style={{ textDecoration: 'underline' }}
                onClick={() => this.closeModal()}>
                Chiudi
              </a>
            </Box>
          )}
        </Box>
      </Modal>
    )
  }
}
