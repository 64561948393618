import React from 'react'
import { Box, FormControlLabel, Typography } from '@mui/material'
import EQSquareCheckbox from './core/EQSquareCheckbox'
import EQTextField from './core/EQTextField'

export default class EQNewsletter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      cbChecked: false,
    }
  }

  updateCbValue(evt) {
    const field = evt.target.id
    this.setState({
      [field]: evt.target.checked,
    })
  }

  subscribeNL() {
    try {
      window.gtag('event', 'clickNewsletter', {})
    } catch (error) {}
  }

  render() {
    return (
      <Box
        mt={4}
        borderRadius={5}
        px={2}
        py={2}
        className="newsletterBox">
        <Box
          pt={1}
          pb={2}>
          <img
            src="/img/aereoplano_temp.png"
            alt="Aereoplano"
          />
        </Box>
        <Typography
          px={2}
          className="titleNL"
          variant="h4">
          Non hai ancora deciso? Rimaniamo in contatto.
        </Typography>
        <Box
          px={1}
          pt={2}>
          <EQTextField
            sx={{ backgroundColor: '#fff' }}
            fullWidth
            label="INSERISCI LA TUA EMAIL"></EQTextField>
        </Box>
        <FormControlLabel
          style={{ width: '100%', paddingLeft: '8px' }}
          control={
            <EQSquareCheckbox
              id="cbChecked"
              required
            />
          }
          label={
            <small>
              Ho letto la{' '}
              <a
                className="linkunderlined"
                href="/privacy"
                target="_blank">
                privacy policy
              </a>{' '}
              e acconsento
            </small>
          }
          onChange={(evt) => this.updateCbValue(evt)}
        />

        <Box
          px={1}
          py={2}
          className="textCenter">
          <a className="buttonBlue">SI, MANDATEMI AGGIORNAMENTI</a>
        </Box>
      </Box>
    )
  }
}
