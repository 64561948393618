import React from 'react'

export default class EQDaSapere extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { label, disabled, ...others } = this.props
    const lblcss = disabled ? 'select-label disabled' : 'select-label'
    return (
      <div className="da-sapere">
        <div className="label">Da sapere</div>
        <div className="exclamation-logo">
          <span>!</span>
        </div>
      </div>
    )
  }
}
