import AddCircleIcon from '@mui/icons-material/AddCircle'
import Card from '@mui/material/Card'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'
import DeleteIcon from '@mui/icons-material/Delete'
import EQAlternatives from './EQAlternatives'
import EQCaregiverModal from '../core/EQCaregiverModal'
import EQService from '../../services/EQService'
import EQState from '../../services/EQState'
import EventIcon from '@mui/icons-material/CalendarMonthTwoTone'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import FavoriteIcon from '@mui/icons-material/Favorite'
import Formats from '../../libs/Formats'
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone'
import React from 'react'
/* eslint-disable react/no-direct-mutation-state */
import { Typography } from '@mui/material'

export default class EQCaregiverCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: true,
    }
  }

  reloadState() {
    this.setState({
      UserSelection: EQState.UserSelection,
    })
  }

  componentDidMount() {
    EQState.addListener(EQState.UserSelection, this)
  }

  componentWillUnmount() {
    EQState.removeListener(EQState.UserSelection, this)
  }

  confirmServizio(sp) {
    sp._confirmed = true
    sp._confirmedFor = this.props.caregiver.idProfessionista
    EQState.change(EQState.UserSelection, {
      Solutions_ConfermedCnt: EQState.UserSelection.Solutions_ConfermedCnt + 1,
    })
  }

  unconfirmServizio(sp) {
    sp._confirmed = false
    sp._confirmedFor = null

    // Gestiamo se era una soluzione alternativa
    if (sp._alternativaPer) {
      sp._alternativaPer._alternativeSelected = false
      let idx = this.props.caregiver._serviziPrenotazione.indexOf(sp)
      this.props.caregiver._serviziPrenotazione.splice(idx, 1)
    }

    EQState.change(EQState.UserSelection, {
      Solutions_ConfermedCnt: Math.max(
        0,
        EQState.UserSelection.Solutions_ConfermedCnt - 1
      ),
    })
  }

  showInfo(e) {
    e.stopPropagation()
    e.preventDefault()
    this.setState({
      showInfo: true,
    })
  }

  closeInfo() {
    this.setState({
      showInfo: false,
    })
  }

  setPreferred(flag) {
    if (flag) {
      EQService.setProfessionistaPreferito(this.props.caregiver).then((pps) => {
        EQState.change(EQState.UserSelection, {
          PreferredCaregivers: pps,
          PreferredCaregivers_Map: pps.map((x) => x.idProfessionista),
        })
      })
    } else {
      EQService.unsetProfessionistaPreferito(this.props.caregiver).then(
        (pps) => {
          EQState.change(EQState.UserSelection, {
            PreferredCaregivers: pps,
            PreferredCaregivers_Map: pps.map((x) => x.idProfessionista),
          })
        }
      )
    }
  }

  searchAlternative(sp) {
    this.setState({
      solutionToChange: sp,
    })
  }

  closeAlteratives() {
    this.setState({
      solutionToChange: null,
    })
  }

  selectAlternative(sp) {
    // Memorizziamo l'alternativa selezionata
    this.state.solutionToChange._alternativeSelected = true

    // Sintetizziamo una soluzione con la giusta struttura
    this.props.caregiver._serviziPrenotazione.push({
      _alternativaPer: this.state.solutionToChange,
      richiestoDal: sp.dal,
      richiestoAl: sp.al,
      _confirmed: true,
      _confirmedFor: this.props.caregiver.idProfessionista,
      soluzioneTrovatas: [
        {
          idProfessionista: this.props.caregiver.idProfessionista,
          prezzo: sp.prezzo,
        },
      ],
    })
    this.state.solutionToChange = null
    EQState.change(EQState.UserSelection, {
      Solutions_ConfermedCnt: EQState.UserSelection.Solutions_ConfermedCnt + 1,
    })
  }

  selectAll() {
    this.props.caregiver._serviziPrenotazione.forEach((sp) => {
      let blocked = false
      if (
        sp._confirmed &&
        sp._confirmedFor !== this.props.caregiver.idProfessionista
      ) {
        blocked = true
      }
      if (!blocked) {
        sp._confirmed = true
        sp._confirmedFor = this.props.caregiver.idProfessionista
        this.confirmServizio(sp)
      }
      this.forceUpdate()
    })
  }

  handleClickOnServizio(sp) {
    let blocked = false
    let confirmed = false
    if (
      sp._confirmed &&
      sp._confirmedFor === this.props.caregiver.idProfessionista
    ) {
      confirmed = true
    }
    if (
      sp._confirmed &&
      sp._confirmedFor !== this.props.caregiver.idProfessionista
    ) {
      blocked = true
    }

    if (!blocked && !confirmed) {
      this.confirmServizio(sp)
    } else if (confirmed) {
      this.unconfirmServizio(sp)
    }
  }

  render() {
    const { caregiver, sx, className, handleGoForward, ...others } = this.props
    const coverage = caregiver._coverage.toFixed(1) * 100
    const cn = className ? 'caregiver-card ' + className : 'caregiver-card'

    // Mappiamo le date in cui il caregiver è disponibile
    let disponibili = []
    caregiver._serviziPrenotazione.forEach((sp) => {
      disponibili.push(sp.idServizioPrenotazione)
    })

    // Determiniamo le soluzioni non disponibili
    let nonDisponibili = []
    EQState.UserSelection.Solutions_Results.servizioPrenotaziones.forEach(
      (sp) => {
        if (disponibili.indexOf(sp.idServizioPrenotazione) >= 0) return null
        nonDisponibili.push(sp)
      }
    )

    let preferito = false
    if (
      EQState.UserSelection.PreferredCaregivers_Map.indexOf(
        caregiver.idProfessionista
      ) >= 0
    )
      preferito = true

    let tipoProfessionista = { nome: '' }
    if (EQState.UserSelection.map_tipiProfessionista)
      tipoProfessionista =
        EQState.UserSelection.map_tipiProfessionista[
          caregiver.idTipoProfessionista
        ]

    let cooperativa = { nome: '' }
    if (EQState.UserSelection.map_cooperative)
      cooperativa =
        EQState.UserSelection.map_cooperative[caregiver.idCooperativa]

    return (
      <Card
        {...others}
        sx={{ display: 'block' }}
        className={cn}>
        {this.state.showInfo && (
          <EQCaregiverModal
            onClose={() => this.closeInfo()}
            caregiver={caregiver}></EQCaregiverModal>
        )}

        {this.state.solutionToChange && (
          <EQAlternatives
            onClose={() => this.closeAlteratives()}
            caregiver={caregiver}
            solution={this.state.solutionToChange}
            onSelect={(sp) => this.selectAlternative(sp)}></EQAlternatives>
        )}

        <div className="fav-outer">
          <div
            className="fav-inner"
            onClick={() => this.setPreferred(!preferito)}>
            {preferito ? <FavoriteIcon /> : <FavoriteBorderIcon />}
          </div>
        </div>

        <div className="card-body">
          <div className="row">
            <div className="col1">
              <div className="img-outer">
                <img
                  src={caregiver.urlImmagineCropped}
                  alt={caregiver.nome}
                />
              </div>
            </div>
            <div className="col2">
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                className="name playFair">
                {caregiver.nome}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                className="role">
                {tipoProfessionista.nome}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                className="company">
                {cooperativa.nome}
                <br />
                da {Formats.formatPrice(caregiver._prezzoTotale)} &euro;
              </Typography>
            </div>
          </div>

          <div className="row">
            <div className="col1">
              <Typography
                variant="body2"
                color="text.secondary"
                className="coverage textRight">
                {coverage}%
              </Typography>
            </div>
            <div className="col2">
              <Typography
                variant="body2"
                color="text.secondary"
                className="coverage textLeft">
                delle ore richieste è disponibile
              </Typography>
            </div>
          </div>

          <div
            className="row"
            style={{ height: '20px', paddingBottom: '6px' }}>
            <div className="col1">
              <InfoTwoToneIcon />
            </div>
            <div className="col2">
              <Typography
                variant="body2"
                color="text.secondary"
                className="textLeft"
                onClick={(e) => this.showInfo(e)}>
                <span>Leggi il curriculum</span>
              </Typography>
            </div>
          </div>

          {this.state.expanded && (
            <div className="solution-list-container">
              {/* {caregiver.nome} è disponibile nelle seguenti date. Prenotale subito per non perderle */}

              {/* caregiver._serviziPrenotazione.length>1 && -- tolto per bugid#7095
                                <div className="row">
                                    <div className="textCenter">
                                        <Box className="needlesButtons" mt={2} mb={2}>
                                            <a onClick={() => this.selectAll()} className="buttonLink selectAll">Seleziona tutto</a>
                                        </Box>
                                    </div>
                                </div>
                            */}

              <div className="solution-list">
                {caregiver._serviziPrenotazione.map((sp, i) => {
                  let dateFrom = Formats.fromServerDate(sp.richiestoDal)
                  let dateTo = Formats.fromServerDate(sp.richiestoAl)
                  let day = Formats.dateToDayMonth(dateFrom)
                  let eday = Formats.dateToDayMonth(dateTo)
                  let s_day = Formats.dateToDayMonthShort(dateFrom)
                  let s_eday = Formats.dateToDayMonthShort(dateTo)
                  let shours = Formats.dateToHourMinutes(dateFrom)
                  let ehours = Formats.dateToHourMinutes(dateTo)

                  let prezzo = 0
                  sp.soluzioneTrovatas.forEach((st) => {
                    if (st.idProfessionista !== caregiver.idProfessionista)
                      return
                    prezzo += parseFloat(st.prezzo)
                  })

                  let blocked = false
                  let confirmed = false
                  let css = 'solution solution-available'
                  if (
                    sp._confirmed &&
                    sp._confirmedFor === caregiver.idProfessionista
                  ) {
                    css = 'solution solution-confirmed'
                    confirmed = true
                  }
                  if (
                    sp._confirmed &&
                    sp._confirmedFor !== caregiver.idProfessionista
                  ) {
                    blocked = true
                    css = 'solution solution-blocked'
                  }

                  return (
                    <div
                      className={css}
                      key={sp.idServizioPrenotazione}>
                      {sp._confirmed &&
                        sp._confirmedFor === caregiver.idProfessionista && (
                          <div className="message row">
                            <div>Data selezionata</div>
                          </div>
                        )}
                      {sp._confirmed &&
                        sp._confirmedFor !== caregiver.idProfessionista && (
                          <div className="message row">
                            <div>Hai già selezionato una data</div>
                          </div>
                        )}
                      {!sp._confirmed && (
                        <div className="message row">
                          <div>Seleziona questa data</div>
                        </div>
                      )}

                      <div
                        className="explanation row"
                        onClick={() => this.handleClickOnServizio(sp)}>
                        <div
                          className="col1"
                          style={{ paddingTop: '12px' }}>
                          <EventIcon />
                        </div>
                        <div className="col2">
                          <div className="col2l">
                            {day === eday && (
                              <div className="main-info">
                                <div style={{ fontWeight: 'bold' }}>{day}</div>
                                <div>
                                  dalle ore{' '}
                                  <span style={{ fontWeight: 'bold' }}>
                                    {shours}
                                  </span>
                                </div>
                                <div>
                                  alle ore{' '}
                                  <span style={{ fontWeight: 'bold' }}>
                                    {ehours}
                                  </span>
                                </div>
                              </div>
                            )}
                            {day !== eday && (
                              <div className="main-info">
                                <div>
                                  dalle{' '}
                                  <span style={{ fontWeight: 'bold' }}>
                                    {shours}
                                  </span>
                                  &nbsp; del{' '}
                                  <span style={{ fontWeight: 'bold' }}>
                                    {s_day}
                                  </span>
                                </div>
                                <div>
                                  alle{' '}
                                  <span style={{ fontWeight: 'bold' }}>
                                    {ehours}
                                  </span>
                                  &nbsp; del{' '}
                                  <span style={{ fontWeight: 'bold' }}>
                                    {s_eday}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="col2r">
                            <div
                              className="textRight"
                              style={{
                                marginRight: '20px',
                                paddingTop: '12px',
                              }}>
                              {!blocked && !confirmed && (
                                <AddCircleIcon className="green-icon" />
                              )}
                              {confirmed && <DeleteIcon />}
                            </div>
                            <div className="price textLeft">
                              {Formats.formatPrice(prezzo)} &euro;
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>

              {nonDisponibili.length > 0 && (
                <div
                  className="row textCenter"
                  style={{ marginTop: '16px' }}>
                  {caregiver.nome} non è disponibile nelle seguenti date. Puoi
                  cercare sue disponibilità alternative.
                </div>
              )}

              <div className="solution-list">
                {nonDisponibili.map((sp, i) => {
                  let dateFrom = Formats.fromServerDate(sp.richiestoDal)
                  let dateTo = Formats.fromServerDate(sp.richiestoAl)
                  let day = Formats.dateToDayMonth(dateFrom)
                  let shours = Formats.dateToHourMinutes(dateFrom)
                  let ehours = Formats.dateToHourMinutes(dateTo)

                  let css = 'solution solution-not-available'
                  if (sp._alternativeSelected) {
                    css = 'solution solution-blocked'
                  } else if (
                    sp._confirmed &&
                    sp._confirmedFor !== caregiver.idProfessionista
                  ) {
                    css = 'solution solution-blocked'
                  }

                  return (
                    <div
                      className={css}
                      key={sp.idServizioPrenotazione}>
                      {sp._confirmed && (
                        <div className="message">
                          <div>Hai selezionato un altro caregiver</div>
                        </div>
                      )}
                      {sp._alternativeSelected && (
                        <div className="message">
                          <div>Hai trovato una alternativa</div>
                        </div>
                      )}
                      {!sp._confirmed && !sp._alternativeSelected && (
                        <div className="message row">
                          <div>Seleziona questa data</div>
                        </div>
                      )}

                      <div className="explanation row">
                        <div className="col1">
                          <EventIcon className="large-icon" />
                        </div>
                        <div className="col2">
                          <div className="col2l">
                            <div className="main-info">
                              <div style={{ fontWeight: 'bold' }}>{day}</div>
                              <div>
                                dalle ore{' '}
                                <span style={{ fontWeight: 'bold' }}>
                                  {shours}
                                </span>
                              </div>
                              <div>
                                alle ore{' '}
                                <span style={{ fontWeight: 'bold' }}>
                                  {ehours}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col2r">
                            <div
                              className="textRight"
                              style={{ marginRight: '20px' }}>
                              {!sp._alternativeSelected && (
                                <ChangeCircleIcon
                                  className="large-icon blue-icon"
                                  onClick={() => this.searchAlternative(sp)}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
      </Card>
    )
  }
}
