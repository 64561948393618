import { Cancel } from '@mui/icons-material'
import ClearIcon from '@mui/icons-material/Clear'
import DoneIcon from '@mui/icons-material/Done'
import EQCalendarStates from './EQCalendarStates'

export default class EQEventHelper {
  static getStatusStyle(a) {
    let bookStatusIcon = null
    let st = { backgroundColor: a.tipoOccupazioneCalendario.codiceColore }
    if (
      a.tipoOccupazioneCalendario.idTipoOccupazione ===
      EQCalendarStates.ServizioInAttesaDiConfermaProfessionista
    ) {
      bookStatusIcon = (
        <img
          src="/img/ico_spinner.png"
          alt="In attesa di conferma"
        />
      )
      st.backgroundColor = '#ffe371'
    } else if (
      a.tipoOccupazioneCalendario.idTipoOccupazione ===
      EQCalendarStates.ServizioConfermatoDalProfessionista
    ) {
      bookStatusIcon = <DoneIcon />
    } else if (
      a.tipoOccupazioneCalendario.idTipoOccupazione ===
      EQCalendarStates.ServizioRifiutatoDalProfessionista
    ) {
      bookStatusIcon = <ClearIcon />
      st.color = ['white']
    } else if (
      a.tipoOccupazioneCalendario.idTipoOccupazione ===
      EQCalendarStates.ServizioIniziato
    ) {
      bookStatusIcon = <ClearIcon />
      st.color = ['white']
    } else if (
      a.tipoOccupazioneCalendario.idTipoOccupazione ===
      EQCalendarStates.ServizioConfermatoDaAltroProfessionista
    ) {
      bookStatusIcon = <Cancel />
      st.color = ['white']
    } else if (a.tipoOccupazioneCalendario.codiceColore === '#5E5E5E') {
      st.color = ['white']
    }

    return {
      style: st,
      icon: bookStatusIcon,
    }
  }
}
