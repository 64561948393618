import EQService from '../services/EQService'

export default class StripeConf {
  static pubkey =
    EQService.isDevelopment() || EQService.isPreProduction()
      ? 'pk_test_51KEE2iKBrzH5aNiSoQNaT2jItqoE5SYPZASlLbxvbhWqr24esuB4gOWZqwe1yBbsg4EtELrrh0fZjQ1ak75q4zud00MCXIdEuf'
      : 'pk_live_51KEE2iKBrzH5aNiStlwFIMFXru0UQjTeLmk16CsbEcP2GQn8ji3TSqciWUjENEfXXgaUFhFVvVFfAUbIDg2hfhZd00YwxJdxTN'

  static create() {
    let baseUrl = window.location.protocol + '//' + window.location.host
    let stripePars = {
      successEndpoint: baseUrl + '/book/success',
      errorEndpoint: baseUrl + '/book/error',
    }
    return stripePars
  }
}
