/* eslint-disable no-unused-vars */

import {
  Box,
  Button,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import React, { Fragment } from 'react'

import AddCircleIcon from '@mui/icons-material/AddCircle'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CircleChecked from '@mui/icons-material/CheckCircleOutline'
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked'
import EQBusinessRules from '../libs/EQBusinessRules'
import EQCheckbox from './core/EQCheckbox'
import EQFloatingFormIcon from './core/EQFloatingFormIcon'
import EQLoading from './EQLoading'
import EQLoginModal from './user/EQLoginModal'
import EQService from '../services/EQService'
import EQServiceHelpModal from './EQServiceHelpModal'
import EQState from '../services/EQState'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import SearchIcon from '@mui/icons-material/Search'
import { withRouter } from 'react-router-dom'

class EQServicesList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      servizi: null,
      UserSelection: EQState.UserSelection,
      showLogin: false,
      serviziSelezionati: [],
      mansioniSelezionate: [],
      categorieMansioni: null,
      queryCategorie: '',
    }
    this.goForward = this.goForward.bind(this)
  }

  reloadState() {
    this.setState({
      UserSelection: EQState.UserSelection,
    })
  }

  componentDidMount() {
    EQState.addListener(EQState.UserSelection, this)
    if (
      EQBusinessRules.isEmergenza(this.state.UserSelection.idTipologiaServizio)
    ) {
      EQService.getGruppiServizioEmergenza().then((m) => {
        this.setState({ gruppi: m })
      })
    } else {
      EQService.getGruppiServizio(
        this.state.UserSelection.idTipologiaServizio
      ).then((m) => {
        this.setState({ gruppi: m })
      })
    }
    EQService.getCategorieMansioni().then((m) => {
      this.setState({ categorieMansioni: m })
    })
  }

  componentWillUnmount() {
    EQState.removeListener(EQState.UserSelection, this)
  }

  setStateAndRunCallback = (val) => {
    if (EQState.UserSelection.autoOpened) {
      document.location.href = '/' // se siamo arrivati qui aprendo in automatico un gruppo con un solo elemento,torniamo all'inizio - bugid#8755
    } else {
      EQState.change(EQState.UserSelection, { showServices: false })
    }
  }

  openHelp(helpCode) {
    this.setState({ helpCode: helpCode ? helpCode : ' ' })
  }

  closeHelp() {
    this.setState({ helpCode: null })
  }

  closeLogin() {
    this.setState({ showLogin: null })
  }

  handleService(event) {
    const idServizio = event.target.value
    if (event.target.checked) {
      this.setState({
        serviziSelezionati: [...this.state.serviziSelezionati, idServizio],
      })
    } else {
      this.setState((prevState) => ({
        serviziSelezionati: prevState.serviziSelezionati.filter(
          (servizio) => servizio !== idServizio
        ),
      }))
    }
  }

  handleMansione(event) {
    let numeroMassimoMansioni = 0
    if (EQState.UserSelection.GruppoServizio.servizios.length === 1)
      numeroMassimoMansioni =
        EQState.UserSelection.GruppoServizio.servizios[0].numeroMassimoMansioni

    // numeroMassimoMansioni=2; // TEST-TODO

    const idMansione = event.target.value
    if (event.target.checked) {
      if (
        numeroMassimoMansioni > 0 &&
        this.state.mansioniSelezionate &&
        this.state.mansioniSelezionate.length >= numeroMassimoMansioni
      ) {
        console.log('showing')
        document.getElementById('anchor_mansioni').scrollIntoView()
        window.scrollTo(0, window.scrollY - 100)
        return // bugid#6856
      }
      this.setState({
        mansioniSelezionate: [...this.state.mansioniSelezionate, idMansione],
      })
    } else {
      this.setState((prevState) => ({
        mansioniSelezionate: prevState.mansioniSelezionate.filter(
          (mansione) => mansione !== idMansione
        ),
      }))
    }
  }

  goForward() {
    //EQState.change(EQState.UserSelection, {});
    EQState.change(EQState.UserSelection, {
      servizi: this.state.serviziSelezionati,
      mansioni: this.state.mansioniSelezionate,
    })
    console.warn('Servizi e mansioni', EQState.UserSelection)

    if (EQService.isLogged()) {
      let target = '/book/person'

      // bugid#8576 - servizi di consulenza
      console.log(
        'isLogged, checking isServiceAdvisory',
        EQBusinessRules.isServiceAdvisory(EQState.UserSelection.GruppoServizio)
      )
      if (
        EQBusinessRules.isServiceAdvisory(EQState.UserSelection.GruppoServizio)
      ) {
        target = '/book/advisory'
      }

      this.props.history.push({
        pathname: target,
      })
    } else {
      this.setState({ showLogin: true })
    }
  }

  canGoForward() {
    if (
      this.state.mansioniSelezionate &&
      this.state.mansioniSelezionate.length > 0
    ) {
      let numeroMinimoMansioni = 0
      let numeroMassimoMansioni = 0
      if (EQState.UserSelection.GruppoServizio.servizios.length === 1) {
        numeroMassimoMansioni =
          EQState.UserSelection.GruppoServizio.servizios[0]
            .numeroMassimoMansioni
        numeroMinimoMansioni =
          EQState.UserSelection.GruppoServizio.servizios[0].numeroMinimoMansioni
      }
      if (
        numeroMinimoMansioni > 0 &&
        this.state.mansioniSelezionate.length < numeroMinimoMansioni
      )
        return false
      if (
        numeroMassimoMansioni > 0 &&
        this.state.mansioniSelezionate.length > numeroMassimoMansioni
      )
        return false
      return true
    } else {
      return false
    }
  }

  isCheckedMansione(s) {
    if (!this.state.mansioniSelezionate) return false
    return this.state.mansioniSelezionate.indexOf('' + s.idMansione) >= 0
  }

  toggleCategoriaMansione(cm) {
    if (this.state.categoriaSelezionata === cm.idCategoriaMansione) {
      this.setState({
        categoriaSelezionata: null,
      })
    } else {
      this.setState({
        categoriaSelezionata: cm.idCategoriaMansione,
      })
    }
  }

  queryCategorieChanged(e) {
    this.setState({
      queryCategorie: e.target.value,
    })
  }

  getBgCode() {
    if (this.state.UserSelection.idTipologiaServizio == 100) {
      return 'EMERGENZA'
    } else if (this.state.UserSelection.idTipologiaServizio == 2) {
      return 'FAMILIARE'
    } else {
      return 'DOMICILIARE'
    }
  }

  render() {
    if (!this.state.gruppi) {
      return (
        <Box my={2}>
          <EQLoading
            type="boxBianco"
            message="Caricamento servizi..."
          />
        </Box>
      )
    }
    if (!this.state.categorieMansioni) {
      return (
        <Box my={2}>
          <EQLoading
            type="boxBianco"
            message="Caricamento categorie..."
          />
        </Box>
      )
    }

    const canGoFor = this.canGoForward()

    let categorieInUso = {}
    if (
      EQState.UserSelection.GruppoServizio.servizios.length === 1 &&
      EQState.UserSelection.GruppoServizio.consentiCategorizzazioneMansioni
    ) {
      EQState.UserSelection.GruppoServizio.servizios[0].mansiones.forEach(
        (m) => {
          if (!m.offline) {
            if (!categorieInUso[m.idCategoriaMansione])
              categorieInUso[m.idCategoriaMansione] = []
            categorieInUso[m.idCategoriaMansione].push(m)
          }
        }
      )
    }

    let lblMinimo = ''
    let lblMassimo = ''
    let lblRange = ''
    let numeroMinimoMansioni = 0
    let numeroMassimoMansioni = 0
    if (EQState.UserSelection.GruppoServizio.servizios.length === 1) {
      numeroMassimoMansioni =
        EQState.UserSelection.GruppoServizio.servizios[0].numeroMassimoMansioni
      if (numeroMassimoMansioni === 1) lblMassimo = '1'
      else lblMassimo = numeroMassimoMansioni + ''
      numeroMinimoMansioni =
        EQState.UserSelection.GruppoServizio.servizios[0].numeroMinimoMansioni
      if (numeroMinimoMansioni === 1) lblMinimo = '1'
      else lblMinimo = numeroMinimoMansioni + ''
      if (numeroMassimoMansioni > 0 && numeroMinimoMansioni > 0) {
        if (numeroMinimoMansioni === numeroMassimoMansioni) {
          lblRange = 'esattamente ' + numeroMinimoMansioni
        } else {
          lblRange = 'da '
          if (numeroMinimoMansioni === 1) lblRange = lblRange + '1 '
          else lblRange = lblRange + numeroMinimoMansioni
          if (numeroMassimoMansioni === 1) lblRange = lblRange + 'a 1'
          else lblRange = lblRange + ' a ' + numeroMassimoMansioni + ''
        }
      }
    }
    // numeroMassimoMansioni=2; // TEST-TODO

    return (
      <Box className="needlesButtons">
        <div id="anchor_mansioni"></div>
        {EQState.UserSelection.GruppoServizio.servizios.length > 1 && (
          <Box>
            <h2 className="playFair normalWeight textCenter">
              Seleziona i servizi
            </h2>
            <p className="textCenter">
              {EQState.UserSelection.GruppoServizio.nome}
            </p>

            <List
              sx={{ width: '100%', maxWidth: 360 }}
              disablePadding>
              {EQState.UserSelection.GruppoServizio.servizios.map((s) => {
                const labelId = `checkbox-list-label-${s.idServizio}`
                let st = 'fab bgDOMICILIARE'
                if (
                  EQBusinessRules.isEmergenza(
                    this.state.UserSelection.idTipologiaServizio
                  )
                ) {
                  st = 'fab bgEMERGENZA'
                }
                return (
                  <ListItem
                    sx={{ marginBottom: '1px' }}
                    key={s.idServizio}
                    secondaryAction={
                      <Box
                        edge="end"
                        aria-label="FAQ">
                        <button
                          onClick={() => this.openHelp(s.testoHelp)}
                          className={st}>
                          ?
                        </button>
                      </Box>
                    }
                    disablePadding>
                    <ListItemButton
                      role={undefined}
                      dense>
                      <ListItemIcon style={{ width: '50px', height: '50px' }}>
                        <Checkbox
                          edge="start"
                          tabIndex={-1}
                          disableRipple
                          onChange={(e) => {
                            this.handleService(e)
                          }}
                          id={'cb' + s.idServizio}
                          value={s.idServizio}
                          icon={<CircleUnchecked />}
                          checkedIcon={
                            <CircleChecked
                              sx={{ width: '50px', height: '50px' }}
                            />
                          }
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={labelId}
                        primary={s.nome}
                      />
                    </ListItemButton>
                  </ListItem>
                )
              })}
            </List>
          </Box>
        )}

        {EQState.UserSelection.GruppoServizio.servizios.length === 1 && (
          <Box mt={2}>
            <h2 className="playFair normalWeight textCenter">
              {EQState.UserSelection.GruppoServizio.servizios[0].titolo}
            </h2>

            {/*
                            { numeroMinimoMansioni>0 && numeroMassimoMansioni<=0 &&
                                <p className='textCenter'>
                                    Il servizio comprende tutte le mansioni sotto indicate, seleziona le principali (almeno {lblMinimo})
                                </p>
                            }
                            { numeroMassimoMansioni>0 && numeroMinimoMansioni<=0 &&
                                <p className='textCenter'>
                                    Il servizio comprende tutte le mansioni sotto indicate, seleziona le principali (al massimo {lblMassimo})
                                </p>
                            }
                            { numeroMassimoMansioni>0 && numeroMinimoMansioni>0 &&
                                <p className='textCenter'>
                                    Il servizio comprende tutte le mansioni sotto indicate, seleziona le principali ({lblRange})
                                </p>
                            }
                            { numeroMinimoMansioni<=0 && numeroMassimoMansioni<=0 &&
                                <p className='textCenter'>
                                    Il servizio comprende tutte le mansioni sotto indicate, seleziona le principali
                                </p>
                            }
*/}
            {/* bugid#8376
                            <p className='textCenter'>
                                {EQState.UserSelection.GruppoServizio.servizios[0].sottoTitolo}
                            </p>
*/}

            {EQState.UserSelection.GruppoServizio
              .consentiCategorizzazioneMansioni && (
              <div className={`service-${this.getBgCode()}`}>
                <p>
                  scegli fino a{' '}
                  <span style={{ fontWeight: 'bold' }}>3 servizi</span> di cui
                  hai bisogno:
                </p>
                <Box style={{ paddingLeft: '70px', paddingRight: '70px' }}>
                  <EQFloatingFormIcon>
                    <SearchIcon />
                  </EQFloatingFormIcon>
                  <Box
                    pb={2}
                    pl={2}
                    pt={1}>
                    <input
                      type="text"
                      placeholder="RICERCA SERVIZIO"
                      defaultValue={''}
                      onChange={(e) => this.queryCategorieChanged(e)}
                      style={{ width: '100%', height: '28px' }}
                    />
                  </Box>
                </Box>
                <Box
                  pl={1}
                  pr={1}
                  mb={3}>
                  {this.state.categorieMansioni.map((cm) => {
                    if (!categorieInUso[cm.idCategoriaMansione]) return null
                    return (
                      <Fragment key={cm.idCategoriaMansione}>
                        {this.state.queryCategorie === '' && (
                          <ListItem
                            sx={{
                              marginBottom: '1px',
                              backgroundColor: '#fff',
                            }}
                            disablePadding>
                            <ListItemButton
                              role={undefined}
                              dense
                              onClick={() => this.toggleCategoriaMansione(cm)}>
                              <ListItemIcon
                                style={{ minWidth: '35px', height: 'auto' }}>
                                {this.state.categoriaSelezionata ===
                                  cm.idCategoriaMansione && (
                                  <div
                                    style={{
                                      borderRadius: '17px',
                                      backgroundColor: 'black',
                                    }}>
                                    <RemoveCircleIcon
                                      className={`larger-icon c-${this.getBgCode()}`}
                                    />
                                  </div>
                                )}
                                {this.state.categoriaSelezionata !==
                                  cm.idCategoriaMansione && (
                                  <div
                                    style={{
                                      borderRadius: '17px',
                                      backgroundColor: 'black',
                                    }}>
                                    <AddCircleIcon
                                      className={`larger-icon c-${this.getBgCode()}`}
                                    />
                                  </div>
                                )}
                              </ListItemIcon>
                              <ListItemText>
                                <label
                                  className="check-label"
                                  style={{
                                    display: 'block',
                                    fontWeight: 'bold',
                                  }}>
                                  {cm.nome}
                                </label>
                              </ListItemText>
                            </ListItemButton>
                          </ListItem>
                        )}
                        <Box>
                          {(this.state.queryCategorie !== '' ||
                            this.state.categoriaSelezionata ===
                              cm.idCategoriaMansione) && (
                            <Fragment>
                              {categorieInUso[cm.idCategoriaMansione].map(
                                (m) => {
                                  const labelId = `checkbox-list-label-${m.idMansione}`
                                  if (
                                    this.state.queryCategorie !== '' &&
                                    m.nome
                                      .toLowerCase()
                                      .indexOf(
                                        this.state.queryCategorie.toLowerCase()
                                      ) < 0
                                  )
                                    return null
                                  let st = 'fab bgDOMICILIARE'
                                  if (
                                    EQBusinessRules.isEmergenza(
                                      this.state.UserSelection
                                        .idTipologiaServizio
                                    )
                                  ) {
                                    st = 'fab bgEMERGENZA'
                                  }
                                  return (
                                    <ListItem
                                      sx={{
                                        marginBottom: '1px',
                                        backgroundColor: '#fff',
                                      }}
                                      key={m.idMansione}
                                      secondaryAction={
                                        <Box
                                          edge="end"
                                          aria-label="FAQ">
                                          <button
                                            onClick={() =>
                                              this.openHelp(m.testoHelp)
                                            }
                                            className={st}>
                                            ?
                                          </button>
                                        </Box>
                                      }
                                      disablePadding>
                                      <ListItemButton
                                        role={undefined}
                                        dense>
                                        <ListItemIcon
                                          style={{ minWidth: '35px' }}>
                                          <EQCheckbox
                                            id={'cb' + m.idMansione}
                                            value={m.idMansione}
                                            checked={this.isCheckedMansione(m)}
                                            edge="start"
                                            onChange={(evt) =>
                                              this.handleMansione(evt)
                                            }
                                            tabIndex={-1}
                                            disableRipple
                                            icon={<CircleUnchecked />}
                                            checkedIcon={<CircleChecked />}
                                            inputProps={{
                                              'aria-labelledby': labelId,
                                            }}
                                          />
                                        </ListItemIcon>
                                        <ListItemText>
                                          <label
                                            className="check-label"
                                            style={{
                                              display: 'block',
                                              fontWeight: 'bold',
                                            }}
                                            htmlFor={'cb' + m.idMansione}>
                                            {m.nome}
                                          </label>
                                        </ListItemText>
                                      </ListItemButton>
                                    </ListItem>
                                  )
                                }
                              )}
                            </Fragment>
                          )}
                        </Box>
                      </Fragment>
                    )
                  })}
                </Box>
              </div>
            )}

            {!EQState.UserSelection.GruppoServizio
              .consentiCategorizzazioneMansioni && (
              <div className={`service-${this.getBgCode()}`}>
                <p className='playFair normalWeight textCenter"'>
                  Indica i principali:
                </p>
                <List
                  sx={{
                    width: '100%',
                    maxWidth: 360,
                    backgroundColor: 'transparent',
                  }}
                  disablePadding
                  className="list-mansioni">
                  {EQState.UserSelection.GruppoServizio.servizios[0].mansiones.map(
                    (s) => {
                      if (s.offline) return null
                      const labelId = `checkbox-list-label-${s.idMansione}`
                      let st = 'fab bg' + this.getBgCode()
                      return (
                        <ListItem
                          sx={{ marginBottom: '1px' }}
                          key={s.idMansione}
                          secondaryAction={
                            <Box
                              edge="end"
                              aria-label="FAQ">
                              <button
                                onClick={() => this.openHelp(s.testoHelp)}
                                className={st}>
                                ?
                              </button>
                            </Box>
                          }
                          disablePadding>
                          <ListItemButton
                            role={undefined}
                            dense>
                            <ListItemIcon
                              style={{ minWidth: '35px' }}
                              sx={{ color: 'transparent' }}>
                              <EQCheckbox
                                typecode={this.getBgCode()}
                                id={'cb' + s.idMansione}
                                value={s.idMansione}
                                checked={this.isCheckedMansione(s)}
                                edge="start"
                                onChange={(evt) => this.handleMansione(evt)}
                                tabIndex={-1}
                                disableRipple
                                icon={<CircleUnchecked />}
                                checkedIcon={<CircleChecked />}
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            </ListItemIcon>
                            <ListItemText id={labelId}>
                              <label
                                className="check-label"
                                style={{ display: 'block', fontWeight: 'bold' }}
                                htmlFor={'cb' + s.idMansione}>
                                {s.nome}
                              </label>
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      )
                    }
                  )}
                </List>
              </div>
            )}
          </Box>
        )}

        <Grid
          container
          className="book-navigation enlarge"
          mt={0}
          mb={0}>
          <Grid
            item
            xs={6}
            style={{ textAlign: 'left' }}>
            <Button
              className="lnkButton lnkBack"
              startIcon={<ArrowBackIcon />}
              onClick={() =>
                this.setStateAndRunCallback({ showServices: false })
              }>
              Indietro
            </Button>
          </Grid>
          {canGoFor && (
            <Grid
              item
              sx={{ textAlign: 'right' }}
              xs={6}>
              <a
                className={`buttonLink lnkForward button-${this.getBgCode()}`}
                onClick={() => this.goForward()}>
                AVANTI
              </a>
            </Grid>
          )}
          {!canGoFor && (
            <Grid
              item
              sx={{ textAlign: 'right' }}
              xs={6}>
              <a
                className={`buttonLink lnkForward disabled button-${this.getBgCode()}`}>
                AVANTI
              </a>
            </Grid>
          )}
        </Grid>

        {this.state.helpCode && (
          <EQServiceHelpModal
            onClose={this.closeHelp.bind(this)}
            code={this.state.helpCode}
            color={this.getBgCode()}></EQServiceHelpModal>
        )}
        {this.state.showLogin && (
          <EQLoginModal
            onClose={this.closeLogin.bind(this)}
            goToAfterLogin="book"></EQLoginModal>
        )}
      </Box>
    )
  }
}

export default withRouter(EQServicesList)
