import React from 'react'
import { Box, Container, InputAdornment, TextField } from '@mui/material'
import EQService from '../services/EQService'
import EQFooter from '../components/EQFooter'
import EQHeader from '../components/EQHeader'
import { MuiThemeProvider } from 'material-ui/styles'
import { IconButton } from 'material-ui'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import validator from 'validator'
import Utils from '../libs/Utils'

class PageChangePassword extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      password: '',
      passwordConfirm: '',
      passwordShown: false,
      showFooter: false,
      guid: '',
      errorMessage: {},
    }
  }

  componentDidMount() {
    const params = queryString.parse(this.props.location.search)
    this.setState({
      guid: params.guid,
    })

    setTimeout(() => {
      this.setState({
        showFooter: true,
      })
    }, 500)
  }

  updateInputValue(evt) {
    const val = evt.target.value
    const field = evt.target.id
    this.setState({
      [field]: val,
    })
  }

  togglePassword() {
    this.setState({
      passwordShown: !this.state.passwordShown,
    })
  }

  confirmChange() {
    if (!validator.isStrongPassword(this.state.password)) {
      this.setState((prev) => ({
        ...prev,
        error: true,
        errorMessage: {
          ...prev.errorMessage,
          password:
            'La password deve contenere almeno: - una lettera maiuscola - una lettera minuscola - un numero - un simbolo ed avere una lunghezza di almeno 8 caratteri',
        },
      }))
      return
    }

    if (this.state.password.trim() !== this.state.verifyPassword) {
      this.setState((prev) => ({
        ...prev,
        error: true,
        errorMessage: {
          ...prev.errorMessage,
          verifyPassword: 'Le password non coincidono',
        },
      }))
      return
    }

    EQService.confirmResetPassword(this.state.password, this.state.guid).then(
      (r) => {
        if (r) {
          Utils.notifyConfirm(
            'Password aggiornata correttamente, a breve verrai rediretto alla home dove potrai effettuare il login'
          )
          setTimeout(() => {
            document.location.href = '/'
          }, 3000)
        } else {
          Utils.notifyError(
            "Errori nell'aggiornamento password, contattare l'assistenza"
          )
        }
      }
    )
  }

  render() {
    return (
      <MuiThemeProvider>
        <div className="userpage pageChangePassword">
          <Box className="editorial">
            <EQHeader></EQHeader>
            <Box mt={7}>
              <div className="logo">
                <img
                  src="/img/logo_big.svg"
                  alt="Logo EqueCure"
                />
              </div>
            </Box>
            <Container
              px={0}
              className="container">
              <Box mx={2}>
                <Box my={2}>
                  <label>Inserisci la nuova password:</label>
                  <TextField
                    className="inputPassword"
                    onChange={(evt) => this.updateInputValue(evt)}
                    type={this.state.passwordShown ? 'text' : 'password'}
                    fullWidth
                    id="password"
                    label="Password"
                    placeholder="****"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => this.togglePassword()}
                            onMouseDown={() => this.togglePassword()}>
                            {this.state.passwordShown ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={!!this.state.errorMessage.password}
                    helperText={
                      this.state.errorMessage.password &&
                      this.state.errorMessage.password
                    }></TextField>
                </Box>
                <Box mb={2}>
                  <label>Conferma la nuova password:</label>
                  <TextField
                    onChange={(evt) => this.updateInputValue(evt)}
                    type="password"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    id="verifyPassword"
                    label="Verifica Password"
                    placeholder="****"
                    error={!!this.state.errorMessage.verifyPassword}
                    helperText={
                      this.state.errorMessage.verifyPassword &&
                      this.state.errorMessage.verifyPassword
                    }></TextField>
                </Box>
                <Box
                  sx={{ textAlign: 'center' }}
                  pb={3}>
                  <button
                    className="buttonGreen"
                    onClick={() => this.confirmChange()}>
                    CAMBIA PASSWORD
                  </button>
                </Box>
              </Box>
            </Container>

            {this.state.showFooter && <EQFooter></EQFooter>}
          </Box>
        </div>
      </MuiThemeProvider>
    )
  }
}
export default withRouter(PageChangePassword)
