import { Box, Grid } from '@mui/material'
import React, { Fragment } from 'react'

import EQFaqBox from '../EQFaqBox'
import EQProfTypes from '../../libs/EQProfTypes'
import EQServiceCaregiver from '../../services/EQServiceCaregiver'
import EQState from '../../services/EQState'

export class EQCaregiver_Numbers extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      CaregiverHome: EQState.CaregiverHome,
      counter: null,
    }
  }

  componentDidMount() {
    if (!EQState.CaregiverHome || !EQState.CaregiverHome.IsLogged) {
      EQState.retrieve(EQState.CaregiverHome)
    }
    if (!EQState.CaregiverHome || !EQState.CaregiverHome.IsLogged) {
      console.warn('Session is not ready in Numbers')
      document.location.href = '/caregiver/login'
      return
    }
    this.loadReportCounter()
  }

  loadReportCounter() {
    EQServiceCaregiver.getReportCounter().then((ll) => {
      this.setState({
        counter: ll.data,
      })
    })
  }

  gotoConfirm() {
    if (this.state.counter.assistenzeDaConfermare > 0)
      document.location.href = '/caregiver/toBeConfirmed'
  }

  render() {
    let professionista = EQState.CaregiverHome.Professionista
    if (!professionista) {
      return null
    }

    let what = 'Ore ferie'
    let what2 = 'permessi'
    if (professionista.idInquadramento !== EQProfTypes.Dipendente) {
      what = 'Ore di'
      what2 = 'indisponibilità'
    }

    return (
      <>
        <Box
          className="boxBianco"
          style={{ padding: '18px', marginTop: '20px' }}>
          <Box className="boxBianco">
            <Box pb={2}>
              <h2 className="playFair textCenter normalWeight">
                I miei <b>numeri</b>
              </h2>
              {this.state.counter && (
                <table
                  id="counter-table"
                  style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <td>
                        <div
                          className="container"
                          style={{ backgroundColor: '#e3e3e3' }}>
                          <div className="large-number">
                            {this.state.counter.assistenzeConcluse}
                          </div>
                          <div className="textCenter playFair app-text small">
                            Assistenze
                            <br />
                            concluse
                          </div>
                        </div>
                      </td>
                      <td>
                        <div
                          className="container"
                          style={{
                            backgroundColor:
                              this.state.counter.assistenzeDaConfermare > 0
                                ? '#ffe371'
                                : 'white',
                          }}
                          onClick={() => this.gotoConfirm()}>
                          <div className="large-number">
                            {this.state.counter.assistenzeDaConfermare}
                          </div>
                          <div className="textCenter playFair app-text small">
                            Assistenze
                            <br />
                            da confermare
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="container"
                        style={{ backgroundColor: '#c996bb' }}>
                        <div className="large-number">
                          {this.state.counter.assistenzeRifiutate}
                        </div>
                        <div className="textCenter playFair app-text small">
                          Assistenze
                          <br />
                          rifiutate
                        </div>
                      </td>
                      <td className="container">
                        <div className="large-number">
                          {this.state.counter.reportNonCompletati}
                        </div>
                        <div className="textCenter playFair app-text small">
                          Report non completati
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="container">
                        <div className="large-number">
                          {this.state.counter.assistenzeConRitardoFino30}
                        </div>
                        <div className="textCenter playFair app-text small">
                          Ritardi
                          <br />
                          fino a 30'
                        </div>
                      </td>
                      <td className="container">
                        <div className="large-number">
                          {this.state.counter.assistenzeConRitardoOltre30}
                        </div>
                        <div className="textCenter playFair app-text small">
                          Ritardi
                          <br />
                          oltre i 30'
                        </div>
                      </td>
                    </tr>
                    {/* TODO
                                    <tr>
                                        <td className="container" style={{backgroundColor:'#ff7474'}}>
                                            <div className="large-number">{this.state.counter.indisponibilita}</div>
                                            <div className='textCenter playFair app-text small'>{what}<br/>{what2}</div>
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                    */}
                  </tbody>
                </table>
              )}
            </Box>
          </Box>
        </Box>
        <EQFaqBox category="CaregiverNumbers" />
      </>
    )
  }
}
