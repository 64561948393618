import EQService from './EQService'

export default class EQServiceCaregiver {
  static listAppuntamentiDaConfermare() {
    const sId = EQService.sessionId()
    let result = new Promise((resolve, reject) => {
      EQService.get(
        '/Professionista/ListAppuntamentiDaConfermareProfessionista',
        { idSessioneWeb: sId }
      ).then((res) => {
        if (!res) return
        resolve(res)
      })
    })
    return result
  }

  static getProfessionista() {
    const sId = EQService.sessionId()
    let result = new Promise((resolve, reject) => {
      EQService.get('/SessioneWeb/IsProfessionista', {
        idSessioneWeb: sId,
      }).then((res) => {
        resolve(res)
      })
    })
    return result
  }

  static getReportCounter() {
    const sId = EQService.sessionId()
    let result = new Promise((resolve, reject) => {
      EQService.get('/Professionista/GetReportCounter', {
        idSessioneWeb: sId,
      }).then((res) => {
        if (!res) return
        resolve(res)
      })
    })
    return result
  }

  static checkSession() {
    const sId = EQService.sessionId()
    const pars = {
      idSessioneWeb: sId,
      DataDal: new Date().toISOString(),
      DataAl: new Date().toISOString(),
    }
    let result = new Promise((resolve, reject) => {
      EQService.get('/Professionista/ListAppuntamentiProfessionista', pars, {
        disableErrorAlert: true,
      }).then((res) => {
        if (!res) reject(res)
        else resolve(res)
      })
    })
    return result
  }

  static listAppuntamenti(dal, al) {
    const sId = EQService.sessionId()
    const pars = {
      idSessioneWeb: sId,
      DataDal: dal.toISOString(),
      DataAl: al.toISOString(),
    }
    let result = new Promise((resolve, reject) => {
      EQService.get(
        '/Professionista/ListAppuntamentiProfessionista',
        pars
      ).then((res) => {
        if (!res) return
        resolve(res)
      })
    })
    return result
  }

  static rifiutaAppuntamento(c, motivazione) {
    const sId = EQService.sessionId()
    const pars = {
      idSessioneWeb: sId,
      idCalendario: c.idCalendario,
      motivazione: motivazione,
    }
    let result = new Promise((resolve, reject) => {
      EQService.get('/Professionista/RifiutaAppuntamento', pars).then((res) => {
        if (!res) return
        resolve(res)
      })
    })
    return result
  }

  static accettaAppuntamento(c) {
    const sId = EQService.sessionId()
    const pars = {
      idSessioneWeb: sId,
      idCalendario: c.idCalendario,
    }
    let result = new Promise((resolve, reject) => {
      EQService.get('/Professionista/ConfermaAppuntamento', pars, {
        disableErrorAlert: false,
      }).then((res) => {
        if (!res) return
        resolve(res)
      })
    })
    return result
  }

  static dichiaraInizio(c) {
    const sId = EQService.sessionId()
    const pars = {
      idSessioneWeb: sId,
      idCalendario: c.idCalendario,
    }
    let result = new Promise((resolve, reject) => {
      EQService.get('/Professionista/DichiaraInizio', pars).then((res) => {
        if (!res) return
        resolve(res)
      })
    })
    return result
  }

  static dichiaraFine(c) {
    const sId = EQService.sessionId()
    const pars = {
      idSessioneWeb: sId,
      idCalendario: c.idCalendario,
    }
    let result = new Promise((resolve, reject) => {
      EQService.get('/Professionista/DichiaraFine', pars).then((res) => {
        if (!res) return
        resolve(res)
      })
    })
    return result
  }

  static getAppuntamento(idCalendario) {
    const sId = EQService.sessionId()
    const pars = {
      idSessioneWeb: sId,
      idCalendario: idCalendario,
    }
    let result = new Promise((resolve, reject) => {
      EQService.get('/Professionista/GetAppuntamentoById', pars).then((res) => {
        if (!res) reject(res)
        else resolve(res)
      })
    })
    return result
  }

  static dichiaraRitardo(c, minuti) {
    const sId = EQService.sessionId()
    const pars = {
      idSessioneWeb: sId,
      idCalendario: c.idCalendario,
      minuti: minuti,
    }
    let result = new Promise((resolve, reject) => {
      EQService.get('/Professionista/DichiaraRitardo', pars).then((res) => {
        if (!res) return
        resolve(res)
      })
    })
    return result
  }

  static richiediFerie(fromDate, toDate) {
    const sId = EQService.sessionId()
    const pars = {
      idSessioneWeb: sId,
      dal: fromDate,
      al: toDate,
    }
    let result = new Promise((resolve, reject) => {
      EQService.get('/Professionista/RichiedeFerie', pars).then((res) => {
        if (!res) return
        resolve(res)
      })
    })
    return result
  }

  static cancellaRichiestaFerie(idCalendario) {
    const sId = EQService.sessionId()
    const pars = {
      idSessioneWeb: sId,
      idCalendario: idCalendario,
    }
    let result = new Promise((resolve, reject) => {
      EQService.get('/Professionista/CancellaRichiestaFerie', pars).then(
        (res) => {
          if (!res) return
          resolve(res)
        }
      )
    })
    return result
  }

  static listAdditionalInfo(idCalendario) {
    const sId = EQService.sessionId()
    const pars = {
      idSessioneWeb: sId,
      idCalendario: idCalendario,
    }
    let result = new Promise((resolve, reject) => {
      EQService.get('/Prenotazione/ListAllAdditionalInfos', pars).then(
        (res) => {
          if (!res) return
          resolve(res)
        }
      )
    })
    return result
  }

  static addAdditionalInfo(idCalendario, tipoAdditionalInfo, additionalInfo) {
    const sId = EQService.sessionId()
    let postPars = JSON.stringify({
      idSessioneWeb: sId,
      idCalendario: idCalendario,
      idTipoAdditionalInfo: tipoAdditionalInfo,
      additionalInfo: additionalInfo,
    })
    let conf = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    let result = new Promise((resolve, reject) => {
      EQService.post('/Prenotazione/AddAdditionalInfo', postPars, conf).then(
        (res) => {
          try {
            resolve(res.data)
          } catch (e) {
            reject(res)
          }
        }
      )
    })
    return result
  }

  static listAdditionalInfoForPatient(idPaziente, idInfoType) {
    const sId = EQService.sessionId()
    const pars = {
      idSessioneWeb: sId,
      idPaziente: idPaziente,
      idInfoType: idInfoType,
    }
    let result = new Promise((resolve, reject) => {
      EQService.get('/Prenotazione/ListAdditionalInfosByPaziente', pars).then(
        (res) => {
          if (!res) return
          resolve(res)
        }
      )
    })
    return result
  }

  static getAllMansioni() {
    return new Promise((resolve, reject) =>
      EQService.get('/Servizio/ListAllMansioni')
        .then((res) => {
          if (!res) return

          let map = res.data

          resolve(map)
        })
        .catch((err) => reject(new Error(err)))
    )
  }
}
