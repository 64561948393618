import React from 'react'
import EQMobileMenu from './EQMobileMenu'
import { AppBar, Box, Toolbar } from '@mui/material'
import EQAccountMenu from './user/EQAccountMenu'

export default class EQHeaderBook extends React.Component {
  goHome() {
    document.location.href = '/'
  }

  render() {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          style={{ background: '#FFF' }}
          position="fixed">
          <Toolbar id="toolbar">
            <EQMobileMenu></EQMobileMenu>
            <Box
              pt={1}
              sx={{ flexGrow: 1, textAlign: 'center' }}
              className="menuImageContainer">
              <img
                onClick={() => this.goHome()}
                src="/img/logo-header.svg"
                alt="Logo EqueCure"
              />
            </Box>
            <EQAccountMenu></EQAccountMenu>
          </Toolbar>
        </AppBar>
      </Box>
    )
  }
}
