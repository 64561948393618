import { Box, Grid, Modal } from '@mui/material'

import EQServiceCaregiver from '../../services/EQServiceCaregiver'
import EQState from '../../services/EQState'
import EQTextField from '../core/EQTextField'
import React from 'react'
import Utils from '../../libs/Utils'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  width: '80%',
  bgcolor: '#ffe371',
  border: '0',
  boxShadow: 24,
  p: 2,
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
}

export default class EQRefuseModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stage: 0,
    }
  }

  closeModal() {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  cancelOperation() {
    this.closeModal()
  }

  continueOperation() {
    this.setState({
      stage: 1,
    })
  }

  handleChange(evt, key) {
    let st = {}
    st[key] = evt.target.value
    this.setState(st)
  }

  actuallyDoOperation() {
    EQServiceCaregiver.rifiutaAppuntamento(this.props.selectedEvent).then(
      (x) => {
        EQServiceCaregiver.getAppuntamento(
          this.props.selectedEvent.idCalendario
        ).then((x) => {
          Utils.refill(this.props.selectedEvent, x.data)
          this.closeModal()

          // Refresh della pagina
          setTimeout(() => {
            EQState.change(EQState.CaregiverHome, {})
          }, 500)
        })
      }
    )
  }

  render() {
    console.log('selectedEvent', this.props.selectedEvent)
    return (
      <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          {this.state.stage === 0 && (
            <Box
              mt={0}
              p={2}>
              <div className="textCenter playFair app-text modal-title">
                <b>Perchè vuoi rifiutare la prenotazione?</b>
              </div>

              <form>
                <EQTextField
                  multiline
                  label="Motivazione"
                  minRows={3}
                  sx={{ width: '100%' }}
                  placeholder=""
                  defaultValue={this.state.reason}
                  onChange={(e) => this.handleChange(e, 'reason')}
                />
              </form>

              <Grid
                container
                mt={0}
                mb={2}>
                <Grid
                  item
                  sx={{ textAlign: 'left' }}
                  xs={6}>
                  <a
                    className="buttonLink bg-red text-white lnkForward"
                    onClick={() => this.cancelOperation()}>
                    ANNULLA
                  </a>
                </Grid>
                <Grid
                  item
                  sx={{ textAlign: 'right' }}
                  xs={6}>
                  {this.state.reason && (
                    <a
                      className="buttonLink bg-green text-white lnkForward"
                      onClick={() => this.continueOperation()}>
                      CONTINUA
                    </a>
                  )}
                </Grid>
              </Grid>
            </Box>
          )}
          {this.state.stage === 1 && (
            <Box
              mt={0}
              py={2}>
              <div className="textCenter playFair app-text modal-title">
                <b>Sei sicuro di voler rifiutare la prenotazione?</b>
              </div>

              <Grid
                container
                mt={0}
                mb={2}>
                <Grid
                  item
                  sx={{ textAlign: 'left' }}
                  xs={6}>
                  <a
                    className="buttonLink bg-red text-white lnkForward"
                    onClick={() => this.cancelOperation()}>
                    ANNULLA
                  </a>
                </Grid>
                <Grid
                  item
                  sx={{ textAlign: 'right' }}
                  xs={6}>
                  <a
                    className="buttonLink bg-green text-white lnkForward"
                    onClick={() => this.actuallyDoOperation()}>
                    SI, RIFIUTO
                  </a>
                </Grid>
              </Grid>

              <Box mt={6}>
                <div className="textCenter playFair app-text">
                  Ti invitiamo a valutare bene l'opportunità di lavoro offerta
                </div>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    )
  }
}
