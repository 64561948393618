import React from 'react'
import Box from '@mui/material/Box'
import { CircularProgress } from 'material-ui'
import { MuiThemeProvider } from 'material-ui/styles'

export default class EQLoading extends React.Component {
  componentDidMount() {
    let col = '#1976d2'
    if (this.props.color) col = this.props.color

    let c = document.getElementsByTagName('circle')
    for (let i = 0; i < c.length; i++) {
      try {
        let outer = c[i].parentNode.parentNode.parentNode.parentNode
        if (outer.classList.contains('loading-container')) {
          c[i].style.stroke = col
        }
      } catch (e) {}
    }
  }

  render() {
    let childComponent = null

    let msg = 'Loading...'
    if (this.props.message) msg = this.props.message

    let tp = 'default'
    if (this.props.type) tp = this.props.type
    if (tp === 'default') {
      childComponent = (
        <div className="loading-outer">
          <div className="loading-container">
            <CircularProgress />
            <div className="loading">{msg}</div>
          </div>
        </div>
      )
    } else if (tp === 'boxCeleste') {
      childComponent = (
        <Box className="boxCeleste loading-outer">
          <Box
            pb={2}
            className="loading-container">
            <CircularProgress />
            <div className="loading">{msg}</div>
          </Box>
        </Box>
      )
    } else if (tp === 'boxBianco') {
      childComponent = (
        <Box className="boxBianco loading-outer">
          <Box
            pb={2}
            className="loading-container">
            <CircularProgress />
            <div className="loading">{msg}</div>
          </Box>
        </Box>
      )
    }

    return <MuiThemeProvider>{childComponent}</MuiThemeProvider>
  }
}
