import { Alert, Box, FormControl, Grid, Modal } from '@mui/material'
import React, { Fragment } from 'react'

import CloseIcon from '@mui/icons-material/HighlightOff'
import EQAdditionalInfoTypes from '../../libs/EQAdditionlInfoTypes'
import EQCalendarStates from '../../libs/EQCalendarStates'
import EQLoading from '../EQLoading'
import EQServiceCaregiver from '../../services/EQServiceCaregiver'
import EQServiceReport_Edit from './EQServiceReport_Edit'
import EQServiceReport_View from './EQServiceReport_View'
import Formats from '../../libs/Formats'

export default class EQServiceReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.loadAdditionalInfos()
  }

  closeModal() {
    if (this.props.onClose) this.props.onClose()
  }

  loadAdditionalInfos() {
    if (!this.props.selectedAdditionalInfo) {
      let idCalendario = this.props.selectedEvent.idCalendario
      EQServiceCaregiver.listAdditionalInfo(idCalendario).then((ais) => {
        console.log('ais', ais.data)
        let additionalInfos = {}
        ais.data.forEach((ai) => {
          additionalInfos[ai.infoType] = ai
        })
        this.setState({
          additionalInfos: additionalInfos,
        })
      })
    } else {
      let additionalInfos = {}
      additionalInfos[this.props.selectedAdditionalInfo.infoType] =
        this.props.selectedAdditionalInfo
      this.setState({
        additionalInfos: additionalInfos,
      })
    }
  }

  ensureTwoDigits(s) {
    s = '' + s
    if (s.length < 2) s = '0' + s
    return s
  }

  formatHHMM(dd) {
    let hh = this.ensureTwoDigits(dd.getHours())
    let mm = this.ensureTwoDigits(dd.getMinutes())
    if (hh === '00' && mm === '00') return '' // giornate complete di ferie non hanno indicazione dell'ora
    return hh + ':' + mm
  }

  render() {
    const e = this.props.selectedEvent
    const ro = this.state.ro

    let dayNames = Formats.dayNames()
    let monthNames = Formats.monthNames()

    let dateDivs = []
    let fromDate = Formats.fromServerDate(e.dal)
    let toDate = Formats.fromServerDate(e.al)
    let fd = new Date(fromDate.getTime())
    fd.setHours(0, 0, 0, 0)
    let td = new Date(toDate.getTime())
    td.setHours(0, 0, 0, 0)
    let dayDiff = false
    if (fd.getTime() === td.getTime()) {
      // evento in un solo giorno
      //console.log("evento in solo giorno")
      let datePresentation =
        dayNames[fromDate.getDay()] +
        ' ' +
        fromDate.getDate() +
        ' ' +
        monthNames[fromDate.getMonth()] +
        ' ' +
        this.formatHHMM(fromDate) +
        ' / ' +
        this.formatHHMM(toDate)
      dateDivs.push(<div key={1}>{datePresentation}</div>)
    } else {
      // evento in un giorni differenti
      //console.log("evento in giorni differenti")
      dayDiff = true
      let shhmm = this.formatHHMM(fromDate)
      let datePresentation =
        'Da ' +
        dayNames[fromDate.getDay()] +
        ' ' +
        fromDate.getDate() +
        ' ' +
        monthNames[fromDate.getMonth()] +
        ' ' +
        shhmm
      dateDivs.push(<div key={1}>{datePresentation}</div>)

      let ehhmm = this.formatHHMM(toDate)
      if ((shhmm === '') & (ehhmm === '')) {
        // caso particolare: evento su giorni interi
        // il punto finale è il giorno escluso
        toDate.setDate(toDate.getDate() - 1)
      }
      datePresentation =
        'A ' +
        dayNames[toDate.getDay()] +
        ' ' +
        toDate.getDate() +
        ' ' +
        monthNames[toDate.getMonth()] +
        ' ' +
        this.formatHHMM(toDate)
      dateDivs.push(<div key={2}>{datePresentation}</div>)
    }

    if (!this.state.additionalInfos) {
      return (
        <div className="helpModal eventModal post-service-report">
          <Box
            style={{ marginTop: '78px' }}
            px={2}
            mb={4}
            id="post-service-report-contents">
            <div className="flex-row space-between">
              <div />
              <div className="playFair normalWeight textCenter bookstep-start-label r-title">
                Report post-servizio
              </div>
              <div
                className="iconClose-outer"
                style={{ position: 'relative', right: '1px' }}>
                <button
                  onClick={() => this.closeModal()}
                  className="iconClose clickable">
                  <Box
                    mt={1}
                    mr={0}>
                    <CloseIcon></CloseIcon>
                  </Box>
                  <div>CHIUDI</div>
                </button>
              </div>
            </div>
            <EQLoading />
          </Box>
        </div>
      )
    }

    //console.log("ser", this.state.servizio)
    return (
      <div className="helpModal eventModal post-service-report">
        <Box
          style={{ marginTop: '78px' }}
          px={2}
          mb={4}>
          <div className="flex-row space-between">
            <div />
            <div className="playFair normalWeight textCenter bookstep-start-label r-title">
              Report post-servizio
            </div>
            <div
              className="iconClose-outer"
              style={{ position: 'relative', right: '1px' }}>
              <button
                onClick={() => this.closeModal()}
                className="iconClose clickable">
                <Box
                  mt={1}
                  mr={0}>
                  <CloseIcon></CloseIcon>
                </Box>
                <div>CHIUDI</div>
              </button>
            </div>
          </div>

          {this.state.additionalInfos &&
            this.state.additionalInfos[EQAdditionalInfoTypes.HTML_COMPLETE] && (
              <Box className="service_report_view">
                <EQServiceReport_View
                  selectedEvent={this.props.selectedEvent}
                  additionalInfos={this.state.additionalInfos}
                  infoType={EQAdditionalInfoTypes.HTML_COMPLETE}
                />
              </Box>
            )}

          {this.state.additionalInfos &&
            !this.state.additionalInfos[
              EQAdditionalInfoTypes.HTML_COMPLETE
            ] && (
              <Box className="service_report_view">
                <EQServiceReport_Edit
                  selectedEvent={this.props.selectedEvent}
                />
              </Box>
            )}
        </Box>
      </div>
    )
  }
}
