import React, { Fragment } from 'react'
import EQService from '../../services/EQService'
import EQState from '../../services/EQState'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import EQLoginModal from './EQLoginModal'
import Drawer from '@mui/material/Drawer'
import CloseIcon from '@mui/icons-material/Close'
import { withRouter } from 'react-router-dom'
import './EQAccountMenu.scss'

class EQAccountMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showLogin: false,
      UserSelection: EQState.retrieve(EQState.UserSelection),
      open: false,
    }
  }

  componentDidMount() {
    this.state.UserSelection = EQState.retrieve(EQState.UserSelection)
    EQState.addListener(EQState.UserSelection, this)
    if (EQState.UserSelection.UserLoginOpen) {
      EQState.change(EQState.UserSelection, { UserLoginOpen: false })
      EQState.persist(EQState.UserSelection)
      setTimeout(() => {
        const el = document.getElementById('openMenu')
        el.click()
      }, 100)
    }
  }

  componentWillUnmount() {
    EQState.removeListener(EQState.UserSelection, this)
  }

  handleClick() {
    this.setState({ open: true })
  }

  handleClose() {
    this.setState({ open: false })
  }

  showLogin() {
    this.setState({ showLogin: true })
  }

  closeLogin() {
    this.setState({ showLogin: null })
  }

  doLogout() {
    EQService.creaSessione().then((x) => {
      EQState.change(
        EQState.UserSelection,
        { IsLogged: false, isJollyWorking: false },
        null,
        true
      )
      EQState.persist(EQState.UserSelection)
      document.location = '/'
    })
  }

  doCall() {
    EQService.phoneCallBy('client')
  }

  render() {
    const authenticated = EQService.isLogged()
    /*
        console.log("checking sessioneWeb.isJolly....",EQService.sessioneWeb.isJolly);
        if (EQService.sessioneWeb.isJolly === true) {
            console.warn("IsJolly=true");
            EQState.change(EQState.UserSelection, {isJollyWorking: true}, null, true);
            EQState.persist(EQState.UserSelection);
        } else {
            console.warn("IsJolly=false");
            EQState.change(EQState.UserSelection, {isJollyWorking: false}, null, true);
            EQState.persist(EQState.UserSelection);
        }
        */
    let nome = 'utente'
    if (EQService.sessioneWeb.user && EQService.sessioneWeb.user.clientes[0])
      nome = EQService.sessioneWeb.user.clientes[0].nome

    return (
      <Fragment>
        {authenticated ? (
          <Fragment>
            <Box
              className={`accountMenu ${
                this.state.UserSelection.isJollyWorking
                  ? 'jollyLogged'
                  : 'logged'
              }`}
              sx={{
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
              }}>
              <AccountCircleOutlinedIcon
                onClick={() => this.handleClick()}></AccountCircleOutlinedIcon>
            </Box>

            <Drawer
              id="accountMenu"
              anchor="right"
              variant="temporary"
              open={this.state.open}
              onClose={() => this.handleClose()}
              sx={{ width: '100%' }}>
              <Box
                className="accountMenuContainer"
                style={{ backgroundColor: '#0082b1' }}>
                <div className="linguetta">
                    <span>
                    Siamo
                    <br />
                    a Milano
                    <br />e provincia
                  </span>
                </div>
                <Box sx={{ textAlign: 'right' }}>
                  <button
                    onClick={() => this.handleClose()}
                    className="iconClose clickable">
                    <Box mt={1}>
                      <CloseIcon></CloseIcon>
                    </Box>
                    <div>CHIUDI</div>
                  </button>
                </Box>
                <Box
                  className="menuItems"
                  mt={15}>
                  <MenuItem>
                    Ciao&nbsp;
                    <span style={{ textTransform: 'capitalize' }}>{nome}</span>,
                    <span style={{ visibility: 'hidden' }}>_</span>
                  </MenuItem>
                  <MenuItem sx={{ marginTop: '30px' }}>
                    <a
                      href="/user/books"
                      className="menu-item">
                      Le mie prenotazioni_
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <a
                      href="/user/caregivers"
                      className="menu-item">
                      I miei caregivers preferiti_
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <a
                      href="/user/persons"
                      className="menu-item">
                      I miei assistiti_
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <a href="/user/settings">Impostazioni_</a>
                  </MenuItem>
                  <MenuItem style={{ marginTop: '20px' }}>
                    <a
                      href="#"
                      onClick={() => this.doLogout()}>
                      Logout
                    </a>
                    <span style={{ visibility: 'hidden' }}>_</span>
                  </MenuItem>
                </Box>

                <Box
                  sx={{
                    display: 'grid',
                    gap: 1,
                    gridTemplateColumns: 'repeat(2, 1fr)',
                  }}
                  mt={12}
                  pr={2}
                  pt={5}
                  pb={5}>
                  <Box
                    className="privacyItems"
                    pl={4}
                    sx={{ textAlign: 'left' }}>
                    <div>
                      <a href="/privacy">Privacy policy</a>
                    </div>
                    <div>
                      <a href="/condizioni">Condizioni d'uso</a>
                    </div>
                    <div>
                      <a href="/cookie-policy">Cookie policy</a>
                    </div>
                  </Box>
                  <Box>
                    <div className="servclienti">
                      <img
                        onClick={this.doCall}
                        src="/img/telefono.svg"
                        className="svg-style"
                      />
                    </div>
                  </Box>
                </Box>
              </Box>
            </Drawer>
          </Fragment>
        ) : (
          <Box
            className="accountMenu"
            id="openMenu"
            onClick={() => this.showLogin()}
            sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
            <AccountCircleOutlinedIcon></AccountCircleOutlinedIcon>
          </Box>
        )}

        {this.state.showLogin && (
          <EQLoginModal
            onClose={this.closeLogin.bind(this)}
            goToAfterLogin="/"></EQLoginModal>
        )}
      </Fragment>
    )
  }
}

export default withRouter(EQAccountMenu)
