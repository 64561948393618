import './EQMyBooks.scss'

import { AccordionDetails, AccordionSummary } from '@mui/material'
import React, { Fragment } from 'react'

import { Accordion } from '@mui/material'
import { Box } from '@mui/system'
import CaregiverIcon from '@mui/icons-material/AccessibilityNewTwoTone'
import DateAdapter from '@mui/lab/AdapterDateFns'
import EQBookModal from './EQBookModal'
import EQBusinessRules from '../../libs/EQBusinessRules'
import EQCalendarStates from '../../libs/EQCalendarStates'
import EQEventHelper from '../../libs/EQEventHelper'
import EQFaqBox from '../EQFaqBox'
import EQLoading from '../EQLoading'
import EQServiceBook from '../../services/EQServiceBook'
import EventIcon from '@mui/icons-material/CalendarMonthTwoTone'
import { ExpandMoreTwoTone } from '@mui/icons-material'
import Formats from '../../libs/Formats'
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import MobileDatePicker from '@mui/lab/MobileDatePicker'
import { TextField } from '@mui/material'
import { Typography } from '@mui/material'
import it from 'date-fns/locale/it'

export default class EQMyBooks extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      appuntamenti: null,
      appuntamentiPassati: null,
      modalBook: null,
      modalOpen: false,
      comuni: null,
      monthToday: new Date().getMonth(),
      yearToday: new Date().getFullYear(),
      monthToSearch: null,
      yearToSearch: null,
      expanded: 'panel1',
    }
  }

  loadEvents() {
    const from = new Date()
    const toPast = new Date()
    const to = EQBusinessRules.getMonthsDxExtremeToLoad()
    const fromPast = new Date(toPast.getFullYear(), toPast.getMonth(), 1)
    this.setState({ appuntamenti: null, appuntamentiPassati: null })
    EQServiceBook.listAppuntamentiCliente(from, to).then((a) => {
      // Gestiamo il caso di appuntamenti "in asta" collassandoli in una sola entry
      let key = null
      let filtered = []
      a.forEach((e) => {
        if (e.servizioPrenotazione.isAsta) {
          if (e.servizioPrenotazione.idPrenotazione !== key) {
            key = e.servizioPrenotazione.idPrenotazione
            filtered.push(e)
          }
        } else {
          filtered.push(e)
        }
      })

      this.setState({ appuntamenti: filtered })
    })
    EQServiceBook.listAppuntamentiCliente(fromPast, toPast).then((a) => {
      // Gestiamo il caso di appuntamenti "in asta" collassandoli in una sola entry
      let key = null
      let filtered = []
      a.forEach((e) => {
        if (e.servizioPrenotazione.isAsta) {
          if (e.servizioPrenotazione.idPrenotazione !== key) {
            key = e.servizioPrenotazione.idPrenotazione
            filtered.push(e)
          }
        } else {
          filtered.push(e)
        }
      })

      this.setState({ appuntamentiPassati: filtered })
    })
  }

  loadOldEvents(monthValue, yearValue) {
    console.log('yearValue: ', yearValue)
    const from = new Date(yearValue, monthValue, 1)
    const today = new Date()

    let lastDayConstrainForMonth = 31
    if (monthValue === 1) {
      lastDayConstrainForMonth = 28
    } else if (
      monthValue === 3 ||
      monthValue === 5 ||
      monthValue === 8 ||
      monthValue === 10
    ) {
      lastDayConstrainForMonth = 30
    }
    let to = new Date(yearValue, monthValue, lastDayConstrainForMonth)
    if (to.getTime() > today.getTime()) {
      to = today
    }
    console.log(to, today)
    EQServiceBook.listAppuntamentiCliente(from, to).then((a) => {
      // Gestiamo il caso di appuntamenti "in asta" collassandoli in una sola entry
      let key = null
      let filtered = []
      a.forEach((e) => {
        if (e.servizioPrenotazione.isAsta) {
          if (e.servizioPrenotazione.idPrenotazione !== key) {
            key = e.servizioPrenotazione.idPrenotazione
            filtered.push(e)
          }
        } else {
          filtered.push(e)
        }
      })

      this.setState({ appuntamentiPassati: filtered })
    })
  }

  componentDidMount() {
    this.loadEvents()
    EQServiceBook.mapComuni().then((m) => {
      this.setState({ comuni: m })
    })
  }

  openDetail(book) {
    this.setState({ modalOpen: true, modalBook: book })
  }

  closeDetail() {
    this.setState({ modalOpen: false })
    this.loadEvents()
  }

  handleChange = (panel) => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? panel : false })
  }

  render() {
    console.log(this.state)
    if (!this.state.appuntamenti) return <EQLoading />
    return (
      <div className="description">
        <span>In questa pagina puoi gestire le tue prenotazioni.</span>

        <Accordion
          expanded={this.state.expanded === 'panel1'}
          onChange={this.handleChange('panel1')}>
          <AccordionSummary
            expandIcon={<ExpandMoreTwoTone />}
            aria-controls="panel1"
            id="panel1">
            <Typography>Prenotazioni Attive e Future</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              {this.state.appuntamenti && this.state.appuntamenti.length > 0 ? (
                this.state.appuntamenti.map((a) => {
                  if (EQCalendarStates.isWaitingPayment(a)) return null
                  if (EQCalendarStates.isAcceptedByOther(a)) return null

                  const appointmentDateStart = Formats.fromServerDate(
                    a.servizioPrenotazione.richiestoDal
                  )
                  const appointmentDateEnd = Formats.fromServerDate(
                    a.servizioPrenotazione.richiestoAl
                  )
                  const dayNames = Formats.dayNames()
                  const monthNames = Formats.monthNames()
                  /*
                            let bookStatusIcon = "";

                            let st = "serviceFooter event-" + a.tipoOccupazioneCalendario.idTipoOccupazione;
                            if(a.tipoOccupazioneCalendario.idTipoOccupazione === EQCalendarStates.ServizioInAttesaDiConfermaProfessionista){
                                bookStatusIcon = ( <img src="/img/ico_spinner.png" alt="In attesa di conferma" /> );
                            }
                            else if(a.tipoOccupazioneCalendario.idTipoOccupazione === EQCalendarStates.ServizioConfermatoDalProfessionista){
                                bookStatusIcon = ( <DoneIcon /> );
                            }
                            else if(a.tipoOccupazioneCalendario.idTipoOccupazione === EQCalendarStates.ServizioRifiutatoDalProfessionista){
                                bookStatusIcon = ( <ClearIcon /> )
                                st.color = ['white'];
                            }
                            else if(a.tipoOccupazioneCalendario.idTipoOccupazione === EQCalendarStates.ServizioIniziato){
                                bookStatusIcon = ( <ClearIcon /> )
                            }
                            */

                  let statusStyle = EQEventHelper.getStatusStyle(a)
                  let bookStatusIcon = statusStyle.icon
                  let st = statusStyle.style

                  let daydiff = false
                  if (
                    appointmentDateStart.getDay() !==
                    appointmentDateEnd.getDay()
                  ) {
                    daydiff = true
                  }

                  console.log('a', a)

                  return (
                    <Fragment key={a.idCalendario}>
                      <div
                        className="bookCard border-silverGray-t-lg mb-30 silverGray-shadow-sm"
                        onClick={() => this.openDetail(a)}>
                        <div className="serviceBody pb-30">
                          <div className="serviceTitle">
                            <img
                              className="infoIcon"
                              src="/img/ico_i.png"
                              alt="Dettagli"
                            />
                            <label>Servizio:</label>
                            <div>
                              <strong>
                                {a.servizioPrenotazione.servizio.nome}
                              </strong>
                            </div>
                          </div>
                          <div className="serviceTime flex-row-start">
                            <div
                              style={{
                                paddingRight: '12px',
                                verticalAlign: 'top',
                              }}>
                              <EventIcon />
                            </div>
                            <div>
                              {!daydiff && (
                                <Fragment>
                                  <div className="dateText">
                                    {dayNames[appointmentDateStart.getDay()]}{' '}
                                    {appointmentDateStart.getDate()}{' '}
                                    {
                                      monthNames[
                                        appointmentDateStart.getMonth()
                                      ]
                                    }
                                  </div>
                                  <div>
                                    dalle ore:{' '}
                                    <strong>
                                      {(
                                        '0' + appointmentDateStart.getHours()
                                      ).slice(-2)}
                                      :
                                      {(
                                        '0' + appointmentDateStart.getMinutes()
                                      ).slice(-2)}
                                    </strong>
                                  </div>
                                  <div>
                                    alle ore:{' '}
                                    <strong>
                                      {(
                                        '0' + appointmentDateEnd.getHours()
                                      ).slice(-2)}
                                      :
                                      {(
                                        '0' + appointmentDateEnd.getMinutes()
                                      ).slice(-2)}
                                    </strong>
                                  </div>
                                </Fragment>
                              )}
                              {daydiff && (
                                <Fragment>
                                  <div>
                                    Da{' '}
                                    <span className="dateText">
                                      {dayNames[appointmentDateStart.getDay()]}{' '}
                                      {appointmentDateStart.getDate()}{' '}
                                      {
                                        monthNames[
                                          appointmentDateStart.getMonth()
                                        ]
                                      }
                                    </span>
                                  </div>
                                  <div>
                                    alle ore:{' '}
                                    <strong>
                                      {(
                                        '0' + appointmentDateStart.getHours()
                                      ).slice(-2)}
                                      :
                                      {(
                                        '0' + appointmentDateStart.getMinutes()
                                      ).slice(-2)}
                                    </strong>
                                  </div>
                                  <div>
                                    A{' '}
                                    <span className="dateText">
                                      {dayNames[appointmentDateEnd.getDay()]}{' '}
                                      {appointmentDateEnd.getDate()}{' '}
                                      {
                                        monthNames[
                                          appointmentDateEnd.getMonth()
                                        ]
                                      }
                                    </span>
                                  </div>
                                  <div>
                                    alle ore:{' '}
                                    <strong>
                                      {(
                                        '0' + appointmentDateEnd.getHours()
                                      ).slice(-2)}
                                      :
                                      {(
                                        '0' + appointmentDateEnd.getMinutes()
                                      ).slice(-2)}
                                    </strong>
                                  </div>
                                </Fragment>
                              )}
                            </div>
                          </div>
                          {EQCalendarStates.isLinkedToASingleCaregiver(a) && (
                            <>
                              <div className=" flex-col-start">
                                <div className="serviceAssistant">
                                  <div
                                    style={{
                                      verticalAlign: 'top',
                                      paddingRight: '10px',
                                    }}>
                                    <HealthAndSafetyIcon />
                                  </div>
                                  <div>
                                    Assistito:{' '}
                                    <strong>
                                      {
                                        a.servizioPrenotazione.prenotazione
                                          .paziente.nome
                                      }{' '}
                                      {
                                        a.servizioPrenotazione.prenotazione
                                          .paziente.cognome
                                      }
                                    </strong>
                                  </div>
                                </div>
                              </div>
                              <div className=" flex-col-start">
                                <div className="serviceAssistant">
                                  <div
                                    style={{
                                      verticalAlign: 'top',
                                      paddingRight: '10px',
                                    }}>
                                    <CaregiverIcon />
                                  </div>
                                  <div>
                                    Assistente:{' '}
                                    <strong>{a.professionista.nome}</strong>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          <div className="serviceNumber">
                            <div>
                              <label>IL TUO CODICE DI PRENOTAZIONE</label>
                            </div>
                            <div>
                              <strong>
                                {
                                  a.servizioPrenotazione.prenotazione
                                    .codicePratica
                                }
                              </strong>
                            </div>
                          </div>
                        </div>
                        <div
                          className="serviceFooter border-b-md"
                          style={st}>
                          <div className="serviceFooterContainer">
                            {bookStatusIcon} {a.tipoOccupazioneCalendario.nome}
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  )
                })
              ) : (
                <span className="inside-text">
                  Nessuna prenotazione trovata
                </span>
              )}
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={this.state.expanded === 'panel2'}
          onChange={this.handleChange('panel2')}>
          <AccordionSummary
            expandIcon={<ExpandMoreTwoTone />}
            aria-controls="panel2"
            id="panel2">
            <Typography>Prenotazioni Passate</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <LocalizationProvider
                dateAdapter={DateAdapter}
                locale={it}>
                <MobileDatePicker
                  views={['year', 'month']}
                  disableFuture={true}
                  value={this.state.dateToSearch}
                  onChange={(newValue) => {
                    this.setState({
                      dateToSearch: newValue,
                    })
                    this.loadOldEvents(
                      newValue.getMonth() + 1,
                      newValue.getFullYear()
                    )
                  }}
                  openTo="month"
                  label="Prenotazioni rispetto a"
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
            <Box mt={2}>
              {' '}
              {this.state.appuntamentiPassati &&
              this.state.appuntamentiPassati.length > 0 ? (
                this.state.appuntamentiPassati.map((a) => {
                  if (EQCalendarStates.isWaitingPayment(a)) return null
                  if (EQCalendarStates.isAcceptedByOther(a)) return null

                  const appointmentDateStart = Formats.fromServerDate(
                    a.servizioPrenotazione.richiestoDal
                  )
                  const appointmentDateEnd = Formats.fromServerDate(
                    a.servizioPrenotazione.richiestoAl
                  )
                  const dayNames = Formats.dayNames()
                  const monthNames = Formats.monthNames()
                  /*
                            let bookStatusIcon = "";

                            let st = "serviceFooter event-" + a.tipoOccupazioneCalendario.idTipoOccupazione;
                            if(a.tipoOccupazioneCalendario.idTipoOccupazione === EQCalendarStates.ServizioInAttesaDiConfermaProfessionista){
                                bookStatusIcon = ( <img src="/img/ico_spinner.png" alt="In attesa di conferma" /> );
                            }
                            else if(a.tipoOccupazioneCalendario.idTipoOccupazione === EQCalendarStates.ServizioConfermatoDalProfessionista){
                                bookStatusIcon = ( <DoneIcon /> );
                            }
                            else if(a.tipoOccupazioneCalendario.idTipoOccupazione === EQCalendarStates.ServizioRifiutatoDalProfessionista){
                                bookStatusIcon = ( <ClearIcon /> )
                                st.color = ['white'];
                            }
                            else if(a.tipoOccupazioneCalendario.idTipoOccupazione === EQCalendarStates.ServizioIniziato){
                                bookStatusIcon = ( <ClearIcon /> )
                            }
                            */

                  let statusStyle = EQEventHelper.getStatusStyle(a)
                  let bookStatusIcon = statusStyle.icon
                  let st = statusStyle.style

                  let daydiff = false
                  if (
                    appointmentDateStart.getDay() !==
                    appointmentDateEnd.getDay()
                  ) {
                    daydiff = true
                  }

                  return (
                    <Fragment key={a.idCalendario}>
                      <div className="bookCard border-silverGray-t-lg mb-30 silverGray-shadow-sm">
                        <div className="serviceBody pb-30">
                          <div className="serviceTitle flex-row">
                            <label>Servizio:</label>
                            <div className="ml-5">
                              <strong>
                                {a.servizioPrenotazione.servizio.nome}
                              </strong>
                            </div>
                          </div>
                          <div className="serviceTime flex-row-start">
                            <div
                              style={{
                                paddingRight: '12px',
                                verticalAlign: 'top',
                              }}>
                              <EventIcon />
                            </div>
                            <div>
                              {!daydiff && (
                                <Fragment>
                                  <div className="dateText">
                                    {dayNames[appointmentDateStart.getDay()]}{' '}
                                    {appointmentDateStart.getDate()}{' '}
                                    {
                                      monthNames[
                                        appointmentDateStart.getMonth()
                                      ]
                                    }
                                  </div>
                                  <div>
                                    dalle ore:{' '}
                                    <strong>
                                      {(
                                        '0' + appointmentDateStart.getHours()
                                      ).slice(-2)}
                                      :
                                      {(
                                        '0' + appointmentDateStart.getMinutes()
                                      ).slice(-2)}
                                    </strong>
                                  </div>
                                  <div>
                                    alle ore:{' '}
                                    <strong>
                                      {(
                                        '0' + appointmentDateEnd.getHours()
                                      ).slice(-2)}
                                      :
                                      {(
                                        '0' + appointmentDateEnd.getMinutes()
                                      ).slice(-2)}
                                    </strong>
                                  </div>
                                </Fragment>
                              )}
                              {daydiff && (
                                <Fragment>
                                  <div>
                                    Da{' '}
                                    <span className="dateText">
                                      {dayNames[appointmentDateStart.getDay()]}{' '}
                                      {appointmentDateStart.getDate()}{' '}
                                      {
                                        monthNames[
                                          appointmentDateStart.getMonth()
                                        ]
                                      }
                                    </span>
                                  </div>
                                  <div>
                                    alle ore:{' '}
                                    <strong>
                                      {(
                                        '0' + appointmentDateStart.getHours()
                                      ).slice(-2)}
                                      :
                                      {(
                                        '0' + appointmentDateStart.getMinutes()
                                      ).slice(-2)}
                                    </strong>
                                  </div>
                                  <div>
                                    A{' '}
                                    <span className="dateText">
                                      {dayNames[appointmentDateEnd.getDay()]}{' '}
                                      {appointmentDateEnd.getDate()}{' '}
                                      {
                                        monthNames[
                                          appointmentDateEnd.getMonth()
                                        ]
                                      }
                                    </span>
                                  </div>
                                  <div>
                                    alle ore:{' '}
                                    <strong>
                                      {(
                                        '0' + appointmentDateEnd.getHours()
                                      ).slice(-2)}
                                      :
                                      {(
                                        '0' + appointmentDateEnd.getMinutes()
                                      ).slice(-2)}
                                    </strong>
                                  </div>
                                </Fragment>
                              )}
                            </div>
                          </div>
                          {EQCalendarStates.isLinkedToASingleCaregiver(a) && (
                            <>
                              <div className=" flex-col-start">
                                <div className="serviceAssistant">
                                  <div
                                    style={{
                                      verticalAlign: 'top',
                                      paddingRight: '10px',
                                    }}>
                                    <HealthAndSafetyIcon />
                                  </div>
                                  <div>
                                    Assistito:{' '}
                                    <strong>
                                      {
                                        a.servizioPrenotazione.prenotazione
                                          .paziente.nome
                                      }{' '}
                                      {
                                        a.servizioPrenotazione.prenotazione
                                          .paziente.cognome
                                      }
                                    </strong>
                                  </div>
                                </div>
                              </div>
                              <div className=" flex-col-start">
                                <div className="serviceAssistant">
                                  <div
                                    style={{
                                      verticalAlign: 'top',
                                      paddingRight: '10px',
                                    }}>
                                    <CaregiverIcon />
                                  </div>
                                  <div>
                                    Assistente:{' '}
                                    <strong>{a.professionista.nome}</strong>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          <div className="serviceNumber">
                            <div>
                              <label>IL TUO CODICE DI PRENOTAZIONE</label>
                            </div>
                            <div>
                              <strong>
                                {
                                  a.servizioPrenotazione.prenotazione
                                    .codicePratica
                                }
                              </strong>
                            </div>
                          </div>
                        </div>
                        <div
                          className="serviceFooter border-b-md"
                          style={st}>
                          <div className="serviceFooterContainer">
                            {bookStatusIcon} {a.tipoOccupazioneCalendario.nome}
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  )
                })
              ) : (
                <span className="inside-text">
                  Nessuna prenotazione trovata
                </span>
              )}
            </Box>
          </AccordionDetails>
        </Accordion>

        {this.state.modalOpen && (
          <EQBookModal
            comuni={this.state.comuni}
            onClose={this.closeDetail.bind(this)}
            mOpen={this.state.modalOpen}
            mBook={this.state.modalBook}></EQBookModal>
        )}

        <Box>
          <EQFaqBox category="MiePrenotazioni"></EQFaqBox>
        </Box>
      </div>
    )
  }
}
