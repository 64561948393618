import React from 'react'
import { AppBar, Box, Toolbar } from '@mui/material'
import EQMobileMenu from './EQMobileMenu'
import EQState from '../services/EQState'
import EQAccountMenu from './user/EQAccountMenu'

export default class EQHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      UserSelection: EQState.retrieve(EQState.UserSelection),
    }
  }

  reloadState() {
    this.setState({
      UserSelection: EQState.UserSelection,
    })
  }

  componentDidMount() {
    EQState.addListener(EQState.UserSelection, this)
  }

  componentWillUnmount() {
    EQState.removeListener(EQState.UserSelection, this)
  }

  gotoHome() {
    document.location.href = '/'
  }

  render() {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          style={{ background: '#FFF' }}
          position="fixed"
          data-html2canvas-ignore="true">
          <Toolbar>
            <EQMobileMenu></EQMobileMenu>

            <Box
              textAlign="center"
              px={2}
              sx={{ flexGrow: 1 }}
              onClick={() => this.gotoHome()}>
              <img
                className="logoComune"
                alt="Logo Comune Milano"
                src="/img/Progetto_finanziato_dal_Comune_di_Milano.svg"
              />
            </Box>
            <EQAccountMenu></EQAccountMenu>
          </Toolbar>
        </AppBar>
      </Box>
    )
  }
}
