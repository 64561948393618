import { Alert } from '@mui/material'
import React from 'react'

export default class EQToast extends React.Component {
  constructor(props) {
    super(props)
  }

  static open(component, deltaState, deltaState2) {
    component.setState(deltaState)
    setTimeout(() => {
      component.setState(deltaState2)
    }, 4000)
  }

  render() {
    const { message, className, severity, ...others } = this.props
    let cn = className + ' ' + severity + '-alert'
    return (
      <Alert
        className={cn}
        severity={severity}
        {...others}>
        {message}
      </Alert>
    )
  }
}
