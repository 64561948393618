import EQState from '../services/EQState'
import React from 'react'

export default class PageReset extends React.Component {
  componentDidMount() {
    console.warn('Reset sessione utente')
    EQState.retrieve(EQState.UserSelection)
    EQState.reset(EQState.UserSelection)
    EQState.persist(EQState.UserSelection)
    document.location.href = '/services'
  }

  render() {
    return null
  }
}
