import CF from '../libs/CF'
import EQService from './EQService'
import Formats from '../libs/Formats'

export default class EQServiceBook {
  static comuni = null
  static CAP = null
  static relazioniParentali = null
  static faq = null
  static tipiLuogo = null

  static sendPayLink() {
    const sId = EQService.sessionId()
    let result = new Promise((resolve, reject) => {
      EQService.get('/Prenotazione/InviaMailRichiestaPagamento', {
        idSessioneWeb: sId,
      }).then((res) => {
        if (!res) return
        resolve(res.data)
      })
    })
    return result
  }

  static forcePaymentDone(idPrenotazione) {
    const sId = EQService.sessionId()
    let result = new Promise((resolve, reject) => {
      EQService.get('/Prenotazione/ForzaIncassoPrenotazione', {
        idSessioneWeb: sId,
        idPrenotazione: idPrenotazione,
      }).then((res) => {
        if (!res) return
        resolve(res.data)
      })
    })
    return result
  }

  static getPrenotazionePendente(sId) {
    if (!sId) sId = EQService.sessionId()
    let result = new Promise((resolve, reject) => {
      EQService.get('/Prenotazione/GetPrenotazionePendente', {
        idSessioneWeb: sId,
      }).then((res) => {
        if (this.DEBUG) console.log('GET PRENOTAZIONE', res)
        if (!res) return
        resolve(res.data)
      })
    })
    return result
  }

  static getPrenotazioneInAttesaDiPagamento(sId) {
    if (!sId) sId = EQService.sessionId()
    let result = new Promise((resolve, reject) => {
      EQService.get('/Prenotazione/GetPrenotazioneInAttesaDiPagamento', {
        idSessioneWeb: sId,
      }).then((res) => {
        if (this.DEBUG) console.log('GET PRENOTAZIONE', res)
        if (!res) return
        resolve(res.data)
      })
    })
    return result
  }

  static eliminaEventualePrenotazionePendente() {
    const sId = EQService.sessionId()
    let result = new Promise((resolve, reject) => {
      EQService.get('/Prenotazione/EliminaEventualePrenotazionePendente', {
        idSessioneWeb: sId,
      }).then((res) => {
        if (!res) return
        resolve(res)
      })
    })
    return result
  }

  static creaPrenotazione(prenotazione) {
    const sId = EQService.sessionId()
    let pars = {
      idSessioneWeb: sId,
      idPaziente: prenotazione.idPaziente,
      Indirizzo: prenotazione.indirizzo,
      idTipoLuogo: prenotazione.idTipoLuogo,
      idComune: prenotazione.idComune,
      idCap: prenotazione.idCap,
      CommentoRichiedente: prenotazione.commentoRichiedente,
      IsEmergenza: prenotazione.emergenza,
      Gender: prenotazione.gender,
    }
    if (!pars.Gender) {
      pars.Gender = 'U'
    }
    let result = new Promise((resolve, reject) => {
      EQService.get('Prenotazione/CreaPrenotazione', pars).then((res) => {
        if (this.DEBUG) console.log('CREA PRENOTAZIONE', res)
        if (!res) return
        resolve(res)
      })
    })
    return result
  }

  static editPrenotazione(prenotazione) {
    let pars = {
      idSessioneWeb: prenotazione.idSessioneWeb,
      idTipoRelazioneParentale: prenotazione.idTipoRelazioneParentale,
      PesoInKG: prenotazione.pesoInKG,
      AltezzaInCM: prenotazione.altezzaInCM,
      HaAnimali: prenotazione.haAnimali,
      fumatore: prenotazione.fumatore,
      idTipoLuogo: prenotazione.idTipoLuogo,
      idComune: prenotazione.idComune,
      CommentoRichiedente: prenotazione.commentoRichiedente,
      Gender: prenotazione.gender,
    }
    if (!pars.Gender) {
      pars.Gender = 'U'
    }
    EQService.post('/Prenotazione/ModificaPrenotazione', pars)
  }

  static aggiungiServizioAPrenotazione(idServizio) {
    const sId = EQService.sessionId()
    let result = new Promise((resolve, reject) => {
      EQService.get('Prenotazione/AggiungiServizioAPrenotazione', {
        idSessioneWeb: sId,
        idPrenotazione: sId,
        idServizio: idServizio,
      }).then((res) => {
        if (!res) return
        let r = res
        resolve(r)
      })
    })
    return result
  }

  static rimuoviServizioDaPrenotazione(idServizio) {
    const sId = EQService.sessionId()
    let result = new Promise((resolve, reject) => {
      EQService.get('Prenotazione/RimuoviServizioDaPrenotazione', {
        idSessioneWeb: sId,
        idPrenotazione: sId,
        idServizio: idServizio,
      }).then((res) => {
        if (!res) return
        let r = res
        resolve(r)
      })
    })
    return result
  }

  static mapComuni() {
    let result = new Promise((resolve, reject) => {
      if (EQServiceBook.comuni) {
        resolve(EQServiceBook.comuni)
        return
      }
      EQService.get('/Prenotazione/ListComuniAbilitati').then((res) => {
        if (!res) return
        let map = res.data
        EQService.comuni = map
        resolve(map)
      })
    })
    return result
  }

  static mapCAP() {
    let result = new Promise((resolve, reject) => {
      if (EQServiceBook.CAP) {
        resolve(EQServiceBook.CAP)
        return
      }
      EQService.get('/Prenotazione/ListAllCapparioAbilitato').then((res) => {
        if (!res) return
        let map = res.data
        EQService.CAP = map
        resolve(map)
      })
    })
    return result
  }

  static listPazienti() {
    const sId = EQService.sessionId()
    let result = new Promise((resolve, reject) => {
      EQService.get('/Paziente/ListPazienti', {
        idSessioneWeb: sId,
      }).then((res) => {
        if (!res) return
        resolve(res.data)
      })
    })
    return result
  }

  static deletePaziente(pid) {
    const sId = EQService.sessionId()
    let result = new Promise((resolve, reject) => {
      EQService.get('/Paziente/ClienteEliminaPaziente', {
        idSessioneWeb: sId,
        idPaziente: pid,
      }).then((res) => {
        if (!res) return
        resolve(res.data)
      })
    })
    return result
  }

  static editPaziente(p) {
    let pars = {
      idSessioneWeb: EQService.sessionId(),
      idPaziente: p.idPaziente === '0' ? null : p.idPaziente,
      nome: p.nome,
      cognome: p.cognome,
      codiceFiscale: p.codiceFiscale,
      PesoInKG: p.pesoInKG,
      AltezzaInCM: p.altezzaInCM,
      HaAnimali: p.haAnimali,
      fumatore: p.fumatore,
      isCliente: p.isCliente === true,
    }
    /*
        if(p.dataNascita && p.dataNascita.getTime)
            pars.dataNascita=p.dataNascita.toISOString(); // ci assicuriamo che la data sia una stringa opportunamente formattata
        else
            pars.dataNascita=p.dataNascita;
        */

    // bugid#8760 - ricostruiamo sempre la data formattata dal codice fiscale
    if (p.codiceFiscale) {
      let bd = CF.birthDateFrom(p.codiceFiscale)
      pars.dataNascita = bd.year + '-' + (bd.month + 1) + '-' + bd.day
    }
    let service = 'ModificaPaziente'
    if (!pars.idPaziente) {
      service = 'CreaPaziente'
      delete pars.idPaziente
    }
    let result = new Promise((resolve, reject) => {
      EQService.get('/Paziente/' + service, pars).then((res) => {
        try {
          resolve(res.data)
        } catch (e) {
          reject(res)
        }
      })
    })
    return result
  }

  static mapFaq() {
    let result = new Promise((resolve, reject) => {
      EQService.get('/Cms/ListOnlineFaq').then((res) => {
        if (!res) return
        let map = res.data
        EQService.faqs = map
        resolve(map)
      })
    })
    return result
  }

  static mapTipiLuogo() {
    let result = new Promise((resolve, reject) => {
      EQService.get('/Cms/ListTipiLuogo').then((res) => {
        if (!res) return
        let map = res.data
        EQService.tipiLuogo = map
        resolve(map)
      })
    })
    return result
  }

  static listPatologie() {
    let result = new Promise((resolve, reject) => {
      EQService.get('/Paziente/ListAllPatologie', {}).then((res) => {
        if (!res) return
        resolve(res.data)
      })
    })
    return result
  }

  static setPatologie(p, arrayIdPatologie) {
    let postPars = JSON.stringify(arrayIdPatologie)
    let queryString =
      '?idSessioneWeb=' + EQService.sessionId() + '&idPaziente=' + p.idPaziente
    let conf = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    let result = new Promise((resolve, reject) => {
      EQService.post(
        '/Paziente/AggiungiPatologiePaziente' + queryString,
        postPars,
        conf
      ).then((res) => {
        try {
          resolve(res.data)
        } catch (e) {
          reject(res)
        }
      })
    })
    return result
  }

  static setServizi(elencoServizi) {
    let postPars = JSON.stringify(elencoServizi)
    let queryString = '?idSessioneWeb=' + EQService.sessionId()
    let conf = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    let result = new Promise((resolve, reject) => {
      EQService.post(
        '/Prenotazione/AggiungiServiziAPrenotazione' + queryString,
        postPars,
        conf
      ).then((res) => {
        try {
          resolve(res.data)
        } catch (e) {
          reject(res)
        }
      })
    })
    return result
  }

  static cercaSoluzioni() {
    let p = {
      idSessioneWeb: EQService.sessionId(),
    }
    let result = new Promise((resolve, reject) => {
      EQService.get('/Prenotazione/CercaSoluzioni', p).then((res) => {
        if (!res) return
        resolve(res.data)
      })
    })
    return result
  }

  static getCoupon(code) {
    let p = {
      idSessioneWeb: EQService.sessionId(),
      codiceCoupon: code,
    }
    let result = new Promise((resolve, reject) => {
      EQService.get('/Prenotazione/GetCoupon', p).then((res) => {
        try {
          resolve(res.data)
        } catch (e) {
          reject(res)
        }
      })
    })
    return result
  }

  static cercaAlternative(professionista, sp) {
    let result = new Promise((resolve, reject) => {
      let pars = {
        idSessioneWeb: EQService.sessionId(),
        idProfessionista: professionista.idProfessionista,
        al: sp.richiestoAl,
        dal: sp.richiestoDal,
        idServizio: sp.idServizio,
      }
      EQService.get('/Prenotazione/CercaAlternativeProfessionista', pars).then(
        (res) => {
          try {
            resolve(res.data)
          } catch (e) {
            reject(res)
          }
        }
      )
    })
    return result
  }

  static book(bookParams) {
    let postPars = JSON.stringify(bookParams)
    let queryString = '?idSessioneWeb=' + EQService.sessionId()
    let conf = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    let result = new Promise((resolve, reject) => {
      EQService.post(
        '/Prenotazione/ConfermaPrenotazione' + queryString,
        postPars,
        conf
      ).then((res) => {
        try {
          resolve(res.data)
        } catch (e) {
          reject(res)
        }
      })
    })
    return result
  }

  static generaTokenStripe(generateParams, sId) {
    if (!sId) sId = EQService.sessionId()
    let pars = {
      idSessioneWeb: sId,
      SuccessUrl: generateParams.successEndpoint,
      CancelUrl: generateParams.errorEndpoint,
    }
    let result = new Promise((resolve, reject) => {
      EQService.get('/Prenotazione/GeneraTokenStripe', pars).then((res) => {
        try {
          resolve(res.data)
        } catch (e) {
          reject(res)
        }
      })
    })
    return result
  }

  static confermaPagamentoPrenotazione(stripeClientSecret) {
    let pars = {
      idSessioneWeb: EQService.sessionId(),
      stripeClientSecret: stripeClientSecret,
    }
    let result = new Promise((resolve, reject) => {
      EQService.get('/Prenotazione/ConfermaPagamentoPrenotazione', pars).then(
        (res) => {
          try {
            resolve(res.data)
          } catch (e) {
            reject(res)
          }
        }
      )
    })
    return result
  }

  static listAppuntamentiCliente(from, to, settings) {
    const sId = EQService.sessionId()
    let result = new Promise((resolve, reject) => {
      EQService.get(
        '/Cliente/ListAppuntamentiCliente',
        {
          idSessioneWeb: sId,
          DataDal: Formats.toServerDate(from),
          DataAl: Formats.toServerDate(to),
        },
        settings
      ).then((res) => {
        if (this.DEBUG) console.log('GET PRENOTAZIONI', res)
        if (!res) return
        resolve(res.data)
      })
    })
    return result
  }

  static getPenali(idCalendario) {
    const sId = EQService.sessionId()
    let result = new Promise((resolve, reject) => {
      EQService.get('/Prenotazione/CalcolaPenale', {
        idSessioneWeb: sId,
        idCalendario: idCalendario,
      }).then((res) => {
        if (!res) return
        resolve(res.data)
      })
    })
    return result
  }

  static cancellaAppuntamento(idCalendario) {
    const sId = EQService.sessionId()
    let result = new Promise((resolve, reject) => {
      EQService.get('/Cliente/CancellaAppuntamento', {
        idSessioneWeb: sId,
        idCalendario: idCalendario,
      }).then((res) => {
        if (!res) return
        resolve(res.data)
      })
    })
    return result
  }

  static getAppuntamento(idCalendario) {
    const sId = EQService.sessionId()
    const pars = {
      idSessioneWeb: sId,
      idCalendario: idCalendario,
    }
    let result = new Promise((resolve, reject) => {
      EQService.get('/Cliente/GetAppuntamentoById', pars).then((res) => {
        if (!res) reject(res)
        else resolve(res)
      })
    })
    return result
  }

  static getQuestionarioBarthel(idPaziente) {
    const sId = EQService.sessionId()
    let result = new Promise((resolve, reject) => {
      EQService.get('/Paziente/GetQuestionario', {
        idSessioneWeb: sId,
        idPaziente: idPaziente,
      }).then((res) => {
        if (!res) return
        resolve(res.data)
      })
    })
    return result
  }

  static inserisciRispostaBarthel(idPaziente, domanda, risposta, punteggio) {
    const sId = EQService.sessionId()
    let result = new Promise((resolve, reject) => {
      EQService.get('/Paziente/InserisciRispostaQuestionario', {
        idSessioneWeb: sId,
        idPaziente: idPaziente,
        domanda: domanda,
        risposta: risposta,
        punteggio: punteggio,
      }).then((res) => {
        if (!res) return
        resolve(res.data)
      })
    })
    return result
  }
}
