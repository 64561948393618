import React, { Fragment } from 'react'

import { Box } from '@mui/material'

export default class EQEditorialBox extends React.Component {
  sendCurriculum() {
    // era https://docs.google.com/forms/d/e/1FAIpQLScYpfcH_7cAGIr8LlmXzK0cANn-e8TH3sWQf0AQD2WhLKcihg/viewform
    const win = window.open(`https://forms.gle/jk8MP4yJD2HLr1Xv6`, '_blank')
    win.focus()
  }

  render() {
    const content = this.props.content
    const boxClasses =
      this.props.boxNumber === 1
        ? 'editorialBox whiteBg'
        : 'editorialBox yellowBg'
    return (
      <Fragment>
        {this.props.boxNumber === 1 && (
          <div className={boxClasses}>
            <img
              src="/img/box_editoriale1.jpg"
              alt="editorial"
            />
            <div className="container">
              <Box
                my={2}
                className="editorialStars">
                <img
                  src="/img/stars.png"
                  alt="stelle"
                />
              </Box>
              <Box
                className="openInvComma"
                mb={1}>
                <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 349.078 349.078"
                  xmlSpace="preserve">
                  <g>
                    <path
                      d="M150.299,26.634v58.25c0,7.9-6.404,14.301-14.304,14.301c-28.186,0-43.518,28.909-45.643,85.966h45.643
                                    c7.9,0,14.304,6.407,14.304,14.304v122.992c0,7.896-6.404,14.298-14.304,14.298H14.301C6.398,336.745,0,330.338,0,322.447V199.455
                                    c0-27.352,2.754-52.452,8.183-74.611c5.568-22.721,14.115-42.587,25.396-59.048c11.608-16.917,26.128-30.192,43.16-39.44
                                    C93.886,17.052,113.826,12.333,136,12.333C143.895,12.333,150.299,18.734,150.299,26.634z M334.773,99.186
                                    c7.896,0,14.305-6.407,14.305-14.301v-58.25c0-7.9-6.408-14.301-14.305-14.301c-22.165,0-42.108,4.72-59.249,14.023
                                    c-17.035,9.248-31.563,22.523-43.173,39.44c-11.277,16.461-19.824,36.328-25.393,59.054c-5.426,22.166-8.18,47.266-8.18,74.605
                                    v122.992c0,7.896,6.406,14.298,14.304,14.298h121.69c7.896,0,14.299-6.407,14.299-14.298V199.455
                                    c0-7.896-6.402-14.304-14.299-14.304h-44.992C291.873,128.095,306.981,99.186,334.773,99.186z"
                    />
                  </g>
                </svg>
              </Box>
              <div>
                <div dangerouslySetInnerHTML={{ __html: content }}></div>
              </div>
              <Box
                className="closeInvComma"
                mt={1}>
                <svg
                  version="1.1"
                  id="Capa_2"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 349.078 349.078"
                  xmlSpace="preserve">
                  <g>
                    <path
                      d="M150.299,26.634v58.25c0,7.9-6.404,14.301-14.304,14.301c-28.186,0-43.518,28.909-45.643,85.966h45.643
                                    c7.9,0,14.304,6.407,14.304,14.304v122.992c0,7.896-6.404,14.298-14.304,14.298H14.301C6.398,336.745,0,330.338,0,322.447V199.455
                                    c0-27.352,2.754-52.452,8.183-74.611c5.568-22.721,14.115-42.587,25.396-59.048c11.608-16.917,26.128-30.192,43.16-39.44
                                    C93.886,17.052,113.826,12.333,136,12.333C143.895,12.333,150.299,18.734,150.299,26.634z M334.773,99.186
                                    c7.896,0,14.305-6.407,14.305-14.301v-58.25c0-7.9-6.408-14.301-14.305-14.301c-22.165,0-42.108,4.72-59.249,14.023
                                    c-17.035,9.248-31.563,22.523-43.173,39.44c-11.277,16.461-19.824,36.328-25.393,59.054c-5.426,22.166-8.18,47.266-8.18,74.605
                                    v122.992c0,7.896,6.406,14.298,14.304,14.298h121.69c7.896,0,14.299-6.407,14.299-14.298V199.455
                                    c0-7.896-6.402-14.304-14.299-14.304h-44.992C291.873,128.095,306.981,99.186,334.773,99.186z"
                    />
                  </g>
                </svg>
              </Box>
            </div>
          </div>
        )}
        {this.props.boxNumber === 2 && (
          <div className="editorialBox yellowBg">
            <img
              src="/img/box_editoriale2.jpg"
              alt="editorial-2"
            />
            <div className="container">
              <Box
                my={2}
                className="editorialStars">
                <img
                  src="/img/stars.png"
                  alt="stelle"
                />
              </Box>
              <Box
                className="openInvComma"
                mb={1}>
                <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 349.078 349.078"
                  xmlSpace="preserve">
                  <g>
                    <path
                      d="M150.299,26.634v58.25c0,7.9-6.404,14.301-14.304,14.301c-28.186,0-43.518,28.909-45.643,85.966h45.643
                                        c7.9,0,14.304,6.407,14.304,14.304v122.992c0,7.896-6.404,14.298-14.304,14.298H14.301C6.398,336.745,0,330.338,0,322.447V199.455
                                        c0-27.352,2.754-52.452,8.183-74.611c5.568-22.721,14.115-42.587,25.396-59.048c11.608-16.917,26.128-30.192,43.16-39.44
                                        C93.886,17.052,113.826,12.333,136,12.333C143.895,12.333,150.299,18.734,150.299,26.634z M334.773,99.186
                                        c7.896,0,14.305-6.407,14.305-14.301v-58.25c0-7.9-6.408-14.301-14.305-14.301c-22.165,0-42.108,4.72-59.249,14.023
                                        c-17.035,9.248-31.563,22.523-43.173,39.44c-11.277,16.461-19.824,36.328-25.393,59.054c-5.426,22.166-8.18,47.266-8.18,74.605
                                        v122.992c0,7.896,6.406,14.298,14.304,14.298h121.69c7.896,0,14.299-6.407,14.299-14.298V199.455
                                        c0-7.896-6.402-14.304-14.299-14.304h-44.992C291.873,128.095,306.981,99.186,334.773,99.186z"
                    />
                  </g>
                </svg>
              </Box>
              <div>
                <div dangerouslySetInnerHTML={{ __html: content }}></div>
              </div>
              <Box
                className="closeInvComma"
                mt={1}>
                <svg
                  version="1.1"
                  id="Capa_2"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 349.078 349.078"
                  xmlSpace="preserve">
                  <g>
                    <path
                      d="M150.299,26.634v58.25c0,7.9-6.404,14.301-14.304,14.301c-28.186,0-43.518,28.909-45.643,85.966h45.643
                                        c7.9,0,14.304,6.407,14.304,14.304v122.992c0,7.896-6.404,14.298-14.304,14.298H14.301C6.398,336.745,0,330.338,0,322.447V199.455
                                        c0-27.352,2.754-52.452,8.183-74.611c5.568-22.721,14.115-42.587,25.396-59.048c11.608-16.917,26.128-30.192,43.16-39.44
                                        C93.886,17.052,113.826,12.333,136,12.333C143.895,12.333,150.299,18.734,150.299,26.634z M334.773,99.186
                                        c7.896,0,14.305-6.407,14.305-14.301v-58.25c0-7.9-6.408-14.301-14.305-14.301c-22.165,0-42.108,4.72-59.249,14.023
                                        c-17.035,9.248-31.563,22.523-43.173,39.44c-11.277,16.461-19.824,36.328-25.393,59.054c-5.426,22.166-8.18,47.266-8.18,74.605
                                        v122.992c0,7.896,6.406,14.298,14.304,14.298h121.69c7.896,0,14.299-6.407,14.299-14.298V199.455
                                        c0-7.896-6.402-14.304-14.299-14.304h-44.992C291.873,128.095,306.981,99.186,334.773,99.186z"
                    />
                  </g>
                </svg>
              </Box>

              <Box mt={4}>
                <a
                  className="linkblack playFair"
                  onClick={() => this.sendCurriculum()}
                  href="#">
                  Vuoi lavorare con noi?
                  <br />
                  Invia la tua candidatura!
                </a>
              </Box>
            </div>
          </div>
        )}
      </Fragment>
    )
  }
}
