import { Box, Button, FormControl, Grid } from '@mui/material'
import React, { Fragment } from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CachedTwoToneIcon from '@mui/icons-material/CachedTwoTone'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ContactPhoneTwoTone from '@mui/icons-material/BadgeTwoTone'
import EQBusinessRules from '../../libs/EQBusinessRules'
import EQFaqBox from '../EQFaqBox'
import EQFloatingFormIcon from '../core/EQFloatingFormIcon'
import EQService from '../../services/EQService'
import EQState from '../../services/EQState'
import EQTextField from '../core/EQTextField'
import NoEncryptionGmailerrorredTwoToneIcon from '@mui/icons-material/NoEncryptionGmailerrorredTwoTone'
import Pages from '../../services/Pages'
import PersonOutlineIcon from '@mui/icons-material/PersonOutlineTwoTone'
import Utils from '../../libs/Utils'
import { withRouter } from 'react-router-dom'

class EQBookStep_Otp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      otp: '',
    }
  }

  componentDidMount() {
    if (!EQState.UserSelection.Solutions_Caregivers) {
      document.location.href = '/'
      return
    }

    if (!EQService.sessioneWeb || !EQService.sessioneWeb.user) {
      EQService.getSessione(EQState.UserSelection._idSessioneWeb).then(() => {
        if (EQService.sessioneWeb.user.isOTPOK) {
          document.location.href = '/book/recap'
          return
        } else {
          this.forceUpdate()
        }
      })
    } else {
      if (EQService.sessioneWeb.user.isOTPOK) {
        document.location.href = '/book/recap'
        return
      }
    }

    setTimeout(() => {
      this.sendOtp()
      document.getElementById('otp-field').focus()
    }, 1000)

    const page = Pages.current()
    document.title = page.title
    window.scrollTo(0, 0)
  }

  goBack() {
    EQState.change(EQState.UserSelection, {
      BookPage: `/book/cart`,
    })
    this.props.history.push({
      pathname: `/book/cart`,
    })
  }

  goForward() {
    EQState.change(EQState.UserSelection, {
      BookPage: `/book/recap`,
    })
    this.props.history.push({
      pathname: `/book/recap`,
    })
  }

  dataChanged(e) {
    let otpInserted = e.target.value

    if (otpInserted.length > EQBusinessRules.lunghezzaOtp()) {
      return
    }

    if (otpInserted.length === EQBusinessRules.lunghezzaOtp()) {
      this.setState({
        otpChecking: true,
        otpWrong: false,
        otpOk: false,
      })

      EQService.otp(otpInserted, EQService.sessioneWeb.idUser).then((m) => {
        if (!m) {
          Utils.notifyError('OTP non valida')
          this.setState({
            otpChecking: false,
            otpWrong: true,
            otpOk: false,
          })
        } else {
          EQService.sessioneWeb.user.isOTPOK = true
          this.setState({
            otpChecking: false,
            otpWrong: false,
            otpOk: true,
          })
          this.goForward()
        }
      })
    }

    this.setState({
      otp: e.target.value,
      otpChecking: false,
      otpWrong: false,
      otpOk: false,
    })
  }

  sendOtp() {
    EQService.sendOtp().then((m) => {
      if (!m) {
        Utils.notifyError("Errore nell'invio del codice")
      } else {
        Utils.notifyConfirm('Codice inviato')
      }
    })
  }

  render() {
    //console.log("EQService.sessioneWeb",EQService.sessioneWeb);
    if (!EQService.sessioneWeb || !EQService.sessioneWeb.user) {
      return null
    }
    if (!EQState.UserSelection.Solutions_Caregivers) {
      return null
    }

    let pname =
      EQState.UserSelection.SelectedRarentalRelation.nome +
      ' ' +
      EQState.UserSelection.SelectedRarentalRelation.cognome

    let canGoForward = true

    return (
      <Fragment>
        <Box>
          <p className="playFair normalWeight textCenter bookstep-start-label">
            Stai prenotando per
          </p>
          <div className="row">
            <div className="col1-icon">
              <PersonOutlineIcon />
            </div>
            <div className="col2-icon">
              <h3 className="textCenter patient-name">{pname}</h3>
            </div>
          </div>

          <Box
            className="boxBianco large-side-padding"
            style={{ marginBottom: '20px' }}>
            <Box
              pl={2}
              pb={2}>
              <p className="playFair normalWeight bookstep-start-label no-y-m">
                Conferma contatti
              </p>
            </Box>
            <Box
              pl={2}
              pb={2}>
              <div style={{ marginBottom: '4px' }}>
                Durante l'erogazione dei servizi richiesti{' '}
                <b>potrebbe essere necessario comunicare</b> tempestivamente con
                te.
              </div>
              <div style={{ marginBottom: '4px' }}>
                Per piacere conferma la correttezza del tuo indirizzo email e
                del tuo numero di telefono.
              </div>
              <div>
                <b>Inserisci il codice che ti abbiamo inviato via email.</b>
              </div>
            </Box>
            <EQFloatingFormIcon>
              <ContactPhoneTwoTone />
            </EQFloatingFormIcon>
            <Box pl={2}>
              <FormControl fullWidth>
                {this.state.otpOk && (
                  <div className="otp-ok">
                    <CheckCircleOutlineIcon />
                  </div>
                )}
                {this.state.otpWrong && (
                  <div className="otp-wrong">
                    <NoEncryptionGmailerrorredTwoToneIcon />
                  </div>
                )}
                {this.state.otpChecking && (
                  <div className="otp-checking">
                    <CachedTwoToneIcon
                      sx={{
                        animation: 'spin 2s linear infinite',
                        '@keyframes spin': {
                          '0%': {
                            transform: 'rotate(360deg)',
                          },
                          '100%': {
                            transform: 'rotate(0deg)',
                          },
                        },
                      }}
                    />
                  </div>
                )}
                <EQTextField
                  label="Codice otp"
                  maxLength={EQBusinessRules.lunghezzaOtp()}
                  id="otp-field"
                  onChange={(e) => this.dataChanged(e)}
                  value={this.state.otp}
                />
              </FormControl>
            </Box>
            <Box pl={0}>
              <p
                className="textCenter playFair"
                style={{ paddingTop: '10px' }}>
                <a
                  onClick={() => this.sendOtp()}
                  style={{ textDecoration: 'underline' }}>
                  Manda un nuovo codice
                </a>
              </p>
            </Box>
          </Box>

          <EQFaqBox category="ConcermaContatti" />

          <Grid
            className="book-navigation shrink"
            container
            mt={0}
            mb={0}>
            <Grid
              item
              xs={6}>
              <Button
                className="lnkButton lnkBack"
                startIcon={<ArrowBackIcon />}
                onClick={() => this.goBack()}>
                Indietro
              </Button>
            </Grid>
            <Grid
              item
              sx={{ textAlign: 'right', paddingRight: '20px' }}
              xs={6}></Grid>
          </Grid>
        </Box>
      </Fragment>
    )
  }
}

export default withRouter(EQBookStep_Otp)
