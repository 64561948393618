/* eslint-disable react/no-direct-mutation-state */
import { Button, FormControl, Grid, Link } from '@mui/material'
import React, { Fragment } from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Box from '@mui/material/Box'
import CF from '../../libs/CF'
import EQBusinessRules from '../../libs/EQBusinessRules'
import EQFaqBox from '../EQFaqBox'
import EQFloatingFormIcon from '../core/EQFloatingFormIcon'
import EQFormGroup from '../core/EQFormGroup'
import EQHelpModal from '../EQHelpModal'
import EQLoading from '../EQLoading'
import EQSelect from '../core/EQSelect'
import EQServiceBook from '../../services/EQServiceBook'
import EQState from '../../services/EQState'
import EQTextField from '../core/EQTextField'
import EQToast from '../core/EQToast'
import Formats from '../../libs/Formats'
import HeightTwoToneIcon from '@mui/icons-material/HeightTwoTone'
import { MenuItem } from 'material-ui'
import { MuiThemeProvider } from 'material-ui/styles'
import Pages from '../../services/Pages'
import PersonAddIcon from '@mui/icons-material/PersonAddAltTwoTone'
import PersonOutlineIcon from '@mui/icons-material/PersonOutlineTwoTone'
import SettingsAccessibilityIcon from '@mui/icons-material/ScaleTwoTone'
import SubtitlesIcon from '@mui/icons-material/CallToActionTwoTone'
import TodayIcon from '@mui/icons-material/CalendarMonthTwoTone'
import { withRouter } from 'react-router-dom'

export class EQBookStep_Person extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      UserSelection: EQState.UserSelection,
      helpCode: null,
      parentalRelations: null,
      isDataConfirmed: false,
    }
  }

  reloadState() {
    this.setState({
      UserSelection: EQState.UserSelection,
    })
  }

  componentDidMount() {
    if (!EQState.UserSelection || !EQState.UserSelection.GruppoServizio) {
      document.location.href = '/'
      return
    }

    const page = Pages.current()
    document.title = page.title

    // bugid#6884
    setTimeout(() => {
      document.body.style.overflow = 'auto'
    }, 500)

    window.scrollTo(0, 0)
    EQState.addListener(EQState.UserSelection, this)

    // Verifichiamo prima di tutto se ci sono pazienti già definiti,
    // altrimenti per prima cosa andiamo alla definizione del primo paziente
    EQServiceBook.listPazienti().then((l) => {
      let nonEliminati = []
      l.forEach((x) => {
        if (!x.eliminatoDaCliente && !x.isCliente) nonEliminati.push(x)
      })

      if (nonEliminati.length === 0) {
        this.state.parentalRelations = nonEliminati
        this.newPerson()
      } else {
        this.setState({
          parentalRelations: nonEliminati,
        })
      }
    })
  }

  componentWillUnmount() {
    EQState.removeListener(EQState.UserSelection, this)
  }

  newPerson() {
    // eslint-disable-next-line
    this.state.original_SelectedRarentalRelation = null
    EQState.change(EQState.UserSelection, {
      SelectedRarentalRelation: null,
      Location: null,
      Patologies: null,
    })
    window.setTimeout(() => {
      // eslint-disable-next-line
      this.state.editOpen = true
      EQState.change(EQState.UserSelection, {
        SelectedRarentalRelation: {
          Changed: true,
          idPaziente: '0',
          nome: '',
          cognome: '',
          dataNascita: new Date(),
          pesoInKG: 0,
          altezzaInCM: 0,
          codiceFiscale: '',
          haAnimali: false,
          fumatore: false,
        },
        Location: null,
        Patologies: null,
      })
    }, 100)
  }

  handleParentalRelationChange(event) {
    let selector = event.target
    if (selector.value === '*') {
      this.newPerson()
    } else if (selector.value === 0) {
      EQState.change(EQState.UserSelection, {
        SelectedRarentalRelation: null,
        Location: null,
        Patologies: null,
      })
    } else {
      let pr = this.state.parentalRelations.filter(
        (x) => x.idPaziente === selector.value
      )[0]
      // eslint-disable-next-line
      this.state.editOpen = false
      EQState.change(EQState.UserSelection, {
        SelectedRarentalRelation: null,
        Location: null,
        Patologies: null,
      })
      window.setTimeout(() => {
        EQState.change(EQState.UserSelection, {
          SelectedRarentalRelation: pr,
        })
      }, 100)
    }
  }

  openHelp(helpCode) {
    this.setState({ helpCode: helpCode })
  }

  closeHelp() {
    this.setState({ helpCode: null })
  }

  buildOptions(min, max) {
    var arr = []
    for (let i = min; i <= max; i++) {
      arr.push(
        <MenuItem
          key={i}
          value={i}>
          {i}
        </MenuItem>
      )
    }
    return arr
  }

  goBack() {
    // per volontà di Equecure bisogna gestire i vari passi come una sorta di sottoprocesso :-(

    // se siamo in inserimento, indietro disattiva l'inserimento senza salvare
    if (this.state.editOpen) {
      this.cancelEdit()
      return
    }

    // se abbiamo selezionato una persona, indietro la deseleziona e ci riporta al selettore iniziale
    if (this.state.UserSelection.SelectedRarentalRelation) {
      this.state.UserSelection.SelectedRarentalRelation = null
      this.forceUpdate()
      return
    }

    EQState.change(EQState.UserSelection, {
      BookPage: `/book/person`,
    })
    document.location.href = '/services'
  }

  goForward() {
    // per volontà di Equecure bisogna gestire i vari passi come una sorta di sottoprocesso :-(

    // se siamo in inserimento, avanti salva le informazioni inserite e poi va avanti
    if (this.state.editOpen) {
      this.setState({
        inTransition: true,
      })
      this.saveData()
      return
    }

    EQState.change(EQState.UserSelection, {
      BookPage: `/book/medical`,
    })
    this.props.history.push({
      pathname: `/book/medical`,
    })
  }

  dataChanged(what, e) {
    this.state.UserSelection.SelectedRarentalRelation.Changed = true
    if (what === 'height')
      this.state.UserSelection.SelectedRarentalRelation.altezzaInCM =
        e.target.value
    else if (what === 'weight')
      this.state.UserSelection.SelectedRarentalRelation.pesoInKG =
        e.target.value
    else if (what === 'cf') {
      this.state.UserSelection.SelectedRarentalRelation.codiceFiscale =
        e.target.value
      if (
        CF.validate(
          this.state.UserSelection.SelectedRarentalRelation.codiceFiscale
        )
      ) {
        let bd = CF.birthDateFrom(
          this.state.UserSelection.SelectedRarentalRelation.codiceFiscale
        )
        console.log('bd', bd)
        let ddd = bd.day
        if (ddd.indexOf('0') === 0) ddd = ddd.substring(1)
        if (ddd.length < 2) ddd = '0' + ddd
        let mmm = bd.month + 1
        if (mmm < 10) mmm = '0' + mmm
        let ss = parseInt(bd.year) + '-' + mmm + '-' + ddd + ' 00:00:00'
        console.log('ss', ss)
        let dd = Formats.fromServerDate(ss) //new Date(bd.year, bd.month+1, bd.day);
        console.log('dd', dd)
        this.state.UserSelection.SelectedRarentalRelation.dataNascita = dd
        this.state.birthDateHidden = true
        setTimeout(() => {
          this.setState({
            birthDateHidden: false,
          })
        }, 0)
      }
    } else if (what === 'name')
      this.state.UserSelection.SelectedRarentalRelation.nome = e.target.value
    else if (what === 'surname')
      this.state.UserSelection.SelectedRarentalRelation.cognome = e.target.value
    else if (what === 'bday') {
      let dd = new Date(
        this.state.UserSelection.SelectedRarentalRelation.dataNascita
      )
      if (!dd) dd = new Date()
      dd.setDate(e.target.value)
      this.state.UserSelection.SelectedRarentalRelation.dataNascita = dd
    } else if (what === 'bmon') {
      let dd = new Date(
        this.state.UserSelection.SelectedRarentalRelation.dataNascita
      )
      if (!dd) dd = new Date()
      dd.setMonth(e.target.value)
      this.state.UserSelection.SelectedRarentalRelation.dataNascita = dd
    } else if (what === 'byear') {
      let dd = new Date(
        this.state.UserSelection.SelectedRarentalRelation.dataNascita
      )
      if (!dd) dd = new Date()
      dd.setFullYear(e.target.value)
      this.state.UserSelection.SelectedRarentalRelation.dataNascita = dd
    }
    this.forceUpdate()
  }

  openEdit() {
    // copia usata per eventuale annulla
    const copied = Object.assign(
      {},
      this.state.UserSelection.SelectedRarentalRelation
    )
    this.setState({
      editOpen: true,
      original_SelectedRarentalRelation: copied,
    })
  }

  cancelEdit() {
    // eslint-disable-next-line
    this.state.editOpen = false
    // eslint-disable-next-line
    this.state.inTransition = true
    EQState.change(EQState.UserSelection, {
      SelectedRarentalRelation: null,
    })
    if (
      this.state.original_SelectedRarentalRelation &&
      this.state.original_SelectedRarentalRelation.idPaziente
    ) {
      // eslint-disable-next-line
      this.state.UserSelection.SelectedRarentalRelation =
        this.state.original_SelectedRarentalRelation
    } else {
      // eslint-disable-next-line
      this.state.UserSelection.SelectedRarentalRelation = null
    }
    window.setTimeout(() => {
      // eslint-disable-next-line
      this.state.inTransition = false
      EQState.change(EQState.UserSelection, {
        SelectedRarentalRelation:
          this.state.UserSelection.SelectedRarentalRelation,
      })
    }, 0)
  }

  saveData() {
    if (!this.dataOk()) return
    EQServiceBook.editPaziente(
      this.state.UserSelection.SelectedRarentalRelation
    )
      .then((p) => {
        if (
          this.state.UserSelection.SelectedRarentalRelation.idPaziente === '0'
        )
          this.state.parentalRelations.push(p)
        this.state.UserSelection.SelectedRarentalRelation = p
        this.setState({
          editOpen: false,
        })

        // Andiamo al passo successivo
        EQState.change(EQState.UserSelection, {
          BookPage: `/book/medical`,
        })
        this.props.history.push({
          pathname: `/book/medical`,
        })
      })
      .catch((e) => {
        console.error('Errore nel salvataggio dati', e)
        this.state.inTransition = false
        EQToast.open(
          this,
          {
            errorMessage: 'Errore (codice fiscale duplicato?)',
          },
          {
            errorMessage: null,
          }
        )
      })
  }

  dataOk() {
    if (!this.state.UserSelection.SelectedRarentalRelation.nome) return false
    if (!this.state.UserSelection.SelectedRarentalRelation.cognome) return false
    if (!this.state.UserSelection.SelectedRarentalRelation.codiceFiscale)
      return false
    if (!this.validateCF()) return false
    if (!this.state.UserSelection.SelectedRarentalRelation.altezzaInCM)
      return false
    if (!this.state.UserSelection.SelectedRarentalRelation.pesoInKG)
      return false
    return true
  }

  validateCF() {
    if (!this.state.UserSelection.SelectedRarentalRelation.codiceFiscale)
      return false
    return CF.validate(
      this.state.UserSelection.SelectedRarentalRelation.codiceFiscale
    )
  }

  cfHelperText() {
    if (!this.state.UserSelection.SelectedRarentalRelation.codiceFiscale) {
      // return "Informazione necessaria";
      return null
    }
    if (
      !CF.validate(
        this.state.UserSelection.SelectedRarentalRelation.codiceFiscale
      )
    ) {
      return 'Codice fiscale non valido'
    }
    return null
  }

  genericValidate(key) {
    if (!this.state.UserSelection.SelectedRarentalRelation[key]) return false
    if (this.state.UserSelection.SelectedRarentalRelation[key].length === 0)
      return false
    return true
  }

  genericHelperText(key, errorHelp) {
    if (!this.state.UserSelection.SelectedRarentalRelation[key]) return null // all'inizio nessun messaggio di errore
    if (this.state.UserSelection.SelectedRarentalRelation[key].length === 0)
      return errorHelp
    return null
  }

  validateBirthYear() {
    try {
      let dd = new Date(
        this.state.UserSelection.SelectedRarentalRelation.dataNascita
      )
      let bd = CF.birthDateFrom(
        this.state.UserSelection.SelectedRarentalRelation.codiceFiscale
      )
      if (dd.getFullYear() !== bd.getFullYear()) return false
      return true
    } catch (e) {
      return false
    }
  }

  yearHelperText() {
    if (!this.validateBirthYear()) return 'Invalido'
    return ''
  }

  getBgCode() {
    if (this.state.UserSelection.idTipologiaServizio == 100) {
      return 'EMERGENZA'
    } else if (this.state.UserSelection.idTipologiaServizio == 2) {
      return 'FAMILIARE'
    } else {
      return 'DOMICILIARE'
    }
  }

  render() {
    if (!EQState.UserSelection || !EQState.UserSelection.GruppoServizio) {
      //console.log("User selection ", EQState.UserSelection);
      //console.log("User selection ", EQState.UserSelection.GruppoServizio);
      return null
    }

    if (this.state.inTransition) {
      return <EQLoading type="boxBianco" />
    }

    let parentalOptions = [
      /*<MenuItem key={0} value={0}></MenuItem>*/
    ]
    if (!this.state.parentalRelations) {
      return <EQLoading type="boxBianco" />
    } else {
      this.state.parentalRelations.forEach((p) => {
        parentalOptions.push(
          <MenuItem
            key={p.idPaziente}
            value={p.idPaziente}>
            {p.nome} {p.cognome}
          </MenuItem>
        )
      })
    }
    parentalOptions.push(
      <MenuItem
        key="*"
        value="*">
        Aggiungi una nuova scheda
      </MenuItem>
    )

    let canGoForward = false
    if (this.state.UserSelection.SelectedRarentalRelation) {
      canGoForward = true
      if (this.state.editOpen) {
        if (!this.dataOk()) {
          canGoForward = false
        }
      }
    }

    const MESI = Formats.monthNames()
    let defaultBDay = 0
    let defaultBMon = 0
    let defaultBMonName = ''
    let defaultBYear = 0
    if (
      this.state.UserSelection.SelectedRarentalRelation &&
      this.state.UserSelection.SelectedRarentalRelation.codiceFiscale
    ) {
      let bd = CF.birthDateFrom(
        this.state.UserSelection.SelectedRarentalRelation.codiceFiscale
      )
      let dd = new Date(bd.year, bd.month, bd.day)
      if (dd && dd.getDate) {
        defaultBDay = dd.getDate()
        defaultBMon = dd.getMonth()
        defaultBMonName = MESI[defaultBMon]
        defaultBYear = dd.getFullYear()
      }
    }

    let defaultW = 0
    let defaultH = 0
    if (this.state.UserSelection.SelectedRarentalRelation) {
      defaultW = this.state.UserSelection.SelectedRarentalRelation.pesoInKG
      defaultH = this.state.UserSelection.SelectedRarentalRelation.altezzaInCM
    }

    let ro = true
    if (this.state.editOpen) ro = false

    let faqCat = 'Assistiti'
    if (this.state.UserSelection.SelectedRarentalRelation) {
      if (!this.state.editOpen) faqCat = 'VerificaAssistito'
      else faqCat = 'NuovoAssistito'
    }

    let cssBox = 'boxCeleste large-side-padding'
    if (
      EQBusinessRules.isEmergenza(EQState.UserSelection.idTipologiaServizio)
    ) {
      cssBox = 'boxCeleste large-side-padding EMERGENZA'
    }
    if (EQState.UserSelection.idTipologiaServizio === 2) {
      cssBox = 'boxCeleste large-side-padding FAMILIARE'
    }

    const bgFaqCss = 'fab bg' + this.getBgCode()

    return (
      <Fragment>
        <Box
          ml={0}
          mr={0}>
          <Box>
            {this.state.errorMessage != null && (
              <EQToast
                severity="error"
                message={this.state.errorMessage}
              />
            )}

            {!this.state.UserSelection.SelectedRarentalRelation && (
              <Fragment>
                <h2 className="playFair normalWeight textCenter">
                  Chi ha bisogno dell'assistenza?
                </h2>
                <p className="textCenter">
                  Scegli per chi vuoi prenotare, crea una nuova scheda o
                  modificala.
                </p>
              </Fragment>
            )}
            {this.state.UserSelection.SelectedRarentalRelation &&
              !this.state.editOpen && (
                <Fragment>
                  <h2 className="playFair normalWeight textCenter">
                    La scheda è aggiornata?
                  </h2>
                  <p className="textCenter">
                    Controlla le informazioni della persona da assistere e
                    aggiornale, se necessario.
                  </p>
                </Fragment>
              )}
            {this.state.UserSelection.SelectedRarentalRelation &&
              this.state.editOpen && (
                <Fragment>
                  <h2 className="playFair normalWeight textCenter">
                    Definisci la scheda
                  </h2>
                  <p className="textCenter">
                    Indica qui le informazioni relative alla persona da
                    assistere.
                  </p>
                </Fragment>
              )}

            {!this.state.UserSelection.SelectedRarentalRelation && (
              <Box className={cssBox}>
                <Box pb={2}>
                  <div className="textCenter playFair app-text">
                    Per chi vuoi prenotare?
                  </div>
                </Box>
                <EQFloatingFormIcon>
                  <PersonOutlineIcon style={{ fontSize: '1.8rem' }} />
                </EQFloatingFormIcon>
                <Box
                  pb={2}
                  pl={2}>
                  <FormControl
                    className="ml-10"
                    fullWidth>
                    <EQSelect
                      label="Per chi stai prenotando"
                      defaultValue={
                        this.state.UserSelection.SelectedRarentalRelation &&
                        this.state.UserSelection.SelectedRarentalRelation
                          .idPaziente
                          ? this.state.UserSelection.SelectedRarentalRelation
                              .idPaziente
                          : 0
                      }
                      onChange={(e) => this.handleParentalRelationChange(e)}>
                      {parentalOptions}
                    </EQSelect>
                  </FormControl>
                </Box>
                <EQFloatingFormIcon>
                  <PersonAddIcon style={{ fontSize: '1.8rem' }} />
                </EQFloatingFormIcon>
                <Box
                  pb={2}
                  pl={2}>
                  <div
                    className="ml-10 mt-10"
                    style={{ textAlign: 'center' }}>
                    <Link
                      onClick={() => this.newPerson()}
                      className="playFair linkblack app-text">
                      Aggiungi una nuova scheda
                    </Link>
                  </div>
                </Box>
              </Box>
            )}
            {this.state.UserSelection.SelectedRarentalRelation && (
              <MuiThemeProvider>
                <Box className={cssBox}>
                  <EQFloatingFormIcon>
                    <PersonOutlineIcon style={{ fontSize: '1.8rem' }} />
                  </EQFloatingFormIcon>
                  <Box
                    pb={2}
                    pl={2}>
                    <FormControl
                      className="ml-10"
                      fullWidth>
                      <EQTextField
                        defaultValue={
                          this.state.UserSelection.SelectedRarentalRelation.nome
                        }
                        label="Nome"
                        onChange={(e) => this.dataChanged('name', e)}
                        disabled={ro}></EQTextField>
                    </FormControl>
                  </Box>
                  <Box
                    pb={2}
                    pl={2}>
                    <FormControl
                      className="ml-10"
                      fullWidth>
                      <EQTextField
                        defaultValue={
                          this.state.UserSelection.SelectedRarentalRelation
                            .cognome
                        }
                        label="Cognome"
                        onChange={(e) => this.dataChanged('surname', e)}
                        disabled={ro}></EQTextField>
                    </FormControl>
                  </Box>
                  <EQFloatingFormIcon>
                    <SubtitlesIcon style={{ fontSize: '1.8rem' }} />
                  </EQFloatingFormIcon>
                  <Box
                    pb={2}
                    pl={2}>
                    <FormControl
                      className="ml-10"
                      fullWidth>
                      <EQTextField
                        defaultValue={
                          this.state.UserSelection.SelectedRarentalRelation
                            .codiceFiscale
                        }
                        error={!this.validateCF()}
                        helperText={this.cfHelperText()}
                        inputProps={{ style: { textTransform: 'uppercase' } }}
                        label="Codice Fiscale"
                        onChange={(e) => this.dataChanged('cf', e)}
                        disabled={ro}></EQTextField>
                    </FormControl>
                  </Box>
                  <EQFloatingFormIcon>
                    <TodayIcon style={{ fontSize: '1.8rem' }} />
                  </EQFloatingFormIcon>
                  <Box
                    pb={2}
                    pl={2}>
                    <EQFormGroup
                      className="ml-20"
                      label="Data di nascita">
                      {!this.validateCF() || this.state.birthDateHidden ? (
                        <div className="group-contents">Non definito</div>
                      ) : (
                        <div className="group-contents">
                          {defaultBDay} {defaultBMonName} {defaultBYear}
                        </div>
                      )}
                    </EQFormGroup>
                  </Box>
                  <EQFloatingFormIcon>
                    <SettingsAccessibilityIcon style={{ fontSize: '1.8rem' }} />
                  </EQFloatingFormIcon>
                  <Box
                    pl={2}
                    className="ml-10">
                    <Grid container>
                      <Grid
                        xs={4}
                        pr={1}
                        item>
                        <FormControl fullWidth>
                          <EQSelect
                            defaultValue={defaultW}
                            label="Peso"
                            onChange={(e) => this.dataChanged('weight', e)}
                            disabled={ro}>
                            <MenuItem value={0}></MenuItem>
                            {this.buildOptions(30, 150)}
                          </EQSelect>
                        </FormControl>
                      </Grid>
                      <Grid
                        xs={1}
                        style={{ paddingTop: '12px' }}
                        item>
                        <HeightTwoToneIcon style={{ fontSize: '1.8rem' }} />
                      </Grid>
                      <Grid
                        xs={4}
                        pr={1}
                        item>
                        <FormControl fullWidth>
                          <EQSelect
                            defaultValue={defaultH}
                            label="Altezza"
                            onChange={(e) => this.dataChanged('height', e)}
                            disabled={ro}>
                            <MenuItem value={0}></MenuItem>
                            {this.buildOptions(130, 210)}
                          </EQSelect>
                        </FormControl>
                      </Grid>
                      <Grid
                        xs={2}
                        item
                        pt={0}>
                        <button
                          onClick={() => this.openHelp('HELP_ALTEZZA_PESO')}
                          className={bgFaqCss}>
                          ?
                        </button>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    pl={2}
                    pb={3}
                    pt={2}>
                    <div className="small-note">
                      Puoi controllare{' '}
                      <a
                        href="/privacy"
                        target="_blank">
                        qui
                      </a>{' '}
                      l'informativa sulla privacy
                    </div>
                  </Box>
                  {!this.state.editOpen && (
                    <div style={{ marginTop: '20px', textAlign: 'center' }}>
                      <Link
                        onClick={() => this.openEdit()}
                        className="playFair linkblack app-text">
                        Modifica scheda
                      </Link>
                    </div>
                  )}
                  {this.state.editOpen && (
                    <div style={{ marginTop: '20px', textAlign: 'center' }}>
                      <Link
                        onClick={() => this.cancelEdit()}
                        className="playFair linkblack app-text">
                        Annulla
                      </Link>
                    </div>
                  )}
                </Box>
              </MuiThemeProvider>
            )}
          </Box>
        </Box>

        <Grid
          className="book-navigation shrink"
          container
          mt={0}
          mb={0}>
          <Grid
            item
            xs={6}>
            <Button
              className="lnkButton lnkBack"
              startIcon={<ArrowBackIcon />}
              onClick={() => this.goBack()}>
              Indietro
            </Button>
          </Grid>
          <Grid
            item
            sx={{ textAlign: 'right' }}
            xs={6}>
            {canGoForward && (
              <a
                className={`buttonLink lnkForward  button-${this.getBgCode()}`}
                onClick={() => this.goForward()}>
                AVANTI
              </a>
            )}
            {!canGoForward && (
              <a
                className={`buttonLink lnkForward disabled button-${this.getBgCode()}`}>
                AVANTI
              </a>
            )}
          </Grid>
        </Grid>

        <Box>
          <EQFaqBox category={faqCat}></EQFaqBox>
        </Box>

        {this.state.helpCode && (
          <EQHelpModal
            onClose={() => this.closeHelp()}
            code={this.state.helpCode}></EQHelpModal>
        )}
      </Fragment>
    )
  }
}

export default withRouter(EQBookStep_Person)
