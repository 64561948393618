import { Button, FormControl, Grid } from '@mui/material'
import { Redirect, withRouter } from 'react-router-dom'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Box from '@mui/material/Box'
import EQBusinessRules from '../../libs/EQBusinessRules'
import EQFaqBox from '../EQFaqBox'
import EQFloatingFormIcon from '../core/EQFloatingFormIcon'
import EQSelect from '../core/EQSelect'
import EQState from '../../services/EQState'
import { MenuItem } from 'material-ui'
import PersonOutlineIcon from '@mui/icons-material/PersonOutlineTwoTone'
import React from 'react'
import { WcTwoTone } from '@mui/icons-material'

class EQBookStep_GenderSelection extends React.Component {
  constructor(props) {
    super(props)
    if (!EQState.UserSelection.Gender) EQState.UserSelection.Gender = 'U'
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  handleGenderChange(event) {
    EQState.change(EQState.UserSelection, {
      Gender: event.target.value,
    })
  }

  goForward() {
    if (this.props.onGoForward) this.props.onGoForward()
  }

  goBack() {
    if (this.props.onGoBack) this.props.onGoBack()
  }

  getBgCode() {
    if (EQState.UserSelection.idTipologiaServizio == 100) {
      return 'EMERGENZA'
    } else if (EQState.UserSelection.idTipologiaServizio == 2) {
      return 'FAMILIARE'
    } else {
      return 'DOMICILIARE'
    }
  }

  render() {
    if (!EQState.UserSelection || !EQState.UserSelection.GruppoServizio) {
      return <Redirect to="/" />
    }

    let pname =
      EQState.UserSelection.SelectedRarentalRelation.nome +
      ' ' +
      EQState.UserSelection.SelectedRarentalRelation.cognome
    let cssBox = 'boxCeleste'
    if (
      EQBusinessRules.isEmergenza(EQState.UserSelection.idTipologiaServizio)
    ) {
      cssBox = 'boxCeleste EMERGENZA'
    }

    return (
      <Box
        mr={0}
        ml={0}>
        <Box
          pl={0}
          pr={0}>
          <p className="playFair normalWeight textCenter bookstep-start-label">
            Stai prenotando per
          </p>
          <div className="row">
            <div className="col1-icon">
              <PersonOutlineIcon />
            </div>
            <div className="col2-icon">
              <h3 className="textCenter patient-name">{pname}</h3>
            </div>
          </div>
          <div className="row">
            <div className="col1-icon"></div>
            <div className="col2-icon">
              <p
                className="playFair textCenter app-text"
                style={{ marginBottom: 0 }}>
                Preferisci assistente uomo o donna?
              </p>
            </div>
          </div>
          <Box className={cssBox}>
            <Box
              style={{
                marginLeft: '24px',
                marginRight: '24px',
                marginTop: '24px',
                marginBottom: '24px',
              }}>
              <EQFloatingFormIcon>
                <WcTwoTone
                  style={{
                    position: 'relative',
                    top: '-2px',
                    fontSize: '1.8rem',
                  }}
                />
              </EQFloatingFormIcon>
              <Box pl={2}>
                <FormControl
                  className="ml-10"
                  fullWidth>
                  <EQSelect
                    label="Seleziona la preferenza"
                    onChange={(e) => this.handleGenderChange(e)}
                    defaultValue={EQState.UserSelection.Gender}>
                    <MenuItem
                      key="U"
                      value="U">
                      Ok entrambi
                    </MenuItem>
                    <MenuItem
                      key="F"
                      value="F">
                      Femmina
                    </MenuItem>
                    <MenuItem
                      key="M"
                      value="M">
                      Maschio
                    </MenuItem>
                  </EQSelect>
                </FormControl>
                <p className="text-red">
                  Scegliere il genere potrebbe diminuire il numero di assistenti
                  disponibili.
                </p>
              </Box>
            </Box>
          </Box>
          <Grid
            className="book-navigation shrink"
            container
            mt={0}
            mb={0}>
            <Grid
              item
              xs={6}>
              <Button
                className="lnkButton lnkBack"
                startIcon={<ArrowBackIcon />}
                onClick={() => this.goBack()}>
                Indietro
              </Button>
            </Grid>
            <Grid
              item
              sx={{ textAlign: 'right' }}
              xs={6}>
              <a
                className={`buttonLink lnkForward  button-${this.getBgCode()}`}
                onClick={() => this.goForward()}>
                AVANTI
              </a>
            </Grid>
          </Grid>
          <Box>
            <EQFaqBox category="GenereProfessionista"></EQFaqBox>
          </Box>
        </Box>
      </Box>
    )
  }
}

export default withRouter(EQBookStep_GenderSelection)
