import * as React from 'react'

const SmokeNotAllowedIcon = () => (
  <svg
    viewBox="1.898 1.395 20 20.936"
    width={20}
    height={23}
    role="img">
    <path
      d="M 11.888 15.395 L 9.128 12.635 L 1.898 12.395 L 1.898 15.395 L 11.888 15.395 Z M 20.398 12.395 L 21.898 12.395 L 21.898 15.395 L 20.398 15.395 L 20.398 12.395 Z M 17.898 12.395 L 19.398 12.395 L 19.398 15.395 L 17.898 15.395 L 17.898 12.395 Z M 18.748 4.275 C 19.368 3.665 19.748 2.825 19.748 1.895 L 18.248 1.895 C 18.248 2.915 17.418 3.745 16.398 3.745 L 16.398 5.245 C 18.638 5.245 20.398 7.075 20.398 9.315 L 20.398 11.395 L 21.898 11.395 L 21.898 9.315 C 21.898 7.085 20.618 5.165 18.748 4.275 Z M 14.398 8.095 L 15.928 8.095 C 16.978 8.095 17.898 8.835 17.898 10.145 L 17.898 11.395 L 19.398 11.395 L 19.398 9.805 C 19.398 8.005 17.798 6.645 15.928 6.645 L 14.398 6.645 C 13.378 6.645 12.548 5.665 12.548 4.645 C 12.548 3.625 13.378 2.895 14.398 2.895 L 14.398 1.395 C 12.548 1.395 11.048 2.895 11.048 4.745 C 11.048 6.595 12.548 8.095 14.398 8.095 Z M 16.898 15.325 L 16.898 12.395 L 13.968 12.395 L 16.898 15.325 Z"
      style={{
        paintOrder: 'stroke markers',
      }}
    />
    <rect
      x={5.768}
      y={8.036}
      width={0.984}
      height={23.824}
      style={{
        fill: 'rgb(216, 216, 216)',
        stroke: 'rgb(255, 0, 0)',
      }}
      transform="matrix(0.722134, -0.691753, 0.676023, 0.737202, -5.75294, 2.833362)"
    />
  </svg>
)
export default SmokeNotAllowedIcon
