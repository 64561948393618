import { createTheme } from '@mui/material/styles'

const EQTheme = createTheme({
  palette: {
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
    secondary: {
      main: '#333333',
      contrastText: '#FFF',
    },
    emergenza: {
      main: '#FFCB00',
      contrastText: '#000',
    },
    domiciliare: {
      main: '#0099FF',
      contrastText: '#000',
    },
    familiare: {
      main: '#B3B0B0',
      contrastText: '#000',
    },
  },
})

export default EQTheme
