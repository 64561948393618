import './App.scss'

import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import { Container } from '@mui/material'
import EQService from './services/EQService'
import EQTheme from './components/EQTheme'
import PageBook from './pages/PageBook'
import PageCaregiver from './pages/PageCaregiver'
import PageChangePassword from './pages/PageChangePassword'
import PageChat from './pages/PageChat'
import PageCondizioni from './pages/editorial/PageCondizioni'
import PageContatti from './pages/editorial/PageContatti'
import PageCookiePolicy from './pages/editorial/PageCookiePolicy'
import PageFaq from './pages/editorial/PageFaq'
import PageHome from './pages/PageHome'
import PagePrivacy from './pages/editorial/PagePrivacy'
import PageReset from './pages/PageReset'
import PageScopriDiPiu from './pages/editorial/PageScopriDiPiu'
import PageServizi from './pages/editorial/PageServizi'
import PageUser from './pages/PageUser'
import { ThemeProvider } from '@mui/material/styles'
import { useEffect } from 'react'

function App() {
  useEffect(() => {
    EQService.relinkSessionId()
  })

  return (
    <div className="App">
      <Container
        disableGutters={true}
        fixed>
        <ThemeProvider
          children={EQTheme}
          theme={EQTheme}>
          <Router>
            <Switch>
              <Route path="/siamo-diversi">
                <PageScopriDiPiu />
              </Route>
              <Route path="/servizio-clienti">
                <PageContatti />
              </Route>
              <Route path="/i-nostri-servizi">
                <PageServizi />
              </Route>

              <Route path="/cookie-policy">
                <PageCookiePolicy />
              </Route>
              <Route path="/condizioni">
                <PageCondizioni />
              </Route>
              <Route path="/faq">
                <PageFaq />
              </Route>
              <Route path="/privacy">
                <PagePrivacy />
              </Route>
              <Route path="/caregiver">
                <PageCaregiver />
              </Route>
              <Route path="/book">
                <PageBook />
              </Route>
              <Route path="/user">
                <PageUser />
              </Route>
              <Route path="/services">
                <PageHome />
              </Route>
              <Route path="/change-password">
                <PageChangePassword />
              </Route>
              <Route path="/reply-msg">
                <PageChat />
              </Route>
              <Route path="/">
                <PageReset />
              </Route>
            </Switch>
          </Router>
        </ThemeProvider>

        {/* div for low level erro */}
        {
        <div
          id="ll_alert_div"
          style={{ display: 'none' }}>
          <div
            className="MuiPaper-root MuiPaper-elevation MuiPaper-elevation0 MuiAlert-root MuiAlert-standardError MuiAlert-standard undefined error-alert"
            role="alert">
            <div
              id="ll_alert_msg"
              className="MuiAlert-message">
              Error message
            </div>
          </div>
        </div>
        }

        {/* div for low level confirm */}
        <div
          id="ll_alert_div_confirm"
          style={{ display: 'none' }}>
          <div
            className="MuiPaper-root MuiPaper-elevation MuiPaper-elevation0 MuiAlert-root MuiAlert-standardConfirm MuiAlert-standard undefined error-confirm"
            role="alert">
            <div
              id="ll_alert_msg_confirm"
              className="MuiAlert-message">
              Error message
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default App
