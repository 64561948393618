export default class CF {
  static validate(cf) {
    if (!cf) return false
    var pattern =
      /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/
    if (cf.search(pattern) === -1) return false

    var validi, i, s, set1, set2, setpari, setdisp
    if (cf === '') return false
    cf = cf.toUpperCase()
    if (cf.length !== 16) return false
    validi = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    for (i = 0; i < 16; i++) {
      if (validi.indexOf(cf.charAt(i)) === -1) return false
    }
    set1 = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    set2 = 'ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ'
    setpari = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    setdisp = 'BAKPLCQDREVOSFTGUHMINJWZYX'
    s = 0
    for (i = 1; i <= 13; i += 2)
      s += setpari.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))))
    for (i = 0; i <= 14; i += 2)
      s += setdisp.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))))
    if (s % 26 !== cf.charCodeAt(15) - 'A'.charCodeAt(0)) return false

    return true
  }

  static birthDateFrom(codiceFiscale) {
    const MESI = {
      A: 0,
      B: 1,
      C: 2,
      D: 3,
      E: 4,
      H: 5,
      L: 6,
      M: 7,
      P: 8,
      R: 9,
      S: 10,
      T: 11,
    }
    let [anno, giorno] = [
      codiceFiscale.substring(6, 8),
      codiceFiscale.substring(9, 11),
    ]
    if (giorno > 40) {
      giorno -= 40
      giorno = parseInt('0' + giorno).toString()
    }
    const mm = codiceFiscale.charAt(8).toUpperCase()

    let result = {
      year: (anno < 20 ? '20' : '19') + anno,
      month: MESI[mm],
      day: giorno,
    }
    console.log('birthDateFrom', codiceFiscale, result)
    return result
  }
}
