import './EQLoginModal.scss'

import {
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

import CloseIcon from '@mui/icons-material/Close'
import Drawer from '@mui/material/Drawer'
import EQBusinessRules from '../../libs/EQBusinessRules'
import EQRegistrationModal from '../EQRegistrationModal'
import EQResetPassword from './EQResetPassword'
import EQService from '../../services/EQService'
import EQSquareCheckbox from '../core/EQSquareCheckbox'
import EQState from '../../services/EQState'
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone'
import React from 'react'
import TextField from '@mui/material/TextField'
import Utils from '../../libs/Utils'
import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone'
import { withRouter } from 'react-router-dom'

const style = {
  /*
    position: 'absolute',
    top: '0',
    right: '0',
    transform: 'translate(0, 1px)',
    overflowY:'scroll',
    maxHeight:'90%',
    borderRadius: '20px 0 0 20px',
    bgcolor: '#3681B1',
    boxShadow: 24,
    height:'100%',
    maxWidth: 800, */
  width: '100%',
  border: '0px solid #000',
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 0,
  outline: 'none',
}

class EQLoginModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      goToAfterLogin: null,
      UserSelection: EQState.UserSelection,
      username: '',
      password: '',
      passwordShown: false,
      rememberMe: false,
      showRegistration: false,
      showResetPassword: false,
    }
    this.goForward = this.goForward.bind(this)
  }

  /*
    reloadState(){
        console.log("reloading state")
        this.setState({
            UserSelection:EQState.UserSelection
        });
    }
    */

  componentDidMount() {
    //EQState.addListener(EQState.UserSelection, this);
    this.setState({ goToAfterLogin: this.props.goToAfterLogin })
  }

  componentWillUnmount() {
    //EQState.removeListener(EQState.UserSelection, this);
    document.body.style.overflow = 'unset'
  }

  closeModal() {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  updateInputValue(evt) {
    const val = evt.target.value
    const field = evt.target.id
    if (field === 'username') {
      this.setState({
        username: val,
      })
    } else {
      this.setState({
        password: val,
      })
    }
  }

  goForward() {
    EQState.change(EQState.UserSelection, { IsLogged: true })
    EQState.UserSelection._idSessioneWeb = EQService.sessionId()
    EQState.UserSelection._loggedTime = new Date()
    if (this.state.rememberMe) {
      EQState.UserSelection._rememberMe = true
    }
    EQState.persist(EQState.UserSelection)

    if (this.props.goToAfterLogin === 'book') {
      // bugid#8576 - servizi di consulenza
      let target = '/book/person'
      console.log(
        'isLogged, checking isServiceAdvisory',
        EQBusinessRules.isServiceAdvisory(EQState.UserSelection.GruppoServizio)
      )
      if (
        EQBusinessRules.isServiceAdvisory(EQState.UserSelection.GruppoServizio)
      ) {
        target = '/book/advisory'
      }

      document.body.style.overflow = 'unset'
      this.props.history.push({
        pathname: target,
      })
    } else {
      /*
            if(document.location.pathname=="" || document.location.pathname=="/") {
                document.location="/";
            } else {
                document.body.style.overflow = 'unset';
                this.closeModal();
            }
            */
      document.location.reload()
    }
  }

  doLogin() {
    if (this.state.username === '' || this.state.password === '') {
      Utils.notifyError('Specificare username e password')
      return
    }

    EQService.creaSessione().then(() => {
      EQService.login(this.state.username, this.state.password).then((m) => {
        if (m.statusString === 'KO') {
          Utils.notifyError('Login errato')
          return
        } else {
          this.goForward()
        }
      })
    })
  }

  togglePassword() {
    this.setState({
      passwordShown: !this.state.passwordShown,
    })
  }

  toggleRememberMe() {
    this.setState({
      rememberMe: !this.state.rememberMe,
    })
  }

  showRegistration() {
    try {
      window.gtag('event', 'clickRegistrazione', {})
    } catch (error) {}

    this.setState({
      showRegistration: true,
    })
  }

  showResetPassword() {
    this.setState({
      showResetPassword: true,
    })
  }

  closeResetPassword() {
    this.setState({
      showResetPassword: false,
    })
  }

  gotoCaregiverLogin() {
    document.location.href = '/caregiver/login'
  }

  render() {
    let content

    if (!this.state.showRegistration && !this.state.showResetPassword) {
      content = (
        <Box>
          <div className="linguetta">
            <span>
                Siamo
                <br />
                a Milano
                <br />e provincia
              </span>
          </div>
          <Box
            px={2}
            pt={8}
            sx={{ backgroundColor: '#0a6aa1' }}>
            <Typography
              px={3}
              mb={2}
              align="center"
              className="playFair"
              variant="h5"
              sx={{ fontSize: '15pt', color: '#fff' }}>
              Per aiutare meglio te e i tuoi cari abbiamo bisogno di
              riconoscerti
            </Typography>
            <Typography
              px={3}
              mb={2}
              align="center"
              className="playFair"
              variant="h5"
              sx={{ fontSize: '15pt', color: '#fff' }}>
              Chi sei?
            </Typography>

            <Box className="loginForm">
              <Box
                mt={3}
                className="login-box-menu-dx">
                <PersonOutlineTwoToneIcon style={{ marginRight: '5px' }} />
                <TextField
                  onChange={(evt) => this.updateInputValue(evt)}
                  fullWidth
                  id="username"
                  label="Username"
                  placeholder="username"
                  InputLabelProps={{ shrink: true }}></TextField>
              </Box>
              <Box
                my={2}
                className="login-box-menu-dx">
                <VpnKeyTwoToneIcon style={{ marginRight: '5px' }} />
                <TextField
                  className="inputPassword"
                  onChange={(evt) => this.updateInputValue(evt)}
                  type={this.state.passwordShown ? 'text' : 'password'}
                  fullWidth
                  id="password"
                  label="Password"
                  placeholder="****"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => this.togglePassword()}
                          onMouseDown={() => this.togglePassword()}>
                          {this.state.passwordShown ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}></TextField>
              </Box>

              <Grid container>
                <Grid
                  item
                  xs={6}>
                  <Box sx={{ color: '#fff', marginLeft: '25px' }}>
                    <FormGroup>
                      {/*<FormControlLabel control={<EQSquareCheckbox sx={{'&.Mui-checked': {color: '#fff',},}} />} label="Mostra password" onChange={() => this.togglePassword()} />*/}
                      <FormControlLabel
                        control={
                          <EQSquareCheckbox
                            sx={{ '&.Mui-checked': { color: '#fff' } }}
                          />
                        }
                        className="w-max"
                        label="Resta connesso"
                        onChange={() => this.toggleRememberMe()}
                      />
                    </FormGroup>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={5}>
                  <Box
                    mt={1}
                    textAlign={'right'}>
                    <a
                      className="text-white"
                      onClick={() => this.showResetPassword()}
                      href="#">
                      Password dimenticata
                    </a>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box
              pt={2}
              pb={6}
              sx={{ textAlign: 'center' }}>
              <button
                className="buttonWhite"
                onClick={() => this.doLogin()}>
                ACCEDI
              </button>
              <a
                onClick={() => this.gotoCaregiverLogin()}
                style={{ position: 'relative', top: '20px' }}
                className="text-white mb-20 text-underline cursor-pointer">
                Sei un professionista?
              </a>
            </Box>
          </Box>
          <Box
            p={2}
            sx={{ borderRadius: '20px 0 0 20px', backgroundColor: '#E3E3E3' }}
            className="gotoRegBox">
            <Typography
              pb={2}
              align="center"
              className="playFair"
              variant="h5"
              sx={{ fontWeight: 'bold', fontSize: '15pt', color: '#000' }}>
              Vuoi registrarti?
            </Typography>

            <Box
              pb={2}
              px={2}>
              <a
                onClick={() => this.showRegistration()}
                className="textCenter buttonBlue">
                REGISTRATI &Egrave; GRATIS
              </a>
            </Box>
            {/*
                        <Box pt={3} pb={3} textAlign={"center"}>
                            <a onClick={() => this.closeModal()} className="textCenter playFair"><u>Chiudi finestra</u></a>
                        </Box>
                        */}
          </Box>
        </Box>
      )
    } else if (this.state.showRegistration) {
      content = (
        <EQRegistrationModal goToAfterLogin="book"></EQRegistrationModal>
      )
    } else if (this.state.showResetPassword) {
      content = (
        <Box sx={{ backgroundColor: '#0a6aa1', borderRadius: '0 0 0 30px' }}>
          <EQResetPassword
            onBack={() => this.closeResetPassword()}></EQResetPassword>
        </Box>
      )
    }

    return (
      <Drawer
        id="accountMenu"
        anchor="right"
        variant="temporary"
        open={true}
        onClose={() => this.closeModal()}
        sx={{ width: '100%' }}>
        <Box
          sx={style}
          className="loginModal">
          <button
            onClick={() => this.closeModal()}
            className="iconClose clickable">
            <Box
              mt={1}
              mx={'auto'}>
              <CloseIcon></CloseIcon>
            </Box>
            <div>CHIUDI</div>
          </button>
          {content}
        </Box>
      </Drawer>
    )
  }
}

export default withRouter(EQLoginModal)
