import React from 'react'
import EQState from '../../services/EQState'
import EQLoading from '../EQLoading'
import Box from '@mui/material/Box'
import { Redirect } from 'react-router-dom'
import EQServiceBook from '../../services/EQServiceBook'
import { withRouter } from 'react-router-dom'
import PersonOutlineIcon from '@mui/icons-material/PersonOutlineTwoTone'
import EQBusinessRules from '../../libs/EQBusinessRules'

class EQBookStep_Do_Setup extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)

    if (
      EQBusinessRules.isServiceAdvisory(EQState.UserSelection.GruppoServizio)
    ) {
      // Aiuto alla famiglia
      // Puliamo una evetnuale prenotazione pendente
      EQServiceBook.eliminaEventualePrenotazionePendente().then((relimina) => {
        // Creiamo una nuova prenotazione
        let prenotazione = {
          idPaziente: EQState.UserSelection.SelectedRarentalRelation.idPaziente,
          indirizzo: '-', // non importante
          idTipoLuogo: 1, // non importante
          idComune: 1, // non importante
          idCap: 0, // non importante 
          commentoRichiedente: '',
          gender: 'U',
          emergenza: false,
        }
        EQServiceBook.creaPrenotazione(prenotazione).then((rprenotazione) => {
          EQState.change(EQState.UserSelection, {
            Reservation: rprenotazione,
            BookPage: `/book/dates`,
          })
          this.props.history.push({
            pathname: `/book/dates`,
          })
        })
      })
    } else {
      // Altri casi
      // Salviamo eventuali dati del paziente non ancora salvati
      EQServiceBook.editPaziente(
        EQState.UserSelection.SelectedRarentalRelation
      ).then((rpaziente) => {
        // Reimpostiamo le patologie del paziente
        EQServiceBook.setPatologie(
          EQState.UserSelection.SelectedRarentalRelation,
          EQState.UserSelection.Patologies
        ).then((rpatologie) => {
          // Puliamo una evetnuale prenotazione pendente
          EQServiceBook.eliminaEventualePrenotazionePendente().then(
            (relimina) => {
              // Creiamo una nuova prenotazione
              let prenotazione = {
                idPaziente:
                  EQState.UserSelection.SelectedRarentalRelation.idPaziente,
                indirizzo: EQState.UserSelection.Location.address,
                idTipoLuogo: 1,
                idComune: EQState.UserSelection.Location.city,
                idCap: EQState.UserSelection.Location.CAP.idCap,
                commentoRichiedente: EQState.UserSelection.Location.notes,
                gender: EQState.UserSelection.Gender,
                emergenza: EQBusinessRules.isEmergenza(
                  EQState.UserSelection.idTipologiaServizio
                ),
              }

              EQServiceBook.creaPrenotazione(prenotazione).then(
                (rprenotazione) => {
                  EQState.change(EQState.UserSelection, {
                    Reservation: rprenotazione,
                    BookPage: `/book/dates`,
                  })
                  this.props.history.push({
                    pathname: `/book/dates`,
                  })
                }
              )
            }
          )
        })
      })
    }
  }

  render() {
    if (!EQState.UserSelection || !EQState.UserSelection.GruppoServizio) {
      return <Redirect to="/" />
    }

    let pname =
      EQState.UserSelection.SelectedRarentalRelation.nome +
      ' ' +
      EQState.UserSelection.SelectedRarentalRelation.cognome

    return (
      <Box>
        <p className="playFair normalWeight textCenter bookstep-start-label">
          Stai prenotando per
        </p>
        <div className="row">
          <div className="col1-icon">
            <PersonOutlineIcon />
          </div>
          <div className="col2-icon">
            <h3 className="textCenter patient-name">{pname}</h3>
          </div>
        </div>
        <EQLoading
          type="boxBianco"
          message="Preparazione della prenotazione..."
        />
      </Box>
    )
  }
}

export default withRouter(EQBookStep_Do_Setup)
